<div class="col-md-12">
    <div class="card">
        <div class="header card-header-title">
            <h5 class="title">{{ title | translate }}</h5>
        </div>
        <div class="content">
            <div class="row">
                <div class="col-md-12 align-items-center justify-content-right" *ngxPermissionsOnly="'cudVisitorMedical'">
                    <button type="button"
                            class="btn btn-primary btn-fill"
                            (click)="utilVisitorPartsPathologiesModalsAdd.openModal(visitor?.id)">
                        <i class="fa fa-plus"></i> {{ 'VISITORS_MEDICAL.ADD_PATHOLOGY' | translate }}
                    </button>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12">
                    <ng-container *ngIf="!isLoading">
                        <ng-container *ngIf="hasData?.visitorPathologies">
                            <div class="overflow-auto pb-3">
                                <table class="table table-condensend table-striped">
                                    <thead>
                                        <tr>
                                            <th class="p-3 text-center-important">#</th>
                                            <th class="p-3 text-center-important" *ngxPermissionsOnly="'cudVisitorMedical'">
                                                {{ 'SHARED.ACTIONS' | translate }}
                                            </th>
                                            <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL.DATE' | translate }}</th>
                                            <ng-container *ngIf="!selfVisitor">
                                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL.VISITOR' | translate }}</th>
                                            </ng-container>
                                            <ng-container *ngFor="let field of objectKeys(fields)">
                                                <th class="p-3 text-center-important" *ngIf="fields[field]?.number">
                                                    {{ fields[field]?.title | translate }}
                                                </th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let visitorPathology of visitorPathologies?.data; let i = index">
                                            <td class="p-3 text-center-important">{{ i + 1 }}</td>
                                            <td class="p-3 text-center-important td-actions" *ngxPermissionsOnly="'cudVisitorMedical'">
                                                <button type="button"
                                                        class="btn btn-success btn-simple p-0"
                                                        title="{{ 'VISITORS_MEDICAL.EDIT_PATHOLOGY' | translate }}"
                                                        (click)="utilVisitorPartsPathologiesModalsAdd.openModal(visitor?.id, visitorPathology)"
                                                        appTooltip>
                                                    <i class="fa fa-edit"></i>
                                                </button>
                                                <button type="button"
                                                        class="btn btn-danger btn-simple ml-3 p-0"
                                                        title="{{ 'VISITORS_MEDICAL.DELETE_PATHOLOGY' | translate }}"
                                                        (click)="delete(visitorPathology)"
                                                        appTooltip>
                                                    <i class="fa fa-trash"></i>
                                                </button>
                                            </td>
                                            <td class="p-3 text-center-important">
                                                {{ visitorPathology?.date | localizedDate | ifEmpty: '-' }}
                                            </td>
                                            <ng-container *ngIf="!selfVisitor">
                                                <td class="p-3 text-center-important">
                                                    <a [routerLink]="['/visitor', visitorPathology?.vId, 'infirmary']"
                                                        class="text-primary">
                                                        {{ visitorPathology?.showName }}
                                                    </a>
                                                </td>
                                            </ng-container>
                                            <ng-container *ngFor="let field of objectKeys(fields)">
                                                <td class="p-3 text-center-important" *ngIf="fields[field]?.number">
                                                    <ng-container *ngIf="fields[field]?.type == 'bool'">
                                                        <app-icon-check-close
                                                            [value]="visitorPathology[field]"
                                                            [title]="getFieldComment(visitorPathology, field)"
                                                            [showTitle]="visitorPathology[field] ? true : false"></app-icon-check-close>
                                                    </ng-container>
                                                    <ng-container *ngIf="fields[field]?.type == 'string'">
                                                        <app-text-limit [text]="visitorPathology[field]" [title]="'REMARK'"></app-text-limit>
                                                    </ng-container>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="align-items-center justify-content-center" *ngIf="visitorPathologies?.last_page > 1">
                                <app-pagination [data]="visitorPathologies" (switchPage)="switchPage($event)"></app-pagination>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!hasData?.visitorPathologies">
                            <p>
                                {{ 'VISITORS_MEDICAL.NO_PATHOLOGY_FOUND' | translate }}
                            </p>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="isLoading">
                        <p>
                            <app-loading-spinner></app-loading-spinner>
                        </p>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<app-util-visitor-parts-medical-infirmary-pathology-add
            [type]="type"
            (addPathologyEvent)="addPathology()" #utilVisitorPartsPathologiesModalsAdd></app-util-visitor-parts-medical-infirmary-pathology-add>