import { Component, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { concat, Observable, of, Subject } from 'rxjs';
import { MiscService, VisitorService, VisitorMeasureService } from '@services/index';
import { Globals } from '@app/app.globals';

@Component({
    selector: 'app-util-visitor-parts-measure-modals-add',
    templateUrl: './add.component.html',
})
export class UtilVisitorPartsMeasureModalsAddComponent implements OnInit {
    @Output() addMeasureEvent = new EventEmitter<any>();

    isSubmitting = false;
    wrongDate = false;
    modal = "#addMeasureModal";
    cud = "add";
    pickerConfig: any;
    visitorMeasure: any;
    visitorId: number;
    visitors: Observable<any[]>;
    visitorsLoading = false;
    visitorsInput = new Subject();
    forbiddenDates = [];
    addForm = this.fb.group({
        id: null,
        visitor_id: [null, Validators.required],
        date: [null, Validators.required],
        blood_pressure: null,
        heartbeat: null,
        blood_sugar: null,
        saturation: null,
        temperature: null,
        weight: null
    });
    hasData = {
        visitorMeasure: false
    };

    @ViewChild('datePicker') datePicker;

    constructor(private cdref: ChangeDetectorRef,
                private fb: UntypedFormBuilder,
                private miscService: MiscService,
                private visitorService: VisitorService,
                private visitorMeasureService: VisitorMeasureService,
                public globals: Globals) {
        this.miscService.setPageTitle("TITLES.INFIRMARY_SETTINGS");
    }

    ngOnInit() {
        this.pickerConfig = JSON.parse(JSON.stringify(this.globals.pickerConfig));
        this.cdref.detectChanges();
    }

    reset() {
        this.addForm.reset();
        this.visitorId = null;
        this.cud = "add";
    }
    
    openModal(visitorId = null, visitorMeasure = null) {
        if(this.miscService.can("cudVisitorMeasure")) {
            this.visitorMeasure = visitorMeasure;
            this.hasData.visitorMeasure = this.miscService.checkDataLength(this.visitorMeasure);
            this.reset();
            this.visitorId = visitorId;
            if(this.visitorId) {
                this.addForm.get('visitor_id').patchValue(visitorId);
                this.getForbiddenDates(true);
            }
            else {
                this.loadVisitors();
            }
            $(this.modal).modal('show');
        }
    }

    getForbiddenDates(setup = false) {
        let data = {
            visitor_id: this.addForm.value.visitor_id,
            visitor_measures_id: this.hasData.visitorMeasure ? [this.visitorMeasure.id] : null
        };
        this.visitorMeasureService.getDatesByVisitor(data).subscribe(data => {
            this.forbiddenDates = data;
            if(setup) {
                this.resetDate();
            }
            else {
                this.checkDate(this.addForm.value.date, "date");
            }
            this.checkEdit();
        });
    }

    checkEdit() {
        if(this.hasData.visitorMeasure) {
            this.cud = "edit";
            this.addForm.patchValue(this.visitorMeasure);
            if(this.visitorMeasure.date) {
                this.datePicker.setDate(this.visitorMeasure.date);
            }
        }
    }

    goToAddVisitorUrl() {
        $(this.modal).modal('hide');
        this.miscService.goToUrlDelay(['/visitor/add']);
    }

    loadVisitors() {
        this.visitors = concat(
            of([]),
            this.visitorsInput.pipe(
                distinctUntilChanged(),
                tap(() => this.visitorsLoading = true),
                debounceTime(500),
                switchMap(term => this.visitorService.getAll({term: term, limit: 5, filter: true}).pipe(
                    catchError(() => of([])),
                    tap(() => this.visitorsLoading = false),
                ))
            )
        );
    }

    dateChanged(date, field) {
        if(date) {
            this.checkDate(date, field);
        }
        else {
            this.resetField(field);
        }
	}

    checkDate(date, field) {
        if(!this.miscService.checkDateInArray(date, this.forbiddenDates)) {
            this.addForm.get(field).patchValue(date);
            this.wrongDate = false;
        }
        else {
            this.miscService.showMsg({ msg: "DATE_ALREADY_USED_FOR_OTHER_MEASURE_FOR_VISITOR", status: "warning", ignoreLastNotif: true });
            this.resetField(field);
            this.wrongDate = true;
        }
    }

    resetDate() {
        this.datePicker.resetDateNow();
    }

    resetField(field) {
        this.addForm.get(field).reset();
    }

    submit() {
        this.isSubmitting = true;
        let data = this.addForm.value;
        if(this.cud == "add") {
            this.create(data);
        }
        else {
            this.update(data);
        }
    }

    create(data) {
        this.visitorMeasureService.create(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_SETTING_CREATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addMeasureEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_SETTING_NOT_CREATED" });
        });
    }

    update(data) {
        this.visitorMeasureService.update(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_SETTING_UPDATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addMeasureEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_SETTING_NOT_UPDATED" });
        });
    }
}
