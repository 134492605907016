import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { StatisticsService } from '@services/index';
import { environment } from '@env/environment';

@Component({
  selector: 'app-util-statistics-export',
  templateUrl: './export.component.html'
})
export class UtilStatisticsExportComponent implements OnInit {
    isValid = true;
    isExporting = false;
    exportModal = "#exportModal";
    docUrl = environment.baseUrl;
    filter: any;
    exportForm = this.fb.group({
        date: [null, Validators.required],
        type: [null, Validators.required],
        users: false,
        visitors: false,
        visitor_daily_entries: true,
        visitor_appointments: false,
        visitor_infirmary_entries: false,
        visitor_treatments: false,
        visitor_medical_consultations: false,
        visitor_measures: false
    });

    constructor(private fb: UntypedFormBuilder,
                private statisticsService: StatisticsService) {}

    ngOnInit() {

    }

    openModal(filter) {
        this.filter = filter;
        this.exportForm.reset({
            date: this.filter !== undefined && this.filter.date !== undefined ? this.filter.date : null,
            type: this.filter !== undefined && this.filter.type !== undefined ? this.filter.type : null,
            users: false,
            visitors: false,
            visitor_daily_entries: true,
            visitor_appointments: false,
            visitor_infirmary_entries: false,
            visitor_treatments: false,
            visitor_medical_consultations: false,
            visitor_measures: false
        });
        this.checkExport();
        $(this.exportModal).modal('show');
    }

    checkExport() {
        let data = this.exportForm.value;
        let isValid = false;
        Object.keys(data).forEach(key => {
            if(data[key]) {
                isValid = true;
            }
        });

        this.isValid = isValid;
    }

    submit() {
        this.isExporting = true;
        let data = this.exportForm.value;
        this.statisticsService.export(data).subscribe(data => {
            let url = this.docUrl + "file/" + data.name;
            var pdfLink = document.createElement("a");     // Obligé de créer un faux lien sinon ça ne marche pas sur Chrome
            pdfLink.setAttribute("id", "link-export-stats");
            pdfLink.href = url;
            pdfLink.target = "_blank";
            pdfLink.download = "export.csv";
            pdfLink.click();
            this.isExporting = false;
            $(this.exportModal).modal('hide');
        });
    }
}
