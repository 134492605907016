export class Address {
    street_number: string;
    route: string;
    locality: string;
    administrative_area_level_2: string;		// Ex. : Liège (province)
    administrative_area_level_1: string;		// Ex. : Wallonie
    country: string;
    postal_code: string;
    full_address: string;
}
