import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
    providedIn: 'root'
})
export class VisitorInfirmaryEntryService extends ApiService {
    getByVisitors(data: any) {
        return super.post('/visitors-infirmary-entry/visitors', data);
    }

    getDatesByVisitor(data: any) {
        return super.post('/visitors-infirmary-entry/visitors/dates', data);
    }

    getCountDayAndFuture(id: number) {
        return super.get("/visitors-infirmary-entry/count/day-future/" + id);
    }

    create(data: any) {
        return super.post('/visitors-infirmary-entry/create', data);
    }

    update(data: any) {
        return super.put('/visitors-infirmary-entry/update', data);
    }

    delete(id: number) {
        return super.delete('/visitors-infirmary-entry/' + id);
    }
}
