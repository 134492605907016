import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
    selector: '[appFormHasErrorClass]',
})
export class FormHasErrorClassDirective implements OnInit {

    @Input() appFormHasErrorClass: AbstractControl | AbstractControl[];

    constructor(
        private element: ElementRef,
        private render: Renderer2,
    ) {
    }

    ngOnInit(): void {

        if (!Array.isArray(this.appFormHasErrorClass)) {
            this.appFormHasErrorClass = [this.appFormHasErrorClass];
        }

        this.appFormHasErrorClass.forEach(control => {

            control.valueChanges.subscribe(() => {
                this.render.removeClass(this.element.nativeElement, 'has-error');
                if (control && control.invalid && (control.touched || control.dirty)) {
                    this.render.addClass(this.element.nativeElement, 'has-error');
                }
            });
        });
    }

}
