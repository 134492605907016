import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Globals } from '@app/app.globals';

@Component({
    selector: 'app-util-visitor-list-filter',
    templateUrl: './filter.component.html',
})
export class UtilVisitorListFilterComponent implements OnInit {
    @Output() filterEvent = new EventEmitter<any>();

    showFilters = true;
    datepickerConfig: any;
    searchForm = this.fb.group({
        number: null,
        lastname: null,
        firstname: null,
        date_of_birth: null,
        suspended: -1,
        archived: 0
    });

    constructor(public fb: UntypedFormBuilder,
                public globals: Globals) {}

    @ViewChild('datePicker') datePicker;

    ngOnInit() {
        this.datepickerConfig = JSON.parse(JSON.stringify(this.globals.dateOnlyPickerConfig));
    }

    toggleShowFilters() {
        this.showFilters = !this.showFilters;
    }

    dateChanged(evt, field) {
        if(evt) {
            let date = evt;
            this.searchForm.patchValue({
                [field]: date
            });
        }
        else {
            this.searchForm.get(field).reset();
        }
	}

    get filledForm() {
        let check = false;
        Object.keys(this.searchForm.controls).forEach(key => {
            if((!["suspended"].includes(key) && this.searchForm.value[key]) || (["suspended"].includes(key) && this.searchForm.value[key] != -1)) {
                check = true;
            }
        });

        return check;
        // return this.searchForm.dirty;    // works, but returns true only if value was never enter in any field, otherwise, always returns false
    }

    reset() {
        this.searchForm.reset({
            suspended: -1,
            archived: 0
        });
        this.resetDate();
        this.submit();
    }

    resetDate() {
        this.datePicker.resetDate();
    }

    submit() {
        let data = this.searchForm.value;
        this.filterEvent.emit(data);
    }
}
