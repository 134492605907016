<div aria-labelledby="suspensionsAddModalLabel" class="modal modal-big fade" id="suspensionsAddModal" role="dialog" tabindex="-1" style="display: none; z-index: 9999;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
                <h4 class="modal-title">
                    <ng-container *ngIf="cud == 'add'">
                        {{ "VISITORS_SUSPENSIONS.ADD_SUSPENSION" | translate }}
                    </ng-container>
                    <ng-container *ngIf="cud == 'edit'">
                        {{ "VISITORS_SUSPENSIONS.EDIT_SUSPENSION" | translate }}
                    </ng-container>
                </h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="addForm" #aForm="ngForm" class="form-horizontal" (submit)="submit()">
                    <div class="form-group">
                        <div class="col-md-3">
                            <label class="control-label control-label-sm" for="begin">
                                {{ "VISITORS_SUSPENSIONS.BEGIN" | translate }} <span class="star">*</span>
                            </label>
                        </div>
                        <div class="col-md-9">
                            <app-date-picker [datepickerConfig]="datepickerConfig"
                                            [id]="'begin'"
                                            [col]="null"
                                            [withHours]="true"
                                            (onDateChanged)="dateChanged($event, 'begin')"
                                            #datePickerBeginSuspension>
                            </app-date-picker>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-md-3">
                            <label class="control-label control-label-sm" for="end">
                                {{ "VISITORS_SUSPENSIONS.END" | translate }}
                            </label>
                        </div>
                        <div class="col-md-9">
                            <app-date-picker [datepickerConfig]="datepickerConfig"
                                            [id]="'end'"
                                            [col]="null"
                                            [withHours]="true"
                                            [hasResetButton]="true"
                                            (onDateChanged)="dateChanged($event, 'end')"
                                            #datePickerEndSuspension>
                            </app-date-picker>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-md-3">
                            <label class="control-label control-label-sm" for="reason">
                                {{ "VISITORS_SUSPENSIONS.REASON" | translate }}
                            </label>
                        </div>
                        <div class="col-md-9">
                            <textarea cols="30"
                                    rows="5"
                                    class="form-control input-sm"
                                    id="reason"
                                    formControlName="reason"></textarea>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="col-md-12">
                            <button type="submit"
                                    class="btn"
                                    [ngClass]="{'btn-default':isSubmitting, 'btn-success': !isSubmitting}"                                                        
                                    [disabled]="!aForm.form.valid || isSubmitting">
                                <i class="fa"
                                    [ngClass]="{'fa-gavel': !isSubmitting,
                                                'fa-spinner fa-spin': isSubmitting}"></i> 
                                <ng-container *ngIf="cud == 'add'">
                                    {{ "SHARED.ADD" | translate }}
                                </ng-container>
                                <ng-container *ngIf="cud == 'edit'">
                                    {{ "SHARED.EDIT" | translate }}
                                </ng-container>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>