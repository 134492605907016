<div class="row mt-3">
    <div class="col-md-12">
        <app-util-visitor-list-filter (filterEvent)="filterVisitors($event)"></app-util-visitor-list-filter>
    </div>
</div>
<div class="row mt-5">
    <div class="col-md-12 align-items-center justify-content-space-between">
        <h5 class="title mb-3">
            {{ 'TITLES.VISITORS' | translate }}
        </h5>
        <div>
            <a [routerLink]="['/visitor/add']" class="btn btn-primary btn-fill" *ngxPermissionsOnly="'cudVisitor'">
                <i class="fa fa-plus"></i> {{ 'VISITORS.ADD_VISITOR' | translate }}
            </a>
            <button type="button"
                    class="btn btn-success btn-fill ml-3"
                    (click)="export()"
                    [attr.disabled]="isExporting ? 'disabled' : null">
                <i class="fa"
                    [ngClass]="{'fa-download': !isExporting,
                                'fa-spinner fa-spin': isExporting}"></i> {{ 'VISITORS.EXPORT_VISITORS' | translate }}
            </button>
            <button type="button"
                    class="btn btn-warning btn-fill ml-3"
                    (click)="cancelAllVisitorSuspensions()"
                    [attr.disabled]="isExporting ? 'disabled' : null">
                <i class="fa"
                    [ngClass]="{'fa-hourglass-end': !isExporting,
                                'fa-spinner fa-spin': isExporting}"></i> {{ 'VISITORS_SUSPENSIONS.END_ALL_SUSPENSIONS' | translate }}
            </button>
        </div>
    </div>
</div>
<div class="row mt-5">
    <div class="col-md-12">
        <ng-container *ngIf="!isLoading">
            <ng-container *ngIf="hasData?.visitors">
                <table class="table table-condensend table-striped">
                    <thead>
                        <tr>
                            <th class="p-3 text-center-important">#</th>
                            <th class="p-3 text-center-important td-actions">{{ 'SHARED.ACTIONS' | translate }}</th>
                            <th class="p-3 text-center-important">{{ 'VISITORS.UNIQUE_CODE' | translate }}</th>
                            <th class="p-3 text-center-important">{{ 'VISITORS.FIRSTNAME' | translate }}</th>
                            <th class="p-3 text-center-important">{{ 'VISITORS.LASTNAME' | translate }}</th>
                            <th class="p-3 text-center-important">{{ 'VISITORS.DATE_OF_BIRTH' | translate }}</th>
                            <th class="p-3 text-center-important">{{ 'VISITORS.GENDER' | translate }}</th>
                            <th class="p-3 text-center-important">{{ 'VISITORS.COUNTRY' | translate }}</th>
                            <th class="p-3 text-center-important">{{ 'VISITORS.BEGIN_SUSPENSION' | translate }}</th>
                            <th class="p-3 text-center-important">{{ 'VISITORS.END_SUSPENSION' | translate }}</th>
                            <th class="p-3 text-center-important">{{ 'VISITORS.REASON_SUSPENSION' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let visitor of visitors?.data; let i = index">
                            <td class="p-3 text-center-important pointer" (click)="goToEntries(visitor?.id)">{{ i + 1 }}</td>
                            <td class="p-3 text-center-important td-actions">
                                <div class="dropdown">
                                    <button type="button"
                                            class="btn btn-xs btn-fill dropdown-toggle"
                                            [attr.id]="'dropdownMenu' + visitor?.id"
                                            data-toggle="dropdown">
                                        {{ 'SHARED.ACTIONS' | translate }} <span class="caret"></span>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-left dropdown-menu-carret-left"
                                        [attr.aria-labelledby]="'dropdownMenu' + visitor?.id">
                                        <li>
                                            <a [routerLink]="['/visitor', visitor?.id]">
                                                <i class="fa fa-link text-primary"></i> {{ 'VISITORS.SEE_PERSONAL_FILE' | translate }}
                                            </a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/visitor', visitor?.id, 'daily-entry']">
                                                <i class="fa fa-link text-primary"></i> {{ 'VISITORS.SEE_DAILY_ENTRY' | translate }}
                                            </a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/visitor', visitor?.id, 'infirmary']">
                                                <i class="fa fa-link text-primary"></i> {{ 'VISITORS.SEE_INFIRMARY' | translate }}
                                            </a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/visitor', visitor?.id, 'medical']">
                                                <i class="fa fa-link text-primary"></i> {{ 'VISITORS.SEE_MEDICAL_FILE' | translate }}
                                            </a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/visitor', visitor?.id, 'psycho']">
                                                <i class="fa fa-link text-primary"></i> {{ 'VISITORS.SEE_PSYCHOLOGICAL_FILE' | translate }}
                                            </a>
                                        </li>
                                        <ng-container *ngxPermissionsOnly="'cudVisitor'">
                                            <li>
                                                <a [routerLink]="['/visitor/edit', visitor?.id]">
                                                    <i class="fa fa-edit text-success"></i> {{ 'VISITORS.EDIT_VISITOR' | translate }}
                                                </a>
                                            </li>
                                            <li>
                                                <a href="javascript:;" (click)="utilVisitorModalsSuspensions.openModal(visitor)">
                                                    <i class="fa fa-gavel text-success"></i> {{ 'VISITORS_SUSPENSIONS.MANAGE_SUSPENSIONS' | translate }}
                                                </a>
                                            </li>
                                            <li>
                                                <a href="javascript:;" (click)="utilVisitorModalsAmu.openModal(visitor)">
                                                    <i class="fa fa-clock-o text-success"></i> {{ 'VISITORS.EDIT_AMU_DUE_DATE' | translate }}
                                                </a>
                                            </li>
                                            <li>
                                                <a href="javascript:;" (click)="archive(visitor)">
                                                    <ng-container *ngIf="!visitor?.isArchived">
                                                        <i class="fa fa-archive text-warning"></i> {{ 'VISITORS.ARCHIVE' | translate }}
                                                    </ng-container>
                                                    <ng-container *ngIf="visitor?.isArchived">
                                                        <i class="fa fa-archive text-success"></i> {{ 'VISITORS.UNARCHIVE' | translate }}
                                                    </ng-container>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="javascript:;" (click)="delete(visitor)">
                                                    <i class="fa fa-trash text-danger"></i> {{ 'SHARED.DELETE' | translate }}
                                                </a>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </td>
                            <td class="p-3 text-center-important pointer" (click)="goToEntries(visitor?.id)">{{ visitor?.number | ifEmpty: '-' }}</td>
                            <td class="p-3 text-center-important pointer" (click)="goToEntries(visitor?.id)">{{ visitor?.firstname | ifEmpty: '-' }}</td>
                            <td class="p-3 text-center-important pointer" (click)="goToEntries(visitor?.id)">{{ visitor?.lastname | ifEmpty: '-' }}</td>
                            <td class="p-3 text-center-important pointer" (click)="goToEntries(visitor?.id)">{{ visitor?.date_of_birth | localizedDate: 'mediumDate' | ifEmpty: '-' }}</td>
                            <td class="p-3 text-center-important pointer" (click)="goToEntries(visitor?.id)">{{ visitor?.gender_name | ifEmpty: '-' }}</td>
                            <td class="p-3 text-center-important pointer" (click)="goToEntries(visitor?.id)">{{ visitor?.country_name | ifEmpty: '-' }}</td>
                            <td class="p-3 text-center-important pointer" (click)="goToEntries(visitor?.id)">
                                <ng-container *ngIf="visitor?.isSuspended">
                                    <span class="text-danger">
                                        {{ visitor?.begin_suspension | localizedDate: 'mediumDate' }}
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="!visitor?.isSuspended">
                                    -
                                </ng-container>
                            </td>
                            <td class="p-3 text-center-important pointer" (click)="goToEntries(visitor?.id)">
                                <ng-container *ngIf="visitor?.isSuspended">
                                    <span class="text-danger">
                                        {{ visitor?.end_suspension | localizedDate: 'mediumDate' | ifEmpty: textVisitorPermanent }}
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="!visitor?.isSuspended">
                                    -
                                </ng-container>
                            </td>
                            <td class="p-3 text-center-important pointer" (click)="goToEntries(visitor?.id)">
                                <ng-container *ngIf="visitor?.isSuspended">
                                    <span class="text-danger">
                                        <app-text-limit [text]="visitor?.reason_suspension" [title]="'REASON_SUSPENSION'"></app-text-limit>
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="!visitor?.isSuspended">
                                    -
                                </ng-container>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="align-items-center justify-content-center" *ngIf="visitors?.last_page > 1">
                    <app-pagination [data]="visitors" (switchPage)="switchPage($event)"></app-pagination>
                </div>
            </ng-container>
            <ng-container *ngIf="!hasData?.visitors">
                <ng-container *ngIf="hasFiltered">
                    <p>{{ 'VISITORS.NO_VISITOR_FOUND' | translate }}</p>
                </ng-container>
                <ng-container *ngIf="!hasFiltered">
                    <p>{{ 'VISITORS.PLEASE_SEARCH_VISITOR' | translate }}</p>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="isLoading">
            <p>
                <app-loading-spinner></app-loading-spinner>
            </p>
        </ng-container>
    </div>
</div>
<app-util-visitor-modals-suspensions (manageEvent)="getVisitors()" #utilVisitorModalsSuspensions></app-util-visitor-modals-suspensions>
<app-util-visitor-modals-amu (amuEvent)="getVisitors()" #utilVisitorModalsAmu></app-util-visitor-modals-amu>