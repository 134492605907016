<div aria-labelledby="addPsychoEntryModalLabel" class="modal modal-big fade" id="addPsychoEntryModal" role="dialog" tabindex="-1" style="display: none; z-index: 9999;">
    <div class="modal-dialog width-900" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
                <h4 class="modal-title">
                    <ng-container *ngIf="cud == 'add'">
                        {{ "VISITORS_PSYCHO.ADD_ENTRY" | translate }}
                    </ng-container>
                    <ng-container *ngIf="cud == 'edit'">
                        {{ "VISITORS_PSYCHO.EDIT_ENTRY" | translate }}
                    </ng-container>
                </h4>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="!isLoading">
                    <form [formGroup]="addForm" #aForm="ngForm" class="form-horizontal" (submit)="submit()">
                        <fieldset>
                            <legend>{{ 'VISITORS.GENERAL_INFORMATION' | translate }}</legend>
                            <div class="form-group" *ngIf="!visitorId && cud == 'add'">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="visitor_id" >
                                        {{ 'VISITORS_PSYCHO.VISITOR' | translate }} <span class="star">*</span>
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <ng-select class="ng-select-input-sm"
                                            id="visitor_id"
                                            formControlName="visitor_id" 
                                            bindValue="id"
                                            bindLabel="showName"
                                            [items]="visitors | async"
                                            [hideSelected]="true"
                                            [loading]="visitorsLoading"
                                            [typeahead]="visitorsInput"
                                            loadingText="{{ 'SHARED.LOADING' | translate }}"
                                            typeToSearchText="{{ 'FILTER.SEARCH_BY_UNIQUE_CODE_FIRSTNAME_LASTNAME' | translate }}"
                                            notFoundText="{{ 'VISITORS.NO_VISITOR_FOUND' | translate }}">
                                        <ng-template ng-footer-tmp>
                                            <p class="add-choices pointer m-0 mt-2 mb-2">
                                                <a href="javascript:void(0)" class="text-primary" (click)="goToAddVisitorUrl()">
                                                    {{ 'VISITORS.ADD_VISITOR' | translate }} <i class="fa fa-plus text-success"></i>
                                                </a>
                                            </p>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="date">
                                        {{ "VISITORS_PSYCHO.DATE" | translate }} <span class="star">*</span>
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <app-date-picker [datepickerConfig]="pickerConfig"
                                                    [id]="'date'"
                                                    [col]="null"
                                                    [withHours]="true"
                                                    (onDateChanged)="dateChanged($event, 'date')"
                                                    #datePicker>
                                    </app-date-picker>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>{{ 'VISITORS.MISCELLANEOUS' | translate }}</legend>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="interview_type">
                                        {{ 'VISITORS_PSYCHO.INTERVIEW_TYPE' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <select class="form-control input-sm" id="interview_type" formControlName="interview_type">
                                        <option [value]="null">-</option>
                                        <option value="formal">{{ 'VISITORS_PSYCHO.FORMAL' | translate }}</option>
                                        <option value="informal">{{ 'VISITORS_PSYCHO.INFORMAL' | translate }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="help_type_id">
                                        {{ 'VISITORS_PSYCHO.HELP_TYPE' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <app-ng-select-add-multiple
                                                            [choiceGroup]="getChoiceGroup('entr_informel')"
                                                            [items]="entrInformels"
                                                            [id]="'help_type_ids'"
                                                            [selectedId]="addForm?.value?.help_type_ids"
                                                            [multiple]="true"
                                                            (addChoicesEvent)="addChoices('entrInformels', $event)"
                                                            (changeEvent)="changeChoice('help_type_ids', $event)"
                                                            #ngSelectAddMultiple></app-ng-select-add-multiple>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="orientation_psy_lf">
                                        {{ 'VISITORS_PSYCHO.ORIENTATION_PSY_LF' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <input type="text"
                                            class="form-control input-sm"
                                            id="orientation_psy_lf"
                                            formControlName="orientation_psy_lf" />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="asker_id">
                                        {{ 'VISITORS_PSYCHO.ASKER' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <app-ng-select-add-multiple
                                                        [choiceGroup]="getChoiceGroup('dde_consult_psy')"
                                                        [items]="ddeConsultPsys"
                                                        [id]="'asker_id'"
                                                        [selectedId]="addForm?.value?.asker_id"
                                                        (addChoicesEvent)="addChoices('ddeConsultPsys', $event)"
                                                        (changeEvent)="changeChoice('asker_id', $event)"></app-ng-select-add-multiple>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="intervision">
                                        {{ 'VISITORS_PSYCHO.INTERVISION' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <input type="text"
                                            class="form-control"
                                            id="intervision"
                                            formControlName="intervision" />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="observations">
                                        {{ 'VISITORS_PSYCHO.OBSERVATIONS' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <textarea cols="30"
                                            rows="5"
                                            class="form-control"
                                            id="observations"
                                            formControlName="observations"></textarea>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm">
                                        {{ "VISITORS_PSYCHO.INFORMATION" | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <div class="row m-0">
                                        <div class="col-md-4" style="padding-left: 6px;">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="consultation"
                                                        formControlName="consultation" />
                                                <label for="consultation">
                                                    {{ "VISITORS_PSYCHO.CONSULTATION" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="accomp_procedure"
                                                        formControlName="accomp_procedure" />
                                                <label for="accomp_procedure">
                                                    {{ "VISITORS_PSYCHO.ACCOMP_PROCEDURE" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="home_hospital_visit"
                                                        formControlName="home_hospital_visit" />
                                                <label for="home_hospital_visit">
                                                    {{ "VISITORS_PSYCHO.HOME_HOSPITAL_VISIT" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="user_concertation"
                                                        formControlName="user_concertation" />
                                                <label for="user_concertation">
                                                    {{ "VISITORS_PSYCHO.USER_CONCERTATION" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <div class="form-group">
                            <div class="col-md-12">
                                <button type="submit"
                                        class="btn"
                                        [ngClass]="{'btn-default':isSubmitting, 'btn-success': !isSubmitting}"                                                        
                                        [disabled]="!aForm.form.valid || isSubmitting || wrongDate">
                                    <ng-container *ngIf="cud == 'add'">
                                        <i class="fa"
                                            [ngClass]="{'fa-plus': !isSubmitting,
                                                        'fa-spinner fa-spin': isSubmitting}"></i> {{ "SHARED.ADD" | translate }}
                                    </ng-container>
                                    <ng-container *ngIf="cud == 'edit'">
                                        <i class="fa"
                                            [ngClass]="{'fa-edit': !isSubmitting,
                                                        'fa-spinner fa-spin': isSubmitting}"></i> {{ "SHARED.EDIT" | translate }}
                                    </ng-container>
                                </button>
                            </div>
                        </div>
                    </form>
                </ng-container>
                <ng-container *ngIf="isLoading">
                    <app-loading-spinner></app-loading-spinner>
                </ng-container>
            </div>
        </div>
    </div>
</div>