<div aria-labelledby="addStatModalLabel" class="modal modal-big fade" id="addStatModal" role="dialog" tabindex="-1" style="display: none; z-index: 9999;">
    <div class="modal-dialog width-900" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
                <h4 class="modal-title">
                    <ng-container *ngIf="cud == 'add'">
                        {{ "VISITORS_MEDICAL_STAT.ADD_STAT" | translate }}
                    </ng-container>
                    <ng-container *ngIf="cud == 'edit'">
                        {{ "VISITORS_MEDICAL_STAT.EDIT_STAT" | translate }}
                    </ng-container>
                </h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="addForm" #aForm="ngForm" class="form-horizontal" (submit)="submit()">
                    <fieldset>
                        <legend>{{ "VISITORS.GENERAL_INFORMATION" | translate }}</legend>
                        <div class="form-group" *ngIf="!visitorId && cud == 'add'">
                            <div class="col-md-3">
                                <label class="control-label control-label-sm" for="visitor_id" >
                                    {{ 'VISITORS_MEDICAL_STAT.VISITOR' | translate }} <span class="star">*</span>
                                </label>
                            </div>
                            <div class="col-md-9">
                                <app-ng-select-add-visitor
                                    [canAddVisitors]="true"
                                    [multiple]="false"
                                    (changeEvent)="changeVisitor($event)"
                                    (goToAddVisitorUrlEvent)="goToAddVisitorUrl()"></app-ng-select-add-visitor>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-md-3">
                                <label class="control-label control-label-sm" for="date">
                                    {{ "VISITORS_MEDICAL_STAT.DATE" | translate }} <span class="star">*</span>
                                </label>
                            </div>
                            <div class="col-md-9">
                                <app-date-picker [datepickerConfig]="pickerConfig"
                                                [id]="'date'"
                                                [col]="null"
                                                [withHours]="true"
                                                (onDateChanged)="dateChanged($event, 'date')"
                                                #datePicker>
                                </app-date-picker>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-md-3">
                                <label class="control-label control-label-sm">
                                    {{ "VISITORS_MEDICAL_STAT.INFORMATION" | translate }}
                                </label>
                            </div>
                            <div class="col-md-9">
                                <div class="row m-0">
                                    <div class="col-md-4" style="padding-left: 6px;">
                                        <div class="checkbox">
                                            <input type="checkbox"
                                                    class="form-control"
                                                    id="consultation"
                                                    formControlName="consultation" />
                                            <label for="consultation">
                                                {{ "VISITORS_MEDICAL_STAT.CONSULTATION" | translate }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>{{ "VISITORS.SERVICES" | translate }}</legend>
                        <div class="form-group">
                            <div class="col-md-3">
                                <label class="control-label control-label-sm">
                                    {{ "VISITORS_MEDICAL_STAT.INFORMATION" | translate }}
                                </label>
                            </div>
                            <div class="col-md-9">
                                <div class="row m-0">
                                    <div class="col-md-4 align-items-center justify-content-left" style="padding-left: 6px;">
                                        <div class="checkbox">
                                            <input type="checkbox"
                                                    class="form-control"
                                                    id="concertation"
                                                    formControlName="concertation" />
                                            <label for="concertation">
                                                {{ "VISITORS_MEDICAL_STAT.CONCERTATION" | translate }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="checkbox">
                                            <input type="checkbox"
                                                    class="form-control"
                                                    id="administrative"
                                                    formControlName="administrative" />
                                            <label for="administrative">
                                                {{ "VISITORS_MEDICAL_STAT.ADMINISTRATIVE" | translate }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="checkbox">
                                            <input type="checkbox"
                                                    class="form-control"
                                                    id="cm"
                                                    formControlName="cm" />
                                            <label for="cm">
                                                {{ "VISITORS_MEDICAL_STAT.CM" | translate }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="checkbox">
                                            <input type="checkbox"
                                                    class="form-control"
                                                    id="network"
                                                    formControlName="network" />
                                            <label for="network">
                                                {{ "VISITORS_MEDICAL_STAT.NETWORK" | translate }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="checkbox">
                                            <input type="checkbox"
                                                    class="form-control"
                                                    id="followed_up_file"
                                                    formControlName="followed_up_file" />
                                            <label for="followed_up_file">
                                                {{ "VISITORS_MEDICAL_STAT.FOLLOWED_UP_FILE" | translate }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>{{ "VISITORS_DAILY_ENTRIES.OTHER" | translate }}</legend>
                        <div class="form-group">
                            <div class="col-md-3">
                                <label class="control-label control-label-sm" for="other">
                                    {{ "VISITORS_DAILY_ENTRIES.OTHER" | translate }}
                                </label>
                            </div>
                            <div class="col-md-9">
                                <input type="text"
                                        class="form-control input-sm"
                                        id="other"
                                        formControlName="other">
                            </div>
                        </div>
                    </fieldset>

                    <div class="form-group">
                        <div class="col-md-12">
                            <button type="submit"
                                    class="btn"
                                    [ngClass]="{'btn-default':isSubmitting, 'btn-success': !isSubmitting}"                                                        
                                    [disabled]="!aForm.form.valid || isSubmitting">
                                <ng-container *ngIf="cud == 'add'">
                                    <i class="fa"
                                        [ngClass]="{'fa-plus': !isSubmitting,
                                                    'fa-spinner fa-spin': isSubmitting}"></i> {{ "SHARED.ADD" | translate }}
                                </ng-container>
                                <ng-container *ngIf="cud == 'edit'">
                                    <i class="fa"
                                        [ngClass]="{'fa-edit': !isSubmitting,
                                                    'fa-spinner fa-spin': isSubmitting}"></i> {{ "SHARED.EDIT" | translate }}
                                </ng-container>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>