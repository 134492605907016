import { Component, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { concat, Observable, of, Subject } from 'rxjs';
import { MiscService, ChoiceGroupService, VisitorService, VisitorInfirmaryEntryService } from '@services/index';
import { Globals } from '@app/app.globals';

@Component({
    selector: 'app-util-visitor-parts-infirmary-entry-modals-add',
    templateUrl: './add.component.html',
})
export class UtilVisitorPartsInfirmaryEntryModalsAddComponent implements OnInit {
    @Output() addInfirmaryEntryEvent = new EventEmitter<any>();

    isLoading = false;
    isLoadingParasites = false;
    isLoadingOrientations = false;
    isSubmitting = false;
    wrongDate = false;
    modal = "#addInfirmaryEntryModal";
    cud = "add";
    pickerConfig: any;
    parasites: any;
    orientations: any;
    visitorInfirmaryEntry: any;
    visitorId: number;
    visitors: Observable<any[]>;
    visitorsLoading = false;
    visitorsInput = new Subject();
    forbiddenDates = [];
    addForm = this.fb.group({
        id: null,
        visitor_id: [null, Validators.required],
        date: [null, Validators.required],
        shower_inf: null,
        footcare: null,
        bandage: null,
        drugstore: null,
        psychological_treatment: null,
        somatic_treatment: null,
        settings: null,
        parasite_ids: null,
        pregnancy_test: null,
        orientation_id: null,
        maintenance: null,
        support: null,
        procedures: null,
        health_promotion: null,
        syringe_exchange: null,
        observations: null
    });
    hasData = {
        visitorInfirmaryEntry: false
    };

    @ViewChild('datePicker') datePicker;

    constructor(private cdref: ChangeDetectorRef,
                private fb: UntypedFormBuilder,
                private miscService: MiscService,
                private choiceGroupService: ChoiceGroupService,
                private visitorService: VisitorService,
                private visitorInfirmaryEntryService: VisitorInfirmaryEntryService,
                public globals: Globals) {}

    ngOnInit() {
        this.pickerConfig = JSON.parse(JSON.stringify(this.globals.pickerConfig));
        this.cdref.detectChanges();
    }

    reset() {
        this.addForm.reset();
        this.visitorId = null;
        this.cud = "add";
    }
    
    openModal(visitorId = null, visitorInfirmaryEntry = null) {
        if(this.miscService.checkEnvPermission("cudVisitorInfirmaryEntry")) {
            this.isLoading = true;
            this.isLoadingParasites = true;
            this.isLoadingOrientations = true;
            this.visitorInfirmaryEntry = visitorInfirmaryEntry;
            this.hasData.visitorInfirmaryEntry = this.miscService.checkDataLength(this.visitorInfirmaryEntry);
            this.reset();
            this.visitorId = visitorId;
            if(this.visitorId) {
                this.addForm.get('visitor_id').patchValue(visitorId);
                this.getForbiddenDates(true);
            }
            else {
                this.loadVisitors();
            }
            this.choiceGroupService.getByKey('parasites').subscribe(data => {
                this.parasites = data;
                this.isLoadingParasites = false;
            });
            this.choiceGroupService.getByKey('orientations').subscribe(data => {
                this.orientations = data;
                this.isLoadingOrientations = false;
            });
            this.isLoading = false;
            $(this.modal).modal('show');
        }
    }

    getForbiddenDates(setup = false) {
        let data = {
            visitor_id: this.addForm.value.visitor_id,
            visitor_infirmary_entries_id: this.hasData.visitorInfirmaryEntry ? [this.visitorInfirmaryEntry.id] : null
        };
        this.visitorInfirmaryEntryService.getDatesByVisitor(data).subscribe(data => {
            this.forbiddenDates = data;
            if(setup) {
                this.resetDate();
            }
            else {
                this.checkDate(this.addForm.value.date, "date");
            }
            this.checkEdit();
        });
    }

    checkEdit() {
        if(this.hasData.visitorInfirmaryEntry) {
            this.cud = "edit";
            this.addForm.patchValue(this.visitorInfirmaryEntry);
            if(this.visitorInfirmaryEntry.date) {
                this.datePicker.setDate(this.visitorInfirmaryEntry.date);
            }
            if(this.miscService.checkDataLength(this.visitorInfirmaryEntry.visitorFkParasites)) {
                this.addParasites(this.visitorInfirmaryEntry.visitorFkParasites);
            }
            if(this.visitorInfirmaryEntry.orientation_id) {
                this.addForm.get('orientation_id').patchValue(this.visitorInfirmaryEntry.orientation_id);
            }
        }
    }

    addParasites(visitorFkParasites) {
        let arr = [];
        visitorFkParasites.forEach(vfp => {
            arr.push(vfp.parasite_id);
        });
        this.addForm.get('parasite_ids').patchValue(arr);
    }

    changeVisitor() {
        this.getForbiddenDates(false);
    }

    goToAddVisitorUrl() {
        $(this.modal).modal('hide');
        this.miscService.goToUrlDelay(['/visitor/add']);
    }

    addChoices(choiceGroup, choices) {
        choices.forEach(choice => {
            this[choiceGroup].choices = [...this[choiceGroup].choices, choice];
        });
    }

    getChoice(value) {
        if(value) {
            if(value.constructor !== Array) {   // If object
                return value.id;
            }
            else {
                let arrIds = [];
                value.forEach(val => {
                    arrIds.push(val.id);
                });
                return arrIds;
            }
        }

        return null;
    }

    changeChoice(field, value) {
        let val = this.getChoice(value);
        this.addForm.get(field).patchValue(val);
    }

    loadVisitors() {
        this.visitors = concat(
            of([]),
            this.visitorsInput.pipe(
                distinctUntilChanged(),
                tap(() => this.visitorsLoading = true),
                debounceTime(500),
                switchMap(term => this.visitorService.getAll({term: term, limit: 5, filter: true}).pipe(
                    catchError(() => of([])),
                    tap(() => this.visitorsLoading = false),
                ))
            )
        );
    }

    dateChanged(date, field) {
        if(date) {
            this.checkDate(date, field);
        }
        else {
            this.resetField(field);
        }
	}

    checkDate(date, field) {
        if(!this.miscService.checkDateInArray(date, this.forbiddenDates)) {
            this.addForm.get(field).patchValue(date);
            this.wrongDate = false;
        }
        else {
            this.miscService.showMsg({ msg: "DATE_ALREADY_USED_FOR_OTHER_INFIRMARY_ENTRY_FOR_VISITOR", status: "warning", ignoreLastNotif: true });
            this.resetField(field);
            this.wrongDate = true;
        }
    }

    resetDate() {
        this.datePicker.resetDateNow();
    }

    resetField(field) {
        this.addForm.get(field).reset();
    }

    submit() {
        this.isSubmitting = true;
        let data = this.addForm.value;
        if(this.cud == "add") {
            this.create(data);
        }
        else {
            this.update(data);
        }
    }

    create(data) {
        this.visitorInfirmaryEntryService.create(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_INFIRMARY_ACT_CREATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addInfirmaryEntryEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_INFIRMARY_ACT_NOT_CREATED" });
        });
    }

    update(data) {
        this.visitorInfirmaryEntryService.update(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_INFIRMARY_ACT_UPDATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addInfirmaryEntryEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_INFIRMARY_ACT_NOT_UPDATED" });
        });
    }
}
