import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MiscService, ChoiceGroupService, VisitorPathologyService } from '@services/index';
import { Globals } from '@app/app.globals';

@Component({
    selector: 'app-util-visitor-parts-medical-infirmary-pathology-add',
    templateUrl: './add.component.html',
})
export class UtilVisitorPartsMedicalInfirmaryPathologyAddComponent implements OnInit {
    @Input() type: any;
    @Output() addPathologyEvent = new EventEmitter<any>();

    isLoading = false;
    isLoadingPathologies = false;
    isSubmitting = false;
    randId = Math.floor(Math.random() * 10000);
    modal = "#addPathologyModal-" + this.randId;
    cud = "add";
    pickerConfig: any;
    pathologies: any;
    visitorPathology: any;
    addForm = this.fb.group({
        id: null,
        type: [null, Validators.required],
        visitor_id: [null, Validators.required],
        date: [null, Validators.required],
        pathologies_ids: null,
        remarks: null,
        comments: this.fb.group({
            dermato: this.fb.group({
                value: null,
                included: false
            }),
            cardio: this.fb.group({
                value: null,
                included: false
            }),
            pulmonary: this.fb.group({
                value: null,
                included: false
            }),
            gastro: this.fb.group({
                value: null,
                included: false
            }),
            endocrino: this.fb.group({
                value: null,
                included: false
            }),
            diabetes: this.fb.group({
                value: null,
                included: false
            }),
            neuro: this.fb.group({
                value: null,
                included: false
            }),
            ophtalmo: this.fb.group({
                value: null,
                included: false
            }),
            dental: this.fb.group({
                value: null,
                included: false
            }),
            uro: this.fb.group({
                value: null,
                included: false
            }),
            gyneco: this.fb.group({
                value: null,
                included: false
            }),
            infection: this.fb.group({
                value: null,
                included: false
            }),
            hepatitis: this.fb.group({
                value: null,
                included: false
            }),
            hiv: this.fb.group({
                value: null,
                included: false
            }),
            bk: this.fb.group({
                value: null,
                included: false
            }),
            diphtheria: this.fb.group({
                value: null,
                included: false
            }),
            ortho: this.fb.group({
                value: null,
                included: false
            })
        })
    });
    defaultForm = this.addForm.getRawValue();
    hasData = {
        visitorPathology: false
    };

    @ViewChild('datePicker') datePicker;

    constructor(private cdref: ChangeDetectorRef,
                private fb: UntypedFormBuilder,
                private miscService: MiscService,
                private choiceGroupService: ChoiceGroupService,
                private visitorPathologyService: VisitorPathologyService,
                public globals: Globals) {}

    ngOnInit() {
        this.pickerConfig = JSON.parse(JSON.stringify(this.globals.dateOnlyPickerConfig));
        this.addForm.get('type').patchValue(this.type);
        this.cdref.detectChanges();
    }

    objectKeys(obj) {
        return Object.keys(obj);
    }

    reset(visitorId) {
        this.addForm.reset(this.defaultForm.value);
        this.addForm.patchValue({
            visitor_id: visitorId,
            type: this.type
        });
        this.resetDate();
        this.cud = "add";
    }
    
    openModal(visitorId, visitorPathology = null) {
        if(this.miscService.checkEnvPermission("cudVisitorMedical")) {
            this.isLoading = true;
            this.isLoadingPathologies = true;
            this.visitorPathology = visitorPathology;
            this.hasData.visitorPathology = this.miscService.checkDataLength(this.visitorPathology);
            this.reset(visitorId);
            if(this.hasData.visitorPathology) {
                this.cud = "edit";
                this.addForm.patchValue(visitorPathology);
                if(this.visitorPathology.date) {
                    this.datePicker.setDate(this.visitorPathology.date);
                }
            }
            this.choiceGroupService.getByKey('pathologies').subscribe(data => {
                this.pathologies = data;
                this.isLoadingPathologies = false;
                if(this.cud == "edit") {
                    this.addPathologies();
                }
            });
            this.isLoading = false;
            $(this.modal).modal('show');
        }
    }

    getCommenText(key) {
        return "VISITORS_MEDICAL." + key.toUpperCase();
    }

    changePathologies() {
        let pathologiesId = this.addForm.value.pathologies_ids;
        this.pathologies.choices.forEach(pathology => {
            let commentKey = pathology.key;
            let hasPathology = pathologiesId.includes(pathology.id);
            if(this.addForm.value.comments[commentKey] !== undefined) {
                this.addForm.get('comments').get(commentKey).patchValue({
                    included: hasPathology
                });
            }
        });
        console.log("form", this.addForm.value)
    }

    addPathologies() {
        let pathologiesIds = [];
        let self = this;
        for(let key in this.visitorPathology) {
            let value = this.visitorPathology[key];
            if(value) {
                let pathology = self.miscService.filterArray(self.pathologies.choices, "key", key);
                if(pathology) {
                    pathologiesIds.push(pathology.id);
                    let commentKey = pathology.key;
                    let commentKeyVP = pathology.key + "_comment";
                    if(this.visitorPathology[commentKeyVP] !== undefined && this.addForm.value.comments[commentKey] !== undefined) {
                        this.addForm.get('comments').get(commentKey).patchValue({
                            value: this.visitorPathology[commentKeyVP],
                            included: true
                        });
                    }
                }
            }
        }
        this.addForm.get('pathologies_ids').patchValue(pathologiesIds);
    }

    addChoices(choiceGroup, choices) {
        choices.forEach(choice => {
            this[choiceGroup].choices = [...this[choiceGroup].choices, choice];
        });
    }

    getChoice(value) {
        if(value) {
            if(value.constructor !== Array) {   // If object
                return value.id;
            }
            else {
                let arrIds = [];
                value.forEach(val => {
                    arrIds.push(val.id);
                });
                return arrIds;
            }
        }

        return null;
    }

    changeChoice(field, value) {
        let val = this.getChoice(value);
        this.addForm.get(field).patchValue(val);
        if(field == "pathologies_ids") {
            this.changePathologies();
        }
    }

    dateChanged(evt, field) {
        if(evt) {
            let date = evt;
            this.addForm.get(field).patchValue(date);
        }
        else {
            this.resetField(field);
        }
	}

    resetDate() {
        this.datePicker.resetDateNow();
    }

    resetField(field) {
        this.addForm.get(field).reset();
    }

    submit() {
        this.isSubmitting = true;
        let data = this.addForm.value;
        if(this.cud == "add") {
            this.create(data);
        }
        else {
            this.update(data);
        }
    }

    create(data) {
        this.visitorPathologyService.create(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_PATHOLOGY_CREATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addPathologyEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_PATHOLOGY_NOT_CREATED" });
        });
    }

    update(data) {
        this.visitorPathologyService.update(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_PATHOLOGY_UPDATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addPathologyEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_PATHOLOGY_NOT_UPDATED" });
        });
    }
}
