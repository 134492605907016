import { ApiService } from '@services/api.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AddressService extends ApiService {
    getAll() {
        return super.get("/addresses");
    }

    getById(id: number) {
        return super.get("/address/" + id);
    }

    getTypesEdit() {
        return super.get("/addresses/types/edit");
    }

    create(data) {
        return super.post("/address", data);
    }

    update(data) {
        return super.put("/address", data);
    }

    delete(id: number) {
        return super.delete("/address/" + id);
    }
}
