<div aria-labelledby="suspensionsModalLabel" class="modal modal-big fade" id="suspensionsModal" role="dialog" tabindex="-1" style="display: none; z-index: 9999;">
    <div class="modal-dialog width-1200" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
                <h4 class="modal-title" *ngIf="hasData?.visitor">
                    {{ visitor?.showName }} - {{ 'VISITORS_SUSPENSIONS.SUSPENSIONS' | translate }}
                </h4>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="!isLoading">
                    <div class="justify-content-right mb-3">
                        <button class="btn btn-success btn-fill"
                                (click)="utilVisitorModalsSuspensionsAdd.openModal(visitor)">
                            <i class="fa fa-plus"></i> {{ 'VISITORS_SUSPENSIONS.ADD_SUSPENSION' | translate }}
                        </button>
                    </div>
                    <ng-container *ngIf="hasData?.visitorSuspensions">
                        <table class="table table-condensend table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th *ngIf="hasPermission?.cudVisitor">
                                        {{ 'SHARED.ACTIONS' | translate }}
                                    </th>
                                    <th>{{ 'VISITORS_SUSPENSIONS.ACTIVE' | translate }}</th>
                                    <th>{{ 'VISITORS_SUSPENSIONS.BEGIN' | translate }}</th>
                                    <th>{{ 'VISITORS_SUSPENSIONS.END' | translate }}</th>
                                    <th>{{ 'VISITORS_SUSPENSIONS.REASON_SUSPENSION' | translate }}</th>
                                    <th>{{ 'VISITORS_SUSPENSIONS.REASON_CANCEL' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let visitorSuspension of visitorSuspensions; let i = index">
                                    <tr [ngClass]="{'line-green': visitorSuspension.active,
                                                    'line-red': !visitorSuspension.active,
                                                    'bg-warning-light': checkCurrentSuspension(visitorSuspension)}">
                                        <td>{{ i + 1 }}</td>
                                        <td class="td-actions" *ngIf="hasPermission?.cudVisitor">
                                            <div class="dropdown">
                                                <button type="button"
                                                        class="btn btn-xs btn-fill dropdown-toggle"
                                                        [attr.id]="'dropdownMenu-0'"
                                                        data-toggle="dropdown">
                                                    {{ 'SHARED.ACTIONS' | translate }} <span class="caret"></span>
                                                </button>
                                                <ul class="dropdown-menu dropdown-menu-left dropdown-menu-carret-left"
                                                    [attr.aria-labelledby]="'dropdownMenu-0'">
                                                    <li>
                                                        <a href="javascript:;"
                                                                (click)="utilVisitorModalsSuspensionsAdd.openModal(visitor, visitorSuspension)">
                                                            <i class="fa fa-edit text-success"></i> {{ 'VISITORS_SUSPENSIONS.EDIT_SUSPENSION' | translate }}
                                                        </a>
                                                    </li>
                                                    <li *ngIf="visitorSuspension?.active">
                                                        <a href="javascript:;"
                                                                (click)="activate(visitorSuspension)">
                                                            <i class="fa fa-lock text-warning"></i> {{ 'VISITORS_SUSPENSIONS.CANCEL_SUSPENSION' | translate }}
                                                        </a>
                                                    </li>
                                                    <li *ngIf="!visitorSuspension?.active">
                                                        <a href="javascript:;"
                                                                (click)="activate(visitorSuspension)">
                                                            <i class="fa fa-unlock text-success"></i> {{ 'VISITORS_SUSPENSIONS.REACTIVATE_SUSPENSION' | translate }}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="javascript:;"
                                                                (click)="delete(visitorSuspension?.id)">
                                                            <i class="fa fa-trash text-danger"></i> {{ 'VISITORS_SUSPENSIONS.DELETE_SUSPENSION' | translate }}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                        <td>
                                            <app-icon-check-close [value]="visitorSuspension?.active"></app-icon-check-close>    
                                            <span class="italic ml-2"
                                                style="font-size: 0.9em;"
                                                *ngIf="checkCurrentSuspension(visitorSuspension)">
                                                ({{ 'VISITORS_SUSPENSIONS.SUSPENSION_IN_PROGRESS' | translate }})
                                            </span>
                                        </td>
                                        <td>{{ visitorSuspension?.begin | localizedDate: true }}</td>
                                        <td>
                                            <ng-container *ngIf="visitorSuspension?.end">
                                                {{ visitorSuspension?.end | localizedDate: true | ifEmpty: "-" }}
                                            </ng-container>
                                            <ng-container *ngIf="!visitorSuspension?.end">
                                                ({{ 'VISITORS.PERMANENT' | translate }})
                                            </ng-container>
                                        </td>
                                        <td>
                                            <app-text-limit [text]="visitorSuspension?.reason" [title]="'REASON_SUSPENSION'"></app-text-limit>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="!visitorSuspension?.active">
                                                <app-text-limit [text]="visitorSuspension?.reason_cancel" [title]="'REASON_CANCEL'"></app-text-limit>
                                            </ng-container>
                                            <ng-container *ngIf="visitorSuspension?.active">
                                                -
                                            </ng-container>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </ng-container>
                    <ng-container *ngIf="!hasData?.visitorSuspensions">
                        <p>{{ 'VISITORS_SUSPENSIONS.NO_VISITOR_SUSPENSION_FOUND' | translate }}</p>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="isLoading">
                    <app-loading-spinner></app-loading-spinner>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<app-util-visitor-modals-suspensions-add (addEvent)="manage()" #utilVisitorModalsSuspensionsAdd></app-util-visitor-modals-suspensions-add>
<app-util-visitor-modals-suspensions-cancel (cancelEvent)="manage()" #utilVisitorModalsSuspensionsCancel></app-util-visitor-modals-suspensions-cancel>