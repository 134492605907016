<div class="row">
    <div class="col-md-12">
        <div class="card mb-0">
            <div class="content">
                <app-input-file [pictures]="pictures" [editable]="editable" [showInput]="showInput" multiple (onFileChanged)="form.get('files').patchValue($event)"></app-input-file>
            </div>
            <div class="footer text-right" *ngIf="form.get('files').value?.length > 0">
                <app-btn-loading icon="floppy-o"
                                fill
                                [loading]="formLoading"
                                [disabled]="!form.get('files').value?.length"
                                (click)="submitForm()">
                    {{ 'SHARED.SAVE' | translate }}
                </app-btn-loading>
            </div>
        </div>
    </div>
</div>

<hr>

<app-loading block center *ngIf="files === null"></app-loading>

<ng-container *ngIf="files !== null">
    <div *ngIf="!files?.length">{{ 'UPLOAD.NO_FILE' | translate }}</div>
    <div *ngIf="files?.length > 0">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="content">
                        {{ 'UPLOAD.DOWNLOAD_OR_DELETE_FILES' | translate }}
                        <label class="customcheck" style="width: 200px; margin-top: 2px;">
                            <span style="padding-left: 25px;">{{ 'UPLOAD.CHECK_OR_UNCHECK_ALL' | translate }}</span>
                            <input type="checkbox" [(ngModel)]="isAllChecked" (change)="onAllCheckboxChanged()">
                            <span class="checkmark"></span>
                        </label>
                        <div>
                            <app-btn-loading [loading]="zipLoading" icon="download" color="primary" fill (click)="zip()" [disabled]="!getFileIdsForChecked()?.length">{{ 'SHARED.DOWNLOAD' | translate }}</app-btn-loading>
                            <app-btn-loading class="ml-3" [loading]="destroyLoading" icon="trash-o" color="danger" fill (click)="destroy()" [disabled]="!getFileIdsForChecked()?.length">{{ 'SHARED.DELETE' | translate }}</app-btn-loading>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6" *ngIf="!showOnly?.length || showOnly.includes('images')">
                <app-util-folders-files [files]="filesFilter.images" (onCheckboxChanged)="onFileCheckboxChanged()" (onDestroyedOrEdited)="loadFiles()" type="{{ 'UPLOAD.IMAGES' | translate }}"></app-util-folders-files>
            </div>
            <div class="col-md-6" *ngIf="!showOnly?.length || showOnly.includes('pdf')">
                <app-util-folders-files [files]="filesFilter.pdf" (onCheckboxChanged)="onFileCheckboxChanged()" (onDestroyedOrEdited)="loadFiles()" type="PDF"></app-util-folders-files>
            </div>
            <div class="col-md-6" *ngIf="!showOnly?.length || showOnly.includes('excel')">
                <app-util-folders-files [files]="filesFilter.excel" (onCheckboxChanged)="onFileCheckboxChanged()" (onDestroyedOrEdited)="loadFiles()" type="Excel"></app-util-folders-files>
            </div>
            <div class="col-md-6" *ngIf="!showOnly?.length || showOnly.includes('others')">
                <app-util-folders-files [files]="filesFilter.others" (onCheckboxChanged)="onFileCheckboxChanged()" (onDestroyedOrEdited)="loadFiles()" type="{{ 'UPLOAD.OTHERS' | translate }}"></app-util-folders-files>
            </div>
        </div>
    </div>
</ng-container>


