
<div class="logo">
	<a class="simple-text logo-mini">
		<div class="logo-img">
			<img src="{{ getUrlLogo() }}" *ngIf="settingsData" />
		</div>
	</a>
</div>

<div class="sidebar-wrapper">
	<div class="user">
		<div class="info">
			<div class="photo">
				<img [src]="currentUserData?.avatar || 'assets/img/faces/face-0.jpg'" alt="avatar">
				<span class="sidebar-mini"></span>
			</div>

			<a data-toggle="collapse" href="#collapseExample" class="collapsed">
				<span>
					{{currentUserData?.firstname}} {{currentUserData?.name}} ({{currentUserData?.identifier}})
					<b class="caret"></b>
				</span>
			</a>

			<div class="collapse" id="collapseExample">
				<ul class="nav">
					<li *ngxPermissionsOnly="['cudUserSelf']">
						<a routerLinkActive="active" (click)="hideBar()" [routerLink]="['/user/edit-pwd']">
							<span class="sidebar-normal">{{ 'USERS.EDIT_PASSWORD_SHORT' | translate }}</span>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>

	<div class="params" *ngIf="isNotMobileMenu()">
		<!-- margin-bottom removed cause ul below -->
		<ul class="nav nav-mobile-menu" style="margin-bottom: 0px;">
			<li class="dropdown">
				<a class="dropdown-toggle" data-toggle="dropdown" href="#" aria-expanded="false">
					<i class="fa fa-wrench"></i>
					<p class="hidden-md hidden-lg">
						Params
						<b class="caret" (click)="hideBar()"></b>
					</p>
				</a>
				<ul class="dropdown-menu dropdown-with-icons">
					<li>
						<a routerLinkActive="active" (click)="hideBar()" [routerLink]="['/settings/edit']">
							<i class="fa fa-wrench"></i>Param. du logiciel
						</a>
					</li>
					<li>
						<a routerLinkActive="active" (click)="hideBar()" [routerLink]="['/companies', companyID ,'edit']">
							<i class="fa fa-wrench"></i>Param. de la société ss
						</a>
					</li>
					<li role="separator" class="divider"></li>
					<li>
						<span>
							<bSwitch
								[switch-on-color]="'primary'"
								[(ngModel)]="isNightMode"
								(ngModelChange)="switchTheme($event)"
								>
							</bSwitch> Night mode
						</span>
					</li>
					<li role="separator" class="divider"></li>
					<li>
						<a [routerLink]="['/auth/lock']">
						<p><i class="fa fa-lock"></i>Verouiller</p>
						</a>
					</li>
					<li>
						<a (click)="logout()" class="btn-logout">
							<p class="text-danger"><i class="fa fa-power-off"></i>Déconnexion</p>
						</a>
					</li>
				</ul>
			</li>
		</ul>
		<ul class="nav nav-mobile-menu">
			<li class="dropdown">
				<a class="dropdown-toggle" data-toggle="dropdown" href="#" aria-expanded="false">
					<i class="fa fa-plus"></i>
					<p class="hidden-md hidden-lg">
						Actions
						<b class="caret"></b>
					</p>
				</a>
				<ul class="dropdown-menu dropdown-with-icons">
					<li class="py-1 px-5">
						<app-quick-action (click)="hideBar()" [mobile]="true"></app-quick-action>
					</li>
				</ul>
			</li>
		</ul>
    </div>

    <ul class="nav">
		<ng-container *ngFor="let menuitem of menuItems">
	        <li routerLinkActive="active">
	            <!--If is a single link-->
	            <a [routerLink]="[menuitem.path]" (click)="hideBar()" *ngIf="menuitem.type === 'link'">
	                <i class="{{menuitem.icontype}}"></i>
	                <p *ngIf="menuitem.trad_key">{{ menuitem.trad_key | translate }}</p>
	                <p *ngIf="!menuitem.trad_key">{{menuitem.title}}</p>
	            </a>
	            <!--If it has a submenu-->
	            <a data-toggle="collapse" href="#{{menuitem.anchor}}" *ngIf="menuitem.type === 'sub'" data-parent="#menuParent">
	                <i class="{{menuitem.icontype}}"></i>
	                <p>{{menuitem.title}}<b class="caret"></b></p>
	            </a>
	            <!--Display the submenu items-->
	            <div id="{{menuitem.anchor}}" class="collapse" *ngIf="menuitem.type === 'sub'">
	                <ul class="nav">
	                    <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngFor="let childitem of menuitem.children">
							<a *ngIf="childitem.path.length && childitem.fullPath" (click)="hideBar()" [routerLink]="childitem.path">
								<span class="sidebar-mini">{{childitem.ab}}</span>
                                <span class="sidebar-normal" *ngIf="childitem.trad_key">{{childitem.trad_key | translate }}</span>
								<span class="sidebar-normal" *ngIf="!childitem.trad_key">{{childitem.title}}</span>
							</a>
							<a *ngIf="childitem.path.length && !childitem.fullPath" (click)="hideBar()" [routerLink]="menuitem.path + childitem.path">
		                        <span class="sidebar-mini">{{childitem.ab}}</span>
		                        <span class="sidebar-normal" *ngIf="childitem.trad_key">{{childitem.trad_key | translate }}</span>
								<span class="sidebar-normal" *ngIf="!childitem.trad_key">{{childitem.title}}</span>
		                    </a>
		                    <a *ngIf="!childitem.path.length" (click)="hideBar()" [routerLink]="[menuitem.path]">
		                        <span class="sidebar-mini">{{childitem.ab}}</span>
		                        <span class="sidebar-normal" *ngIf="childitem.trad_key">{{childitem.trad_key | translate }}</span>
								<span class="sidebar-normal" *ngIf="!childitem.trad_key">{{childitem.title}}</span>
		                    </a>
	                    </li>
	                </ul>
	            </div>
	        </li>
		</ng-container>
    </ul>
</div>
