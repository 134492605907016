import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
    selector: "app-filters-users",
    templateUrl: "./users.component.html"
})
export class FiltersUsersComponent implements OnInit {
    @Output() filterEvent = new EventEmitter<any>();

    filtersForm = this.fb.group({
        term: null
    });

    constructor(private fb: UntypedFormBuilder) {}

    ngOnInit(): void {}

    submit() {
        let data = this.filtersForm.value;
        this.filterEvent.emit(data);
    }

    reset() {
        this.filtersForm.reset();
        this.submit();
    }
}
