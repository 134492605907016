import { ApiService } from '@services/api.service';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class LogoutService extends ApiService {
    logout() {
        return super.get("/logout");
    }

    logoutClose() {
        let url = environment.apiUrl + "/logout";
        return fetch(url, {
            keepalive: true,
            method: 'GET',
            headers: {
              'content-type': 'application/json',
            },
            body: JSON.stringify({}),
        });
    }
}
