import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/_guards';
import { AdminLayoutComponent } from '@layouts/admin/admin-layout.component';
import { NgxPermissionsGuard } from 'ngx-permissions';

const routes: Routes = [
    { path: 'auth', loadChildren: () => import('./components/auth/auth.module').then(m => m.AuthModule) },
    { path: 'login', redirectTo: 'auth/login' },
    {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'dashboard', loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule) },
            { path: 'settings', loadChildren: () => import('./components/settings/settings.module').then(m => m.SettingsModule), canActivate: [NgxPermissionsGuard], data: { permissions: { only: ['cudSetting', 'cudOption', 'cudRoles'], redirectTo: '/no-permission' }}},
            { path: 'user', loadChildren: () => import('./components/user/user.module').then(m => m.UserModule), canActivate: [NgxPermissionsGuard], data: { permissions: { only: ['getUser', 'cudUser'], redirectTo: '/no-permission' } } },
            { path: 'visitor', loadChildren: () => import('./components/visitor/visitor.module').then(m => m.VisitorModule), canActivate: [NgxPermissionsGuard], data: { permissions: { only: ['getVisitor', 'cudVisitor'], redirectTo: '/no-permission' } } },
            { path: 'daily-entries', loadChildren: () => import('./components/daily-entries/daily-entries.module').then(m => m.DailyEntriesModule), canActivate: [NgxPermissionsGuard], data: { permissions: { only: ['getVisitorDailyEntry'], redirectTo: '/no-permission' } } },
            { path: 'appointments', loadChildren: () => import('./components/appointments/appointments.module').then(m => m.AppointmentsModule), canActivate: [NgxPermissionsGuard], data: { permissions: { only: ['getVisitorAppointment'], redirectTo: '/no-permission' } } },            
            { path: 'statistics', loadChildren: () => import('./components/statistics/statistics.module').then(m => m.StatisticsModule), canActivate: [NgxPermissionsGuard], data: { permissions: { only: ['getStatistic'], redirectTo: '/no-permission' }}}
        ]
    },

    { path: '404', loadChildren: () => import('./components/error/not-found/not-found.module').then(m => m.NotFoundModule) },
    { path: 'no-permission', loadChildren: () => import('./components/error/no-permission/no-permission.module').then(m => m.NoPermissionModule) },
    { path: '**',  redirectTo: '/404' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
    useHash: false,
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
}),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
