<div aria-labelledby="exportModalLabel" class="modal modal-big fade" id="exportModal" role="dialog" tabindex="-1" style="display: none; z-index: 9999;">
    <div class="modal-dialog width-900" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
                <h4 class="modal-title">
                    {{ "STATISTICS.EXPORT_DATA" | translate }}
                </h4>
            </div>
            <div class="modal-body">
                <p>
                    <i class="fa fa-exclamation-triangle text-warning"></i> {{ 'STATISTICS.OPERATION_CAN_TAKE_TIME' | translate }}
                </p>
                <form [formGroup]="exportForm" #eForm="ngForm" class="form-horizontal" (submit)="submit()">
                    <div class="form-group">
                        <div class="col-md-3">
                            <label class="control-label control-label-sm">
                                {{ "STATISTICS.DATA_TO_EXPORT" | translate }}
                            </label>
                        </div>
                        <div class="col-md-9">
                            <div class="row m-0">
                                <div class="col-md-4">
                                    <div class="checkbox">
                                        <input type="checkbox"
                                                class="form-control"
                                                id="users"
                                                formControlName="users"
                                                (change)="checkExport()" />
                                        <label for="users">
                                            {{ "STATISTICS.USERS" | translate }}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="checkbox">
                                        <input type="checkbox"
                                                class="form-control"
                                                id="visitors"
                                                formControlName="visitors"
                                                (change)="checkExport()" />
                                        <label for="visitors">
                                            {{ "STATISTICS.VISITORS" | translate }}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="checkbox">
                                        <input type="checkbox"
                                                class="form-control"
                                                id="visitor_daily_entries"
                                                formControlName="visitor_daily_entries"
                                                (change)="checkExport()" />
                                        <label for="visitor_daily_entries">
                                            {{ "STATISTICS.DAILIES_ENTRIES" | translate }}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="checkbox">
                                        <input type="checkbox"
                                                class="form-control"
                                                id="visitor_appointments"
                                                formControlName="visitor_appointments"
                                                (change)="checkExport()" />
                                        <label for="visitor_appointments">
                                            {{ "STATISTICS.APPOINTMENTS" | translate }}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="checkbox">
                                        <input type="checkbox"
                                                class="form-control"
                                                id="visitor_infirmary_entries"
                                                formControlName="visitor_infirmary_entries"
                                                (change)="checkExport()" />
                                        <label for="visitor_infirmary_entries">
                                            {{ "STATISTICS.INFIRMARY_ACTS" | translate }}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="checkbox">
                                        <input type="checkbox"
                                                class="form-control"
                                                id="visitor_treatments"
                                                formControlName="visitor_treatments"
                                                (change)="checkExport()" />
                                        <label for="visitor_treatments">
                                            {{ "STATISTICS.TREATMENTS" | translate }}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="checkbox">
                                        <input type="checkbox"
                                                class="form-control"
                                                id="visitor_medical_consultations"
                                                formControlName="visitor_medical_consultations"
                                                (change)="checkExport()" />
                                        <label for="visitor_medical_consultations">
                                            {{ "STATISTICS.MEDICAL_CONSULTATIONS" | translate }}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="checkbox">
                                        <input type="checkbox"
                                                class="form-control"
                                                id="visitor_measures"
                                                formControlName="visitor_measures"
                                                (change)="checkExport()" />
                                        <label for="visitor_measures">
                                            {{ "STATISTICS.SETTINGS" | translate }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="col-md-12">
                            <button type="submit"
                                    class="btn"
                                    [ngClass]="{'btn-default':isExporting, 'btn-success': !isExporting}"                                                        
                                    [disabled]="!eForm.form.valid || isExporting || !isValid">
                                <i class="fa"
                                    [ngClass]="{'fa-download': !isExporting,
                                                'fa-spinner fa-spin': isExporting}"></i> {{ "SHARED.EXPORT" | translate }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>