import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MiscService, VisitorSuspensionService } from '@services/index';

@Component({
    selector: 'app-util-visitor-modals-suspensions-cancel',
    templateUrl: './cancel.component.html',
})
export class UtilVisitorModalsSuspensionsCancelComponent implements OnInit {
    @Output() cancelEvent = new EventEmitter<any>();

    isSubmitting = false;
    modal = "#suspensionsCancelModal";
    datepickerConfig: any;
    visitor: any;
    cancelForm = this.fb.group({
        id: [null, Validators.required],
        reason_cancel: null
    });

    constructor(private cdref: ChangeDetectorRef,
                private fb: UntypedFormBuilder,
                private miscService: MiscService,
                private visitorSuspensionService: VisitorSuspensionService) {}

    ngOnInit() {
        this.cdref.detectChanges();
    }
    
    openModal(visitorSuspension) {
        if(this.miscService.checkEnvPermission("cudVisitor")) {
            this.cancelForm.reset({id: visitorSuspension.id});
            $(this.modal).modal('show');
        }
    }

    submit() {
        this.isSubmitting = true;
        let data = this.cancelForm.value;
        this.visitorSuspensionService.activate(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_SUSPENSION_CANCELED", status: "success" });
            $(this.modal).modal('hide');
            this.cancelEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_SUSPENSION_NOT_CANCELED" });
        });
    }
}
