import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MiscService } from '@services/index';

@Component({
    selector: 'app-ng-select-add-multiple',
    templateUrl: './multiple.component.html'
})
export class NgSelectAddMultipleComponent implements OnInit {
    @Input() choiceGroup: any;
    @Input() items: any;
    @Input() selectedId: any = null;
    @Input() multiple = false;
    @Input() closeOnSelect = true;
    @Input() resettable = true;
    @Input() canAdd = true;
    @Output() changeEvent = new EventEmitter<any>();
    @Output() addChoicesEvent = new EventEmitter<any>();

    value: any;

    @ViewChild('choiceModalAll') choiceModalAll;

    constructor(private miscService: MiscService) {}

    ngOnInit() {
        if(this.selectedId) {
            let value = null;
            if(this.selectedId.constructor !== Array) {
                value = this.miscService.filterArray(this.items, "id", this.selectedId);
            }
            else {
                value = [];
                this.selectedId.forEach(id => {
                    let val = this.miscService.filterArray(this.items, "id", id);
                    if(val) {
                        value.push(val);
                    }
                });
            }
            if(value) {
                this.value = value;
            }
        }
    }

    createNewChoice() {
        this.choiceModalAll.openModal();
    }

    addChoices(choices) {
        if(this.multiple) {
            this.value = this.value.concat(choices);
        }
        else {
            this.value = choices[0];
        }
        this.addChoicesEvent.emit(choices);
        let self = this;
        setTimeout(function() {
            self.change();
        }, 300);
    }

    change() {
        this.changeEvent.emit(this.value);
    }

    reset() {
        this.value = null;
        this.changeEvent.emit(null);
    }

    setValue(data) {
        let value = null;
        if(data !== undefined && data !== null) {
            if(data.constructor !== Array) {
                value = this.miscService.filterArray(this.items, "id", data);
            }
            else {
                value = [];
                data.forEach(id => {
                    let val = this.miscService.filterArray(this.items, "id", id);
                    if(val) {
                        value.push(val);
                    }
                });
            }
        }
        this.value = value;
    }
}