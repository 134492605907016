import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgxPermissionsService } from 'ngx-permissions';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService, PageTitleService, UserService } from '@services/index';
import { Title } from '@angular/platform-browser';
import { environment } from '@env/environment';

declare let moment: any;
declare const $: any;

@Injectable({
    providedIn: 'root'
})
export class MiscService {
    public lastNotif = null;

    constructor(private router: Router,
                public fb: UntypedFormBuilder,
                private datePipe: DatePipe,
                private localStorageService: LocalStorageService,
                private ngxPermissionsService: NgxPermissionsService,
                private pageTitleService: PageTitleService,
                private translateService: TranslateService,
                private userService: UserService,
                private title: Title) {
        // this.translateService.setDefaultLang("fr");
    }

    setLanguage() {
        let lang = this.getLanguage();
        this.translateService.setDefaultLang(lang);

        return lang;
    }

    getLanguage() {
        let lang = "fr";
        if(this.localStorageService.checkItem('lang')) {
            lang = this.localStorageService.getLSItem('lang');
            if(lang) {
                lang = lang;
            }
            else {
                lang = "fr";
            }
        }

        return lang;
    }

    _t(str, data = null) {
        let text = "";
        if(data) {
            this.translateService.get(str, data).subscribe(data => {
                text = data;
            },
            error => {},
            () => {
                return text;
            });
        }
        else {
            this.translateService.get(str).subscribe(data => {
                text = data;
            },
            error => {},
            () => {
                return text;
            });
        }

        return text;
    }

    setPageTitle(titlePage = "") {
        if(!titlePage) {
            titlePage = environment.softwareName;
        }
        else {
            titlePage = this._t(titlePage);
        }
        this.pageTitleService.changeTitle(titlePage);
        this.title.setTitle(titlePage);
    }

    getTextPermanent() {
        return "(" + this._t("VISITORS.PERMANENT") + ")";
    }

    randomString(length = 15) {
        var result = "";
        var characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }
        return result;
    }

    slugify(text) {
        const a = "àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœṕŕßśșțùúüûǘẃẍÿź·/_,:;";
        const b = "aaaaaaaaceeeeghiiiimnnnoooooprssstuuuuuwxyz------";
        const p = new RegExp(a.split("").join("|"), "g");
        return text
            .toString()
            .toLowerCase()
            .replace(/\s+/g, "-") // Replace spaces with -
            .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
            .replace(/&/g, "-and-") // Replace & with ‘and’
            .replace(/[^\w\-]+/g, "") // Remove all non-word characters
            .replace(/\-\-+/g, "-") // Replace multiple - with single -
            .replace(/^-+/, "") // Trim - from start of text
            .replace(/-+$/, ""); // Trim - from end of text
    }

    cleanUrl(url) {
        return encodeURIComponent(url);
    }

    trimNumber(value) {
        return value
            .toString()
            .replace(" ", "")
            .replace(/[\r|\n|\r\n]$/, "") // supprime les retours à la ligne
            .replace(",", ".") // remplace la virgule par un point
            .replace(/[^0-9.-]/g, "") // supprime tous les caractères sauf les chiffres, le point le -
            .replace(/(\..*)\./g, "$1") // supprime les points multiples
            .replace(/(?!^)-/g, "") // supprime les signes - qui ne sont pas au début
            .replace(/^0+(\d)/gm, "$1"); // supprime les 0 inutiles devant le nombre
    }

    nl2br(str) {
        var breakTag = "<br />";
        return str.replace(
            /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
            "$1" + breakTag + "$2"
        );
    }

    resizeWindow(element) {
        this.setFixedWidth(element);
    }

    setFixedWidth(element) {
        if($(window).width() >= 768) {
            $(element).addClass("position-fixed");
            let width = $(element).parent().width();
            $(element).width(width);
        }
        else {
            $(element).removeClass("position-fixed");
        }
    }

    checkFloat(value) {
        let floatRegex = /\d+([\,\.]\d{1,})?/;
        if(value == null || (value !== 0 && !value.toString().match(floatRegex))) {
            return false;
        }

        return true;
    }

    checkDateInArray(date, forbiddenDates) {
        if(date && forbiddenDates.length) {
            let dateArr = date.split(" ");  // removes the hour if necessary
            date = dateArr[0];

            return forbiddenDates.includes(date);
        }

        return false;
    }

    jsonToFormData(data: any) {
        const formData = new FormData();
        this.buildFormData(formData, data);

        return formData;
    }

    buildFormData(formData: FormData, data: any, parentKey: string = null) {
        if(data !== null && data !== undefined && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof moment) && !(typeof data === 'boolean')) {
            Object.keys(data).forEach(key => {
                this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        }
        else {
            let value;
            if(data instanceof moment) {
                value = `${data.format('YYYY-MM-DD HH:mm')}`;
            }
            else if(typeof data === 'boolean') {
                value = data ? '1' : '0';
            }
            else {
                value = data == null ? '' : data;
            }

            if((data instanceof File) && 'new_name' in data) {
                const index = (data['name'] as String).lastIndexOf('.');
                const extension = (data['name'] as String).substring(index + 1).toLowerCase();
                formData.append(parentKey, value, `${data['new_name']}.${extension}`);
            }
            else {
                formData.append(parentKey, value);
            }
        }
    }

    downloadFile(file: any, dir = "uploads") {
        let href = null;
        if(typeof file === 'string') {
            href = this.downloadFilePath(file, dir, 1);
        }
        else {
            href = this.generatePathFromFile(file, 1);
        }

        const a = document.createElement('a');
        a.href = href;
        a.target = '_blank';
        a.click();
    }

    downloadFilePath(path: string, dir, download: boolean|number = 0) {
        const apiToken = this.localStorageService.getLSItem('currentUser').api_token;
        if(path.startsWith('/')) {
            return `${environment.apiUp}/show/${dir}${path}?api_token=${apiToken}&download=${download}`;
        }
        else {
            return `${environment.apiUp}/show/${dir}/${path}?api_token=${apiToken}&download=${download}`;
        }
    }

    generatePathFromFile(file: any, download: number) {
        const filesToken = this.userService.getFilesTokens;
        const queryParamsString = `?kol=${filesToken.kol}&aar=${filesToken.aar}&fel=${filesToken.fel}`
        if(file.path.startsWith('/')) {
            return `${environment.baseUrl}/files${file.id}/${file.path}${queryParamsString}&download=${download}`;
        }
        else {
            return `${environment.baseUrl}/files/${file.id}/${file.path}${queryParamsString}&download=${download}`;
        }
    }

    goToUrlDelay(url, delay = 100) {
        let self = this;
        setTimeout(function() {
            self.router.navigate(url);
        }, delay);
    }

    /**
     * Retrouve un objet dans un tableau d'objets selon la valeur d'une propriété
     * @param array array                le tableau d'objets
     * @param nodeValue string           le nom de la propriété à comparer
     * @param compareValue any           la valeur de la propriété pour la comparaison
     * @return object / boolean          l'objet si la comparaison a été fructueuse sinon false
     */
    filterArray(array, nodeValue, compareValue,) {
        if(array && array.length) {
            let dataFiltered = array.filter(function(node) {
                return node[nodeValue] == compareValue;
            });
            if(dataFiltered.length) {
                return dataFiltered[0];
            }
        }

        return false;
    }

     /**
     * Retrouve des objets dans un tableau d'objets selon la valeur d'une propriété
     * @param array array                le tableau d'objets
     * @param nodeValue string           le nom de la propriété à comparer
     * @param compareValue any           la valeur de la propriété pour la comparaison
     * @return object / boolean          l'objet si la comparaison a été fructueuse sinon false
     */
     filterArrayMultiple(array, nodeValue, compareValue) {
        if(array && array.length) {
            let dataFiltered = array.filter(function(node) {
                return node[nodeValue] == compareValue;
            });
            if(dataFiltered.length) {
                return dataFiltered;
            }
        }

        return false;
    }

    /**
     * Retrouve l'index d'un objet dans un tableau d'objets selon la valeur d'une propriété
     * @param array array                le tableau d'objets
     * @param nodeValue string           le nom de la propriété à comparer
     * @param compareValue any           la valeur de la propriété pour la comparaison
     * @return object / boolean          l'objet si la comparaison a été fructueuse sinon false
     */
    filterArrayIndex(array, nodeValue, compareValue) {
        if (array && array.length) {
            let i = null;
            array.forEach(function (node, index) {
                if (node[nodeValue] === compareValue) {
                    i = index;
                }
            });
            if (i) {
                return i;
            }
        }

        return false;
    }

    /**
     * Retrouve l'index d'un objet dans un tableau d'objets selon la valeur d'une propriété
     * @param array array                le tableau d'objets
     * @param compareValue any           la valeur de la propriété pour la comparaison
     * @return object / boolean          l'objet si la comparaison a été fructueuse sinon false
     */
    filterSimpleArrayIndex(array, compareValue) {
        if (array && array.length) {
            let i = null;
            array.forEach(function (node, index) {
                if (node === compareValue) {
                    i = index;
                }
            });
            if (i !== null) {
                return +i;
            }
        }

        return false;
    }

    /**
     * Retrouve un objet dans un tableau d'objets selon la valeur d'une propriété
     * @param array array                le tableau d'objets
     * @param compareValue any           la valeur de la propriété pour la comparaison
     * @return object / boolean          l'objet si la comparaison a été fructueuse sinon false
     */
    filterArrayNoNode(array, compareValue) {
        if (array && array.length) {
            let dataFiltered = array.filter(function (node) {
                return node == compareValue;
            });
            if (dataFiltered.length) {
                return dataFiltered[0];
            }
        }

        return false;
    }

    /**
     * Trouve la valeur minimum ou maximum d'une propriété dans un tableau d'objets
     * @param array array                le tableau d'objets
     * @param nodeValue string           le nom de la propriété à comparer
     * @param type string                le type de comparaison : min ou max
     * @return float / boolean           la valeur trouvée si la recherche a été fructueuse sinon false
     */
    getExtremeValueArray(array, nodeValue, type = "min") {
        if (array && array.length) {
            if (nodeValue in array[0]) {
                if (type == "min") {
                    return Math.min.apply(
                        Math,
                        array.map(function (obj) {
                            return +obj[nodeValue];
                        })
                    );
                } else if (type == "max") {
                    return Math.max.apply(
                        Math,
                        array.map(function (obj) {
                            return +obj[nodeValue];
                        })
                    );
                }
            }
        }

        return false;
    }

    /**
     * Supprime une entrée d'un tableau d'objets sur base de la valeur d'une clé
     * @param array array                le tableau d'objets
     * @param nodeValue string           le nom de la propriété à comparer
     * @param compareValue any           la valeur de la propriété pour la comparaison
     * @return array                     le tableau sans l'entrée trouvée
     */
    removeFromArray(array, nodeValue, compareValue) {
        var array = $.grep(array, function (e) {
            return e[nodeValue] != compareValue;
        });
        return array;
    }

    removeDuplicatesFromArray(array) {
        return array.reduce(function (a, b) {
            if (a.indexOf(b) < 0) a.push(b);
            return a;
        }, []);
    }

    removeDuplicatesFromArrayOfObjects(array) {
        return array.filter(
            (v, i, a) => a.findIndex((t) => t.id === v.id) === i
        );
    }

    inArray(array, value) {
        return (array && Array.isArray(array)) ? (array.indexOf(value) != -1 ? true : false) : false;
    }

    isSuperAdmin(roles) {
        return this.filterArray(roles, "name", "superadmin");
    }

    isAdmin(roles) {
        return this.filterArray(roles, "name", "admin");
    }

    ucfirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    rangeNumbers(min, max, addZero) {
        var numbers = [];
        for (var i = min; i <= max; i += 1) {
            if (addZero) {
                numbers.push(this.addZeroNumber(i));
            } else {
                numbers.push(i);
            }
        }
        return numbers;
    }

    addZeroNumber(number, max = 10) {
        let numberStr = number.toString();
        if (number < max) {
            numberStr = "0" + numberStr;
        }

        return numberStr;
    }

    formatDate(date, format = "dd/MM/yyyy HH:mm:ss") {
        return this.datePipe.transform(new Date(date), format);
    }

    can(permissions: string|string[], logicalOperator = "and") {
        let check = true;
        if(!Array.isArray(permissions)) {
            check = this.checkEnvPermission(permissions);
        }
        else {
            if(logicalOperator == "and") {
                check = this.checkEnvPermArray(permissions);
            }
            else {
                check = this.checkEnvPermArrayOr(permissions);
            }
        }
        
        if(!check) {
            this.router.navigate(['/no-permission']);
        }

        return check;
    }

    getEnvPermissions() {
        return Object.keys(this.ngxPermissionsService.getPermissions());
    }

    checkEnvPermission(perm) {
        if(perm) {
            const perms = this.getEnvPermissions();
            return perms.indexOf(perm) >= 0;
        }

        return true;
    }

    checkEnvPermArray(permArray) {
        let check = true;
        permArray.forEach((perm) => {
            if(!this.checkEnvPermission(perm)) {
                check = false;
            }
        });

        return check;
    }

    checkEnvPermArrayOr(permArray) {
        for(let perm of permArray) {
            if(this.checkEnvPermission(perm)) {
                return true;
            }
        }
        return false;
    }

    sortArrayOrder(a, b) {
        if (+a.value.order == +b.value.order) {
            return 0;
        } else {
            return +a.value.order < +b.value.order ? -1 : 1;
        }
    }

    checkDataLength(data) {
        let check = false;
        if(data != undefined && data != null) {
            check = (Array.isArray(data) && data.length) || (!Array.isArray(data) && Object.keys(data).length) ? true : false;
        }

        return check;
    }

    addClassElement(element, className) {
        $(element).addClass(className);
    }

    setTableScroll(contentEl, tableEl) {
        let contentWidth = $(contentEl).width();
        let tableWidth = $(tableEl).outerWidth();
        if (tableWidth > contentWidth) {
            $(tableEl).addClass("table-horizontal-extended");
        } else {
            $(tableEl).removeClass("table-horizontal-extended");
        }
    }

    setDaysHoursMinutesText(minutes) {
        let data = this.setDaysHoursMinutesData(minutes);
        let text = "";
        if (data.days) {
            text += data.days + " jour(s), ";
        }
        if (data.hours) {
            text += data.hours + " heure(s), ";
        }
        if (data.minutes) {
            text += data.minutes + " minute(s)";
        }
        if (text[text.length - 1] == " ") {
            text = text.slice(0, -2);
        }
        return text;
    }

    setDaysHoursMinutesData(minutes) {
        minutes = Math.ceil(minutes);
        let data = {
            days: 0,
            hours: 0,
            minutes: 0,
        };
        if (minutes / (60 * 24) >= 1) {
            data.days = Math.floor(minutes / (60 * 24));
            minutes -= data.days * 60 * 24;
        }
        if (minutes / 60 >= 1) {
            data.hours = Math.floor(minutes / 60);
            minutes -= data.hours * 60;
        }
        data.minutes = minutes;
        return data;
    }

    getBrowser() {
        const agent = window.navigator.userAgent.toLowerCase();
        switch (true) {
            case agent.indexOf("edge") > -1:
                return "edge";
            case agent.indexOf("opr") > -1 && !!(<any>window).opr:
                return "opera";
            case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
                return "chrome";
            case agent.indexOf("trident") > -1:
                return "ie";
            case agent.indexOf("firefox") > -1:
                return "firefox";
            case agent.indexOf("safari") > -1:
                return "safari";
            default:
                return "other";
        }
    }

    addChoices(items, choices) {
        choices.forEach(choice => {
            items = [...items, choice];
        });

        return items;
    }

    getChoice(value) {
        if(value) {
            if(value.constructor !== Array) {   // If object
                return value.id;
            }
            else {
                let arrIds = [];
                value.forEach(val => {
                    arrIds.push(val.id);
                });
                
                return arrIds;
            }
        }

        return null;
    }

    displayTempMessage(msg = "ERROR", status = "error", delay = 4000) {
        let type = "danger";
        let icon = "pe-7s-close-circle";
        switch (status) {
            case "success":
                type = "success";
                icon = "pe-7s-check";
                break;
            case "error":
                type = "danger";
                icon = "pe-7s-close-circle";
                break;
            case "warning":
                type = "warning";
                icon = "pe-7s-attention";
                break;
        }
        $.notify(
            {
                icon: icon,
                message: msg,
            },
            {
                type: type,
                delay: delay,
                placement: {
                    from: "top",
                    align: "center",
                },
            }
        );
    }

    showSpecialMsg(msg, delay = 4000, status = "error", data: any = {}) {
        let now = moment();
        if (
            !this.lastNotif ||
            (this.lastNotif && now.diff(this.lastNotif, "milliseconds") > delay)
        ) {
            let message = msg || "ERROR";

            let type = "danger";
            let icon = "pe-7s-close-circle";
            switch (status) {
                case "success":
                    type = "success";
                    icon = "pe-7s-check";
                    break;
                case "error":
                    type = "danger";
                    icon = "pe-7s-close-circle";
                    break;
                case "warning":
                    type = "warning";
                    icon = "pe-7s-attention";
                    break;
            }

            $.notify(
                {
                    icon: icon,
                    message: message,
                },
                {
                    type: type,
                    delay: delay,
                    placement: {
                        from: "top",
                        align: "center",
                    },
                }
            );
            this.lastNotif = now;
        }
    }

    showMsg(data) {
        let now = moment();
        let msg = data.msg;
        let delay = data.hasOwnProperty('delay') ? data.delay : 4000;
        let status = data.hasOwnProperty('status') ? data.status : "error";
        let value = data.hasOwnProperty('value') ? data.value : null;
        let ignoreLastNotif = data.hasOwnProperty('ignoreLastNotif') ? data.ignoreLastNotif : false;

        if((!this.lastNotif || ignoreLastNotif) || (this.lastNotif && now.diff(this.lastNotif, "milliseconds") > delay)) {
            msg = "SHOWMSG." + status.toString().toUpperCase() + "." + msg.toString().toUpperCase();
            let message = this._t(msg, value);

            let type = "danger";
            let icon = "pe-7s-close-circle";
            switch(status) {
                case "success":
                    type = "success";
                    icon = "pe-7s-check";
                    break;
                case "error":
                    type = "danger";
                    icon = "pe-7s-close-circle";
                    break;
                case "warning":
                    type = "warning";
                    icon = "pe-7s-attention";
                    break;
            }

            $.notify({
                icon: icon,
                message: message,
            },
            {
                type: type,
                delay: delay,
                placement: {
                    from: "top",
                    align: "center",
                },
            });
            this.lastNotif = now;
        }
    }

    readableColorFromHex(bg_color: string, alpha: number = 1) {
        let color = "rgba(0,0,0,1)";
        if (bg_color && bg_color.match(/^#([a-f0-9]{3}|[a-f0-9]{6})$/i)) {
            let formatColor = bg_color;
            if (formatColor.match(/^#[a-f0-9]{3}$/i)) {
                formatColor =
                    "#" +
                    formatColor[1] +
                    formatColor[1] +
                    formatColor[2] +
                    formatColor[2] +
                    formatColor[3] +
                    formatColor[3];
            }
            let rgb = this.invertColor(this.hexColorToRGB(formatColor));
            color = `rgba(${rgb.r},${rgb.g},${rgb.b},${alpha})`;
        }

        return color;
    }

    private invertColor(rgb) {
        let yuv = this.rgb2yuv(rgb);
        let factor = 100;
        let threshold = 100;
        yuv.y = this.clamp(yuv.y + (yuv.y > threshold ? -factor : factor));
        return this.yuv2rgb(yuv);
    }

    hexColorToRGB(color) {
        color = color.substring(1);
        return {
            r: parseInt(color.substring(0, 2), 16),
            g: parseInt(color.substring(2, 4), 16),
            b: parseInt(color.substring(4, 6), 16),
            a: 1,
        };
    }

    private rgb2yuv(rgb) {
        let y = this.clamp(rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114);
        let u = this.clamp(
            rgb.r * -0.16874 + rgb.g * -0.33126 + rgb.b * 0.5 + 128
        );
        let v = this.clamp(
            rgb.r * 0.5 + rgb.g * -0.41869 + rgb.b * -0.08131 + 128
        );
        return { y: y, u: u, v: v };
    }

    private yuv2rgb(yuv) {
        let y = yuv.y;
        let u = yuv.u;
        let v = yuv.v;
        let r = this.clamp(y + (v - 128) * 1.402);
        let g = this.clamp(y + (u - 128) * -0.34414 + (v - 128) * -0.71414);
        let b = this.clamp(y + (u - 128) * 1.772);
        return { r: r, g: g, b: b };
    }

    private clamp(n) {
        if (n < 0) {
            return 0;
        }
        if (n > 255) {
            return 255;
        }
        return Math.floor(n);
    }

    private dec2hex(n) {
        let hex = n.toString(16);
        if (hex.length < 2) {
            return "0" + hex;
        }
        return hex;
    }

    uuid(): string {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                let r = (Math.random() * 16) | 0,
                    v = c == "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            }
        );
    }

    hasRole(roles: string[] | string): boolean {
        const currentUser = this.localStorageService.getLSItem("currentUser");
        if (!currentUser) {
            return false;
        }

        if (!Array.isArray(roles)) {
            roles = [roles];
        }

        for (let role of currentUser.roles || []) {
            if (roles.includes(role.name)) {
                return true;
            }
        }

        return false;
    }

    patchErrors(errors: any, form: UntypedFormGroup|UntypedFormArray) {
        for (let key in errors) {
            const control = form.get(key);
            if (control) {
                control.markAsTouched();
                control.setErrors({ message: errors[key][0] });
            }
        }
    }

    pad(number, size) {
        let s = `${number}`;
        while (s.length < size) {
            s = "0" + s;
        }
        return s;
    }

    validLang(code: string): boolean {
        return !!this.availableLangs().find(l => l.code == code);
    }

    availableLangs(): any[] {
        return [
            { code: 'fr', flag: 'FR', name: 'Français', },
            { code: 'nl', flag: 'NL', name: 'Nederlands', },
        ];
    }

    setLang(code?: string): void {
        if(!this.validLang(code)) {
            code = 'fr';
        }

        this.translateService.use(code);
        this.localStorageService.setLSItem('lang', code);
        this.translateService.setDefaultLang(code);

        moment.locale(code);
    }

    setUserLanguage(code) {
        if(this.checkDataLength(this.localStorageService.getLSItem('currentUser'))) {
            this.userService.updateLanguage({ code: code }).subscribe(data => {
                this.translateService.setDefaultLang(code);
            }, error => {
                this.showMsg({ msg: "LANGUAGE_NOT_FOUND" });
            });
        }
    }
}
