import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
    providedIn: 'root'
})
export class VisitorMeasureService extends ApiService {
    getByVisitors(data: any) {
        return super.post('/visitors-measure/visitors', data);
    }

    getDatesByVisitor(data: any) {
        return super.post('/visitors-measure/visitors/dates', data);
    }

    getCountDayAndFuture(id: number) {
        return super.get("/visitors-measure/count/day-future/" + id);
    }

    create(data: any) {
        return super.post('/visitors-measure/create', data);
    }

    update(data: any) {
        return super.put('/visitors-measure/update', data);
    }

    delete(id: number) {
        return super.delete('/visitors-measure/' + id);
    }
}
