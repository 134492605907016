import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
    providedIn: 'root'
})
export class VisitorPsychoService extends ApiService {
    getById(id: number) {
        return super.get('/visitors-psycho/' + id);
    }

    getByVisitor(id: number) {
        return super.get('/visitors-psycho/visitor/' + id);
    }

    create(data: any) {
        return super.post('/visitors-psycho/create', data);
    }

    update(data: any) {
        return super.put('/visitors-psycho/update', data);
    }

    delete(id: number) {
        return super.delete('/visitors-psycho/' + id);
    }
}
