import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { User } from '@app/_models/index';
import { LocalStorageService } from '@services/localStorage.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from '../../../node_modules/rxjs/operators';
import { ApiService } from '@services/api.service';

@Injectable({
    providedIn: 'root'
})
export class UserService extends ApiService {
    private currentUserSubject = new BehaviorSubject(null);
    public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

    private isCustomerSubject = new BehaviorSubject(true);
    public isCustomerObsevable = this.isCustomerSubject.asObservable().pipe(distinctUntilChanged());

    public filesTokensSubject = new BehaviorSubject(null);
    public filesTokens = this.filesTokensSubject.asObservable().pipe(distinctUntilChanged());

    get getCurrentUser() {
        return this.currentUserSubject.value;
    }

    get isCustomer() {
        return this.isCustomerSubject.value;
    }

    get getFilesTokens(): any {
        return this.filesTokensSubject.value;
    }

    constructor(protected http: HttpClient,
                private localStorageService: LocalStorageService,
                private ngxPermissionsService: NgxPermissionsService) {
        super(http);
    }

    getAll() {
        return this.http.get(environment.apiUrl + '/users').pipe(map((data:any) => data));
    }

    getAllFilters(data: any) {
        return this.http.post(environment.apiUrl + '/users/filters', data).pipe(map((data:any) => data));
    }

    getById(id: number) {
        return this.http.get(environment.apiUrl + '/users/' + id).pipe(map((data:any) => data));
    }
    
    checkLogin(data: any) {
        return this.http.post(environment.apiUrl + '/users/login/check', data).pipe(map((data: any) => data));
    }

    checkUserConnected() {
        return this.http.get(environment.apiUrl + '/users/check/connected').pipe(map((data: any) => data));
    }

    create(data: any) {
        return this.http.post(environment.apiUrl + '/users', data).pipe(map((data:any) => data));
    }

    update(data: any) {
        return this.http.put(environment.apiUrl + '/users', data).pipe(map((data:any) => data));
    }

    updatePwd(user: User) {
        return this.http.put(environment.apiUrl + '/users/pwd', user).pipe(map((data:any) => data));
    }

    updatePassword(data: any) {
        return super.put('/users/password', data);
    }

    updateLanguage(data: any) {
        return this.http.put(environment.apiUrl + '/users/language', data).pipe(map((data:any) => data));
    }

    requestPwdEmail(data) {
        return this.http.post(environment.apiUrl + '/password/email', data).pipe(map((data:any) => data));
    }

    requestPwdReset(data) {
        return this.http.post(environment.apiUrl + '/password/reset', data).pipe(map((data:any) => data));
    }

    setSuperAdmins(data: any) {
        return this.http.post(environment.apiUrl + '/users/super-admins', data).pipe(map((data:any) => data));
    }

    forgetCache() {
        return this.http.get(environment.apiUrl + '/perm').pipe(map((data:any) => data));
    }

    delete(id: number) {
        return this.http.delete(environment.apiUrl + '/users/' + id).pipe(map((data:any) => data));
    }

    me() {
        let user = this.localStorageService.getLSItem('currentUser');
        if (user && user.api_token) {
            this.http.get<any>(environment.apiUrl + '/me').subscribe(
                data => this.saveAuth(data),
                () => this.cleanAuth(),
            );
        } else {
            this.cleanAuth();
        }
    }

    saveAuth(data: any) {
        const user = data.user || data.data || null;

        if (user) {
            this.currentUserSubject.next(user);
            if(data.permissions != undefined && data.permissions) {
                this.ngxPermissionsService.loadPermissions(data.permissions);
            }
            if (!user.roles) {
                this.isCustomerSubject.next(true);
            } else {
                this.isCustomerSubject.next(!!(user.roles || []).find(role => role.name == 'customer'));
            }

            this.filesTokensSubject.next(data?.files_tokens);

            if(data.permissions) this.localStorageService.setLSItem('currentUserPermissions', data.permissions);
            if(data.env) this.localStorageService.setLSItem('env', data.env);
        } else {
            this.cleanAuth();
        }
    }

    cleanAuth() {
        this.currentUserSubject.next(false);
        this.ngxPermissionsService.flushPermissions();

        this.localStorageService.removeLSItem('currentUser');
        this.localStorageService.removeLSItem('currentUserPermissions');
        this.localStorageService.removeLSItem('params');
    }
}
