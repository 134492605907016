<div class="row">
    <div class="col-md-12">
        <app-util-visitor-parts-daily-entry-list-filter
                                            [selfVisitor]="selfVisitor"
                                            (filterEvent)="filterVisitorEntries($event)"></app-util-visitor-parts-daily-entry-list-filter>
    </div>
</div>
<div class="row mt-5">
    <div class="col-md-12 align-items-center justify-content-space-between">
        <h5 class="title mb-3">
            <ng-container *ngIf="selfVisitor">
                {{ 'VISITORS_DAILY_ENTRIES.VISITOR_ENTRIES' | translate }}
            </ng-container>
            <ng-container *ngIf="!selfVisitor">
                {{ 'VISITORS_DAILY_ENTRIES.VISITORS_ENTRIES' | translate }}
            </ng-container>
        </h5>
        <button type="button"
                class="btn btn-primary btn-fill"
                (click)="utilVisitorPartsDailyEntryModalsAdd.openModal(visitor?.id)"
                *ngxPermissionsOnly="'cudVisitorDailyEntry'">
            <i class="fa fa-plus"></i> {{ 'VISITORS_DAILY_ENTRIES.ADD_ENTRY' | translate }}
        </button>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-12">
        <ng-container *ngIf="!isLoading">
            <ng-container *ngIf="hasData?.choiceGroupInstruction">
                <ng-container *ngIf="!isLoadingVisitors">
                    <ng-container *ngIf="hasData?.visitorEntries">
                        <div class="overflow-auto pb-3">
                            <table class="table table-condensend table-striped">
                                <thead>
                                    <tr>
                                        <th class="p-3 text-center-important">#</th>
                                        <th class="p-3 text-center-important"
                                            *ngxPermissionsOnly="'cudVisitorDailyEntry'">{{ 'SHARED.ACTIONS' | translate }}</th>
                                        <th class="p-3 text-center-important">{{ 'VISITORS_DAILY_ENTRIES.DATE' | translate }}</th>
                                        <ng-container *ngIf="!selfVisitor">
                                            <th class="p-3 text-center-important">{{ 'VISITORS_DAILY_ENTRIES.VISITOR' | translate }}</th>
                                        </ng-container>
                                        <th class="p-3 text-center-important" *ngFor="let header of columnHeaders">
                                            {{ header?.text }}
                                            <ng-container *ngIf="!isInArray(['instruction_id', 'instruction_paper_id', 'miscellaneous'], header?.key)">
                                                <br />
                                                {{ header?.count }}
                                            </ng-container>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let visitorEntry of visitorEntries?.data; let i = index">
                                        <td class="p-3 text-center-important">{{ i + 1 }}</td>
                                        <td class="p-3 text-center-important td-actions"
                                            *ngxPermissionsOnly="'cudVisitorDailyEntry'">
                                            <button type="button"
                                                    class="btn btn-success btn-simple p-0"
                                                    title="{{ 'VISITORS_DAILY_ENTRIES.EDIT_ENTRY' | translate }}"
                                                    (click)="utilVisitorPartsDailyEntryModalsAdd.openModal(visitor?.id, visitorEntry)"
                                                    appTooltip>
                                                <i class="fa fa-edit"></i>
                                            </button>
                                            <button type="button"
                                                    class="btn btn-danger btn-simple ml-3 p-0"
                                                    title="{{ 'VISITORS_DAILY_ENTRIES.DELETE_ENTRY' | translate }}"
                                                    (click)="delete(visitorEntry)"
                                                    appTooltip>
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </td>
                                        <td class="p-3 text-center-important">{{ visitorEntry?.date | localizedDate: true | ifEmpty: '-' }}</td>
                                        <ng-container *ngIf="!selfVisitor">
                                            <td class="p-3 text-center-important">
                                                <a [routerLink]="['/visitor', visitorEntry?.vId, 'daily-entry']"
                                                    class="text-primary">
                                                    {{ visitorEntry?.showName }}
                                                </a>
                                            </td>
                                        </ng-container>
                                        <ng-container *ngFor="let header of columnHeaders">
                                            <td class="p-3 text-center-important">
                                                <ng-container *ngIf="!isInArray(['instruction_id', 'instruction_paper_id', 'miscellaneous'], header?.key)">
                                                    <app-icon-check-close [value]="visitorEntry[header?.key]"></app-icon-check-close>
                                                    <ng-container *ngIf="header?.key == 'shower' && visitorEntry?.number_showers">
                                                        ({{ visitorEntry?.number_showers }})
                                                    </ng-container>
                                                    <ng-container *ngIf="header?.key == 'laundry' && visitorEntry?.number_laundries">
                                                        ({{ visitorEntry?.number_laundries }})
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngIf="isInArray(['instruction_id', 'instruction_paper_id'], header?.key)">
                                                    {{ showInstructionChoice(visitorEntry[header?.key]) }}
                                                </ng-container>
                                                <ng-container *ngIf="isInArray(['miscellaneous'], header?.key)">
                                                    <app-text-limit [text]="visitorEntry[header?.key]" [title]="'REMARK'"></app-text-limit>
                                                </ng-container>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="align-items-center justify-content-center" *ngIf="visitorEntries?.last_page > 1">
                                <app-pagination [data]="visitorEntries" (switchPage)="switchPage($event)"></app-pagination>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!hasData?.visitorEntries">
                        <p>
                            <ng-container *ngIf="selfVisitor">
                                {{ 'VISITORS_DAILY_ENTRIES.NO_VISITOR_ENTRY_FOUND' | translate }}
                            </ng-container>
                            <ng-container *ngIf="!selfVisitor">
                                {{ 'VISITORS_DAILY_ENTRIES.NO_VISITORS_ENTRY_FOUND' | translate }}
                            </ng-container>
                        </p>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="isLoadingVisitors">
                    <p>
                        <app-loading-spinner></app-loading-spinner>
                    </p>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!hasData?.choiceGroupInstruction">
                <p>{{ 'CHOICES.NO_CHOICES_INSTRUCTION_FOUND' | translate }}</p>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="isLoading">
            <p>
                <app-loading-spinner></app-loading-spinner>
            </p>
        </ng-container>
    </div>
</div>
<app-util-visitor-parts-daily-entry-modals-add (addEntryEvent)="getVisitorsEntries()" #utilVisitorPartsDailyEntryModalsAdd></app-util-visitor-parts-daily-entry-modals-add>