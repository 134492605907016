import { Component, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MiscService, VisitorSuspensionService } from '@services/index';
import { Globals } from '@app/app.globals';
import * as moment from 'moment';

declare var swal: any;

@Component({
    selector: 'app-util-visitor-modals-suspensions',
    templateUrl: './suspensions.component.html',
})
export class UtilVisitorModalsSuspensionsComponent implements OnInit {
    @Output() manageEvent = new EventEmitter<any>();

    isLoading = false;
    modal = "#suspensionsModal";
    visitor: any;
    visitorSuspensions: any;
    hasData = {
        visitor: false,
        visitorSuspensions: false
    };
    hasPermission = {
        cudVisitor: this.miscService.checkEnvPermission("cudVisitor")
    };

    constructor(private cdref: ChangeDetectorRef,
                private miscService: MiscService,
                private visitorSuspensionService: VisitorSuspensionService,
                public globals: Globals) {}

    @ViewChild('utilVisitorModalsSuspensionsCancel') utilVisitorModalsSuspensionsCancel;

    ngOnInit() {
        this.cdref.detectChanges();
    }
    
    openModal(visitor) {
        this.isLoading = true;
        this.visitor = visitor;
        this.hasData.visitor = this.miscService.checkDataLength(this.visitor);
        if(this.hasData.visitor) {
            this.getVisitorSuspensions(true);
        }
    }

    getVisitorSuspensions(openModal = true) {
        this.isLoading = true;
        this.visitorSuspensionService.getByVisitor(this.visitor.id).subscribe(data => {
            this.visitorSuspensions = data;
            this.hasData.visitorSuspensions = this.miscService.checkDataLength(this.visitorSuspensions);
            if(openModal) {
                $(this.modal).modal('show');
            }
            this.isLoading = false;
        });
    }

    checkCurrentSuspension(visitorSuspension) {
        if(visitorSuspension.active) {
            let now = moment();
            if(now.isAfter(visitorSuspension.begin)) {
                if(!visitorSuspension.end || now.isBefore(visitorSuspension.end)) {
                    return true;
                }
            }
        }

        return false;
    }

    manage() {
        this.getVisitorSuspensions(false);
        this.manageEvent.emit();
    }

    activate(visitorSuspension) {
        let self = this;
        if(visitorSuspension.active) {
            this.utilVisitorModalsSuspensionsCancel.openModal(visitorSuspension);
        }
        else {
            swal({
                title: this.miscService._t('VISITORS_SUSPENSIONS.REACTIVATE_SUSPENSION') + " ?",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: this.miscService._t('SHARED.CONFIRM'),
                cancelButtonText: this.miscService._t('SHARED.CANCEL'),
                closeOnConfirm: true,
                closeOnCancel: true,
            }, function(isConfirm) {
                if(isConfirm) {
                    let data = {
                        id: visitorSuspension.id
                    }
                    self.visitorSuspensionService.activate(data).subscribe(data => {
                        self.getVisitorSuspensions(false);
                        self.manageEvent.emit();
                        self.miscService.showMsg({ msg: "VISITOR_SUSPENSION_REACTIVATED", status: "success" });
                    }, error => {
                        self.miscService.showMsg({ msg: "VISITOR_SUSPENSION_NOT_REACTIVATED" });
                    });
                }
            });
        }
    }

    delete(id) {
        let self = this;
        swal({
            title: this.miscService._t('VISITORS_SUSPENSIONS.DELETE_SUSPENSION') + " ?",
            text: this.miscService._t('VISITORS_SUSPENSIONS.ARE_YOU_SURE_DELETE_SUSPENSION'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.miscService._t('SHARED.CONFIRM'),
            cancelButtonText: this.miscService._t('SHARED.CANCEL'),
            closeOnConfirm: true,
            closeOnCancel: true,
        }, function(isConfirm) {
            if(isConfirm) {
                self.visitorSuspensionService.delete(id).subscribe(data => {
                    self.getVisitorSuspensions(false);
                    self.manageEvent.emit();
                    self.miscService.showMsg({ msg: "VISITOR_SUSPENSION_DELETED", status: "success" });
                }, error => {
                    self.miscService.showMsg({ msg: "VISITOR_SUSPENSION_NOT_DELETED" });
                });
            }
        });
    }
}
