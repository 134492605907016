import { Component, OnInit } from '@angular/core';
import { MiscService, AuthenticationService, UserService } from '@services/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';

declare var $:any;

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    anchor?: string;
    trad_key?: string;
    // icon: string;
    permissionsOr?: string[];
    permissionsAnd?: string[];
    permissionsNot?: string[];
    right?: string;
    children?: ChildrenItems[];
    canActivate?: any;
}

export interface ChildrenItems {
    path: string;
    title: string;
    trad_key?: string,
    ab: string;
    fullPath?: boolean;
    type?: string;
    permissionsOr?: string[];
}

//Menu Items
export const ROUTES: RouteInfo[] = [
    {
        path: '/dashboard',
        title: 'Dashboard',
        trad_key: 'TITLES.DASHBOARD',
        icontype: 'pe-7s-home',
        type: 'link'
    },
    {
        path: '/visitor',
        title: 'Visiteurs',
        trad_key: 'TITLES.VISITORS',
        icontype: 'pe-7s-id',
        anchor: 'vis',
        type: 'sub',
        children: [
            { path: '', title: 'Visiteurs', trad_key: 'TITLES.VISITORS', ab: "v", permissionsOr: ['getVisitor'] },
            { path: '/add', title: 'Ajouter un visiteur', trad_key: 'TITLES.VISITOR_ADD', ab: "av", permissionsOr: ['cudVisitor'] },
        ],
        permissionsOr: ['getVisitor']
    },
    {
        path: '/daily-entries',
        title: 'Entrées du jour',
        trad_key: 'TITLES.DAILY_ENTRIES',
        icontype: 'pe-7s-credit',
        type: 'link'
    },
    {
        path: '/appointments',
        title: 'Rendez-vous',
        trad_key: 'TITLES.APPOINTMENTS',
        icontype: 'pe-7s-alarm',
        type: 'link'
    },
    {
        path: '/user',
        title: 'Utilisateurs',
        trad_key: 'TITLES.USERS',
        icontype:'pe-7s-users',
        anchor: 'user',
        type: 'sub',
        children: [
            { path: '', title: 'Utilisateurs', trad_key: 'TITLES.USERS', ab: "u", permissionsOr: ['getUser'] },
            { path: '/add', title: 'Ajouter un utilisateur', trad_key: 'TITLES.USER_ADD', ab: "au", permissionsOr: ['cudUser'] },
        ],
        permissionsOr: ['getUser']
    },
    {
        path: '/statistics',
        title: 'Statistiques',
        trad_key: 'TITLES.STATISTICS',
        icontype: 'pe-7s-graph2',
        type: 'link',
        permissionsOr: ['getStatistic']
    }
];

@Component({
    moduleId: module.id,
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public isNightMode: any;
    public companyID:number;
    public settingsData = {
        logo_url: { value: "/assets/img/logo.png" },
        company_id: null
    };
    public companyData:any = {
        name: environment.defaultSoftwareName,
    };
    public currentUserData:any;
    private permissions: any;

    constructor(private miscService: MiscService,
                private ngxPermissionsService: NgxPermissionsService,
                private translateService: TranslateService,
                private userService: UserService,
                private authenticationService: AuthenticationService) {
        this.translateService.setDefaultLang("fr");
    }

    isNotMobileMenu() {
        if($(window).width() > 991) {
            return false;
        }
        return true;
    }

    ngOnInit() {
        this.userService.currentUser.subscribe(data => {
            this.currentUserData = data;
        });
        var isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;
        this.permissions = Object.keys(this.ngxPermissionsService.getPermissions());
        isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;
        this.checkSidebar();
    }

    checkSidebar() {
        this.menuItems = ROUTES.filter(menuItem => {
            return this.checkPermission(menuItem);
        });
        for(let i in this.menuItems) {
            if(this.menuItems[i].hasOwnProperty("children")) {
                this.menuItems[i].children = this.menuItems[i].children.filter(mi => {
                    mi.title = this.miscService._t(mi.title);
                    return this.checkPermission(mi);
                });
            }
            this.menuItems[i].title = this.miscService._t(this.menuItems[i].title);
        }
    }

    ngAfterViewInit() {
        var $sidebarParent = $('.sidebar .nav > li.active .collapse li.active > a').parent().parent().parent();
        var collapseId = $sidebarParent.siblings('a').attr("href");
        $(collapseId).collapse("show");
    }

    hideBar() {
        var body = document.getElementsByTagName('body')[0];
        var toggleButton = body.getElementsByClassName('navbar-toggle')[0];
        if(toggleButton && body) {
            toggleButton.classList.remove('toggled');
            body.classList.remove('nav-open');
        }
    }

    checkRight(mi, rights) {
        if(mi.hasOwnProperty("right")) {
            let check = false;
            for(var i in rights) {
                if(rights[i].key == mi.right) {
                    check = true;
                }
            }
            return check;
        }

        return true;
    }

    checkPermission(mi) {
        if(mi.hasOwnProperty("permissionsNot")) {   // aucune permission ne doit être présente
            for(let perm of mi.permissionsNot) {
                if(this.permissions.indexOf(perm) >= 0) {
                    return false;    // false si au moins une des permessions est là
                }
            }
        }

        if(mi.hasOwnProperty("permissionsAnd")) {   // toutes les permissions doivent être présentes
            for(let perm of mi.permissionsAnd) {
                if(this.permissions.indexOf(perm) == -1) {
                    return false;       // false si au moins une des permissions manque
                }
            }
            return true;    // false si toutes les permissions sont là
        }
        else if(mi.hasOwnProperty("permissionsOr")) {   // au moins une des permissions doit être présente
            for(let perm of mi.permissionsOr) {
                if(this.permissions.indexOf(perm) >= 0) {
                    return true;    // true si au moins une des permissions est là
                }
            }
            return false;   // false si aucune des permissions n'a été trouvée
        }

        return true;    // true si aucune permission n'est nécessaire
    }

    getUrlLogo(): string {
        return this.settingsData!= null ? this.settingsData.logo_url.value ? this.settingsData.logo_url.value : '/assets/img/logo.png' : '/assets/img/logo.png';
    }

    logout() {
        this.authenticationService.logout();
        this.hideBar();
    }
}
