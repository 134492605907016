<ng-container *ngIf="!isLoading">
    <div class="align-items-center justify-content-space-between">
        <button type="button"
                class="btn btn-success"
                (click)="utilVisitorMedicalFileModalsAdd.openModal(visitor?.id, visitorMedical)"
                *ngIf="!isLoading">
            <ng-container *ngIf="!hasData?.visitorMedical">
                <i class="fa fa-edit"></i> {{ 'VISITORS_MEDICAL.CREATE_MEDICAL_DATA' | translate }}
            </ng-container>
            <ng-container *ngIf="hasData?.visitorMedical">
                <i class="fa fa-plus"></i> {{ 'VISITORS_MEDICAL.EDIT_MEDICAL_DATA' | translate }}
            </ng-container>
        </button>
        <ng-container *ngIf="hasData?.visitorMedical">
            <div>
                <button type="button"
                        class="btn btn-primary"
                        (click)="utilVisitorPartsMedicalMedicalModalsFiles.openModal()"
                        *ngIf="!isLoading">
                    <i class="fa fa-file"></i> {{ 'UPLOAD.SEE_FILES' | translate }}
                </button>
                <app-util-visitor-parts-medical-medical-modals-files [visitorMedicalId]="visitorMedical?.id"
                                                                #utilVisitorPartsMedicalMedicalModalsFiles></app-util-visitor-parts-medical-medical-modals-files>
            </div>
        </ng-container>
    </div>
    <ng-container *ngIf="hasData?.visitorMedical">
        <div class="row mt-3">
            <div class="col-md-12">
                <div class="card">
                    <div class="header card-header-title">
                        <h5 class="title">{{ 'VISITORS_MEDICAL.CATEGORIES_DIAGNOSTIC' | translate }}</h5>
                    </div>
                    <div class="content">
                        <div class="row">
                            <div class="col-md-6 col-xs-12">
                                <p>
                                    <strong>{{ 'VISITORS_MEDICAL.ANXIETY_DISORDER' | translate }} :</strong> 
                                    <app-icon-check-close [value]="visitorMedical?.anxiety_disorder" [moreClasses]="'ml-3'"></app-icon-check-close>
                                </p>
                                <p>
                                    <strong>{{ 'VISITORS_MEDICAL.ALCOHOL_ADDICTION' | translate }} :</strong> 
                                    <app-icon-check-close [value]="visitorMedical?.alcohol_addiction" [moreClasses]="'ml-3'"></app-icon-check-close>
                                    <ng-container *ngIf="visitorMedical?.alcohol_addiction && visitorMedical?.alcohol_addiction_remark">
                                        <i class="fa fa-question-circle fa-tiny ml-2"
                                            [attr.title]="visitorMedical?.alcohol_addiction_remark"
                                            appTooltip></i>
                                    </ng-container>
                                </p>
                                <p>
                                    <strong>{{ 'VISITORS_MEDICAL.DRUGS_ADDICTION' | translate }} :</strong> 
                                    <app-icon-check-close [value]="visitorMedical?.drugs_addiction" [moreClasses]="'ml-3'"></app-icon-check-close>
                                    <ng-container *ngIf="visitorMedical?.drugs_addiction && visitorMedical?.drugs_addiction_remark">
                                        <i class="fa fa-question-circle fa-tiny ml-2"
                                            [attr.title]="visitorMedical?.drugs_addiction_remark"
                                            appTooltip></i>
                                    </ng-container>
                                </p>
                                <p>
                                    <strong>{{ 'VISITORS_MEDICAL.PHARMACEUTICALS_ADDICTION' | translate }} :</strong> 
                                    <app-icon-check-close [value]="visitorMedical?.pharmaceuticals_addiction" [moreClasses]="'ml-3'"></app-icon-check-close>
                                    <ng-container *ngIf="visitorMedical?.pharmaceuticals_addiction && visitorMedical?.pharmaceuticals_addiction_remark">
                                        <i class="fa fa-question-circle fa-tiny ml-2"
                                            [attr.title]="visitorMedical?.pharmaceuticals_addiction_remark"
                                            appTooltip></i>
                                    </ng-container>
                                </p>
                                <p>
                                    <strong>{{ 'VISITORS_MEDICAL.UNIPOLAR_DEPRESSION' | translate }} :</strong> 
                                    <app-icon-check-close [value]="visitorMedical?.unipolar_depression" [moreClasses]="'ml-3'"></app-icon-check-close>
                                </p>
                                <p>
                                    <strong>{{ 'VISITORS_MEDICAL.BIPOLAR_DISORDERS' | translate }} :</strong> 
                                    <app-icon-check-close [value]="visitorMedical?.bipolar_disorders" [moreClasses]="'ml-3'"></app-icon-check-close>
                                </p>
                                <p>
                                    <strong>{{ 'VISITORS_MEDICAL.PSYCHOSIS' | translate }} :</strong> 
                                    <app-icon-check-close [value]="visitorMedical?.psychosis" [moreClasses]="'ml-3'"></app-icon-check-close>
                                </p>
                            </div>
                            <div class="col-md-6 col-xs-12">
                                <p>
                                    <strong>{{ 'VISITORS_MEDICAL.MENTAL_RETARDATION' | translate }} :</strong> 
                                    <app-icon-check-close [value]="visitorMedical?.mental_retardation" [moreClasses]="'ml-3'"></app-icon-check-close>
                                </p>
                                <p>
                                    <strong>{{ 'VISITORS_MEDICAL.SIGNIFICANT_COGNITIVE_DISORDERS' | translate }} :</strong> 
                                    <app-icon-check-close [value]="visitorMedical?.significant_cognitive_disorders" [moreClasses]="'ml-3'"></app-icon-check-close>
                                </p>
                                <p>
                                    <strong>{{ 'VISITORS_MEDICAL.EATING_DISORDERS' | translate }} :</strong> 
                                    <app-icon-check-close [value]="visitorMedical?.eating_disorders" [moreClasses]="'ml-3'"></app-icon-check-close>
                                </p>
                                <p>
                                    <strong>{{ 'VISITORS_MEDICAL.PERSONALITY_DISORDERS' | translate }} :</strong> 
                                    <app-icon-check-close [value]="visitorMedical?.personality_disorders" [moreClasses]="'ml-3'"></app-icon-check-close>
                                </p>
                                <p>
                                    <strong>{{ 'VISITORS_MEDICAL.PATHOLOGICAL_GAMBLING' | translate }} :</strong> 
                                    <app-icon-check-close [value]="visitorMedical?.pathological_gambling" [moreClasses]="'ml-3'"></app-icon-check-close>
                                </p>
                                <p>
                                    <strong>{{ 'VISITORS_MEDICAL.OTHER_DISORDER' | translate }} :</strong> 
                                    <app-icon-check-close [value]="visitorMedical?.other_disorder" [moreClasses]="'ml-3'"></app-icon-check-close>
                                </p>
                                <p>
                                    <strong>{{ 'VISITORS_MEDICAL.NOT_ESTABLISHED_DIAGNOSTIC' | translate }} :</strong> 
                                    <app-icon-check-close [value]="visitorMedical?.not_established_disorders" [moreClasses]="'ml-3'"></app-icon-check-close>
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p>
                                    <strong>{{ 'VISITORS_MEDICAL.INFORMATION' | translate }} :</strong> 
                                    {{ visitorMedical?.informations | ifEmpty: '-' }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12">
                <div class="card">
                    <div class="header card-header-title">
                        <h5 class="title">{{ 'VISITORS_MEDICAL.PSY_DATA' | translate }}</h5>
                    </div>
                    <div class="content">
                        <p>
                            <strong>{{ 'VISITORS_MEDICAL.PSY_HISTORY' | translate }} :</strong> 
                            {{ visitorMedical?.psy_history | ifEmpty: '-' }}
                        </p>
                        <p>
                            <strong>{{ 'VISITORS_MEDICAL.SOMATIC_HISTORY' | translate }} :</strong> 
                            {{ visitorMedical?.psy_somatic_history | ifEmpty: '-' }}
                        </p>
                        <p>
                            <strong>{{ 'VISITORS_MEDICAL.OLD_TREATMENTS' | translate }} :</strong> 
                            {{ visitorMedical?.psy_old_treatments | ifEmpty: '-' }}
                        </p>
                        <p>
                            <strong>{{ 'VISITORS_MEDICAL.INTOLERANCE' | translate }} :</strong> 
                            {{ visitorMedical?.psy_intolerance | ifEmpty: '-' }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-12">
                <div class="card">
                    <div class="header card-header-title">
                        <h5 class="title">{{ 'VISITORS_PSY_FOLLOW_UPS.PSY_FOLLOW_UPS' | translate }}</h5>
                    </div>
                    <div class="content">
                        <app-util-visitor-parts-medical-follow-up-list [visitor]="visitor"></app-util-visitor-parts-medical-follow-up-list>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!hasData?.visitorMedical">
        <p class="mt-3">{{ 'VISITORS_MEDICAL.NO_MEDICAL_DATA_FOUND' | translate }}</p>
    </ng-container>
</ng-container>
<ng-container *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
</ng-container>
<app-util-visitor-parts-medical-file-modals-add (addMedicalEvent)="getVisitorMedical()" #utilVisitorMedicalFileModalsAdd></app-util-visitor-parts-medical-file-modals-add>