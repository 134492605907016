import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
    providedIn: 'root'
})
export class VisitorTreatmentService extends ApiService {
    getByVisitors(data: any) {
        return super.post('/visitors-treatment/visitors', data);
    }

    getCount(id: number) {
        return super.get("/visitors-treatment/count/" + id);
    }

    generatePdf(data: any) {
        return super.post('/visitors-treatment/pdf', data);
    }

    create(data: any) {
        return super.post('/visitors-treatment/create', data);
    }

    update(data: any) {
        return super.put('/visitors-treatment/update', data);
    }

    delete(id: number) {
        return super.delete('/visitors-treatment/' + id);
    }
}
