<footer class="footer">
    <div class="container-fluid flex flex-vertical-center position-relative overflow-hidden">
        <nav class="footer-nav" hidden>
        	<ul>
        		<li>
        			<a [routerLink]="'/help'">A l'aide!</a>
        		</li>
        	</ul>
        </nav>
        <p class="copyright flex-pull-right">
            Version {{version}} (release: <ng-container *ngIf="updateDate">{{ updateDate | localizedDate }}</ng-container>) &mdash; &copy; <a href="https://yumanit.be" target="_blank">YumanIT</a>
        </p>
    </div>
</footer>
