import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MiscService, VisitorService } from '@services/index';

@Component({
    selector: 'app-ng-select-add-visitor',
    templateUrl: './visitor.component.html'
})
export class NgSelectAddVisitorComponent implements OnInit {
    @Input() canAddVisitors = false;
    @Input() multiple = true;
    @Output() changeEvent = new EventEmitter<any>();
    @Output() goToAddVisitorUrlEvent = new EventEmitter();

    isLoading = false;
    limit = 5;
    page = 1;
    term = "";
    value = null;
    visitors = [];
    hasData = {
        visitors: false
    };

    constructor(private miscService: MiscService,
                private visitorService: VisitorService) {}

    @ViewChild('ngSelectVisitor') ngSelectVisitor;

    ngOnInit() {
        this.getVisitors();
    }

    getVisitors() {
        this.isLoading = true;
            let data = {
                term: this.term,
                limit: 5,
                page: this.page,
                filter: true
            };
            this.visitorService.getAll(data).subscribe(data => {
                if(this.page == 1) {
                    this.visitors = data;
                }
                else {
                    this.visitors = this.visitors.concat(data);
                }
                this.hasData.visitors = this.miscService.checkDataLength(this.visitors);
                this.isLoading = false;
            });
    }

    nextPage() {
        this.page++;
        this.getVisitors();
    }

    search(term) {
        this.page = 1;
        this.term = term;
        this.getVisitors();
    }

    onFocus() {
        this.term = "";
        this.page = 1;
        this.visitors = [];
        this.getVisitors();
    }

    change() {
        this.changeEvent.emit(this.value ? this.value : null);
    }

    reset() {
        this.value = null;
        this.changeEvent.emit(null);
    }

    goToAddVisitorUrl() {
        this.goToAddVisitorUrlEvent.emit();
    }
}