<div class="input-group datepicker-{{ randId }} {{col}} {{moreClasses}}" id="{{ id }}" >
    <input class="form-control input-sm datepicker-{{ randId }}"
            id="datepicker-{{ randId }}"
            type="text"
            [ngModelOptions]="{standalone: true}"
            [(ngModel)]="dateModel"
            (change)="dateChanged()" />
    <div class="input-group-btn">
        <button type="button" class="btn btn-input-group-multiple set-date-now" (click)="resetDateNow()">
            <i class="fa fa-clock-o"></i>
        </button>
        <button type="button" class="btn btn-danger btn-input-group-multiple" *ngIf="dateModel && hasResetButton" (click)="resetDate()">
            <i class="fa fa-close"></i>
        </button>
    </div>
</div>
<div>
    <p class="m-0" style="font-size: 0.9em; font-style: italic;">
        {{ 'DATE.EXPECTED_FORMAT' | translate }} : {{ currentDate }}
    </p>
</div>
