import { Injectable } from '@angular/core';

declare var $:any;

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {
    // Main: sessionStorage
    checkItem(itemName) {
        return sessionStorage.getItem(itemName) !== null;
    }

  	setLSItem(itemName, object) {
		sessionStorage.setItem(itemName, JSON.stringify(object));
	}

	getLSItem(itemName) {
        if(this.checkItem(itemName)) {
            try {
                return JSON.parse(sessionStorage.getItem(itemName) || '{}');
            }
            catch(e) {
                return "";
            }
        }

		return "";
	}

	removeLSItem(itemName) {
		sessionStorage.removeItem(itemName);
	}

    // Secondary: localStorage (used to store version)
    checkRealLSItem(itemName) {
        return localStorage.getItem(itemName) !== null;
    }

  	setRealLSItem(itemName, object) {
		localStorage.setItem(itemName, JSON.stringify(object));
	}

	getRealLSItem(itemName) {
        if(this.checkRealLSItem(itemName)) {
            try {
                return JSON.parse(localStorage.getItem(itemName) || '{}');
            }
            catch(e) {
                return "";
            }
        }

		return "";
	}

	removeRealLSItem(itemName) {
		localStorage.removeItem(itemName);
	}
}
