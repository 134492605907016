import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ifEmpty'
})
export class IfEmptyPipe implements PipeTransform {

  transform(value: any, args?: string): any {
  	if (value === null || value === ""){
  		return args;
  	} else {
  		return value
  	}
  }

}
