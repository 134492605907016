import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { forkJoin, interval } from 'rxjs';
import { MiscService, UserService } from '@services/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { PermissionsService } from '@services/permissions.service';
import { LocalStorageService } from '@services/localStorage.service';
import { SoftwareService } from '@services/software.service';

declare var $:any;

@Component({
  selector: 'app-version-checker',
  templateUrl: './version-checker.component.html'
})
export class VersionCheckerComponent implements OnInit {
    public objectKeys = Object.keys;
    public checkVersionPeriod = 1000 * 60 * 10;  // every 10 mins
    public version: string;
    public updateDate: string;
    public url = "";
    public forbiddenUrls = ["/auth/password/reset"];
    public noModalUrls = [];
    public authUrl = true;
    public authModal = true;
    ver = null;

    constructor(private miscService: MiscService,
                private userService: UserService,
                private ngxPermissionsService: NgxPermissionsService,
                private permissionsService: PermissionsService,
                private localStorageService: LocalStorageService,
                private softwareService: SoftwareService) {}

    ngOnInit() {
        this.url = window.location.pathname;
        this.checkUrl();
        this.checkUrlModal();
    }

    trigger() {
        if(this.authUrl) {
            this.check();
            interval(this.checkVersionPeriod).subscribe(x => {
                this.check();
            });
        }
    }

    checkUrl() {
        let check = true;
        this.forbiddenUrls.forEach(fu => {
            if(this.url.indexOf(fu) != -1) {
                check = false;
            }
        });

        this.authUrl = check;
    }

    checkUrlModal() {
        let check = true;
        this.noModalUrls.forEach(nmu => {
            if(this.url.indexOf(nmu) != -1) {
                check = false;
            }
        });

        this.authModal = check;
    }

    check() {
        this.softwareService.currentSoftware.subscribe(software => {
            const user = this.localStorageService.getRealLSItem('currentUser');
            let isUser = false;
            if(user && user.api_token && user.roles && user.roles.length) {
                isUser = true;
            }
            if(software) {
                this.checkVersion(software.version, isUser);
            }
        })
    }

    checkVersion(version, isUser) {
        let versionLS = this.localStorageService.getRealLSItem("version");
        if(versionLS) {
            if(+version.version != +versionLS) {
                this.set(version, isUser);
                this.showWarning();
            }
        }
        else {
            this.set(version, isUser);
            this.showWarning();
        }
    }

    arrayUnique(array) {
        var a = array.concat();
        for(var i=0; i<a.length; ++i) {
            for(var j=i+1; j<a.length; ++j) {
                if(a[i] === a[j])
                    a.splice(j--, 1);
            }
        }

        return a;
    }

    set(version, isUser) {
        this.localStorageService.setRealLSItem("version", +version.version);
        this.localStorageService.setRealLSItem("last_update", version.release);

        if(isUser) {
            this.permissionsService.getUserPermissions().subscribe(data => {
                this.ngxPermissionsService.loadPermissions(data);
                this.localStorageService.setLSItem('currentUserPermissions', JSON.stringify(data));
            });
        }
    }

    showWarning() {
        if(this.authModal) {
            $('#versionModal').modal('show');
        }
    }

    closeVersionModal() {
        $("#versionModal").modal("hide");
        window.location.reload();
    }

    checkUserConnected() {
        return this.userService.checkUserConnected().pipe(map(data => data));
    }
}
