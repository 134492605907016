<div aria-labelledby="amuModalLabel" class="modal modal-big fade" id="amuModal" role="dialog" tabindex="-1" style="display: none; z-index: 9999;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
                <h4 class="modal-title" *ngIf="hasData?.visitor">
                    {{ 'VISITOR.AMU' | translate }} {{ visitor?.showName }}
                </h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="amuForm" #sForm="ngForm" class="form-horizontal" (submit)="submit()">
                    <div class="form-group">
                        <div class="col-md-3">
                            <label class="control-label control-label-sm" for="amu">
                                {{ "VISITORS.AMU" | translate }}
                            </label>
                        </div>
                        <div class="col-md-9">
                            <app-date-picker [datepickerConfig]="datepickerConfig"
                                            [id]="'amu'"
                                            [col]="null"
                                            [hasResetButton]="true"
                                            (onDateChanged)="dateChanged($event, 'amu')"
                                            #datePickerAmu>
                            </app-date-picker>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="col-md-12">
                            <button type="submit"
                                    class="btn"
                                    [ngClass]="{'btn-default':isSubmitting, 'btn-success': !isSubmitting}"                                                        
                                    [disabled]="!sForm.form.valid || isSubmitting">
                                <i class="fa"
                                    [ngClass]="{'fa-gavel': !isSubmitting,
                                                'fa-spinner fa-spin': isSubmitting}"></i> {{ 'SHARED.CONFIRM' | translate }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>