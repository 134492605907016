<div class="row mt-3" *ngIf="!selfVisitor">
    <div class="col-md-12">
        <app-util-visitor-parts-appointment-list-filter
                                            [selfVisitor]="selfVisitor"
                                            (filterEvent)="filterVisitorAppointments($event)"></app-util-visitor-parts-appointment-list-filter>
    </div>
</div>
<div class="row mt-5">
    <div class="col-md-12 align-items-center justify-content-space-between">
        <h5 class="title mb-3">
            <ng-container *ngIf="selfVisitor">
                {{ 'VISITORS_APPOINTMENTS.VISITOR_APPOINTMENTS' | translate }}
            </ng-container>
            <ng-container *ngIf="!selfVisitor">
                {{ 'VISITORS_APPOINTMENTS.VISITORS_APPOINTMENTS' | translate }}
            </ng-container>
        </h5>
        <button type="button"
                class="btn btn-primary btn-fill"
                (click)="utilVisitorPartsAppointmentModalsAdd.openModal(visitor?.id)"
                *ngxPermissionsOnly="'cudVisitorAppointment'">
            <i class="fa fa-plus"></i> {{ 'VISITORS_APPOINTMENTS.ADD_APOINTMENT' | translate }}
        </button>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-12">
        <ng-container *ngIf="!isLoading">
            <ng-container *ngIf="hasData?.visitorAppointments">
                <div class="overflow-auto pb-3">
                    <table class="table table-condensend table-striped">
                        <thead>
                            <tr>
                                <th class="p-3 text-center-important">#</th>
                                <th class="p-3 text-center-important"
                                    *ngxPermissionsOnly="'cudVisitorAppointment'">{{ 'SHARED.ACTIONS' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_APPOINTMENTS.DATE' | translate }}</th>
                                <ng-container *ngIf="!selfVisitor">
                                    <th class="p-3 text-center-important">{{ 'VISITORS_APPOINTMENTS.VISITOR' | translate }}</th>
                                </ng-container>
                                <th class="p-3 text-center-important">{{ 'VISITORS_APPOINTMENTS.PLACE' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_APPOINTMENTS.ATTENDANT' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_APPOINTMENTS.CONSULTED_PROFESSIONAL' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_APPOINTMENTS.REMARK' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let visitorAppointment of visitorAppointments?.data; let i = index">
                                <td class="p-3 text-center-important">{{ i + 1 }}</td>
                                <td class="p-3 text-center-important td-actions"
                                    *ngxPermissionsOnly="'cudVisitorAppointment'">
                                    <button type="button"
                                            class="btn btn-success btn-simple p-0"
                                            title="{{ 'VISITORS_APPOINTMENTS.EDIT_APPOINTMENT' | translate }}"
                                            (click)="utilVisitorPartsAppointmentModalsAdd.openModal(visitor?.id, visitorAppointment)"
                                            appTooltip>
                                        <i class="fa fa-edit"></i>
                                    </button>
                                    <button type="button"
                                            class="btn btn-danger btn-simple ml-3 p-0"
                                            title="{{ 'VISITORS_APPOINTMENTS.DELETE_APPOINTMENT' | translate }}"
                                            (click)="delete(visitorAppointment)"
                                            appTooltip>
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </td>
                                <td class="p-3 text-center-important">{{ visitorAppointment?.date | localizedDate: true | ifEmpty: '-' }}</td>
                                <ng-container *ngIf="!selfVisitor">
                                    <td class="p-3 text-center-important">
                                        <a [routerLink]="['/visitor', visitorAppointment?.vId, 'daily-entry', 'appointments']"
                                            class="text-primary">
                                            {{ visitorAppointment?.showName }}
                                        </a>
                                    </td>
                                </ng-container>
                                <td class="p-3 text-center-important">
                                    {{ visitorAppointment?.plaName | ifEmpty: '-' }}
                                </td>
                                <td class="p-3 text-center-important">
                                    {{ visitorAppointment?.attName | ifEmpty: '-' }}
                                </td>
                                <td class="p-3 text-center-important">
                                    {{ visitorAppointment?.consulted_professional | ifEmpty: '-' }}
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-text-limit [text]="visitorAppointment?.remark" [title]="'REMARK'"></app-text-limit>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="align-items-center justify-content-center" *ngIf="visitorAppointments?.last_page > 1">
                        <app-pagination [data]="visitorAppointments" (switchPage)="switchPage($event)"></app-pagination>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!hasData?.visitorAppointments">
                <p>
                    <ng-container *ngIf="selfVisitor">
                        {{ 'VISITORS_APPOINTMENTS.NO_VISITOR_APOINTMENT_FOUND' | translate }}
                    </ng-container>
                    <ng-container *ngIf="!selfVisitor">
                        {{ 'VISITORS_APPOINTMENTS.NO_VISITORS_APOINTMENT_FOUND' | translate }}
                    </ng-container>
                </p>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="isLoading">
            <p>
                <app-loading-spinner></app-loading-spinner>
            </p>
        </ng-container>
    </div>
</div>
<app-util-visitor-parts-appointment-modals-add (addAppointmentEvent)="addAppointment()" #utilVisitorPartsAppointmentModalsAdd></app-util-visitor-parts-appointment-modals-add>