import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from '@services/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { PermissionsService } from '@services/permissions.service'

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private localStorageService: LocalStorageService, private ngxPermissionsService: NgxPermissionsService, private permissionsService: PermissionsService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.localStorageService.getLSItem('currentUser')) {
            // get perms
            let perms = this.localStorageService.getLSItem('currentUserPermissions');

            if (perms){
                this.ngxPermissionsService.loadPermissions(perms);
            } else {
                this.permissionsService.getUserPermissions().subscribe(data => {
                    this.ngxPermissionsService.loadPermissions(data);
                });
            }

            // logged in so return true
            return true;
        } else {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url }});
            return false;
        }

    }
}
