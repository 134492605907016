import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { MiscService, VisitorPsychoService } from '@services/index';

@Component({
    selector: 'app-util-visitor-parts-psycho-file',
    templateUrl: './file.component.html'
})
export class UtilVisitorPartsPsychoFileComponent implements OnInit {
    @Input() visitor: any;
    
    isLoading = false;
    visitorPsycho: any;
    hasData = {
        visitorPsycho: false
    };

    constructor(private cdref: ChangeDetectorRef,
                private miscService: MiscService,
                private VisitorPsychoService: VisitorPsychoService) {
        this.miscService.setPageTitle("TITLES.VISITOR_PSYCHO_PSYCHOLOGICAL_FILE");
    }

    ngOnInit() {
        this.miscService.can("getVisitorPsycho");
        this.getVisitorPsycho();
        this.cdref.detectChanges();
    }

    getVisitorPsycho() {
        this.isLoading = true;
        this.VisitorPsychoService.getByVisitor(this.visitor.id).subscribe(data => {
            this.visitorPsycho = data;
            this.hasData.visitorPsycho = this.miscService.checkDataLength(this.visitorPsycho);
            this.isLoading = false;
        });
    }

    getInterviewType(interviewType) {
        if(interviewType) {
            if(interviewType == "formal") {
                return this.miscService._t("VISITORS_PSYCHO.FORMAL");
            }
            else {
                return this.miscService._t("VISITORS_PSYCHO.INFORMAL");
            }
        }

        return "-";
    }
}
