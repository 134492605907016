<div aria-labelledby="filesMedicalModalLabel" class="modal modal-big fade" id="filesMedicalModal" role="dialog" tabindex="-1" style="display: none; z-index: 9990;">
    <div class="modal-dialog width-1200" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
                <h4 class="modal-title">
                    {{ 'VISITORS_MEDICAL.FILES_MEDICAL_FILE' | translate }}
                </h4>
            </div>
            <div class="modal-body">
                <app-util-folders pictures
                                [params]="{ referencable_type: 'App\\VisitorMedical', referencable_id: visitorMedicalId }"></app-util-folders>
            </div>
        </div>
    </div>
</div>