<div aria-labelledby="addAppointmentModalLabel" class="modal modal-big fade" id="addAppointmentModal" role="dialog" tabindex="-1" style="display: none; z-index: 9999;">
    <div class="modal-dialog width-900" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
                <h4 class="modal-title">
                    <ng-container *ngIf="cud == 'add'">
                        {{ "VISITORS_APPOINTMENTS.ADD_APOINTMENT" | translate }}
                    </ng-container>
                    <ng-container *ngIf="cud == 'edit'">
                        {{ "VISITORS_APPOINTMENTS.EDIT_APPOINTMENT" | translate }}
                    </ng-container>
                </h4>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="!isLoading">
                    <form [formGroup]="addForm" #aForm="ngForm" class="form-horizontal" (submit)="submit()">
                        <div class="form-group" *ngIf="!visitorId && cud == 'add'">
                            <div class="col-md-3">
                                <label class="control-label control-label-sm" for="visitor_id" >
                                    {{ 'VISITORS_APPOINTMENTS.VISITOR' | translate }} <span class="star">*</span>
                                </label>
                            </div>
                            <div class="col-md-9">
                                <app-ng-select-add-visitor
                                        [canAddVisitors]="true"
                                        [multiple]="false"
                                        (changeEvent)="changeVisitor($event)"
                                        (goToAddVisitorUrlEvent)="goToAddVisitorUrl()"></app-ng-select-add-visitor>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-md-3">
                                <label class="control-label control-label-sm" for="date">
                                    {{ "VISITORS_APPOINTMENTS.DATE" | translate }} <span class="star">*</span>
                                </label>
                            </div>
                            <div class="col-md-9">
                                <app-date-picker [datepickerConfig]="pickerConfig"
                                                [id]="'date'"
                                                [col]="null"
                                                [withHours]="true"
                                                (onDateChanged)="dateChanged($event, 'date')"
                                                #datePicker>
                                </app-date-picker>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-md-3">
                                <label class="control-label control-label-sm" for="place_id" >
                                    {{ 'VISITORS_APPOINTMENTS.PLACE' | translate }}
                                </label>
                            </div>
                            <div class="col-md-9">
                                <app-ng-select-add-single [items]="places?.choices"
                                                        [key]="'places'"
                                                        [id]="'place_id'"
                                                        [selectedId]="addForm?.value?.place_id"
                                                        [needsLoading]="false"
                                                        (changeEvent)="changeChoice('place_id', $event)"
                                                        #ngSelectAddAppointmentDataSinglePlace></app-ng-select-add-single>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-md-3">
                                <label class="control-label control-label-sm" for="attendant_id" >
                                    {{ 'VISITORS_APPOINTMENTS.ATTENDANT' | translate }}
                                </label>
                            </div>
                            <div class="col-md-9">
                                <app-ng-select-add-single [items]="attendants?.choices"
                                                        [key]="'attendants'"
                                                        [id]="'attendant_id'"
                                                        [selectedId]="addForm?.value?.attendant_id"
                                                        [needsLoading]="false"
                                                        (changeEvent)="changeChoice('attendant_id', $event)"
                                                        #ngSelectAddAppointmentDataSingleAttendant></app-ng-select-add-single>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-md-3">
                                <label class="control-label control-label-sm" for="consulted_professional">
                                    {{ "VISITORS_APPOINTMENTS.CONSULTED_PROFESSIONAL" | translate }}
                                </label>
                            </div>
                            <div class="col-md-9">
                                <input type="text"
                                        rows="5"
                                        class="form-control input-sm"
                                        id="consulted_professional"
                                        formControlName="consulted_professional" />
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-md-3">
                                <label class="control-label control-label-sm" for="remark">
                                    {{ "VISITORS_APPOINTMENTS.REMARK" | translate }}
                                </label>
                            </div>
                            <div class="col-md-9">
                                <textarea cols="30"
                                        rows="5"
                                        class="form-control input-sm"
                                        id="remark"
                                        formControlName="remark"></textarea>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="col-md-12">
                                <button type="submit"
                                        class="btn"
                                        [ngClass]="{'btn-default':isSubmitting, 'btn-success': !isSubmitting}"                                                        
                                        [disabled]="!aForm.form.valid || isSubmitting">
                                    <ng-container *ngIf="cud == 'add'">
                                        <i class="fa"
                                            [ngClass]="{'fa-plus': !isSubmitting,
                                                        'fa-spinner fa-spin': isSubmitting}"></i> {{ "SHARED.ADD" | translate }}
                                    </ng-container>
                                    <ng-container *ngIf="cud == 'edit'">
                                        <i class="fa"
                                            [ngClass]="{'fa-edit': !isSubmitting,
                                                        'fa-spinner fa-spin': isSubmitting}"></i> {{ "SHARED.EDIT" | translate }}
                                    </ng-container>
                                </button>
                            </div>
                        </div>
                    </form>
                </ng-container>
                <ng-container *ngIf="isLoading">
                    <app-loading-spinner></app-loading-spinner>
                </ng-container>
            </div>
        </div>
    </div>
</div>