import { Component, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MiscService, VisitorService } from '@services/index';
import { Globals } from '@app/app.globals';
import * as moment from 'moment';

@Component({
    selector: 'app-util-visitor-modals-amu',
    templateUrl: './amu.component.html',
})
export class UtilVisitorModalsAmuComponent implements OnInit {
    @Output() amuEvent = new EventEmitter<any>();

    isSubmitting = false;
    modal = "#amuModal";
    datepickerConfig: any;
    visitor: any;
    amuForm = this.fb.group({
        id: [null, Validators.required],
        amu: null
    });
    hasData = {
        visitor: false
    };

    @ViewChild('datePickerAmu') datePickerAmu;

    constructor(private cdref: ChangeDetectorRef,
                private fb: UntypedFormBuilder,
                private miscService: MiscService,
                private visitorService: VisitorService,
                public globals: Globals) {}

    ngOnInit() {
        this.datepickerConfig = JSON.parse(JSON.stringify(this.globals.dateOnlyPickerConfig));
        this.cdref.detectChanges();
    }
    
    openModal(visitor) {
        if(this.miscService.checkEnvPermission("cudVisitor")) {
            this.visitor = visitor;
            this.hasData.visitor = this.miscService.checkDataLength(this.visitor);
            if(this.hasData.visitor) {
                this.amuForm.reset({
                    id: visitor.id,
                    amu: visitor.amu
                });
                this.datePickerAmu.dateModel = null;
                if(this.visitor.amu) {
                    this.datePickerAmu.dateModel = moment(this.visitor.amu).format(this.globals.formatDateFrench);
                }
                $(this.modal).modal('show');
            }
        }
    }

    dateChanged(evt, field) {
        if(evt) {
            let date = evt;
            this.amuForm.get(field).patchValue(date);
        }
        else {
            this.resetField(field);
        }
	}

    resetField(field) {
        this.amuForm.get(field).reset();
    }

    submit() {
        this.isSubmitting = true;
        let data = this.amuForm.value;
        this.visitorService.setAmu(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_AMU_DUE_DATE_UPDATED", status: 'success' });
            $(this.modal).modal('hide');
            this.amuEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_AMU_DUE_DATE_NOT_UPDATED" });
        });
    }
}
