import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { MiscService, VisitorMedicalStatService } from '@services/index';
import * as moment from 'moment';

declare var swal: any;

@Component({
    selector: 'app-util-visitor-parts-medical-stat-list',
    templateUrl: './list.component.html',
})
export class UtilVisitorPartsMedicalStatListComponent implements OnInit {
    @Input() visitor: any = null;

    isLoadingVisitors = false;
    selfVisitor = false;
    filter: any = {
        date: moment().startOf('day').format('YYYY-MM-DD HH:mm')
    };
    limit = 20;
    page = 1;
    visitorMedicalStats: any;
    countStats = {
        consultation: 0,
        concertation: 0,
        administrative: 0,
        cm: 0,
        network: 0,
        followed_up_file: 0
    };
    hasData = {
        visitorMedicalStats: false
    };

    constructor(private cdref: ChangeDetectorRef,
                private miscService: MiscService,
                private visitorMedicalStatService: VisitorMedicalStatService) {
        this.miscService.setPageTitle("TITLES.DAILY_ENTRY_ENTRIES");
    }

    ngOnInit() {
        this.miscService.can("getVisitorDailyEntry");
        this.selfVisitor = this.miscService.checkDataLength(this.visitor) ? true : false;
        this.getVisitorsMedicalStats();
        this.cdref.detectChanges();
    }

    filterVisitorMedicalStats(filter = null) {
        this.filter = filter;
        this.getVisitorsMedicalStats(true);
    }

    getVisitorsMedicalStats(hasFilter = false) {
        this.isLoadingVisitors = true;
        let data = this.filter;
        data.visitor_ids = this.selfVisitor ? [this.visitor.id] : (data.visitor_ids && data.visitor_ids.length ? data.visitor_ids : null);
        data.limit = this.limit;
        data.page = !hasFilter ? this.page : 1;     // if user has filtered, then reset page to 1 (number of pages can change due to filters)
        this.visitorMedicalStatService.getByVisitors(data).subscribe(data => {
            this.visitorMedicalStats = data;
            this.hasData.visitorMedicalStats = this.miscService.checkDataLength(this.visitorMedicalStats.data);
            this.countStats = this.visitorMedicalStats.count;
            this.isLoadingVisitors = false;
        }, error => {
            this.visitorMedicalStats = null;
            this.hasData.visitorMedicalStats = false;
            this.miscService.showMsg({ msg: "VISITOR_MEDICAL_STATS_NOT_FOUND_PLEASE_CHECK_FILTERS" });
            this.isLoadingVisitors = false;
        });
    }

    switchPage(page) {
        this.page = page;
        this.getVisitorsMedicalStats();
    }

    delete(visitorEntry) {
        let self = this;
        let title = this.miscService._t('VISITORS_MEDICAL_STAT.DELETE_STAT') + " ?";
        swal({
            title: title,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.miscService._t('SHARED.CONFIRM'),
            cancelButtonText: this.miscService._t('SHARED.CANCEL'),
            closeOnConfirm: true,
            closeOnCancel: true,
        }, function(isConfirm) {
            if(isConfirm) {
                self.visitorMedicalStatService.delete(visitorEntry.id).subscribe(data => {
                    self.getVisitorsMedicalStats(true);  // resets page to 1 (in case of there is only 1 visitor entry on the current page)
                    self.miscService.showMsg({ msg: "VISITOR_MEDICAL_STAT_DELETED", status: "success" });
                }, error => {
                    self.miscService.showMsg({ msg: "VISITOR_MEDICAL_STAT_NOT_DELETED" });
                });
            }
        });
    }
}
