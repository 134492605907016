<div class="row">
    <div class="col-md-12">
        <app-util-visitor-parts-infirmary-entry-list-filter
                                                [selfVisitor]="selfVisitor"
                                                (filterEvent)="filterVisitorInfirmaryEntries($event)"></app-util-visitor-parts-infirmary-entry-list-filter>
    </div>
</div>
<div class="row mt-5">
    <div class="col-md-12 align-items-center justify-content-space-between">
        <h5 class="title mb-3">
            <ng-container *ngIf="selfVisitor">
                {{ 'VISITORS_MEDICAL.VISITOR_INFIRMARY_ACTS' | translate }}
            </ng-container>
            <ng-container *ngIf="!selfVisitor">
                {{ 'VISITORS_MEDICAL.VISITORS_INFIRMARY_ACTS' | translate }}
            </ng-container>
        </h5>
        <ng-container *ngxPermissionsOnly="'cudVisitorInfirmaryEntry'">
            <button type="button"
                    class="btn btn-primary btn-fill"
                    (click)="utilVisitorPartsInfirmaryEntryModalsAdd.openModal(visitor?.id)"
                    *ngIf="manageEntries">
                <i class="fa fa-plus"></i> {{ 'VISITORS_MEDICAL.ADD_ACT' | translate }}
            </button>
        </ng-container>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-12">
        <ng-container *ngIf="!isLoading">
            <ng-container *ngIf="hasData?.visitorInfirmaryEntries">
                <div class="overflow-auto pb-3">
                    <table class="table table-condensend table-striped">
                        <thead>
                            <tr>
                                <th class="p-3 text-center-important">#</th>
                                <ng-container *ngxPermissionsOnly="'cudVisitorInfirmaryEntry'">
                                    <th class="p-3 text-center-important"
                                        *ngIf="manageEntries">
                                        {{ 'SHARED.ACTIONS' | translate }}
                                    </th>
                                </ng-container>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL.DATE' | translate }}</th>
                                <ng-container *ngIf="!selfVisitor">
                                    <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL.VISITOR' | translate }}</th>
                                </ng-container>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_ENTRIES.TITLES.SHOWER_INF' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_ENTRIES.TITLES.FOOTCARE' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_ENTRIES.TITLES.BANDAGE' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_ENTRIES.TITLES.DRUGSTORE' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_ENTRIES.TITLES.PSYCHOLOGICAL_TREATMENT' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_ENTRIES.TITLES.SOMATIC_TREATMENT' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_ENTRIES.TITLES.SETTINGS' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_ENTRIES.TITLES.PARASITES' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_ENTRIES.TITLES.PREGNANCY_TEST' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_ENTRIES.TITLES.MAINTENANCE' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_ENTRIES.TITLES.SUPPORTS' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_ENTRIES.TITLES.PROCEDURES' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_ENTRIES.TITLES.HEALTH_PROMOTION' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_ENTRIES.TITLES.SYRINGUE_EXCHANGE' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_ENTRIES.TITLES.ORIENTATION' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_ENTRIES.TITLES.OBSERVATIONS' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let visitorInfirmaryEntry of visitorInfirmaryEntries?.data; let i = index">
                                <td class="p-3 text-center-important">{{ i + 1 }}</td>
                                <ng-container *ngxPermissionsOnly="'cudVisitorInfirmaryEntry'">
                                    <ng-container *ngIf="manageEntries">
                                        <td class="p-3 text-center-important td-actions" >
                                            <button type="button"
                                                    class="btn btn-success btn-simple p-0"
                                                    title="{{ 'VISITORS_MEDICAL.EDIT_ACT' | translate }}"
                                                    (click)="utilVisitorPartsInfirmaryEntryModalsAdd.openModal(visitor?.id, visitorInfirmaryEntry)"
                                                    appTooltip>
                                                <i class="fa fa-edit"></i>
                                            </button>
                                            <button type="button"
                                                    class="btn btn-danger btn-simple ml-3 p-0"
                                                    title="{{ 'VISITORS_MEDICAL.DELETE_ACT' | translate }}"
                                                    (click)="delete(visitorInfirmaryEntry)"
                                                    appTooltip>
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </td>
                                    </ng-container>
                                </ng-container>
                                <td class="p-3 text-center-important">{{ visitorInfirmaryEntry?.date | localizedDate: true | ifEmpty: '-' }}</td>
                                <ng-container *ngIf="!selfVisitor">
                                    <td class="p-3 text-center-important">
                                        <a [routerLink]="['/visitor', visitorInfirmaryEntry?.vId, 'infirmary']"
                                            class="text-primary">
                                            {{ visitorInfirmaryEntry?.showName }}
                                        </a>
                                    </td>
                                </ng-container>
                                <td class="p-3 text-center-important">
                                    <app-icon-check-close [value]="visitorInfirmaryEntry?.shower_inf"></app-icon-check-close>
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-icon-check-close [value]="visitorInfirmaryEntry?.footcare"></app-icon-check-close>
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-icon-check-close [value]="visitorInfirmaryEntry?.bandage"></app-icon-check-close>
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-icon-check-close [value]="visitorInfirmaryEntry?.drugstore"></app-icon-check-close>
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-icon-check-close [value]="visitorInfirmaryEntry?.psychological_treatment"></app-icon-check-close>
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-icon-check-close [value]="visitorInfirmaryEntry?.somatic_treatment"></app-icon-check-close>
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-icon-check-close [value]="visitorInfirmaryEntry?.settings"></app-icon-check-close>
                                </td>
                                <td class="p-3 text-center-important">
                                    <ng-container *ngIf="visitorInfirmaryEntry?.visitorFkParasites?.length">
                                        <span class="list-comma">
                                            <span *ngFor="let visitorFkParasite of visitorInfirmaryEntry?.visitorFkParasites">
                                                {{ visitorFkParasite?.name }}<span class="separator-comma">, </span>
                                            </span>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="!visitorInfirmaryEntry?.visitorFkParasites?.length">
                                        -
                                    </ng-container>
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-icon-check-close [value]="visitorInfirmaryEntry?.pregnancy_test"></app-icon-check-close>
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-icon-check-close [value]="visitorInfirmaryEntry?.maintenance"></app-icon-check-close>
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-icon-check-close [value]="visitorInfirmaryEntry?.support"></app-icon-check-close>
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-icon-check-close [value]="visitorInfirmaryEntry?.procedures"></app-icon-check-close>
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-icon-check-close [value]="visitorInfirmaryEntry?.health_promotion"></app-icon-check-close>
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-icon-check-close [value]="visitorInfirmaryEntry?.syringe_exchange"></app-icon-check-close>
                                </td>
                                <td class="p-3 text-center-important">
                                    {{ visitorInfirmaryEntry?.orientation?.name | ifEmpty: '-' }}
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-text-limit [text]="visitorInfirmaryEntry?.observations" [title]="'OBSERVATIONS'"></app-text-limit>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="align-items-center justify-content-center" *ngIf="visitorInfirmaryEntries?.last_page > 1">
                        <app-pagination [data]="visitorInfirmaryEntries" (switchPage)="switchPage($event)"></app-pagination>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!hasData?.visitorInfirmaryEntries">
                <p>
                    {{ 'VISITORS_MEDICAL.NO_MEDICAL_DATA_FOUND' | translate }}
                </p>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="isLoading">
            <p>
                <app-loading-spinner></app-loading-spinner>
            </p>
        </ng-container>
    </div>
</div>
<app-util-visitor-parts-infirmary-entry-modals-add (addInfirmaryEntryEvent)="addInfirmaryEntry()" #utilVisitorPartsInfirmaryEntryModalsAdd></app-util-visitor-parts-infirmary-entry-modals-add>