import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { MiscService, VisitorTreatmentService } from '@services/index';
import { environment } from '@env/environment';

@Component({
    selector: 'app-util-visitor-parts-treatment-pdf',
    templateUrl: './pdf.component.html',
})
export class UtilVisitorPartsTreatmentPdfComponent implements OnInit {
    @Input() visitor: any = null;
    @Input() archived: boolean;

    isLoadingPdf = false;
    docUrl = environment.baseUrl;

    constructor(private cdref: ChangeDetectorRef,
                private miscService: MiscService,
                private visitorTreatmentService: VisitorTreatmentService) {}

    ngOnInit() {
        this.miscService.can("getVisitorTreatment");
        this.cdref.detectChanges();
    }

    generatePdf() {
        this.isLoadingPdf = true;
        let data = {
            visitor_ids: [this.visitor.id],
            archived: this.archived
        };
        this.visitorTreatmentService.generatePdf(data).subscribe(data => {
            if(typeof data != "string") {
                let url = this.docUrl + "file/" + data.name;
                var pdfLink = document.createElement("a");     // Obligé de créer un faux lien sinon ça ne marche pas sur Chrome
                pdfLink.href = url;
                pdfLink.target = "_blank";
                pdfLink.download = "treatments.pdf";
                pdfLink.click();
            }
            else {
                this.miscService.showMsg({msg: "GENERATE_PDF_NOT_DONE_NO_TREATMENT", status: "warning"});
            }
            this.isLoadingPdf = false;
        }, error => {
            this.miscService.showMsg({msg: "GENERATE_PDF_FAILED"});
            this.isLoadingPdf = false;
        });
    }
}
