import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-util-visitor-parts-medical-file-modals-files',
    templateUrl: './files.component.html',
})
export class UtilVisitorPartsMedicalFileModalsFilesComponent implements OnInit {
    @Input() visitorMedicalId: number;

    modal = "#filesMedicalModal";

    constructor() {}

    ngOnInit() {}

    openModal() {
        $(this.modal).modal('show');
    }
}
