import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MiscService } from '@services/index';

@Component({
    selector: 'app-util-visitor-parts-treatment-archived',
    templateUrl: './archived.component.html',
})
export class UtilVisitorPartsTreatmentArchivedComponent implements OnInit {
    @Input() visitor: any = null;
    @Input() limit = 10;
    @Output() updateEvent = new EventEmitter();

    constructor(private cdref: ChangeDetectorRef,
                private miscService: MiscService) {}

    @ViewChild('utilVisitorPartsTreatmentPerOsList') utilVisitorPartsTreatmentPerOsList;
    @ViewChild('utilVisitorPartsTreatmentInjectionList') utilVisitorPartsTreatmentInjectionList;
    @ViewChild('utilVisitorPartsTreatmentOthersList') utilVisitorPartsTreatmentOthersList;

    ngOnInit() {
        this.miscService.can("getVisitorTreatment");
        this.cdref.detectChanges();
    }

    switchPage(page = 1) {
        this.utilVisitorPartsTreatmentPerOsList.switchPage(page);
        this.utilVisitorPartsTreatmentInjectionList.switchPage(page);
        this.utilVisitorPartsTreatmentOthersList.switchPage(page);
    }

    update() {
        this.updateEvent.emit();
    }
}
