import {AfterViewInit, Directive, HostListener} from '@angular/core'

@Directive({
    selector:'input[type="number"]'
})
export class DisableInteractionDirective{

    constructor(){}

    //disable wheel events (lots of hostlistener to try to catch all events)
    @HostListener('wheel', ['$event'])
    onWheel(event: Event) {
        event.preventDefault();
    }

    @HostListener('mousewheel', ['$event'])
    customOnWheel($event) {
        $event.preventDefault();
    }

    @HostListener('onwheel', ['$event'])
    customOnWheel1($event) {
        $event.preventDefault();
    }

    @HostListener('document:mousewheel', ['$event'])
    customOnWheel2($event) {
        $event.preventDefault();
    }

    @HostListener('document:scroll', ['$event'])
    customOnWheel3($event) {
        $event.preventDefault();
    }

    @HostListener('keydown', ['$event'])
    customOnKeyDown($event) {
        //disable arrows
        if ($event.keyCode === 38 || $event.keyCode === 40) {
            $event.preventDefault();
        }
    }
}
