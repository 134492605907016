import { AfterViewInit, Component, HostListener, OnInit, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { VersionCheckerComponent } from '@utils/version-checker/version-checker.component';
import { MiscService, LocalStorageService, AuthenticationService, InactivityService, SoftwareService, UserService } from '@services/index';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';

declare var $:any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit {
    objectKeys = Object.keys;
    load: boolean = false;

	constructor(private cdref: ChangeDetectorRef,
                public location: Location,
                private miscService: MiscService,
                private localStorageService: LocalStorageService,
                private authenticationService: AuthenticationService,
                private inactivityService: InactivityService,
                private softwareService: SoftwareService,
				private translateService: TranslateService,
                private userService: UserService,
        		private ngSelectConfig: NgSelectConfig) {
		this.ngSelectConfig.clearAllText = 'Tout effacer';
		this.ngSelectConfig.notFoundText = 'Aucun résultat trouvé';
		this.ngSelectConfig.typeToSearchText = 'Tapez votre recherche';
		this.miscService.setLanguage();
        this.miscService.setPageTitle();

        // detects inactivity and logs out the user
        inactivityService.$onIdle.subscribe(() => {
            authenticationService.logout();
        });
	}

    @HostListener('window:beforeunload', [ '$event' ])
    beforeUnloadHandler(event) {
        this.authenticationService.logout(true);
    }
    
    @ViewChildren('vercheck') vercheck: QueryList<VersionCheckerComponent>;

	ngOnInit(){
		this.translateService.onLangChange.subscribe(() => {
			this.ngSelectConfig.addTagText = this.translateService.instant('SHARED.ADD') + ' : ';
			this.ngSelectConfig.loadingText = this.translateService.instant('SHARED.LOADING');
		});

		/**
		 * If key is empty or not exist :
		 * 	- Show the key => comment the line
		 * 	- Show default trad => uncomment the line
		 */
		// this.translateService.setDefaultLang('fr');

		const localLang = this.localStorageService.getLSItem('lang');

		if(localLang && this.miscService.validLang(localLang)) {
			this.miscService.setLang(localLang);
		}
        else {
			this.miscService.setLang(navigator.language);
		}
        this.cdref.detectChanges();
	}

	ngAfterViewInit() {
        this.userService.currentUser.subscribe(data => {
			if(data !== null) {
				this.load = true;
				let sidebarMini = this.localStorageService.getLSItem('sidebarMini');
				if (sidebarMini) { $('body').addClass('sidebar-mini'); }
				let params = this.localStorageService.getLSItem('params') ? this.localStorageService.getLSItem('params') : "{}";
				this.localStorageService.setLSItem('params', params);
			}
		});
		this.softwareService.init();
		this.userService.me();
        this.vercheck.changes.subscribe((vercheck) => {
			vercheck.first.trigger();
		})
		$.fn.modal.Constructor.prototype.enforceFocus = function() {};
        this.cdref.detectChanges();
	}

	isMap(path) {
		var titlee = this.location.prepareExternalUrl(this.location.path());
		titlee = titlee.slice( 1 );
		if(path == titlee){
			return false;
		}
		else {
			return true;
		}
	}
}
