<div class="form-group {{ formGroupClass }}" [class.has-error]="control && control.invalid && (control.touched || control.dirty)">
    <ng-container *ngIf="col_label || col_input">
        <ng-container *ngTemplateOutlet="horizontalTemplate"></ng-container>
    </ng-container>
    <ng-container *ngIf="!col_label && !col_input">
        <ng-container *ngTemplateOutlet="verticalTemplate"></ng-container>
    </ng-container>
</div>

<ng-template #horizontalTemplate>
    <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
    <div [ngClass]="col_input ? 'col-md-' + col_input : 'col-md-' + col_label">
        <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
    </div>
</ng-template>

<ng-template #verticalTemplate>
    <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
</ng-template>

<ng-template #labelTemplate>
    <label [htmlFor]="forId" class="control-label" [class.control-label-sm]="sm" *ngIf="label !== null && label !== undefined" [ngClass]="col_label ? 'col-md-' + col_label : ''" [attr.title]="title" appTooltip>
        {{ label }} <span class="star" *ngIf="star === true"> *</span>
    </label>
</ng-template>

<ng-template #inputTemplate>
    <ng-content></ng-content>
    <app-form-errors [control]="control"></app-form-errors>
</ng-template>
