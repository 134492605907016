<div aria-labelledby="suspensionsCancelModalLabel" class="modal modal-big fade" id="suspensionsCancelModal" role="dialog" tabindex="-1" style="display: none; z-index: 9999;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
                <h4 class="modal-title">
                    {{ "VISITORS_SUSPENSIONS.CANCEL_SUSPENSION" | translate }}
                </h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="cancelForm" #cForm="ngForm" class="form-horizontal" (submit)="submit()">
                    <div class="form-group">
                        <div class="col-md-3">
                            <label class="control-label control-label-sm" for="reason_cancel">
                                {{ "VISITORS_SUSPENSIONS.REASON_CANCEL" | translate }}
                            </label>
                        </div>
                        <div class="col-md-9">
                            <textarea cols="30"
                                    rows="5"
                                    class="form-control input-sm"
                                    id="reason_cancel"
                                    formControlName="reason_cancel"></textarea>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="col-md-12">
                            <button type="submit"
                                    class="btn"
                                    [ngClass]="{'btn-default':isSubmitting, 'btn-success': !isSubmitting}"                                                        
                                    [disabled]="!cForm.form.valid || isSubmitting">
                                <i class="fa"
                                    [ngClass]="{'fa-gavel': !isSubmitting,
                                                'fa-spinner fa-spin': isSubmitting}"></i> 
                                {{ "SHARED.CANCEL" | translate }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>