<app-util-visitor-parts-treatment-pdf [visitor]="visitor" [archived]="true"></app-util-visitor-parts-treatment-pdf>

<app-util-visitor-parts-treatment-per-os-list
                            [visitor]="visitor"
                            [archived]="true"
                            [limit]="limit"
                            (updateEvent)="update()"
                            #utilVisitorPartsTreatmentPerOsList></app-util-visitor-parts-treatment-per-os-list>
<app-util-visitor-parts-treatment-injection-list
                            [visitor]="visitor"
                            [archived]="true"
                            [limit]="limit"
                            (updateEvent)="update()"
                            #utilVisitorPartsTreatmentInjectionList></app-util-visitor-parts-treatment-injection-list>
<app-util-visitor-parts-treatment-others-list
                            [visitor]="visitor"
                            [archived]="true"
                            [limit]="limit"
                            (updateEvent)="update()"
                            #utilVisitorPartsTreatmentOthersList></app-util-visitor-parts-treatment-others-list>