import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';
import { BehaviorSubject } from 'rxjs';
import { delay, distinctUntilChanged, repeatWhen } from 'rxjs/operators';
import { Software } from '@app/_models';

@Injectable({
    providedIn: 'root'
})
export class SoftwareService extends ApiService {
    private currentSoftwareSubject = new BehaviorSubject<Software>(null);
    public currentSoftware = this.currentSoftwareSubject.asObservable().pipe(distinctUntilChanged());
    public checkVersionPeriod = 1000 * 60 * 10;  // every 10 mins

    init() {
        const version = localStorage.getItem('last_changelog');
        return this.get('/software', { version }).pipe(repeatWhen(x => x.pipe(delay(this.checkVersionPeriod)))).subscribe(data => {
            const software = new Software().deserialize(data);
            this.currentSoftwareSubject.next(software);
        });
    }

}
