import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MiscService } from '@services/index';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FileService } from '@services/file.service';

declare var $: any;
declare var swal: any;

@Component({
    selector: 'app-util-folders-files',
    templateUrl: './files.component.html'
})
export class UtilFoldersFilesComponent implements OnInit {
    @Input() files: any[];
    @Input() type: string;
    
    @Output() onCheckboxChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDestroyedOrEdited: EventEmitter<any> = new EventEmitter<any>();

    selectedFile: any = null;

    editModalId: string = this.miscService.uuid();
    editFormLoading: boolean = false;
    editForm: UntypedFormGroup = this.fb.group({
        name: [null, [Validators.required, Validators.maxLength(255)]],
    });

    constructor(private miscService: MiscService,
                private fileService: FileService,
                public fb: UntypedFormBuilder) {}

    ngOnInit() {}

    onCheckboxClicked() {
        this.onCheckboxChanged.emit();
    }

    openEditModal(file) {
        this.selectedFile = file;
        let fileNameParts = file.name.split('.');
        fileNameParts.pop();

        this.editForm.reset({
            name: fileNameParts.join('.'),
        });

        this.editFormLoading = false;
        $(`#${this.editModalId}`).appendTo('.main-content').modal('show');
    }

    submitEditForm() {
        this.editFormLoading = true;
        this.fileService.update(this.selectedFile.id, this.editForm.value).subscribe(
            () => {
                this.onDestroyedOrEdited.emit();
                this.miscService.showMsg({msg: "FILE_SAVED", status: "success"});
                $(`#${this.editModalId}`).modal('hide');
            },
            () => this.miscService.showMsg({msg: "FILE_NOT_SAVED"})
        ).add(() => this.editFormLoading = false);
    }

    destroy(file: any) {
        const self = this;
        swal({
            title: this.miscService._t("UPLOAD.ARE_YOU_SURE_DELETE_FILE", {file: file.name}),
            type: 'warning',
            showCancelButton: true,
            confirmButtonClass: 'btn btn-danger btn-fill',
            confirmButtonText: this.miscService._t('SHARED.DELETE'),
            cancelButtonClass: 'btn btn-default btn-fill',
            cancelButtonText: this.miscService._t('SHARED.CANCEL'),
            closeOnConfirm: true,
            closeOnCancel: true,
        },
        function(isConfirm) {
            if(isConfirm) {
                self.fileService.destroy(file.id).subscribe(
                    () => {
                        self.onDestroyedOrEdited.emit();
                        self.miscService.showMsg({msg: "FILE_DELETED", status: "success"});
                    },
                    () => {
                        self.miscService.showMsg({msg: "FILE_NOT_DELETED"});
                    }
                )
            }
        });
    }
}
