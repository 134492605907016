import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
    providedIn: 'root'
})
export class VisitorMedicalService extends ApiService {
    getByVisitor(id: number) {
        return super.get('/visitors-medical/visitor/' + id);
    }

    create(data: any) {
        return super.post('/visitors-medical/create', data);
    }

    update(data: any) {
        return super.put('/visitors-medical/update', data);
    }

    delete(id: number) {
        return super.delete('/visitors-medical/' + id);
    }
}
