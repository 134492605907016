import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
    providedIn: 'root'
})
export class OptionService extends ApiService {
    getAll() {
        return super.get('/options');
    }

    getAllEditable() {
        return super.get('/options/editable');
    }

    getByKey(key: string) {
        return super.get('/options/key/' + key);
    }

    create(data: any) {
        return super.post('/options', data);
    }

    update(data: any) {
        return super.put('/options', data);
    }

    delete(id: number) {
        return super.delete('/options/' + id);
    }
}
