<div aria-labelledby="addMedicalModalLabel" class="modal modal-big fade" id="addMedicalModal" role="dialog" tabindex="-1" style="display: none; z-index: 9999;">
    <div class="modal-dialog width-900" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
                <h4 class="modal-title">
                    <ng-container *ngIf="cud == 'add'">
                        {{ "VISITORS_MEDICAL.CREATE_MEDICAL_DATA" | translate }}
                    </ng-container>
                    <ng-container *ngIf="cud == 'edit'">
                        {{ "VISITORS_MEDICAL.EDIT_MEDICAL_DATA" | translate }}
                    </ng-container>
                </h4>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="!isLoading">
                    <form [formGroup]="addForm" #aForm="ngForm" class="form-horizontal" (submit)="submit()">
                        <fieldset>
                            <legend>{{ 'VISITORS_MEDICAL.CATEGORIES_DIAGNOSTIC' | translate }}</legend>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm">
                                        {{ "VISITORS_MEDICAL.INFORMATION" | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <div class="row m-0">
                                        <div class="col-md-6" style="padding-left: 6px;">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="anxiety_disorder"
                                                        formControlName="anxiety_disorder" />
                                                <label for="anxiety_disorder">
                                                    {{ "VISITORS_MEDICAL.ANXIETY_DISORDER" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="alcohol_addiction"
                                                        formControlName="alcohol_addiction" />
                                                <label for="alcohol_addiction">
                                                    {{ "VISITORS_MEDICAL.ALCOHOL_ADDICTION" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="drugs_addiction"
                                                        formControlName="drugs_addiction" />
                                                <label for="drugs_addiction">
                                                    {{ "VISITORS_MEDICAL.DRUGS_ADDICTION" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="pharmaceuticals_addiction"
                                                        formControlName="pharmaceuticals_addiction" />
                                                <label for="pharmaceuticals_addiction">
                                                    {{ "VISITORS_MEDICAL.PHARMACEUTICALS_ADDICTION" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="unipolar_depression"
                                                        formControlName="unipolar_depression" />
                                                <label for="unipolar_depression">
                                                    {{ "VISITORS_MEDICAL.UNIPOLAR_DEPRESSION" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="bipolar_disorders"
                                                        formControlName="bipolar_disorders" />
                                                <label for="bipolar_disorders">
                                                    {{ "VISITORS_MEDICAL.BIPOLAR_DISORDERS" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="psychosis"
                                                        formControlName="psychosis" />
                                                <label for="psychosis">
                                                    {{ "VISITORS_MEDICAL.PSYCHOSIS" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="mental_retardation"
                                                        formControlName="mental_retardation" />
                                                <label for="mental_retardation">
                                                    {{ "VISITORS_MEDICAL.MENTAL_RETARDATION" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="significant_cognitive_disorders"
                                                        formControlName="significant_cognitive_disorders" />
                                                <label for="significant_cognitive_disorders">
                                                    {{ "VISITORS_MEDICAL.SIGNIFICANT_COGNITIVE_DISORDERS" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="eating_disorders"
                                                        formControlName="eating_disorders" />
                                                <label for="eating_disorders">
                                                    {{ "VISITORS_MEDICAL.EATING_DISORDERS" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="personality_disorders"
                                                        formControlName="personality_disorders" />
                                                <label for="personality_disorders">
                                                    {{ "VISITORS_MEDICAL.PERSONALITY_DISORDERS" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="pathological_gambling"
                                                        formControlName="pathological_gambling" />
                                                <label for="pathological_gambling">
                                                    {{ "VISITORS_MEDICAL.PATHOLOGICAL_GAMBLING" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="other_disorder"
                                                        formControlName="other_disorder" />
                                                <label for="other_disorder">
                                                    {{ "VISITORS_MEDICAL.OTHER_DISORDER" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="not_established_disorders"
                                                        formControlName="not_established_disorders" />
                                                <label for="not_established_disorders">
                                                    {{ "VISITORS_MEDICAL.NOT_ESTABLISHED_DIAGNOSTIC" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="addForm?.value?.alcohol_addiction">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="alcohol_addiction_remark">
                                        {{ 'VISITORS_MEDICAL.ALCOHOL_ADDICTION_REMARK' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <input type="text"
                                            class="form-control input-sm"
                                            id="alcohol_addiction_remark"
                                            formControlName="alcohol_addiction_remark">
                                </div>
                            </div>
                            <div class="form-group" *ngIf="addForm?.value?.drugs_addiction">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="drugs_addiction_remark">
                                        {{ 'VISITORS_MEDICAL.DRUGS_ADDICTION_REMARK' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <input type="text"
                                            class="form-control input-sm"
                                            id="drugs_addiction_remark"
                                            formControlName="drugs_addiction_remark">
                                </div>
                            </div>
                            <div class="form-group" *ngIf="addForm?.value?.pharmaceuticals_addiction">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="pharmaceuticals_addiction_remark">
                                        {{ 'VISITORS_MEDICAL.PHARMACEUTICALS_ADDICTION_REMARK' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <input type="text"
                                            class="form-control input-sm"
                                            id="pharmaceuticals_addiction_remark"
                                            formControlName="pharmaceuticals_addiction_remark">
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="informations">
                                        {{ "VISITORS_MEDICAL.INFORMATION" | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <textarea cols="30"
                                            rows="5"
                                            class="form-control input-sm"
                                            id="informations"
                                            formControlName="informations"></textarea>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset>
                            <legend>{{ 'VISITORS_MEDICAL.PSY_DATA' | translate }}</legend>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="psy_history">
                                        {{ "VISITORS_MEDICAL.PSY_HISTORY" | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <textarea cols="30"
                                            rows="5"
                                            class="form-control input-sm"
                                            id="psy_history"
                                            formControlName="psy_history"></textarea>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="psy_somatic_history">
                                        {{ "VISITORS_MEDICAL.SOMATIC_HISTORY" | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <textarea cols="30"
                                            rows="5"
                                            class="form-control input-sm"
                                            id="psy_somatic_history"
                                            formControlName="psy_somatic_history"></textarea>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="psy_old_treatments">
                                        {{ "VISITORS_MEDICAL.OLD_TREATMENTS" | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <textarea cols="30"
                                            rows="5"
                                            class="form-control input-sm"
                                            id="psy_old_treatments"
                                            formControlName="psy_old_treatments"></textarea>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="psy_intolerance">
                                        {{ "VISITORS_MEDICAL.INTOLERANCE" | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <textarea cols="30"
                                            rows="5"
                                            class="form-control input-sm"
                                            id="psy_intolerance"
                                            formControlName="psy_intolerance"></textarea>
                                </div>
                            </div>
                        </fieldset>

                        <div class="form-group">
                            <div class="col-md-12">
                                <button type="submit"
                                        class="btn"
                                        [ngClass]="{'btn-default':isSubmitting, 'btn-success': !isSubmitting}"                                                        
                                        [disabled]="!aForm.form.valid || isSubmitting">
                                    <ng-container *ngIf="cud == 'add'">
                                        <i class="fa"
                                            [ngClass]="{'fa-plus': !isSubmitting,
                                                        'fa-spinner fa-spin': isSubmitting}"></i> {{ "SHARED.ADD" | translate }}
                                    </ng-container>
                                    <ng-container *ngIf="cud == 'edit'">
                                        <i class="fa"
                                            [ngClass]="{'fa-edit': !isSubmitting,
                                                        'fa-spinner fa-spin': isSubmitting}"></i> {{ "SHARED.EDIT" | translate }}
                                    </ng-container>
                                </button>
                            </div>
                        </div>
                    </form>
                </ng-container>
                <ng-container *ngIf="isLoading">
                    <app-loading-spinner></app-loading-spinner>
                </ng-container>
            </div>
        </div>
    </div>
</div>