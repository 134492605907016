<form [formGroup]="filtersForm" #fForm="ngForm" (ngSubmit)="submit()" class="form-horizontal" autocomplete="off">
    <div class="form-group">
        <div class="col-md-12">
            <input type="text"
                    class="form-control input-sm"
                    placeholder="{{ 'FILTER.SEARCH_BY_IDENTIFIER_EMAIL' | translate }}"
                    formControlName="term" />
        </div>
    </div>

    <button type="submit" class="btn btn-success">
        <i class="fa fa-filter"></i> {{ 'SHARED.FILTER' | translate }}
    </button>
    <button type="button" class="btn btn-danger ml-3" (click)="reset()" *ngIf="filtersForm?.value?.term">
        <i class="fa fa-close"></i> {{ 'SHARED.RESET' | translate }}
    </button>
</form>