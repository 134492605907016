<div class="row">
    <div class="col-md-12">
        <app-util-visitor-parts-medical-stat-list-filter
                                            [selfVisitor]="selfVisitor"
                                            (filterEvent)="filterVisitorMedicalStats($event)"></app-util-visitor-parts-medical-stat-list-filter>
    </div>
</div>
<div class="row mt-5">
    <div class="col-md-12 align-items-center justify-content-space-between">
        <h5 class="title mb-3">
            {{ 'VISITORS_MEDICAL_STAT.ACTIONS' | translate }}
        </h5>
        <button type="button"
                class="btn btn-primary btn-fill"
                (click)="utilVisitorPartsMedicalStatModalsAdd.openModal(visitor?.id)"
                *ngxPermissionsOnly="'cudVisitorMedicalStat'">
            <i class="fa fa-plus"></i> {{ 'VISITORS_MEDICAL_STAT.ADD_STAT' | translate }}
        </button>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-12">
        <ng-container *ngIf="!isLoadingVisitors">
            <ng-container *ngIf="hasData?.visitorMedicalStats">
                <div class="overflow-auto pb-3">
                    <table class="table table-condensend table-striped">
                        <thead>
                            <tr>
                                <th class="p-3 text-center-important">#</th>
                                <th class="p-3 text-center-important" *ngxPermissionsOnly="'cudVisitorMedicalStat'">
                                    {{ 'SHARED.ACTIONS' | translate }}
                                </th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_STAT.DATE' | translate }}</th>
                                <ng-container *ngIf="!selfVisitor">
                                    <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_STAT.VISITOR' | translate }}</th>
                                </ng-container>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_STAT.CONSULTATION' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_STAT.CONCERTATION' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_STAT.ADMINISTRATIVE' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_STAT.CM' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_STAT.NETWORK' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_STAT.FOLLOWED_UP_FILE' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL_STAT.OTHER' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let visitorMedicalStat of visitorMedicalStats?.data; let i = index">
                                <td class="p-3 text-center-important">{{ i + 1 }}</td>
                                <td class="p-3 text-center-important td-actions" *ngxPermissionsOnly="'cudVisitorMedicalStat'">
                                    <button type="button"
                                            class="btn btn-success btn-simple p-0"
                                            title="{{ 'VISITORS_MEDICAL_STAT.EDIT_STAT' | translate }}"
                                            (click)="utilVisitorPartsMedicalStatModalsAdd.openModal(visitor?.id, visitorMedicalStat)"
                                            appTooltip>
                                        <i class="fa fa-edit"></i>
                                    </button>
                                    <button type="button"
                                            class="btn btn-danger btn-simple ml-3 p-0"
                                            title="{{ 'VISITORS_MEDICAL_STAT.DELETE_STAT' | translate }}"
                                            (click)="delete(visitorMedicalStat)"
                                            appTooltip>
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </td>
                                <td class="p-3 text-center-important">{{ visitorMedicalStat?.date | localizedDate: true | ifEmpty: '-' }}</td>
                                <ng-container *ngIf="!selfVisitor">
                                    <td class="p-3 text-center-important">
                                        <a [routerLink]="['/visitor', visitorMedicalStat?.vId, 'daily-entry']"
                                            class="text-primary">
                                            {{ visitorMedicalStat?.showName }}
                                        </a>
                                    </td>
                                </ng-container>
                                <td class="p-3 text-center-important">
                                    <app-icon-check-close [value]="visitorMedicalStat?.consultation"></app-icon-check-close>
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-icon-check-close [value]="visitorMedicalStat?.concertation"></app-icon-check-close>
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-icon-check-close [value]="visitorMedicalStat?.administrative"></app-icon-check-close>
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-icon-check-close [value]="visitorMedicalStat?.cm"></app-icon-check-close>
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-icon-check-close [value]="visitorMedicalStat?.network"></app-icon-check-close>
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-icon-check-close [value]="visitorMedicalStat?.followed_up_file"></app-icon-check-close>
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-text-limit [text]="visitorMedicalStat?.other" [title]="'TEXT'"></app-text-limit>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th class="p-3 text-center-important">{{ 'SHARED.TOTAL' | translate }}</th>
                                <th class="p-3 text-center-important" *ngxPermissionsOnly="'cudVisitorMedicalStat'"></th>
                                <th class="p-3 text-center-important"></th>
                                <th class="p-3 text-center-important" *ngIf="!selfVisitor"></th>
                                <th class="p-3 text-center-important">
                                    {{ countStats.consultation }}
                                </th>
                                <th class="p-3 text-center-important">
                                    {{ countStats.concertation }}
                                </th>
                                <th class="p-3 text-center-important">
                                    {{ countStats.administrative }}
                                </th>
                                <th class="p-3 text-center-important">
                                    {{ countStats.cm }}
                                </th>
                                <th class="p-3 text-center-important">
                                    {{ countStats.network }}
                                </th>
                                <th class="p-3 text-center-important">
                                    {{ countStats.followed_up_file }}
                                </th>
                                <th class="p-3 text-center-important"></th>
                            </tr>
                        </tfoot>
                    </table>
                    <div class="align-items-center justify-content-center" *ngIf="visitorEntries?.last_page > 1">
                        <app-pagination [data]="visitorEntries" (switchPage)="switchPage($event)"></app-pagination>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!hasData?.visitorMedicalStats">
                <p>
                    <ng-container *ngIf="selfVisitor">
                        {{ 'VISITORS_MEDICAL_STAT.NO_VISITOR_MEDICAL_STAT_FOUND' | translate }}
                    </ng-container>
                    <ng-container *ngIf="!selfVisitor">
                        {{ 'VISITORS_MEDICAL_STAT.NO_VISITORS_MEDICAL_STAT_FOUND' | translate }}
                    </ng-container>
                </p>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="isLoadingVisitors">
            <p>
                <app-loading-spinner></app-loading-spinner>
            </p>
        </ng-container>
    </div>
</div>
<app-util-visitor-parts-medical-stat-modals-add
    (addEntryEvent)="getVisitorsMedicalStats()"
    #utilVisitorPartsMedicalStatModalsAdd></app-util-visitor-parts-medical-stat-modals-add>