import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
    providedIn: 'root'
})
export class VisitorService extends ApiService {
    getById(id: number) {
        return super.get('/visitors/' + id);
    }

    getAll(data: any) {
        return super.post('/visitors', data);
    }

    export(data: any) {
        return super.post('/visitors/export', data);
    }

    create(data: any) {
        return super.post('/visitors/create', data);
    }

    update(data: any) {
        return super.put('/visitors/update', data);
    }

    suspend(data: any) {
        return super.put('/visitors/suspend', data);
    }

    suspendCancel(data: any) {
        return super.put('/visitors/suspend/cancel', data);
    }

    setAmu(data: any) {
        return super.put('/visitors/amu', data);
    }

    archive(id: number) {
        return super.put('/visitors/archive/' + id);
    }

    delete(id: number) {
        return super.delete('/visitors/' + id);
    }
}
