<div aria-labelledby="versionModalLabel" class="modal fade" id="versionModal" role="dialog" tabindex="-1" style="display: none;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
                <h4 class="modal-title" id="versionModalLabel">Nouvelle version des paramètres du logiciel</h4>
            </div>
            <div class="modal-body">
                <p class="text-left">
                    Les paramètres du logiciel ont été mis à jour. Veuillez rafraîchir la page pour appliquer les modifications.
                </p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-success btn-fill" (click)="closeVersionModal()">Rafraîchir la page !</button>
            </div>
        </div>
    </div>
</div>