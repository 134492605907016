import { Component, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { concat, Observable, of, Subject } from 'rxjs';
import { MiscService, VisitorService, VisitorTreatmentOtherService } from '@services/index';
import { Globals } from '@app/app.globals';

@Component({
    selector: 'app-util-visitor-parts-treatment-others-modals-add',
    templateUrl: './add.component.html',
})
export class UtilVisitorPartsTreatmentOthersModalsAddComponent implements OnInit {
    @Output() addTreatmentEvent = new EventEmitter<any>();

    isLoading = false;
    isSubmitting = false;
    modal = "#addTreatmentOtherModal";
    cud = "add";
    datepickerConfig: any;
    visitorTreatment: any;
    visitorId: number;
    visitors: Observable<any[]>;
    visitorsLoading = false;
    visitorsInput = new Subject();
    addForm = this.fb.group({
        id: null,
        visitor_id: [null, Validators.required],
        medication_name: [null, Validators.required],
        remarks: null,
        date_begin: null,
        date_end: null
    });
    hasData = {
        visitorTreatment: false
    };

    @ViewChild('datePickerBegin') datePickerBegin;
    @ViewChild('datePickerEnd') datePickerEnd;

    constructor(private cdref: ChangeDetectorRef,
                private fb: UntypedFormBuilder,
                private miscService: MiscService,
                private visitorService: VisitorService,
                private visitorTreatmentOtherService: VisitorTreatmentOtherService,
                public globals: Globals) {
        this.miscService.setPageTitle("TITLES.INFIRMARY_TREATMENT_OTHERS");
    }

    ngOnInit() {
        this.datepickerConfig = JSON.parse(JSON.stringify(this.globals.dateOnlyPickerConfig));
        this.cdref.detectChanges();
    }

    reset() {
        this.addForm.reset();
        this.visitorId = null;
        this.cud = "add";
    }
    
    openModal(visitorId = null, visitorTreatment = null) {
        if(this.miscService.checkEnvPermission("cudVisitorTreatment")) {
            this.isLoading = true;
            this.visitorTreatment = visitorTreatment;
            this.hasData.visitorTreatment = this.miscService.checkDataLength(this.visitorTreatment);
            this.reset();
            this.visitorId = visitorId;
            if(this.visitorId) {
                this.addForm.get('visitor_id').patchValue(visitorId);
            }
            else {
                this.loadVisitors();
            }
            if(this.hasData.visitorTreatment) {
                this.cud = "edit";
                this.addForm.patchValue(this.visitorTreatment);
                if(this.visitorTreatment.date_begin) {
                    this.datePickerBegin.setDate(this.visitorTreatment.date_begin);
                }
                if(this.visitorTreatment.date_end) {
                    this.datePickerEnd.setDate(this.visitorTreatment.date_end);
                }
            }
            this.isLoading = false;
            $(this.modal).modal('show');
        }
    }

    goToAddVisitorUrl() {
        $(this.modal).modal('hide');
        this.miscService.goToUrlDelay(['/visitor/add']);
    }

    loadVisitors() {
        this.visitors = concat(
            of([]),
            this.visitorsInput.pipe(
                distinctUntilChanged(),
                tap(() => this.visitorsLoading = true),
                debounceTime(500),
                switchMap(term => this.visitorService.getAll({term: term, limit: 5, filter: true}).pipe(
                    catchError(() => of([])),
                    tap(() => this.visitorsLoading = false),
                ))
            )
        );
    }

    dateChanged(evt, field) {
        if(evt) {
            let date = evt;
            this.addForm.get(field).patchValue(date);
        }
        else {
            this.resetField(field);
        }
	}

    resetField(field) {
        this.addForm.get(field).reset();
    }

    submit() {
        this.isSubmitting = true;
        let data = this.addForm.value;
        if(this.cud == "add") {
            this.create(data);
        }
        else {
            this.update(data);
        }
    }

    create(data) {
        this.visitorTreatmentOtherService.create(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_TREATMENT_OTHERS_CREATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addTreatmentEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_TREATMENT_OTHERS_NOT_CREATED" });
        });
    }

    update(data) {
        this.visitorTreatmentOtherService.update(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_TREATMENT_OTHERS_UPDATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addTreatmentEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_TREATMENT_OTHERS_NOT_UPDATED" });
        });
    }
}
