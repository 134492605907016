import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: "app-address-input",
    templateUrl: "./address-input.component.html",
})
export class AddressInputComponent implements OnInit {
    @Output() changeEvent = new EventEmitter<any>();

    isValid = false;
    randId = Math.floor(Math.random() * 10000);
    compId = "#address-" + this.randId;
    addressData: any;

    ngOnInit() {}

    setAddress(address = null) {
        if(address && address.full_address !== undefined && address.full_address) {
            this.handleAddressChange(address);
        }
    }

    setAddresFromParent(address) {
        this.addressData = address;
        $(this.compId).val(address.full_address);
    }

    handleAddressChange(address) {
        if(this.addressData === undefined) {
			this.addressData = {};
		}
		this.addressData['street_number'] = null;
		this.addressData['route'] = null;
		this.addressData['locality'] = null;
		this.addressData['administrative_area_level_2'] = null;
		this.addressData['administrative_area_level_1'] = null;
		this.addressData['country'] = null;
		this.addressData['postal_code'] = null;
		this.addressData['full_address'] = null;

		for(let a of address.address_components) {
			let types = a.types;
			if(types.indexOf("street_number") != -1) {
				this.addressData['street_number'] = a.long_name;
			}
			if(types.indexOf("route") != -1 || types.indexOf("street") != -1) {
				this.addressData['route'] = a.long_name;
			}
			if(types.indexOf("locality") != -1) {
				this.addressData['locality'] = a.long_name;
			}
			if(types.indexOf("administrative_area_level_2") != -1) {
				this.addressData['administrative_area_level_2'] = a.long_name;
			}
			if(types.indexOf("administrative_area_level_1") != -1) {
				this.addressData['administrative_area_level_1'] = a.long_name;
			}
			if(types.indexOf("country") != -1) {
				this.addressData['country'] = a.short_name;
			}
			if(types.indexOf("postal_code") != -1) {
				this.addressData['postal_code'] = a.long_name;
			}
		}
		this.addressData['full_address'] = address.formatted_address;
        this.checkAddressValid();
	}

    resetAddress() {
        this.addressData = null;
        $(this.compId).val("");
        this.changeEvent.emit(null);
    }

    checkAddressValid() {
		let valid = true;
        if(this.addressData.full_address == null) {
            valid = false;
        }
		this.isValid = valid;
        if(this.isValid) {
            this.changeEvent.emit(this.addressData);
        }
	}
}
