
<h5 class="title mb-3">
    <ng-container *ngIf="type == 'year'">
        {{ 'STATISTICS.BY_YEAR' | translate }}
    </ng-container>
    <ng-container *ngIf="type == 'month'">
        {{ 'STATISTICS.BY_MONTH' | translate }}
    </ng-container>
    <ng-container *ngIf="type == 'day'">
        {{ 'STATISTICS.BY_DAY' | translate }}
    </ng-container>
    <i class="fa pointer text-primary" 
        [ngClass]="{'fa-angle-double-down': !showFilters,
                    'fa-angle-double-up': showFilters}"
        (click)="toggleShowFilters()"></i>
</h5>
<form [formGroup]="searchForm" (ngSubmit)="submit()" #sForm="ngForm" autocomplete="off" [hidden]="!showFilters">
    <div class="row">
        <div class="form-group">
            <div class="col-md-3">
                <label class="control-label control-label-sm" for="date">
                    <ng-container *ngIf="type == 'year'">
                        {{ 'STATISTICS.YEAR' | translate }}
                    </ng-container>
                    <ng-container *ngIf="type == 'month'">
                        {{ 'STATISTICS.MONTH' | translate }}
                    </ng-container>
                    <ng-container *ngIf="type == 'day'">
                        {{ 'STATISTICS.DAY' | translate }}
                    </ng-container>
                </label>
            </div>
            <div class="col-md-9">
                <input type="text"
                        id="date"
                        class="form-control input-sm"
                        formControlName="date"
                        (input)="changeDate()" />
                <p class="m-0" style="font-size: 0.9em; font-style: italic;">
                    <ng-container *ngIf="type == 'year'">
                        {{ 'DATE.EXPECTED_FORMAT' | translate }} : 2024
                    </ng-container>
                    <ng-container *ngIf="type == 'month'">
                        {{ 'DATE.EXPECTED_FORMAT' | translate }} : 02/2024
                    </ng-container>
                    <ng-container *ngIf="type == 'day'">
                        {{ 'DATE.EXPECTED_FORMAT' | translate }} : 25/02/2024
                    </ng-container>
                </p>
            </div>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-md-12">
            <button type="submit"
                    class="btn btn-success btn-fill"
                    [disabled]="!sForm.form.valid || !isValid">
                <i class="fa fa-search"></i> {{ 'SHARED.SEARCH' | translate }}
            </button>
            <button type="button"
                    class="btn btn-danger btn-fill ml-3"
                    (click)="reset()"
                    *ngIf="filledForm">
                <i class="fa fa-close"></i> {{ 'SHARED.RESET' | translate }}
            </button>
        </div>
    </div>
</form>