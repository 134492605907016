<div class="row" *ngIf="data && last_page > 1">
    <div class="hidden-sm hidden-xs align-items-center">
        <div class="col-md-2 text-left">
            <button type="button" class="btn btn-{{ size }} btn-simple pl-0" [disabled]="current_page <= 1" (click)="switch(current_page - 1)">
                <i class="fa fa-angle-left"></i> {{ 'SHARED.PREVIOUS' | translate }}
            </button>
        </div>

        <div class="col-md-8 text-center">
            <ng-container *ngIf="current_page > 3">
                <button type="button" class="btn btn-{{ size }} btn-simple ml-1 mr-1" (click)="switch(1)">1</button>
                <button type="button" class="btn btn-{{ size }} btn-simple ml-1 mr-1" disabled>...</button>
            </ng-container>

            <button type="button" class="btn btn-{{ size }} btn-simple ml-1 mr-1" (click)="switch(current_page - 2)" *ngIf="current_page - 2 > 0">{{ current_page - 2 }}</button>
            <button type="button" class="btn btn-{{ size }} btn-simple ml-1 mr-1" (click)="switch(current_page - 1)" *ngIf="current_page - 1 > 0">{{ current_page - 1 }}</button>

            <button type="button" class="btn btn-{{ size }} btn-{{ color }} btn-fill ml-1 mr-1">{{ current_page }}</button>

            <button type="button" class="btn btn-{{ size }} btn-simple ml-1 mr-1" (click)="switch(current_page + 1)" *ngIf="current_page + 1 < last_page + 1">{{ current_page + 1 }}</button>
            <button type="button" class="btn btn-{{ size }} btn-simple ml-1 mr-1" (click)="switch(current_page + 2)" *ngIf="current_page + 2 < last_page + 1">{{ current_page + 2 }}</button>

            <ng-container *ngIf="current_page < last_page - 2">
                <button type="button" class="btn btn-{{ size }} btn-simple ml-1 mr-1" disabled>...</button>
                <button type="button" class="btn btn-{{ size }} btn-simple ml-1 mr-1" (click)="switch(last_page)">{{ last_page }}</button>
            </ng-container>
        </div>

        <div class="col-md-2 text-right">
            <button type="button" class="btn btn-{{ size }} btn-simple pr-0" [disabled]="current_page >= last_page" (click)="switch(current_page + 1)">
                {{ 'SHARED.NEXT' | translate }} <i class="fa fa-angle-right"></i>
            </button>
        </div>
    </div>

    <div class="col-md-12 hidden-md hidden-lg hidden-xl justify-content-space-between align-items-center">
        <div class="text-left">
            <button type="button" class="btn btn-{{ size }} btn-default btn-fill" [disabled]="current_page <= 1" (click)="switch(current_page - 1)">
                <i class="fa fa-angle-left"></i>
            </button>
        </div>

        <div class="text-center">
            {{ 'PAGE_OF' | translate: {currentPage: current_page, lastPage: last_page} }}
        </div>

        <div class="text-right">
            <button type="button" class="btn btn-{{ size }} btn-default btn-fill" [disabled]="current_page >= last_page" (click)="switch(current_page + 1)">
                <i class="fa fa-angle-right"></i>
            </button>
        </div>
    </div>
</div>
