// src/app/auth/auth.service.ts
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@services/index';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private localStorageService: LocalStorageService) {}

    public getToken(): string {
        let currentUser = this.localStorageService.getLSItem('currentUser');
        if (currentUser && currentUser.api_token) {
            return currentUser.api_token;
        }
    }

    public isAuthenticated(): boolean {
        // get the token
        const token = this.getToken();

        // return a boolean reflecting
        // whether or not the token is expired
        return false;
    }
}
