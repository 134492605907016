import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
    providedIn: 'root'
})
export class FileService extends ApiService {
    index(params: any = {}) {
        return super.get('/files', params);
    }

    update(id: number, data: any = {}) {
        return super.put('/files/' + id, data);
    }

    destroy(id: number) {
        return super.delete('/files/' + id);
    }

    destroyMany(params: any = {}) {
        return super.deleteBis('/files', params);
    }

    upload(data: any) {
        return super.post('/files/upload', data);
    }

    uploadFiles(data: any) {
        return super.post('/files/upload/files', data);
    }

    zip(data: any) {
        return super.post('/files/zip', data);
    }
}
