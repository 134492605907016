import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'app-util-statistics-list-filter',
    templateUrl: './filter.component.html',
})
export class UtilStatisticsListFilterComponent implements OnInit {
    @Input() type: string;
    @Output() filterEvent = new EventEmitter<any>();
    @Output() changedDateEvent = new EventEmitter<any>();

    isValid = true;
    showFilters = true;
    searchForm = this.fb.group({
        date: [null, Validators.required],
        type: [null, Validators.required]
    });

    constructor(public fb: UntypedFormBuilder) {}

    ngOnInit() {
        this.searchForm.patchValue({
            date: this.resetDate(),
            type: this.type
        });
        this.submit();
    }

    resetDate() {
        let date = null;
        switch(this.type) {
            case "year":
                date = moment().format('YYYY');
                break;
            case "month":
                date = moment().format('MM/YYYY');
                break;
            case "day":
                date = moment().format('DD/MM/YYYY');
                break;
        }
        this.isValid = true;

        return date;
    }

    changeDate() {
        let isValid = true;
        let date = this.searchForm.value.date;
        if(!date.length) {
            isValid = false;
        }
        else {
            switch(this.type) {
                case "year":
                    if(date.length != 4) {
                        isValid = false;
                    }
                    break;
                case "month":
                    if(date.length != 7) {
                        isValid = false;
                    }
                    break;
                case "day":
                    if(date.length != 10) {
                        isValid = false;
                    }
                    break;
            }
        }
        this.isValid = isValid;
        if(this.isValid) {
            this.changedDateEvent.emit(date);
        }
    }

    toggleShowFilters() {
        this.showFilters = !this.showFilters;
    }

    get filledForm() {
        return this.searchForm.value.date;
    }

    reset() {
        this.searchForm.reset({
            date: this.resetDate(),
            type: this.type
        });
        this.submit();
    }

    submit() {
        let data = this.searchForm.value;
        this.filterEvent.emit(data);
    }
}
