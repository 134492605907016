<div aria-labelledby="addPsychoModalLabel" class="modal modal-big fade" id="addPsychoModal" role="dialog" tabindex="-1" style="display: none; z-index: 9999;">
    <div class="modal-dialog width-900" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
                <h4 class="modal-title">
                    <ng-container *ngIf="cud == 'add'">
                        {{ "VISITORS_PSYCHO.CREATE_PSYCHO_DATA" | translate }}
                    </ng-container>
                    <ng-container *ngIf="cud == 'edit'">
                        {{ "VISITORS_PSYCHO.EDIT_PSYCHO_DATA" | translate }}
                    </ng-container>
                </h4>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="!isLoading">
                    <form [formGroup]="addForm" #aForm="ngForm" class="form-horizontal" (submit)="submit()">
                        <fieldset>
                            <legend>{{ 'VISITORS.GENERAL_INFORMATION' | translate }}</legend>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="disparition_person">
                                        {{ 'VISITORS_PSYCHO.DISPARITION_PERSON' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <input type="text"
                                            class="form-control input-sm"
                                            id="disparition_person"
                                            formControlName="disparition_person" />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="death_person">
                                        {{ 'VISITORS_PSYCHO.DEATH_PERSON' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <input type="text"
                                            class="form-control input-sm"
                                            id="death_person"
                                            formControlName="death_person" />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="asker_id">
                                        {{ 'VISITORS_PSYCHO.ASKER' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <app-ng-select-add-multiple
                                                        [choiceGroup]="getChoiceGroup('dde_consult_psy')"
                                                        [items]="ddeConsultPsys"
                                                        [id]="'asker_id'"
                                                        [selectedId]="addForm?.value?.asker_id"
                                                        (addChoicesEvent)="addChoices('ddeConsultPsys', $event)"
                                                        (changeEvent)="changeChoice('asker_id', $event)"></app-ng-select-add-multiple>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="atcd_somatic_monitoring">
                                        {{ 'VISITORS_PSYCHO.SOMATIC_ATCD_FOLLOW_UP' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <input type="text"
                                            class="form-control input-sm"
                                            id="atcd_somatic_monitoring"
                                            formControlName="atcd_somatic_monitoring" />
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>{{ 'VISITORS_PSYCHO.PREVIOUS_CONTACTS' | translate }}</legend>
                            <div class="form-group">
                                <div class="col-md-12">
                                    <p class="m-0">
                                        <strong>{{ 'VISITORS_PSYCHO.ADDICTIONS' | translate }}</strong>
                                    </p>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="prior_addictions_hospi_id">
                                        {{ 'VISITORS_PSYCHO.HOSPI' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-4">
                                    <app-ng-select-add-multiple
                                                        [choiceGroup]="getChoiceGroup('yes-no')"
                                                        [items]="yesNos"
                                                        [id]="'prior_addictions_hospi_id'"
                                                        [selectedId]="addForm?.value?.prior_addictions_hospi_id"
                                                        (addChoicesEvent)="addChoices('yesNos', $event)"
                                                        (changeEvent)="changeChoice('prior_addictions_hospi_id', $event)"></app-ng-select-add-multiple>
                                </div>
                                <div class="col-md-5">
                                    <input type="text"
                                            class="form-control"
                                            id="prior_addictions_hospi_remark"
                                            formControlName="prior_addictions_hospi_remark" />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="prior_addictions_ambulatory_id">
                                        {{ 'VISITORS_PSYCHO.AMBULATORY' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-4">
                                    <app-ng-select-add-multiple
                                                        [choiceGroup]="getChoiceGroup('yes-no')"
                                                        [items]="yesNos"
                                                        [id]="'prior_addictions_ambulatory_id'"
                                                        [selectedId]="addForm?.value?.prior_addictions_ambulatory_id"
                                                        (addChoicesEvent)="addChoices('yesNos', $event)"
                                                        (changeEvent)="changeChoice('prior_addictions_ambulatory_id', $event)"></app-ng-select-add-multiple>
                                </div>
                                <div class="col-md-5">
                                    <input type="text"
                                            class="form-control"
                                            id="prior_addictions_ambulatory_remark"
                                            formControlName="prior_addictions_ambulatory_remark" />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-12">
                                    <p class="m-0">
                                        <strong>{{ 'VISITORS_PSYCHO.PSY' | translate }}</strong>
                                    </p>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="prior_psy_hospi_id">
                                        {{ 'VISITORS_PSYCHO.HOSPI' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-4">
                                    <app-ng-select-add-multiple
                                                        [choiceGroup]="getChoiceGroup('yes-no')"
                                                        [items]="yesNos"
                                                        [id]="'prior_psy_hospi_id'"
                                                        [selectedId]="addForm?.value?.prior_psy_hospi_id"
                                                        (addChoicesEvent)="addChoices('yesNos', $event)"
                                                        (changeEvent)="changeChoice('prior_psy_hospi_id', $event)"></app-ng-select-add-multiple>
                                </div>
                                <div class="col-md-5">
                                    <input type="text"
                                            class="form-control"
                                            id="prior_psy_hospi_remark"
                                            formControlName="prior_psy_hospi_remark" />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="prior_psy_ambulatory_id">
                                        {{ 'VISITORS_PSYCHO.AMBULATORY' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-4">
                                    <app-ng-select-add-multiple
                                                        [choiceGroup]="getChoiceGroup('yes-no')"
                                                        [items]="yesNos"
                                                        [id]="'prior_psy_ambulatory_id'"
                                                        [selectedId]="addForm?.value?.prior_psy_ambulatory_id"
                                                        (addChoicesEvent)="addChoices('yesNos', $event)"
                                                        (changeEvent)="changeChoice('prior_psy_ambulatory_id', $event)"></app-ng-select-add-multiple>
                                </div>
                                <div class="col-md-5">
                                    <input type="text"
                                            class="form-control"
                                            id="prior_psy_ambulatory_remark"
                                            formControlName="prior_psy_ambulatory_remark" />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="prior_psy_path_theta_id">
                                        {{ 'VISITORS_PSYCHO.PATH_PSY_WITH_THETA_NECESSARY' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-4">
                                    <app-ng-select-add-multiple
                                                        [choiceGroup]="getChoiceGroup('yes-no')"
                                                        [items]="yesNos"
                                                        [id]="'prior_psy_path_theta_id'"
                                                        [selectedId]="addForm?.value?.prior_psy_path_theta_id"
                                                        (addChoicesEvent)="addChoices('yesNos', $event)"
                                                        (changeEvent)="changeChoice('prior_psy_path_theta_id', $event)"></app-ng-select-add-multiple>
                                </div>
                                <div class="col-md-5">
                                    <input type="text"
                                            class="form-control"
                                            id="prior_psy_path_theta_remark"
                                            formControlName="prior_psy_path_theta_remark" />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="prior_psy_theta_entry_id">
                                        {{ 'VISITORS_PSYCHO.THETA_AT_ENTRY' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-4">
                                    <app-ng-select-add-multiple
                                                        [choiceGroup]="getChoiceGroup('yes-no')"
                                                        [items]="yesNos"
                                                        [id]="'prior_psy_theta_entry_id'"
                                                        [selectedId]="addForm?.value?.prior_psy_theta_entry_id"
                                                        (addChoicesEvent)="addChoices('yesNos', $event)"
                                                        (changeEvent)="changeChoice('prior_psy_theta_entry_id', $event)"></app-ng-select-add-multiple>
                                </div>
                                <div class="col-md-5">
                                    <input type="text"
                                            class="form-control"
                                            id="prior_psy_theta_entry_remark"
                                            formControlName="prior_psy_theta_entry_remark" />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="prior_psy_theta_entry_no_reason_id">
                                        {{ 'VISITORS_PSYCHO.IF_NOT_WHY' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-4">
                                    <app-ng-select-add-multiple
                                                            [choiceGroup]="getChoiceGroup('stopping_treatment')"
                                                            [items]="stoppingTreatments"
                                                            [id]="'prior_psy_theta_entry_no_reason_ids'"
                                                            [selectedId]="addForm?.value?.prior_psy_theta_entry_no_reason_ids"
                                                            [multiple]="true"
                                                            (addChoicesEvent)="addChoices('stoppingTreatments', $event)"
                                                            (changeEvent)="changeChoice('prior_psy_theta_entry_no_reason_ids', $event)"></app-ng-select-add-multiple>
                                </div>
                                <div class="col-md-5">
                                    <input type="text"
                                            class="form-control"
                                            id="prior_psy_theta_entry_no_reason_remark"
                                            formControlName="prior_psy_theta_entry_no_reason_remark" />
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>{{ 'VISITORS_PSYCHO.REFERRALS' | translate }}</legend>
                            <div class="form-group">
                                <div class="col-md-12">
                                    <p class="m-0">
                                        <strong>{{ 'VISITORS_PSYCHO.TO_SM_CARE' | translate }}</strong>
                                    </p>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="vp_healthcare_sm_ambulatory_ids">
                                        {{ 'VISITORS_PSYCHO.AMBULATORY' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <app-ng-select-add-multiple
                                                        [choiceGroup]="getChoiceGroup('ambulatory')"
                                                        [items]="ambulatories"
                                                        [id]="'vp_healthcare_sm_ambulatory_ids'"
                                                        [selectedId]="addForm?.value?.vp_healthcare_sm_ambulatory_ids"
                                                        [multiple]="true"
                                                        (addChoicesEvent)="addChoices('ambulatories', $event)"
                                                        (changeEvent)="changeChoice('vp_healthcare_sm_ambulatory_ids', $event)"></app-ng-select-add-multiple>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="vp_healthcare_sm_hospi_ids">
                                        {{ 'VISITORS_PSYCHO.HOSPITALS' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <app-ng-select-add-multiple
                                                        [choiceGroup]="getChoiceGroup('hospitable')"
                                                        [items]="hospitables"
                                                        [id]="'vp_healthcare_sm_hospi_ids'"
                                                        [selectedId]="addForm?.value?.vp_healthcare_sm_hospi_ids"
                                                        [multiple]="true"
                                                        (addChoicesEvent)="addChoices('hospitables', $event)"
                                                        (changeEvent)="changeChoice('vp_healthcare_sm_hospi_ids', $event)"></app-ng-select-add-multiple>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="healthcare_sm_worry_notices">
                                        {{ 'VISITORS_PSYCHO.WORRY_NOTICES' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <input type="text"
                                            class="form-control input-sm"
                                            id="healthcare_sm_worry_notices"
                                            formControlName="healthcare_sm_worry_notices" />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="healthcare_sm_prison_care">
                                        {{ 'VISITORS_PSYCHO.HEALTHCARE_PRISON_CARE' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <input type="text"
                                            class="form-control input-sm"
                                            id="healthcare_sm_prison_care"
                                            formControlName="healthcare_sm_prison_care" />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-12">
                                    <p class="m-0">
                                        <strong>{{ 'VISITORS_PSYCHO.TO_OUTPATIENT_SOMATIC_CARE' | translate }}</strong>
                                    </p>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="vp_somatic_ambulatory_ids">
                                        {{ 'VISITORS_PSYCHO.TO_OUTPATIENT_SOMATIC_CARE' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <app-ng-select-add-multiple
                                                        [choiceGroup]="getChoiceGroup('somatic')"
                                                        [items]="somatics"
                                                        [id]="'vp_somatic_ambulatory_ids'"
                                                        [selectedId]="addForm?.value?.vp_somatic_ambulatory_ids"
                                                        [multiple]="true"
                                                        (addChoicesEvent)="addChoices('somatics', $event)"
                                                        (changeEvent)="changeChoice('vp_somatic_ambulatory_ids', $event)"></app-ng-select-add-multiple>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-12">
                                    <p class="m-0">
                                        <strong>{{ 'VISITORS_PSYCHO.TO_HOSTING' | translate }}</strong>
                                    </p>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="vp_hosting_ids">
                                        {{ 'VISITORS_PSYCHO.TO_HOSTING' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <app-ng-select-add-multiple
                                                        [choiceGroup]="getChoiceGroup('lodging')"
                                                        [items]="lodgings"
                                                        [id]="'vp_hosting_ids'"
                                                        [selectedId]="addForm?.value?.vp_hosting_ids"
                                                        [multiple]="true"
                                                        (addChoicesEvent)="addChoices('lodgings', $event)"
                                                        (changeEvent)="changeChoice('vp_hosting_ids', $event)"></app-ng-select-add-multiple>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-12">
                                    <p class="m-0">
                                        <strong>{{ 'VISITORS_PSYCHO.SOCIO_PROFESSIONAL' | translate }}</strong>
                                    </p>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="vp_socio_professional_ids">
                                        {{ 'VISITORS_PSYCHO.SOCIO_PROFESSIONAL' | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <app-ng-select-add-multiple
                                                        [choiceGroup]="getChoiceGroup('socio-pro')"
                                                        [items]="socioPros"
                                                        [id]="'vp_socio_professional_ids'"
                                                        [selectedId]="addForm?.value?.vp_socio_professional_ids"
                                                        [multiple]="true"
                                                        (addChoicesEvent)="addChoices('socioPros', $event)"
                                                        (changeEvent)="changeChoice('vp_socio_professional_ids', $event)"></app-ng-select-add-multiple>
                                </div>
                            </div>
                        </fieldset>

                        <div class="form-group">
                            <div class="col-md-12">
                                <button type="submit"
                                        class="btn"
                                        [ngClass]="{'btn-default':isSubmitting, 'btn-success': !isSubmitting}"                                                        
                                        [disabled]="!aForm.form.valid || isSubmitting">
                                    <ng-container *ngIf="cud == 'add'">
                                        <i class="fa"
                                            [ngClass]="{'fa-plus': !isSubmitting,
                                                        'fa-spinner fa-spin': isSubmitting}"></i> {{ "SHARED.ADD" | translate }}
                                    </ng-container>
                                    <ng-container *ngIf="cud == 'edit'">
                                        <i class="fa"
                                            [ngClass]="{'fa-edit': !isSubmitting,
                                                        'fa-spinner fa-spin': isSubmitting}"></i> {{ "SHARED.EDIT" | translate }}
                                    </ng-container>
                                </button>
                            </div>
                        </div>
                    </form>
                </ng-container>
                <ng-container *ngIf="isLoading">
                    <app-loading-spinner></app-loading-spinner>
                </ng-container>
            </div>
        </div>
    </div>
</div>