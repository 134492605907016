import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
    transform(value: number): string {
        const days: number = Math.floor(value / 60 / 24) >= 1 ? Math.floor(value / 60 / 24) : 0;
        const daysTxt: string = days ? days + "j " : "";
        const hours: number = Math.floor((value - (days * 24 * 60)) / 60);
        
        return daysTxt + this.numberDigits(hours) + "h" + this.numberDigits(Math.floor(value - (days * 24 * 60) - (hours * 60)));
    }

    numberDigits(value) {
        if(value < 10) {
            return "0" + value;
        }

        return value;
    }
}
