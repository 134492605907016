import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

declare let moment: any;

@Pipe({
    name: 'localizedDate',
    pure: false,
})
export class LocalizedDatePipe implements PipeTransform {

    constructor(private translateService: TranslateService) {
        this.translateService.setDefaultLang("fr");
    }

    transform(value: any, withHours = false): any {
        if(!value) {
            return null;
        }

        if(typeof value === 'string') {
            if(moment(value).isValid()) {
                value = moment(value);
            }
            else {
                let format = 'DD-MM-YY';
                if(withHours) {
                    format += ' HH:mm';
                }

                if(moment(value, format).isValid()) {
                    value = moment(value, format);
                }
                else {
                    return value;
                }
            }
            value = value.format();
        }
        let format = 'dd/MM/YY';
        if(withHours) {
            format += ' HH:mm';
        }

        const datePipe = new DatePipe(this.translateService.currentLang);
        return datePipe.transform(value, format);
    }

}
