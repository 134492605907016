<ng-container *ngIf="title && showTitle">
    <span [attr.title]="title" appTooltip>
        <i class="fa {{ moreClasses }}"
            [ngClass]="{'fa-check-square-o text-success': value,
                        'fa-minus': !value}"></i>
        <i class="fa fa-question fa-tiny"></i>
    </span>
</ng-container>
<ng-container *ngIf="!title || !showTitle">
    <i class="fa {{ moreClasses }}"
        [ngClass]="{'fa-check-square-o text-success': value,
                    'fa-minus': !value}"></i>
</ng-container>
<ng-container *ngIf="text">
    <span class="ml-1">
        {{ text | translate }}
    </span>
</ng-container>