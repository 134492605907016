<div class="row" *ngIf="!selfVisitor">
    <div class="col-md-12">
        <app-util-visitor-parts-treatment-per-os-list-filter
                                            [selfVisitor]="selfVisitor"
                                            (filterEvent)="filterVisitorTreatments($event)"></app-util-visitor-parts-treatment-per-os-list-filter>
    </div>
</div>
<div class="row mt-5">
    <div class="col-md-12 align-items-center justify-content-space-between">
        <h5 class="title mb-3">
            <ng-container *ngIf="!archived">
                {{ 'VISITORS_MEDICAL.TREATMENTS_PER_OS_IN_PROGRESS' | translate }}
            </ng-container>
            <ng-container *ngIf="archived">
                {{ 'VISITORS_MEDICAL.TREATMENTS_PER_OS_HISTORY' | translate }}
            </ng-container>
        </h5>
        <ng-container *ngxPermissionsOnly="'cudVisitorTreatment'">
            <div *ngIf="!archived">
                <button type="button"
                        class="btn btn-primary btn-fill"
                        (click)="utilVisitorPartsTreatmentPerOsModalsAdd.openModal(visitor?.id)">
                    <i class="fa fa-plus"></i> {{ 'VISITORS_MEDICAL.ADD_TREATMENT' | translate }}
                </button>
            </div>
        </ng-container>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-12">
        <ng-container *ngIf="!isLoading">
            <ng-container *ngIf="hasData?.visitorTreatments">
                <div class="overflow-auto pb-3">
                    <table class="table table-condensend table-striped">
                        <thead>
                            <tr>
                                <th class="p-3 text-center-important">#</th>
                                <ng-container *ngxPermissionsOnly="'cudVisitorTreatment'">
                                    <th class="p-3 text-center-important" *ngIf="!archived">{{ 'SHARED.ACTIONS' | translate }}</th>
                                </ng-container>
                                <ng-container *ngIf="!selfVisitor">
                                    <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL.VISITOR' | translate }}</th>
                                </ng-container>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL.NAME_AND_DOSAGE' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'FILTER.DATE_BEGIN' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'FILTER.DATE_END' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'FILTER.DATE' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL.MORNING' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL.NOON' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL.SUPPER' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL.BEDTIME' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL.REMARKS' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let visitorTreatment of visitorTreatments?.data; let i = index">
                                <td class="p-3 text-center-important" style="width: 70px;">{{ i + 1 }}</td>
                                <ng-container *ngxPermissionsOnly="'cudVisitorTreatment'">
                                    <td class="p-3 text-center-important td-actions"
                                        style="width: 100px;"
                                        *ngIf="!archived">
                                        <button type="button"
                                                class="btn btn-success btn-simple p-0"
                                                title="{{ 'VISITORS_MEDICAL.EDIT_TREATMENT' | translate }}"
                                                (click)="utilVisitorPartsTreatmentPerOsModalsAdd.openModal(visitor?.id, visitorTreatment)"
                                                appTooltip>
                                            <i class="fa fa-edit"></i>
                                        </button>
                                        <button type="button"
                                                class="btn btn-danger btn-simple ml-3 p-0"
                                                title="{{ 'VISITORS_MEDICAL.DELETE_TREATMENT' | translate }}"
                                                (click)="delete(visitorTreatment)"
                                                appTooltip>
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </td>
                                </ng-container>
                                <ng-container *ngIf="!selfVisitor">
                                    <td class="p-3 text-center-important">
                                        <a [routerLink]="['/visitor', visitorTreatment?.vId, 'infirmary', 'treatments']"
                                            class="text-primary">
                                            {{ visitorTreatment?.showName }}
                                        </a>
                                    </td>
                                </ng-container>
                                <td class="p-3 text-center-important" style="width: 300px;">
                                    <app-text-limit [text]="visitorTreatment?.medication_name" [limit]="30" [title]="'NAME_AND_DOSAGE'"></app-text-limit>
                                </td>
                                <td class="p-3 text-center-important" style="width: 120px;">
                                    {{ visitorTreatment?.date_begin | localizedDate | ifEmpty: '-' }}
                                </td>
                                <td class="p-3 text-center-important" style="width: 120px;">
                                    {{ visitorTreatment?.date_end | localizedDate | ifEmpty: '-'}}
                                </td>
                                <td class="p-3 text-center-important" style="width: 120px;">
                                    {{ visitorTreatment?.date | localizedDate | ifEmpty: '-' }}
                                </td>
                                <td class="p-3 text-center-important">
                                    {{ visitorTreatment?.morning | ifEmpty: '-' }}
                                </td>
                                <td class="p-3 text-center-important">
                                    {{ visitorTreatment?.noon | ifEmpty: '-' }}
                                </td>
                                <td class="p-3 text-center-important">
                                    {{ visitorTreatment?.supper | ifEmpty: '-' }}
                                </td>
                                <td class="p-3 text-center-important">
                                    {{ visitorTreatment?.bedtime | ifEmpty: '-' }}
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-text-limit [text]="visitorTreatment?.remarks" [title]="'REMARK'"></app-text-limit>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="align-items-center justify-content-center" *ngIf="visitorTreatments?.last_page > 1">
                        <app-pagination [data]="visitorTreatments" (switchPage)="switchPage($event)"></app-pagination>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!hasData?.visitorTreatments">
                <p>
                    {{ 'VISITORS_MEDICAL.NO_TREATMENT_PER_OS_FOUND' | translate }}
                </p>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="isLoading">
            <p>
                <app-loading-spinner></app-loading-spinner>
            </p>
        </ng-container>
    </div>
</div>
<app-util-visitor-parts-treatment-per-os-modals-add (addTreatmentEvent)="addTreatment()" #utilVisitorPartsTreatmentPerOsModalsAdd></app-util-visitor-parts-treatment-per-os-modals-add>