<div style="width: 100%;">
    <div style="width: 100%;">
        <ng-select [items]="items"
                    bindId="id"
                    bindLabel="name"
                    [loading]="!items"
                    [multiple]="multiple"
                    [closeOnSelect]="closeOnSelect"
                    [(ngModel)]="value"
                    (change)="change()"
                    (clear)="reset()">
            <ng-container *ngxPermissionsOnly="'cudChoice'">
                <ng-container *ngIf="canAdd">
                    <ng-template ng-footer-tmp>
                        <p class="add-choices pointer m-0 mt-2 mb-2" (click)="createNewChoice()">
                            {{ 'CHOICES.ADD_CHOICES' | translate }} <i class="fa fa-plus text-success"></i>
                        </p>
                    </ng-template>
                </ng-container>
            </ng-container>
        </ng-select>
    </div>
</div>
<app-choice-modal-add-multiple [choiceGroup]="choiceGroup" (addEvent)="addChoices($event)" #choiceModalAll></app-choice-modal-add-multiple>