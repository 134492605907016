import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-loading-spinner',
    templateUrl: './loading-spinner.component.html'
})
export class LoadingSpinnerComponent implements OnInit {
    @Input() pAlign = "center";
    @Input() pMoreClasses = "";

    ngOnInit() {}
}
