import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MiscService } from '@services/index';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FileService } from '@services/file.service';
import { InputFileComponent } from '@utils/input-file/input-file.component';

declare var $: any;
declare var swal: any;

@Component({
    selector: 'app-util-folders',
    templateUrl: './util-folders.component.html',
    styleUrls: ['./util-folders.component.scss'],
})
export class UtilFoldersComponent implements OnInit, OnChanges {
    @Input() pictures: boolean = false;
    @Input() editable: boolean = true;
    @Input() showInput: boolean = true;
    @Input() params: any = null;
    @Input() showOnly = [];

    isAllChecked: boolean = false;
    files: any[] = null;
    imageExtensions: string[] = ['png', 'jpg', 'jpeg', 'gif'];
    pdfExtensions: string[] = ['pdf'];
    excelExtensions: string[] = ['csv', 'xls', 'xlsx'];
    formLoading: boolean = false;
    form: UntypedFormGroup = this.fb.group({
        files: [],
    });
    zipLoading: boolean = false;
    destroyLoading: boolean = false;
    filesFilter: any = {
        images: [],
        pdf: [],
        excel: [],
        others: [],
    };

    @ViewChild(InputFileComponent, { static: false }) inputFileComponent: InputFileComponent;

    constructor(private miscService: MiscService,
                private fileService: FileService,
                public fb: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.pictures = this.pictures != undefined && this.pictures !== false;
    }

    ngOnChanges(changes: SimpleChanges) {
        if(this.params) {
            this.setup(this.params);
        }
    }

    setup(params: { referencable_id: number, type?: string, referencable_subtype_upload?:string, disk: "uploads" }): void {
        this.params = params;
        this.isAllChecked = false;
        this.files = null;

        if(this.inputFileComponent) {
            this.inputFileComponent.clear();
        }

        this.form.reset();
        this.loadFiles();
    }

    loadFiles() {
        this.fileService.index(this.params).subscribe(data => {
            this.files = data;
            this.filesFilter = {
                images: [],
                pdf: [],
                excel: [],
                others: []
            };

            for(let file of this.files) {
                const parts = file.path.split('.');
                if(parts.length > 0) {
                    const extension = parts[parts.length - 1];
                    if(this.imageExtensions.includes(extension)) {
                        this.filesFilter.images.push(file);
                    }
                    else if(this.pdfExtensions.includes(extension)) {
                        this.filesFilter.pdf.push(file);
                    }
                    else if(this.excelExtensions.includes(extension)) {
                        this.filesFilter.excel.push(file);
                    }
                    else {
                        this.filesFilter.others.push(file);
                    }
                }
            }
        });
    }

    onAllCheckboxChanged() {
        for(let file of this.files) {
            file.is_selected = this.isAllChecked;
        }
    }

    onFileCheckboxChanged() {
        let isAllChecked = true;
        for(let file of this.files) {
            if(!file.is_selected) {
                isAllChecked = false;
                break;
            }
        }

        this.isAllChecked = isAllChecked;
    }

    submitForm() {
        this.formLoading = true;
        let formData = { ...this.params, ...this.form.value };
        formData = this.miscService.jsonToFormData(formData);

        this.fileService.uploadFiles(formData).subscribe(
            () => {
                this.loadFiles();
                this.miscService.showMsg({msg: "UPLOAD_SUCCESSED", status: "success"});
                this.inputFileComponent.clear();
                this.form.reset();
            },
            () => this.miscService.showMsg({msg: 'UPLOAD_FAILED'}),
        ).add(() => this.formLoading = false);
    }

    zip() {
        const ids = this.getFileIdsForChecked();
        if(ids.length > 0) {
            this.zipLoading = true;
            let data = {
                ids: ids
            };
            this.fileService.zip(data).subscribe(
                path => this.miscService.downloadFile(path, "public"),
                () => this.miscService.showMsg({msg: "EXPORT_FAILED"}),
            ).add(() => this.zipLoading = false);
        }
    }

    destroy() {
        const ids = this.getFileIdsForChecked();
        if(ids.length > 0) {
            const self = this;
            swal({
                title: this.miscService._t('UPLOAD.ARE_YOU_SURE_DELETE_FILES'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: this.miscService._t('SHARED.DELETE'),
                cancelButtonClass: 'btn btn-default btn-fill',
                cancelButtonText: this.miscService._t('SHARED.CANCEL'),
                closeOnConfirm: true,
                closeOnCancel: true,
            }, 
            function(isConfirm) {
                if(isConfirm) {
                    self.destroyLoading = true;
                    self.fileService.destroyMany({ ids }).subscribe(
                        () => {
                            self.loadFiles();
                            self.miscService.showMsg({msg: "FILES_DELETED", status: "success"});
                        },
                        () => {
                            self.miscService.showMsg({msg: "FILES_NOT_DELETED"});
                        }
                    ).add(() => self.destroyLoading = false);
                }
            });
        }
    }

    getFileIdsForChecked() {
        return this.files.filter(f => f.is_selected).map(f => f.id);
    }
}
