import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, LowerCasePipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { Ng2CompleterModule } from 'ng2-completer';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

import { RouterModule } from '@angular/router';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { NightModeService } from '@services/nightMode.service';
import { DisableControlDirective } from '../_directives/disable-control.directive';

import { NgxPermissionsModule } from 'ngx-permissions';
import { AngularMarkdownEditorModule } from 'angular-markdown-editor';
import { MarkdownModule } from 'ngx-markdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { Globals } from '@app/app.globals';
import { Title } from '@angular/platform-browser';
import { TooltipDirective } from '@app/_directives/tooltip.directive';
import { ReadableColorDirective } from '@app/_directives/readable-color.directive';
import { FormHasErrorClassDirective } from '@app/_directives/form-has-error-class.directive';
import { ColorSketchModule } from 'ngx-color/sketch';
import { DragAndDropDirective } from '@app/_directives/dragAndDrop.directive';
import { AutoFocusDirective } from '@app/_directives/auto-focus.directive';
import { DisableInteractionDirective } from '@app/_directives/disable-interaction.directive';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from '@pipes/pipe.module';
import { HttpClientModule } from '@angular/common/http';

/** Utils */
import { AddressInputComponent } from '@utils/address-input/address-input.component';
import { BtnLoadingComponent } from '@utils/btn-loading/btn-loading.component';
import { CameraModalComponent } from '@utils/camera-modal/camera-modal.component';
import { ChoiceModalsAddMultipleComponent } from '@utils/choice/modals/add/multiple/multiple.component';
import { DatePickerComponent } from '@utils/date-picker/date-picker.component';
import { FiltersUsersComponent } from '@utils/filters/users/users.component';
import { FormGroupComponent } from '@utils/form-group/form-group.component';
import { IconComponent } from '@utils/icon/icon.component';
import { IconCheckCloseComponent } from '@utils/icon/check-close/check-close.component';
import { InputFileComponent } from '@utils/input-file/input-file.component';
import { LoadingSpinnerComponent } from '@utils/loading-spinner/loading-spinner.component';
import { NgSelectAddMultipleComponent } from '@utils/ng-select-add/multiple/multiple.component';
import { NgSelectAddSingleComponent } from '@utils/ng-select-add/single/single.component';
import { NgSelectAddVisitorComponent } from '@utils/ng-select-add/visitor/visitor.component';
import { PaginationComponent } from '@utils/pagination/pagination.component';
import { TextLimitComponent } from '@utils/text-limit/text-limit.component';
import { UtilFoldersComponent } from '@utils/util-folders/util-folders.component';
import { UtilFoldersFilesComponent } from '@utils/util-folders/files/files.component';
import { UtilStatisticsExportComponent } from '@utils/util-statistics/export/export.component';
import { UtilStatisticsListComponent } from '@utils/util-statistics/list/list.component';
import { UtilStatisticsListFilterComponent } from '@utils/util-statistics/list/filter/filter.component';
import { UtilVisitorListComponent } from '@utils/util-visitor/list/list.component';
import { UtilVisitorListFilterComponent } from '@utils/util-visitor/list/filter/filter.component';
import { UtilVisitorModalsAmuComponent } from '@utils/util-visitor/modals/amu/amu.component';
import { UtilVisitorModalsSuspensionsComponent } from '@utils/util-visitor/modals/suspensions/suspensions.component';
import { UtilVisitorModalsSuspensionsAddComponent } from '@utils/util-visitor/modals/suspensions/add/add.component';
import { UtilVisitorModalsSuspensionsCancelComponent} from '@utils/util-visitor/modals/suspensions/cancel/cancel.component';
import { UtilVisitorPartsAppointmentListComponent } from '@utils/util-visitor/parts/appointment/list/list.component';
import { UtilVisitorPartsAppointmentListFilterComponent } from '@utils/util-visitor/parts/appointment/list/filter/filter.component';
import { UtilVisitorPartsAppointmentModalsAddComponent } from '@utils/util-visitor/parts/appointment/modals/add/add.component';
import { UtilVisitorPartsDailyEntryListComponent } from '@utils/util-visitor/parts/daily-entry/list/list.component';
import { UtilVisitorPartsDailyEntryListFilterComponent } from '@utils/util-visitor/parts/daily-entry/list/filter/filter.component';
import { UtilVisitorPartsDailyEntryModalsAddComponent } from '@utils/util-visitor/parts/daily-entry/modals/add/add.component';
import { UtilVisitorPartsFollowUpListComponent } from '@utils/util-visitor/parts/follow-up/list/list.component';
import { UtilVisitorPartsFollowUpListFilterComponent } from '@utils/util-visitor/parts/follow-up/list/filter/filter.component';
import { UtilVisitorPartsFollowUpModalsAddComponent } from '@utils/util-visitor/parts/follow-up/modals/add/add.component';
import { UtilVisitorPartsInfirmaryEntryListComponent } from '@utils/util-visitor/parts/infirmary-entry/list/list.component';
import { UtilVisitorPartsInfirmaryEntryListFilterComponent } from '@utils/util-visitor/parts/infirmary-entry/list/filter/filter.component';
import { UtilVisitorPartsInfirmaryEntryModalsAddComponent } from '@utils/util-visitor/parts/infirmary-entry/modals/add/add.component';
import { UtilVisitorPartsMeasureListComponent } from '@utils/util-visitor/parts/measure/list/list.component';
import { UtilVisitorPartsMeasureListFilterComponent } from '@utils/util-visitor/parts/measure/list/filter/filter.component';
import { UtilVisitorPartsMeasureModalsAddComponent } from '@utils/util-visitor/parts/measure/modals/add/add.component';
import { UtilVisitorPartsMedicalFollowUpListComponent } from '@utils/util-visitor/parts/medical/follow-up/list/list.component';
import { UtilVisitorPartsMedicalFollowUpListFilterComponent } from '@utils/util-visitor/parts/medical/follow-up/list/filter/filter.component';
import { UtilVisitorPartsMedicalFollowUpModalsAddComponent } from '@utils/util-visitor/parts/medical/follow-up/modals/add/add.component';
import { UtilVisitorPartsMedicalInfirmaryComponent } from '@utils/util-visitor/parts/medical/infirmary/infirmary.component';
import { UtilVisitorPartsMedicalInfirmaryPathologyComponent } from '@utils/util-visitor/parts/medical/infirmary/pathology/pathology.component';
import { UtilVisitorPartsMedicalInfirmaryPathologyAddComponent } from '@utils/util-visitor/parts/medical/infirmary/pathology/add/add.component';
import { UtilVisitorPartsMedicalInfirmaryModalsAddComponent } from '@utils/util-visitor/parts/medical/infirmary/modals/add/add.component';
import { UtilVisitorPartsMedicalFileComponent } from '@utils/util-visitor/parts/medical/file/file.component';
import { UtilVisitorPartsMedicalFileModalsAddComponent } from '@utils/util-visitor/parts/medical/file/modals/add/add.component';
import { UtilVisitorPartsMedicalFileModalsFilesComponent } from '@utils/util-visitor/parts/medical/file/modals/files/files.component';
import { UtilVisitorPartsMedicalStatListComponent } from '@utils/util-visitor/parts/medical/stat/list/list.component';
import { UtilVisitorPartsMedicalStatListFilterComponent } from '@utils/util-visitor/parts/medical/stat/list/filter/filter.component';
import { UtilVisitorPartsMedicalStatModalsAddComponent } from '@utils/util-visitor/parts/medical/stat/modals/add/add.component';
import { UtilVisitorPartsMedicalConsultationListComponent } from '@utils/util-visitor/parts/medical-consultation/list/list.component';
import { UtilVisitorPartsMedicalConsultationListFilterComponent } from '@utils/util-visitor/parts/medical-consultation/list/filter/filter.component';
import { UtilVisitorPartsMedicalConsultationModalsAddComponent } from '@utils/util-visitor/parts/medical-consultation/modals/add/add.component';
import { UtilVisitorPartsObservationListComponent } from '@utils/util-visitor/parts/observation/list/list.component';
import { UtilVisitorPartsObservationListFilterComponent } from '@utils/util-visitor/parts/observation/list/filter/filter.component';
import { UtilVisitorPartsObservationModalsAddComponent } from '@utils/util-visitor/parts/observation/modals/add/add.component';
import { UtilVisitorPartsPsychoEntryListComponent } from '@utils/util-visitor/parts/psycho/entry/list/list.component';
import { UtilVisitorPartsPsychoEntryListFilterComponent } from '@utils/util-visitor/parts/psycho/entry/list/filter/filter.component';
import { UtilVisitorPartsPsychoEntryModalsAddComponent } from '@utils/util-visitor/parts/psycho/entry/modals/add/add.component';
import { UtilVisitorPartsPsychoFileComponent } from '@utils/util-visitor/parts/psycho/file/file.component';
import { UtilVisitorPartsPsychoFileModalsAddComponent } from '@utils/util-visitor/parts/psycho/file/modals/add/add.component';
import { UtilVisitorPartsTreatmentComponent } from '@utils/util-visitor/parts/treatment/treatment.component';
import { UtilVisitorPartsTreatmentActiveComponent } from '@utils/util-visitor/parts/treatment/active/active.component';
import { UtilVisitorPartsTreatmentArchivedComponent } from '@utils/util-visitor/parts/treatment/archived/archived.component';
import { UtilVisitorPartsTreatmentPerOsListComponent } from '@utils/util-visitor/parts/treatment/per-os/list/list.component';
import { UtilVisitorPartsTreatmentPerOsListItemsComponent } from '@utils/util-visitor/parts/treatment/per-os/list/items/items.component';
import { UtilVisitorPartsTreatmentPerOsListFilterComponent } from '@utils/util-visitor/parts/treatment/per-os/list/filter/filter.component';
import { UtilVisitorPartsTreatmentPerOsModalsAddComponent } from '@utils/util-visitor/parts/treatment/per-os/modals/add/add.component';
import { UtilVisitorPartsTreatmentPdfComponent } from '@utils/util-visitor/parts/treatment/pdf/pdf.component';
import { UtilVisitorPartsTreatmentInjectionListComponent } from '@utils/util-visitor/parts/treatment/injection/list/list.component';
import { UtilVisitorPartsTreatmentInjectionListItemsComponent } from '@utils/util-visitor/parts/treatment/injection/list/items/items.component';
import { UtilVisitorPartsTreatmentInjectionListFilterComponent } from '@utils/util-visitor/parts/treatment/injection/list/filter/filter.component';
import { UtilVisitorPartsTreatmentInjectionModalsAddComponent } from '@utils/util-visitor/parts/treatment/injection/modals/add/add.component';
import { UtilVisitorPartsTreatmentOthersListComponent } from '@utils/util-visitor/parts/treatment/others/list/list.component';
import { UtilVisitorPartsTreatmentOthersListItemsComponent } from '@utils/util-visitor/parts/treatment/others/list/items/items.component';
import { UtilVisitorPartsTreatmentOthersListFilterComponent } from '@utils/util-visitor/parts/treatment/others/list/filter/filter.component';
import { UtilVisitorPartsTreatmentOthersModalsAddComponent } from '@utils/util-visitor/parts/treatment/others/modals/add/add.component';
import { VersionCheckerComponent } from '@utils/version-checker/version-checker.component';

@NgModule({
    imports: [
        CommonModule,
        DragDropModule,
        RouterModule,
        HttpClientModule,
        JwBootstrapSwitchNg2Module,
        GooglePlaceModule,
        Ng2CompleterModule,
        JwBootstrapSwitchNg2Module,
        Ng2CompleterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPermissionsModule,
        AngularMarkdownEditorModule,
        MarkdownModule,
        NgSelectModule,
        ColorSketchModule,
        TranslateModule,
        PipeModule
    ],
    declarations: [
        DragAndDropDirective,
        ReadableColorDirective,
        FormHasErrorClassDirective,
        TooltipDirective,
        AutoFocusDirective,
        DisableInteractionDirective,
        AddressInputComponent,
        BtnLoadingComponent,
        CameraModalComponent,
        ChoiceModalsAddMultipleComponent,
        DatePickerComponent,
        FiltersUsersComponent,
        FormGroupComponent,
        IconComponent,
        IconCheckCloseComponent,
        InputFileComponent,
        LoadingSpinnerComponent,
        NgSelectAddMultipleComponent,
        NgSelectAddSingleComponent,
        NgSelectAddVisitorComponent,
        DisableControlDirective,
        PaginationComponent,
        TextLimitComponent,
        UtilFoldersComponent,
        UtilFoldersFilesComponent,
        UtilStatisticsExportComponent,
        UtilStatisticsListComponent,
        UtilStatisticsListFilterComponent,
        UtilVisitorListComponent,
        UtilVisitorListFilterComponent,
        UtilVisitorModalsAmuComponent,
        UtilVisitorModalsSuspensionsComponent,
        UtilVisitorModalsSuspensionsAddComponent,
        UtilVisitorModalsSuspensionsCancelComponent,
        UtilVisitorPartsAppointmentListComponent,
        UtilVisitorPartsAppointmentListFilterComponent,
        UtilVisitorPartsAppointmentModalsAddComponent,
        UtilVisitorPartsDailyEntryListComponent,
        UtilVisitorPartsDailyEntryListFilterComponent,
        UtilVisitorPartsDailyEntryModalsAddComponent,
        UtilVisitorPartsFollowUpListComponent,
        UtilVisitorPartsFollowUpListFilterComponent,
        UtilVisitorPartsFollowUpModalsAddComponent,
        UtilVisitorPartsInfirmaryEntryListComponent,
        UtilVisitorPartsInfirmaryEntryListFilterComponent,
        UtilVisitorPartsInfirmaryEntryModalsAddComponent,
        UtilVisitorPartsMeasureListComponent,
        UtilVisitorPartsMeasureListFilterComponent,
        UtilVisitorPartsMeasureModalsAddComponent,
        UtilVisitorPartsMedicalFollowUpListComponent,
        UtilVisitorPartsMedicalFollowUpListFilterComponent,
        UtilVisitorPartsMedicalFollowUpModalsAddComponent,
        UtilVisitorPartsMedicalInfirmaryComponent,
        UtilVisitorPartsMedicalInfirmaryPathologyComponent,
        UtilVisitorPartsMedicalInfirmaryPathologyAddComponent,
        UtilVisitorPartsMedicalInfirmaryModalsAddComponent,
        UtilVisitorPartsMedicalFileComponent,
        UtilVisitorPartsMedicalFileModalsAddComponent,
        UtilVisitorPartsMedicalFileModalsFilesComponent,
        UtilVisitorPartsMedicalStatListComponent,
        UtilVisitorPartsMedicalStatListFilterComponent,
        UtilVisitorPartsMedicalStatModalsAddComponent,
        UtilVisitorPartsMedicalConsultationListComponent,
        UtilVisitorPartsMedicalConsultationListFilterComponent,
        UtilVisitorPartsMedicalConsultationModalsAddComponent,
        UtilVisitorPartsObservationListComponent,
        UtilVisitorPartsObservationListFilterComponent,
        UtilVisitorPartsObservationModalsAddComponent,
        UtilVisitorPartsPsychoEntryListComponent,
        UtilVisitorPartsPsychoEntryListFilterComponent,
        UtilVisitorPartsPsychoEntryModalsAddComponent,
        UtilVisitorPartsPsychoFileComponent,
        UtilVisitorPartsPsychoFileModalsAddComponent,
        UtilVisitorPartsTreatmentComponent,
        UtilVisitorPartsTreatmentActiveComponent,
        UtilVisitorPartsTreatmentArchivedComponent,
        UtilVisitorPartsTreatmentPerOsListComponent,
        UtilVisitorPartsTreatmentPerOsListItemsComponent,
        UtilVisitorPartsTreatmentPerOsListFilterComponent,
        UtilVisitorPartsTreatmentPerOsModalsAddComponent,
        UtilVisitorPartsTreatmentPdfComponent,
        UtilVisitorPartsTreatmentInjectionListComponent,
        UtilVisitorPartsTreatmentInjectionListItemsComponent,
        UtilVisitorPartsTreatmentInjectionListFilterComponent,
        UtilVisitorPartsTreatmentInjectionModalsAddComponent,
        UtilVisitorPartsTreatmentOthersListComponent,
        UtilVisitorPartsTreatmentOthersListItemsComponent,
        UtilVisitorPartsTreatmentOthersListFilterComponent,
        UtilVisitorPartsTreatmentOthersModalsAddComponent,
        VersionCheckerComponent
    ],
    providers: [
        NightModeService,
        DatePipe,
        LowerCasePipe,
        Globals,
        Title
    ],
    exports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        NgSelectModule,
        JwBootstrapSwitchNg2Module,
        GooglePlaceModule,
        Ng2CompleterModule,
        JwBootstrapSwitchNg2Module, // move this to dedicated or import on each modules
        FormsModule,
        ReactiveFormsModule,
        PipeModule,
        ReadableColorDirective,
        FormHasErrorClassDirective,
        TooltipDirective,
        NgxPermissionsModule,
        TranslateModule,
        AddressInputComponent,
        BtnLoadingComponent,
        CameraModalComponent,
        ChoiceModalsAddMultipleComponent,
        DatePickerComponent,
        FiltersUsersComponent,
        FormGroupComponent,
        IconComponent,
        IconCheckCloseComponent,
        InputFileComponent,
        LoadingSpinnerComponent,
        NgSelectAddMultipleComponent,
        NgSelectAddSingleComponent,
        NgSelectAddVisitorComponent,
        DisableControlDirective,
        DragAndDropDirective,
        AutoFocusDirective,
        DisableInteractionDirective,
        PaginationComponent,
        TextLimitComponent,
        UtilFoldersComponent,
        UtilFoldersFilesComponent,
        UtilStatisticsExportComponent,
        UtilStatisticsListComponent,
        UtilStatisticsListFilterComponent,
        UtilVisitorListComponent,
        UtilVisitorListFilterComponent,
        UtilVisitorModalsAmuComponent,
        UtilVisitorModalsSuspensionsComponent,
        UtilVisitorModalsSuspensionsAddComponent,
        UtilVisitorModalsSuspensionsCancelComponent,
        UtilVisitorPartsAppointmentListComponent,
        UtilVisitorPartsAppointmentListFilterComponent,
        UtilVisitorPartsAppointmentModalsAddComponent,
        UtilVisitorPartsDailyEntryListComponent,
        UtilVisitorPartsDailyEntryListFilterComponent,
        UtilVisitorPartsDailyEntryModalsAddComponent,
        UtilVisitorPartsFollowUpListComponent,
        UtilVisitorPartsFollowUpListFilterComponent,
        UtilVisitorPartsFollowUpModalsAddComponent,
        UtilVisitorPartsInfirmaryEntryListComponent,
        UtilVisitorPartsInfirmaryEntryListFilterComponent,
        UtilVisitorPartsInfirmaryEntryModalsAddComponent,
        UtilVisitorPartsMeasureListComponent,
        UtilVisitorPartsMeasureListFilterComponent,
        UtilVisitorPartsMeasureModalsAddComponent,
        UtilVisitorPartsMedicalFollowUpListComponent,
        UtilVisitorPartsMedicalFollowUpListFilterComponent,
        UtilVisitorPartsMedicalFollowUpModalsAddComponent,
        UtilVisitorPartsMedicalInfirmaryComponent,
        UtilVisitorPartsMedicalInfirmaryPathologyComponent,
        UtilVisitorPartsMedicalInfirmaryPathologyAddComponent,
        UtilVisitorPartsMedicalInfirmaryModalsAddComponent,
        UtilVisitorPartsMedicalFileComponent,
        UtilVisitorPartsMedicalFileModalsAddComponent,
        UtilVisitorPartsMedicalFileModalsFilesComponent,
        UtilVisitorPartsMedicalStatListComponent,
        UtilVisitorPartsMedicalStatListFilterComponent,
        UtilVisitorPartsMedicalStatModalsAddComponent,
        UtilVisitorPartsMedicalConsultationListComponent,
        UtilVisitorPartsMedicalConsultationListFilterComponent,
        UtilVisitorPartsMedicalConsultationModalsAddComponent,
        UtilVisitorPartsObservationListComponent,
        UtilVisitorPartsObservationListFilterComponent,
        UtilVisitorPartsObservationModalsAddComponent,
        UtilVisitorPartsPsychoEntryListComponent,
        UtilVisitorPartsPsychoEntryListFilterComponent,
        UtilVisitorPartsPsychoEntryModalsAddComponent,
        UtilVisitorPartsPsychoFileComponent,
        UtilVisitorPartsPsychoFileModalsAddComponent,
        UtilVisitorPartsTreatmentComponent,
        UtilVisitorPartsTreatmentActiveComponent,
        UtilVisitorPartsTreatmentArchivedComponent,
        UtilVisitorPartsTreatmentPerOsListComponent,
        UtilVisitorPartsTreatmentPerOsListItemsComponent,
        UtilVisitorPartsTreatmentPerOsListFilterComponent,
        UtilVisitorPartsTreatmentPerOsModalsAddComponent,
        UtilVisitorPartsTreatmentPdfComponent,
        UtilVisitorPartsTreatmentInjectionListComponent,
        UtilVisitorPartsTreatmentInjectionListItemsComponent,
        UtilVisitorPartsTreatmentInjectionListFilterComponent,
        UtilVisitorPartsTreatmentInjectionModalsAddComponent,
        UtilVisitorPartsTreatmentOthersListComponent,
        UtilVisitorPartsTreatmentOthersListItemsComponent,
        UtilVisitorPartsTreatmentOthersListFilterComponent,
        UtilVisitorPartsTreatmentOthersModalsAddComponent,
        VersionCheckerComponent
    ],
})
export class SharedModule {}
