import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MiscService, VisitorFollowUpService } from '@services/index';

declare var swal: any;

@Component({
    selector: 'app-util-visitor-parts-follow-up-list',
    templateUrl: './list.component.html',
})
export class UtilVisitorPartsFollowUpListComponent implements OnInit {
    @Input() visitor: any = null;
    @Output() updateEvent = new EventEmitter();

    isLoading = true;
    isLoadingVisitors = false;
    selfVisitor = false;
    filter: any = {
        date_begin: null,
        date_end: null
    };
    limit = 20;
    page = 1;
    visitorFollowUps: any;
    hasData = {
        visitorFollowUps: false
    };

    constructor(private cdref: ChangeDetectorRef,
                private miscService: MiscService,
                private visitorFollowUpService: VisitorFollowUpService) {}

    ngOnInit() {
        this.miscService.can("getVisitorFollowUp");
        this.isLoading = true;
        this.selfVisitor = this.miscService.checkDataLength(this.visitor) ? true : false;
        this.getVisitorsFollowUps();
        this.cdref.detectChanges();
    }

    filterVisitorFollowUps(filter = null) {
        this.filter = filter;
        this.getVisitorsFollowUps(true);
    }

    getVisitorsFollowUps(hasFilter = false) {
        this.isLoading = true;
        let data = this.filter;
        data.visitor_ids = this.selfVisitor ? [this.visitor.id] : (data.visitor_ids && data.visitor_ids.length ? data.visitor_ids : null);
        data.limit = this.limit;
        data.page = !hasFilter ? this.page : 1;     // if user has filtered, then reset page to 1 (number of pages can change due to filters)
        this.visitorFollowUpService.getByVisitors(data).subscribe(data => {
            this.visitorFollowUps = data;
            this.hasData.visitorFollowUps = this.miscService.checkDataLength(this.visitorFollowUps.data);
            this.isLoading = false;
        }, error => {
            this.visitorFollowUps = null;
            this.hasData.visitorFollowUps = false;
            this.miscService.showMsg({ msg: "VISITOR_FOLLOW_UPS_NOT_FOUND_PLEASE_CHECK_FILTERS" });
            this.isLoading = false;
        });
    }
    
    addFollowUp() {
        this.getVisitorsFollowUps();
        this.updateEvent.emit();
    }

    switchPage(page) {
        this.page = page;
        this.getVisitorsFollowUps();
    }

    delete(visitorFollowUp) {
        let self = this;
        let title = this.miscService._t('VISITORS_FOLLOW_UPS.DELETE_FOLLOW_UP') + " ?";
        swal({
            title: title,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.miscService._t('SHARED.CONFIRM'),
            cancelButtonText: this.miscService._t('SHARED.CANCEL'),
            closeOnConfirm: true,
            closeOnCancel: true,
        }, function(isConfirm) {
            if(isConfirm) {
                self.visitorFollowUpService.delete(visitorFollowUp.id).subscribe(data => {
                    self.getVisitorsFollowUps(true);  // resets page to 1 (in case of there is only 1 visitor follow up on the current page)
                    self.miscService.showMsg({ msg: "VISITOR_FOLLOW_UP_DELETED", status: "success" });
                    self.updateEvent.emit();
                }, error => {
                    self.miscService.showMsg({ msg: "VISITOR_FOLLOW_UP_NOT_DELETED" });
                });
            }
        });
    }
}
