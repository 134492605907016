import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MiscService } from '@services/index';

@Component({
    selector: 'app-util-visitor-parts-treatment',
    templateUrl: './treatment.component.html',
})
export class UtilVisitorPartsTreatmentComponent implements OnInit {
    @Input() visitor: any = null;
    @Output() updateEvent = new EventEmitter();

    limit = 10;

    constructor(private cdref: ChangeDetectorRef,
                private miscService: MiscService) {}

    ngOnInit() {
        this.miscService.can("getVisitorTreatment");
        this.cdref.detectChanges();
    }

    update() {
        this.updateEvent.emit();
    }
}
