import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AuthGuard } from '@app/_guards';
import { TokenInterceptor } from './_others/token.interceptor';
import { CanDeactivateGuard } from '@guards/can-deactivate-guard';

/**
 * Modules
 */
import { SharedModule } from './_others/shared.module';
import { AdminLayoutComponent } from '@layouts/admin/admin-layout.component';
import { FooterModule } from '@layouts/shared/footer/footer.module';
import { NavbarModule } from '@layouts/shared/navbar/navbar.module';
import { SidebarModule } from '@layouts/shared/sidebar/sidebar.module';
import { AppRoutingModule } from './app.routing';
import { NgxPermissionsModule } from 'ngx-permissions';


/**
 * Components
 */
import { AppComponent } from './app.component';
import { BreadcrumbsComponent } from '@layouts/shared/breadcrumbs/breadcrumbs.component';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@env/environment';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import localeNl from '@angular/common/locales/nl';
import localeDe from '@angular/common/locales/de';

import { AngularMarkdownEditorModule } from 'angular-markdown-editor';
import { MarkdownModule } from 'ngx-markdown';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MyTranslateLoader } from '@app/_others/my-translate-loader';

registerLocaleData(localeFr, "fr");
registerLocaleData(localeNl, "nl");

export function HttpLoaderFactory(http: HttpClient) {
    return new MyTranslateLoader(http);
}

@NgModule({
    declarations: [
        AdminLayoutComponent,
        AppComponent,
        BreadcrumbsComponent
      ],
    imports: [
        BrowserModule,
        SharedModule,
        NavbarModule,
        FooterModule,
        SidebarModule,
        AppRoutingModule,
        NgxPermissionsModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),

        AngularMarkdownEditorModule.forRoot({ iconlibrary: "fa" }),
        MarkdownModule.forRoot(),

        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: environment.production,
        }),
    ],
    providers: [
        AuthGuard,
        CanDeactivateGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useValue: "fr"
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {}
}
