<div class="row">
    <div class="col-md-12">
        <app-util-visitor-parts-medical-follow-up-list-filter
            [selfVisitor]="selfVisitor"
            (filterEvent)="filterVisitorMedicalFollowUps($event)"></app-util-visitor-parts-medical-follow-up-list-filter>
    </div>
</div>
<div class="row mt-5">
    <div class="col-md-12 align-items-center justify-content-space-between">
        <h5 class="title mb-3">
            <ng-container *ngIf="selfVisitor">
                {{ 'VISITORS_PSY_FOLLOW_UPS.VISITOR_PSY_FOLLOW_UPS' | translate }}
            </ng-container>
            <ng-container *ngIf="!selfVisitor">
                {{ 'VISITORS_PSY_FOLLOW_UPS.VISITORS_PSY_FOLLOW_UPS' | translate }}
            </ng-container>
        </h5>
        <button type="button"
                class="btn btn-primary btn-fill"
                (click)="utilVisitorPartsMedicalFollowUpModalsAdd.openModal(visitor?.id)"
                *ngxPermissionsOnly="'cudVisitorPsychoFollowUp'">
            <i class="fa fa-plus"></i> {{ 'VISITORS_PSY_FOLLOW_UPS.ADD_FOLLOW_UP' | translate }}
        </button>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-12">
        <ng-container *ngIf="!isLoading">
            <ng-container *ngIf="hasData?.visitorPsyFollowUps">
                <div class="overflow-auto pb-3">
                    <table class="table table-condensend table-striped">
                        <thead>
                            <tr>
                                <th class="p-3 text-center-important">#</th>
                                <th class="p-3 text-center-important" *ngxPermissionsOnly="'cudVisitorPsychoFollowUp'">
                                    {{ 'SHARED.ACTIONS' | translate }}
                                </th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_PSY_FOLLOW_UPS.DATE' | translate }}</th>
                                <ng-container *ngIf="!selfVisitor">
                                    <th class="p-3 text-center-important">{{ 'VISITORS_PSY_FOLLOW_UPS.VISITOR' | translate }}</th>
                                </ng-container>
                                <th class="p-3 text-center-important">{{ 'VISITORS_PSY_FOLLOW_UPS.INFORMATIONS' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let visitorPsyFollowUp of visitorPsyFollowUps?.data; let i = index">
                                <td class="p-3 text-center-important">{{ i + 1 }}</td>
                                <td class="p-3 text-center-important td-actions" *ngxPermissionsOnly="'cudVisitorPsychoFollowUp'">
                                    <button type="button"
                                            class="btn btn-success btn-simple p-0"
                                            title="{{ 'VISITORS_PSY_FOLLOW_UPS.EDIT_FOLLOW_UP' | translate }}"
                                            (click)="utilVisitorPartsMedicalFollowUpModalsAdd.openModal(visitor?.id, visitorPsyFollowUp)"
                                            appTooltip>
                                        <i class="fa fa-edit"></i>
                                    </button>
                                    <button type="button"
                                            class="btn btn-danger btn-simple ml-3 p-0"
                                            title="{{ 'VISITORS_PSY_FOLLOW_UPS.DELETE_FOLLOW_UP' | translate }}"
                                            (click)="delete(visitorPsyFollowUp)"
                                            appTooltip>
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </td>
                                <td class="p-3 text-center-important">{{ visitorPsyFollowUp?.date | localizedDate: true | ifEmpty: '-' }}</td>
                                <ng-container *ngIf="!selfVisitor">
                                    <td class="p-3 text-center-important">
                                        <a [routerLink]="['/visitor', visitorPsyFollowUp?.vId, 'follow-ups']"
                                            class="text-primary">
                                            {{ visitorPsyFollowUp?.showName }}
                                        </a>
                                    </td>
                                </ng-container>
                                <td class="p-3 text-center-important">
                                    <app-text-limit [text]="visitorPsyFollowUp?.informations" [limit]="100" [title]="'INFORMATIONS'"></app-text-limit>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="align-items-center justify-content-center" *ngIf="visitorPsyFollowUps?.last_page > 1">
                        <app-pagination [data]="visitorPsyFollowUps" (switchPage)="switchPage($event)"></app-pagination>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!hasData?.visitorPsyFollowUps">
                <p>
                    <ng-container *ngIf="selfVisitor">
                        {{ 'VISITORS_PSY_FOLLOW_UPS.NO_VISITOR_PSY_FOLLOW_UP_FOUND' | translate }}
                    </ng-container>
                    <ng-container *ngIf="!visitorPsyFollowUps">
                        {{ 'VISITORS_PSY_FOLLOW_UPS.NO_VISITORS_PSY_FOLLOW_UP_FOUND' | translate }}
                    </ng-container>
                </p>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="isLoading">
            <p>
                <app-loading-spinner></app-loading-spinner>
            </p>
        </ng-container>
    </div>
</div>
<app-util-visitor-parts-medical-follow-up-modals-add
    (addFollowUpEvent)="addFollowUp()"
    #utilVisitorPartsMedicalFollowUpModalsAdd></app-util-visitor-parts-medical-follow-up-modals-add>