import { Pipe, PipeTransform } from '@angular/core';
import { MiscService } from '@services/index';

@Pipe({
  name: 'durationDays'
})
export class DurationDaysPipe implements PipeTransform {
    constructor(private miscService: MiscService) {}

    transform(days: number): string {
        let years = Math.floor(days / 365) >= 1 ? Math.floor(days / 365) : 0;
        let yearsTxt = years ? (years + " " + (years > 1 ? this.miscService._t("DATES.YEARS") : this.miscService._t("DATES.YEAR"))) : "";
        days = days % 365;
        
        let months = Math.floor(days / 30);
        var monthsTxt = months ? ", " + this.miscService._t("DATES.MONTHS") : "";
        days = days % 30;

        let daysText = days ? (days + " " + (days > 1 ? this.miscService._t("DATES.DAYS") : this.miscService._t("DATES.DAY"))) : "";

        return yearsTxt + monthsTxt + daysText;
    }
}
