import { Injectable } from '@angular/core';
import { Observable, fromEvent, interval, merge } from 'rxjs';
import { repeat, takeUntil, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {
    readonly timeoutDelay = 1000 * 60 * 60; // 60 minutes
    readonly $onIdle: Observable<void>;

    constructor() {
        const events = ['keypress', 'click', 'wheel', 'mousemove', 'ontouchstart'];
        const $signal = merge(...events.map(eventName => fromEvent(document, eventName)));
        this.$onIdle = interval(this.timeoutDelay).pipe(takeUntil($signal), map(() => undefined), repeat());
    }
}