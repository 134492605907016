import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MiscService, LocalStorageService, LogoutService, UserService } from '@services/index';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    constructor(private httpClient: HttpClient,
                private router: Router,
                private miscService: MiscService,
                private localStorageService: LocalStorageService,
                private logoutService: LogoutService,
                private userService: UserService) { }

    login(identifier: string, password: string) {
        return this.httpClient.post(environment.apiUrl + '/login', { identifier, password }).pipe(
            map((response: any) => {
                // login successful if there's a jwt token in the response
                let user  = response.data;
                if(user && user.api_token) {
                    let language = user.language;
                    this.localStorageService.setLSItem('currentUser', user);
                    this.localStorageService.setLSItem('lang', language.code);
                    this.miscService.setLanguage();
                    this.miscService.setLang(language.code);
                    this.userService.saveAuth(response);
                }

                return user;
            }));
    }

    logout(close = false) {
        // remove user from local storage to log user out
        if(!close) {
            this.logoutService.logout().subscribe(data => {
                this.localStorageService.removeLSItem('currentUser');
                this.localStorageService.removeLSItem('currentUserPermissions');
                this.localStorageService.removeLSItem('params');

                this.router.navigate(['/auth/login']);
            });
        }
        else {
            this.logoutService.logoutClose();
            this.localStorageService.removeLSItem('currentUser');
            this.localStorageService.removeLSItem('currentUserPermissions');
            this.localStorageService.removeLSItem('params');

            this.router.navigate(['/auth/login']);
        }
    }

    lock() {
        let currentUser = this.localStorageService.getLSItem('currentUser');

        if (currentUser){
            if (currentUser.hasOwnProperty('api_token')){
                delete currentUser.api_token;
            }
        } else {
            this.router.navigate(['/auth/login']);
        }
        this.localStorageService.setLSItem('currentUser', currentUser);
    }
}
