<ng-container *ngIf="!isLoading">
    <ng-container *ngxPermissionsOnly="'cudVisitorMedical'">
        <button type="button"
                class="btn btn-success"
                (click)="utilVisitorPartsMedicalModalsAdd.openModal(visitor?.id, visitorMedical)"
                *ngIf="!isLoading">
            <ng-container *ngIf="!hasData?.visitorMedical">
                <i class="fa fa-plus"></i> {{ 'VISITORS_MEDICAL.CREATE_MEDICAL_DATA' | translate }}  
            </ng-container>
            <ng-container *ngIf="hasData?.visitorMedical">
                <i class="fa fa-edit"></i> {{ 'VISITORS_MEDICAL.EDIT_MEDICAL_DATA' | translate }}  
            </ng-container>
        </button>
    </ng-container>
    <ng-container *ngIf="hasData?.visitorMedical">
        <div class="row mt-3">
            <div class="col-md-12">
                <div class="card">
                    <div class="header card-header-title">
                        <h5 class="title">{{ 'VISITORS.GENERAL_INFORMATION' | translate }}</h5>
                    </div>
                    <div class="content">
                        <p>
                            <strong>{{ 'VISITORS_MEDICAL.INFIRMARY_COMMENT' | translate }} :</strong> {{ visitorMedical?.infirmary_comment | ifEmpty: '-' }}
                        </p>
                        <p>
                            <strong>{{ 'VISITORS_MEDICAL.ALCOHOL_ADDICTION' | translate }} :</strong> 
                            <app-icon-check-close [value]="visitorMedical?.alcohol_addiction" [moreClasses]="'ml-3'"></app-icon-check-close>
                            <ng-container *ngIf="visitorMedical?.alcohol_addiction && visitorMedical?.alcohol_addiction_remark">
                                <i class="fa fa-question-circle fa-tiny ml-2"
                                    [attr.title]="visitorMedical?.alcohol_addiction_remark"
                                    appTooltip></i>
                            </ng-container>
                        </p>
                        <p>
                            <strong>{{ 'VISITORS_MEDICAL.DRUGS_ADDICTION' | translate }} :</strong> 
                            <app-icon-check-close [value]="visitorMedical?.drugs_addiction" [moreClasses]="'ml-3'"></app-icon-check-close>
                            <ng-container *ngIf="visitorMedical?.drugs_addiction && visitorMedical?.drugs_addiction_remark">
                                <i class="fa fa-question-circle fa-tiny ml-2"
                                    [attr.title]="visitorMedical?.drugs_addiction_remark"
                                    appTooltip></i>
                            </ng-container>
                        </p>
                        <p>
                            <strong>{{ 'VISITORS_MEDICAL.PHARMACEUTICALS_ADDICTION' | translate }} :</strong> 
                            <app-icon-check-close [value]="visitorMedical?.pharmaceuticals_addiction" [moreClasses]="'ml-3'"></app-icon-check-close>
                            <ng-container *ngIf="visitorMedical?.pharmaceuticals_addiction && visitorMedical?.pharmaceuticals_addiction_remark">
                                <i class="fa fa-question-circle fa-tiny ml-2"
                                    [attr.title]="visitorMedical?.pharmaceuticals_addiction_remark"
                                    appTooltip></i>
                            </ng-container>
                        </p>
                    </div>
                </div>
            </div>
            <app-util-visitor-parts-medical-infirmary-pathology
                [visitor]="visitor"
                [type]="'current'"
                [title]="'VISITORS_MEDICAL.ASSOCIATED_PATHOLOGIES_COMORBIDITIES'"></app-util-visitor-parts-medical-infirmary-pathology>
            <app-util-visitor-parts-medical-infirmary-pathology
                [visitor]="visitor"
                [type]="'former'"
                [title]="'VISITORS_MEDICAL.FORMER_PATHOLOGIES_BACKGROUND'"></app-util-visitor-parts-medical-infirmary-pathology>
        </div>
    </ng-container>
    <ng-container *ngIf="!hasData?.visitorMedical">
        <p class="mt-3">{{ 'VISITORS_MEDICAL.NO_MEDICAL_DATA_FOUND' | translate }}</p>
    </ng-container>
</ng-container>
<ng-container *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
</ng-container>
<app-util-visitor-parts-medical-infirmary-modals-add (addMedicalEvent)="getVisitorMedical()" #utilVisitorPartsMedicalModalsAdd></app-util-visitor-parts-medical-infirmary-modals-add>