import { Component, OnInit } from '@angular/core';
import { PageTitleService } from "@services/index";

@Component({
    selector: 'breadcrumbs-cmp',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent implements OnInit {
    public breadcrumbsData: any;

    constructor(private pageTitleService: PageTitleService) { }    

    ngOnInit() {}

    getBreadCrumbs() {
        this.pageTitleService.breadcrumbs.subscribe(title => {
            this.breadcrumbsData = title;
        });
        return this.breadcrumbsData;
    }
}
