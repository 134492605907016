<div aria-labelledby="addPathologyModalLabel" class="modal modal-big fade" id="addPathologyModal-{{ randId }}" role="dialog" tabindex="-1" style="display: none; z-index: 9999;">
    <div class="modal-dialog width-900" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
                <h4 class="modal-title">
                    <ng-container *ngIf="cud == 'add'">
                        {{ "VISITORS_MEDICAL.ADD_PATHOLOGY" | translate }}
                    </ng-container>
                    <ng-container *ngIf="cud == 'edit'">
                        {{ "VISITORS_MEDICAL.EDIT_PATHOLOGY" | translate }}
                    </ng-container>
                </h4>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="!isLoading">
                    <form [formGroup]="addForm" #aForm="ngForm" class="form-horizontal" (submit)="submit()">
                        <fieldset>
                            <legend>{{ 'VISITORS.GENERAL_INFORMATION' | translate }}</legend>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="date">
                                        {{ "VISITORS_MEDICAL.DATE" | translate }} <span class="star">*</span>
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <app-date-picker [datepickerConfig]="pickerConfig"
                                                    [id]="'date'"
                                                    [col]="null"
                                                    [withHours]="false"
                                                    [randId]="randId"
                                                    (onDateChanged)="dateChanged($event, 'date')"
                                                    #datePicker>
                                    </app-date-picker>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="country_id">
                                        {{ "VISITORS_MEDICAL.PATHOLOGIES" | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <ng-container *ngIf="!isLoadingPathologies">
                                        <app-ng-select-add-multiple
                                                            [choiceGroup]="pathologies"
                                                            [items]="pathologies?.choices"
                                                            [id]="'pathologies_ids'"
                                                            [selectedId]="addForm?.value?.pathologies_ids"
                                                            [multiple]="true"
                                                            [closeOnSelect]="false"
                                                            [canAdd]="false"
                                                            (addChoicesEvent)="addChoices('pathologies', $event)"
                                                            (changeEvent)="changeChoice('pathologies_ids', $event)"></app-ng-select-add-multiple>
                                    </ng-container>
                                    <ng-container *ngIf="isLoadingPathologies">
                                        <app-loading-spinner></app-loading-spinner>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="remarks">
                                        {{ "VISITORS_MEDICAL.REMARKS" | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <input type="text"
                                            class="form-control input-sm"
                                            id="remarks"
                                            formControlName="remarks" />
                                </div>
                            </div>
                        </fieldset>

                        <fieldset>
                            <legend>{{ 'VISITORS.COMMENTS' | translate }}</legend>
                            <ng-container [formGroup]="addForm?.get('comments')">
                                <ng-container *ngFor="let comment of objectKeys(addForm?.get('comments')?.controls)">
                                    <ng-container [formGroupName]="comment">
                                        <div class="form-group" *ngIf="addForm?.value?.comments[comment]?.included">
                                            <div class="col-md-3">
                                                <label class="control-label control-label-sm" for="comment-{{ comment }}">
                                                    {{ getCommenText(comment) | translate }}
                                                </label>
                                            </div>
                                            <div class="col-md-9">
                                                <input type="text"
                                                        class="form-control input-sm"
                                                        id="comment-{{ comment }}"
                                                        formControlName="value" />
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </fieldset>

                        <div class="form-group">
                            <div class="col-md-12">
                                <button type="submit"
                                        class="btn"
                                        [ngClass]="{'btn-default':isSubmitting, 'btn-success': !isSubmitting}"                                                        
                                        [disabled]="!aForm.form.valid || isSubmitting">
                                    <ng-container *ngIf="cud == 'add'">
                                        <i class="fa"
                                            [ngClass]="{'fa-plus': !isSubmitting,
                                                        'fa-spinner fa-spin': isSubmitting}"></i> {{ "SHARED.ADD" | translate }}
                                    </ng-container>
                                    <ng-container *ngIf="cud == 'edit'">
                                        <i class="fa"
                                            [ngClass]="{'fa-edit': !isSubmitting,
                                                        'fa-spinner fa-spin': isSubmitting}"></i> {{ "SHARED.EDIT" | translate }}
                                    </ng-container>
                                </button>
                            </div>
                        </div>
                    </form>
                </ng-container>
                <ng-container *ngIf="isLoading">
                    <app-loading-spinner></app-loading-spinner>
                </ng-container>
            </div>
        </div>
    </div>
</div>