<div class="container-fluid">
	<ol class="breadcrumb">
		<ng-container *ngIf="getBreadCrumbs().length">
	        <li *ngFor="let bc of getBreadCrumbs(); let last = last">
	        	<a *ngIf="bc.link" href="{{bc.link}}" [ngClass]="{'active': last}">{{bc.name}}</a>
		        <span *ngIf="!bc.link">{{bc.name}}</span>
	        </li>
	    </ng-container>
    </ol>
</div>
