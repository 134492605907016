import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MiscService } from '@services/index';

@Component({
    selector: 'app-util-visitor-parts-treatment-per-os-list',
    templateUrl: './list.component.html',
})
export class UtilVisitorPartsTreatmentPerOsListComponent implements OnInit {
    @Input() visitor: any = null;
    @Input() archived = false;
    @Input() limit = 10;
    @Output() updateEvent = new EventEmitter();

    constructor(private cdref: ChangeDetectorRef,
                private miscService: MiscService) {}

    @ViewChild('utilVisitorPartsTreatmentPerOsListItems') utilVisitorPartsTreatmentPerOsListItems;

    ngOnInit() {
        this.miscService.can("getVisitorTreatment");
        this.cdref.detectChanges();
    }

    switchPage(page = 1) {
        this.utilVisitorPartsTreatmentPerOsListItems.switchPage(page);
    }

    update() {
        this.updateEvent.emit();
    }
}
