import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class PageTitleService {
    private titleSource = new BehaviorSubject<string>(environment.defaultSoftwareName);
    private breadcrumbsSource = new BehaviorSubject<any>([]);

    /**
     [
     {
		"name": "nom du bc",
		"link": "full link from root"
	  },
     {
		"name": "nom du bc",
		"link": "full link from root"
	  },
     ]
     **/
    currentTitle = this.titleSource.asObservable();
    breadcrumbs = this.breadcrumbsSource.asObservable();

    constructor(private title: Title,
                private translateService: TranslateService) {
        // this.miscService.setLanguage();
    }

    changeTitle(message: any) {
        this.titleSource.next(message)
        this.title.setTitle(message);
    }

    changeBreadcrumbs(message: any) {
        this.breadcrumbsSource.next(message)
    }

    changeTitleTradKey(key: string) {
        const message = this.translateService.instant(key);
        this.titleSource.next(message)
        this.title.setTitle(message);

        this.translateService.onLangChange.subscribe(() => {
            this.translateService.get(key).subscribe(message => {
                this.titleSource.next(message)
                this.title.setTitle(message);
            });
        });
    }
}
