import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
    providedIn: 'root'
})
export class VisitorDailyEntryService extends ApiService {
    getOrderColumns() {
        return super.get('/visitors-daily-entries/order-columns');
    }

    getById(id: number) {
        return super.get('/visitors-daily-entries/' + id);
    }

    getByVisitors(data: any) {
        return super.post('/visitors-daily-entries/visitors', data);
    }

    getDatesByVisitor(data: any) {
        return super.post('/visitors-daily-entries/visitors/dates', data);
    }

    create(data: any) {
        return super.post('/visitors-daily-entries/create', data);
    }

    update(data: any) {
        return super.put('/visitors-daily-entries/update', data);
    }

    delete(id: number) {
        return super.delete('/visitors-daily-entries/' + id);
    }
}
