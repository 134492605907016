import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MiscService, ChoiceGroupService, VisitorPsychoService } from '@services/index';

@Component({
    selector: 'app-util-visitor-parts-psycho-file-modals-add',
    templateUrl: './add.component.html',
})
export class UtilVisitorPartsPsychoFileModalsAddComponent implements OnInit {
    @Output() addPsychoEvent = new EventEmitter<any>();

    isLoading = false;
    isSubmitting = false;
    modal = "#addPsychoModal";
    cud = "add";
    hasDataChoiceGroups = true;
    choiceGroups: any;
    choiceNo: any;
    ancDosPsys: any;
    yesNos: any;
    stoppingTreatments: any;
    ddeConsultPsys: any;
    entrInformels: any;
    ambulatories: any;
    hospitables: any;
    somatics: any;
    lodgings: any;
    socioPros: any;
    addForm = this.fb.group({
        id: null,
        visitor_id: [null, Validators.required],
        old_psy_file_id: null,
        prior_addictions_hospi_id: null,
        prior_addictions_hospi_remark: null,
        prior_addictions_ambulatory_id: null,
        prior_addictions_ambulatory_remark: null,
        prior_psy_hospi_id: null,
        prior_psy_hospi_remark: null,
        prior_psy_ambulatory_id: null,
        prior_psy_ambulatory_remark: null,
        prior_psy_path_theta_id: null,
        prior_psy_path_theta_remark: null,
        prior_psy_theta_entry_id: null,
        prior_psy_theta_entry_remark: null,
        prior_psy_theta_entry_no_reason_ids: null,
        prior_psy_theta_entry_no_reason_remark: null,
        atcd_somatic_monitoring: null,
        healthcare_sm_worry_notices: null,
        healthcare_sm_prison_care: null,
        disparition_person: null,
        death_person: null,
        asker_id: null,
        vp_healthcare_sm_ambulatory_ids: null,
        vp_healthcare_sm_hospi_ids: null,
        vp_hosting_ids: null,
        vp_socio_professional_ids: null,
        vp_somatic_ambulatory_ids: null
    });
    hasData = {
        visitorPsycho: false,
        choiceGroups: {
            'anc-dos-psy': false,
            'yes-no': false,
            'stopping_treatment': false,
            'dde_consult_psy': false,
            'entr_informel': false,
            'ambulatory': false,
            'hospitable': false,
            'somatic': false,
            'lodging': false,
            'socio-pro': false
        }
    };

    constructor(private cdref: ChangeDetectorRef,
                private fb: UntypedFormBuilder,
                private miscService: MiscService,
                private choiceGroupService: ChoiceGroupService,
                private visitorPsychoService: VisitorPsychoService) {}

    ngOnInit() {
        this.cdref.detectChanges();
    }

    reset() {
        this.addForm.reset();
        this.hasData.visitorPsycho = false;
        this.cud = "add";
    }
    
    openModal(visitorId, visitorPsycho = null) {
        if(this.miscService.can("cudVisitorPsycho")) {
            this.isLoading = true;
            let keys = {
                keys: [
                    "anc-dos-psy", "yes-no", "stopping_treatment", "dde_consult_psy", "entr_informel", "ambulatory", "hospitable",
                    "somatic", "lodging", "socio-pro"
                ]
            };
            this.choiceGroupService.getMultipleByKeys(keys).subscribe(data => {
                this.choiceGroups = data;
                this.setHasDataChoiceGroups();
                this.reset();
                this.hasData.visitorPsycho = this.miscService.checkDataLength(visitorPsycho);
                this.addForm.get('visitor_id').patchValue(visitorId);
                if(this.hasData.visitorPsycho) {
                    this.cud = "edit";
                    this.addForm.patchValue(visitorPsycho);
                    if(this.miscService.checkDataLength(visitorPsycho.visitor_psycho_fk_no_reasons)) {
                        this.addNoReasons(visitorPsycho.visitor_psycho_fk_no_reasons);
                    }
                    if(this.miscService.checkDataLength(visitorPsycho.vp_healthcare_sm_ambulatories)) {
                        this.addSideData("vp_healthcare_sm_ambulatory_ids", visitorPsycho.vp_healthcare_sm_ambulatories, "ambulatory_id");
                    }
                    if(this.miscService.checkDataLength(visitorPsycho.vp_healthcare_sm_hospis)) {
                        this.addSideData("vp_healthcare_sm_hospi_ids", visitorPsycho.vp_healthcare_sm_hospis, "hospi_id");
                    }
                    if(this.miscService.checkDataLength(visitorPsycho.vp_hostings)) {
                        this.addSideData("vp_hosting_ids", visitorPsycho.vp_hostings, "hosting_id");
                    }
                    if(this.miscService.checkDataLength(visitorPsycho.vp_socio_professionals)) {
                        this.addSideData("vp_socio_professional_ids", visitorPsycho.vp_socio_professionals, "socio_professional_id");
                    }
                    if(this.miscService.checkDataLength(visitorPsycho.vp_somatic_ambulatories)) {
                        this.addSideData("vp_somatic_ambulatory_ids", visitorPsycho.vp_somatic_ambulatories, "somatic_id");
                    }
                }
                else {
                    this.setChoices();
                }
                this.isLoading = false;
                $(this.modal).modal('show');
            });
        }
    }

    addNoReasons(visitorPsychoFkNoReasons) {
        let arr = [];
        visitorPsychoFkNoReasons.forEach(vfp => {
            arr.push(vfp.no_reason_id);
        });
        this.addForm.get('prior_psy_theta_entry_no_reason_ids').patchValue(arr);
    }

    setHasDataChoiceGroups() {
        this.hasDataChoiceGroups = true;
        this.choiceGroups.forEach(cg => {
            if(cg.choices.length && this.hasData.choiceGroups[cg.key] !== undefined) {
                this.hasData.choiceGroups[cg.key] = true;
                switch(cg.key) {
                    case "anc-dos-psy":
                        this.ancDosPsys = cg.choices;
                        break;
                    case "yes-no":
                        this.yesNos = cg.choices;
                        break;
                    case "stopping_treatment":
                        this.stoppingTreatments = cg.choices;
                        break;
                    case "dde_consult_psy":
                        this.ddeConsultPsys = cg.choices;
                        break;
                    case "entr_informel":
                        this.entrInformels = cg.choices;
                        break;
                    case "ambulatory":
                        this.ambulatories = cg.choices;
                        break;
                    case "hospitable":
                        this.hospitables = cg.choices;
                        break;
                    case "somatic":
                        this.somatics = cg.choices;
                        break;
                    case "lodging":
                        this.lodgings = cg.choices;
                        break;
                    case "socio-pro":
                        this.socioPros = cg.choices;
                        break;
                }
            }
        });
        Object.keys(this.hasData.choiceGroups).forEach(key => {
            if(!this.hasData.choiceGroups[key]) {
                this.hasDataChoiceGroups = false;
            }
        });
        this.choiceNo = this.miscService.filterArray(this.yesNos, "key", "non");
    }

    setChoices() {
        this.addForm.patchValue({
            prior_addictions_hospi_id: this.choiceNo.id,
            prior_addictions_ambulatory_id: this.choiceNo.id,
            prior_psy_hospi_id: this.choiceNo.id,
            prior_psy_ambulatory_id: this.choiceNo.id,
            prior_psy_path_theta_id: this.choiceNo.id,
            prior_psy_theta_entry_id: this.choiceNo.id
        });
    }

    addSideData(field, data, idName) {
        let arr = [];
        data.forEach(d => {
            arr.push(d[idName]);
        });
        this.addForm.get(field).patchValue(arr);
    }

    getChoiceGroup(key) {
        let choiceGroup = this.miscService.filterArray(this.choiceGroups, "key", key);

        return choiceGroup ? choiceGroup : null;
    }

    addChoices(items, choices) {
        this[items] = this.miscService.addChoices(items, choices);
    }

    changeChoice(field, value) {
        let val = this.miscService.getChoice(value);
        this.addForm.get(field).patchValue(val);
    }

    submit() {
        this.isSubmitting = true;
        let data = this.addForm.value;
        data.from = "medical";
        data.prior_addictions_hospi_id = data.prior_addictions_hospi_id != null ? data.prior_addictions_hospi_id : this.choiceNo.id;
        data.prior_addictions_ambulatory_id = data.prior_addictions_ambulatory_id != null ? data.prior_addictions_ambulatory_id : this.choiceNo.id;
        data.prior_psy_hospi_id = data.prior_psy_hospi_id != null ? data.prior_psy_hospi_id : this.choiceNo.id;
        data.prior_psy_ambulatory_id = data.prior_psy_ambulatory_id != null ? data.prior_psy_ambulatory_id : this.choiceNo.id;
        data.prior_psy_path_theta_id = data.prior_psy_path_theta_id != null ? data.prior_psy_path_theta_id : this.choiceNo.id;
        data.prior_psy_theta_entry_id = data.prior_psy_theta_entry_id != null ? data.prior_psy_theta_entry_id : this.choiceNo.id;
        if(this.cud == "add") {
            this.create(data);
        }
        else {
            this.update(data);
        }
    }

    create(data) {
        this.visitorPsychoService.create(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_PSYCHO_DATA_CREATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addPsychoEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_PSYCHO_DATA_NOT_CREATED" });
        });
    }

    update(data) {
        this.visitorPsychoService.update(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_PSYCHO_DATA_UPDATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addPsychoEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_PSYCHO_DATA_NOT_UPDATED" });
        });
    }
}
