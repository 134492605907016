<div class="row">
    <div class="col-md-12">
        <app-util-visitor-parts-measure-list-filter
                                            [selfVisitor]="selfVisitor"
                                            (filterEvent)="filterVisitorMeasures($event)"></app-util-visitor-parts-measure-list-filter>
    </div>
</div>
<div class="row mt-5">
    <div class="col-md-12 align-items-center justify-content-space-between">
        <h5 class="title mb-3">
            <ng-container *ngIf="selfVisitor">
                {{ 'VISITORS_MEDICAL.VISITOR_SETTINGS' | translate }}
            </ng-container>
            <ng-container *ngIf="!selfVisitor">
                {{ 'VISITORS_MEDICAL.VISITORS_SETTINGS' | translate }}
            </ng-container>
        </h5>
        <button type="button"
                class="btn btn-primary btn-fill"
                (click)="utilVisitorPartsMeasureModalsAdd.openModal(visitor?.id)"
                *ngxPermissionsOnly="'cudVisitorMeasure'">
            <i class="fa fa-plus"></i> {{ 'VISITORS_MEDICAL.ADD_SETTING' | translate }}
        </button>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-12">
        <ng-container *ngIf="!isLoading">
            <ng-container *ngIf="hasData?.visitorMeasures">
                <div class="overflow-auto pb-3">
                    <table class="table table-condensend table-striped">
                        <thead>
                            <tr>
                                <th class="p-3 text-center-important">#</th>
                                <th class="p-3 text-center-important"
                                    *ngxPermissionsOnly="'cudVisitorMeasure'">{{ 'SHARED.ACTIONS' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL.DATE' | translate }}</th>
                                <ng-container *ngIf="!selfVisitor">
                                    <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL.VISITOR' | translate }}</th>
                                </ng-container>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL.BLOOD_PRESSURE' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL.HEARTBEATS' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL.BLOOD_SUGAR' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL.SATURATION' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL.TEMPERATURE' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_MEDICAL.WEIGHT' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let visitorMeasure of visitorMeasures?.data; let i = index">
                                <td class="p-3 text-center-important">{{ i + 1 }}</td>
                                <td class="p-3 text-center-important td-actions" *ngxPermissionsOnly="'cudVisitorMeasure'">
                                    <button type="button"
                                            class="btn btn-success btn-simple p-0"
                                            title="{{ 'VISITORS_MEDICAL.EDIT_SETTING' | translate }}"
                                            (click)="utilVisitorPartsMeasureModalsAdd.openModal(visitor?.id, visitorMeasure)"
                                            appTooltip>
                                        <i class="fa fa-edit"></i>
                                    </button>
                                    <button type="button"
                                            class="btn btn-danger btn-simple ml-3 p-0"
                                            title="{{ 'VISITORS_MEDICAL.DELETE_SETTING' | translate }}"
                                            (click)="delete(visitorMeasure)"
                                            appTooltip>
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </td>
                                <td class="p-3 text-center-important">{{ visitorMeasure?.date | localizedDate: true | ifEmpty: '-' }}</td>
                                <ng-container *ngIf="!selfVisitor">
                                    <td class="p-3 text-center-important">
                                        <a [routerLink]="['/visitor', visitorMeasure?.vId, 'infirmary', 'measures']"
                                            class="text-primary">
                                            {{ visitorMeasure?.showName }}
                                        </a>
                                    </td>
                                </ng-container>
                                <td class="p-3 text-center-important">
                                    {{ visitorMeasure?.blood_pressure | ifEmpty: '-' }}
                                </td>
                                <td class="p-3 text-center-important">
                                    {{ visitorMeasure?.heartbeat | ifEmpty: '-' }}
                                </td>
                                <td class="p-3 text-center-important">
                                    {{ visitorMeasure?.blood_sugar | ifEmpty: '-' }}
                                </td>
                                <td class="p-3 text-center-important">
                                    {{ visitorMeasure?.saturation | ifEmpty: '-' }}
                                </td>
                                <td class="p-3 text-center-important">
                                    {{ visitorMeasure?.temperature | ifEmpty: '-' }}
                                </td>
                                <td class="p-3 text-center-important">
                                    {{ visitorMeasure?.weight | ifEmpty: '-' }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="align-items-center justify-content-center" *ngIf="visitorMeasures?.last_page > 1">
                        <app-pagination [data]="visitorMeasures" (switchPage)="switchPage($event)"></app-pagination>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!hasData?.visitorMeasures">
                <p>
                    <ng-container *ngIf="selfVisitor">
                        {{ 'VISITORS_MEDICAL.NO_VISITOR_SETTING_FOUND' | translate }}
                    </ng-container>
                    <ng-container *ngIf="!selfVisitor">
                        {{ 'VISITORS_MEDICAL.NO_VISITORS_SETTING_FOUND' | translate }}
                    </ng-container>
                </p>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="isLoading">
            <p>
                <app-loading-spinner></app-loading-spinner>
            </p>
        </ng-container>
    </div>
</div>
<app-util-visitor-parts-measure-modals-add (addMeasureEvent)="addMeasure()" #utilVisitorPartsMeasureModalsAdd></app-util-visitor-parts-measure-modals-add>