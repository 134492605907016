import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
    providedIn: 'root'
})
export class LanguageService extends ApiService {
    getAll() {
        return super.get('/language/all');
    }

    getById(id: number) {
        return super.get('/language/' + id);
    }

    getByCode(code: string) {
        return super.get('/language/code/' + code);
    }

    create(data: any) {
        return super.post('/language/', data);
    }

    update(data: any) {
        return super.put('/language/', data);
    }

    delete(id: number) {
        return super.delete('/language/' + id);
    }
}
