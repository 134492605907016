import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

import { NavbarComponent } from '@layouts/shared/navbar/navbar.component';
import { LocalStorageService } from '@services/index';
import { PageTitleService } from '@services/pageTitle.service';
import { NightModeService } from '@services/nightMode.service';

import { environment } from '@env/environment';
import { Globals } from '@app/app.globals';
import { SoftwareService } from '@services/software.service';

declare var $: any;

@Component({
    selector: 'app-layout',
    templateUrl: './admin-layout.component.html'
})

export class AdminLayoutComponent implements OnInit {
    location: Location;
    public pageTitle:string;
    public mainColor = environment.defaultMainColor;
    public env = environment;
    public isEaster: boolean = false;
    public isNightMode: boolean;
    public layoutAdmin = true;

    @ViewChild('sidebar', { static: false }) sidebar;
    @ViewChild(NavbarComponent, { static: false }) navbar: NavbarComponent;
    constructor(private router: Router,
                private localStorageService: LocalStorageService,
                private pageTitleService: PageTitleService,
                private nightModeService: NightModeService,
                private softwareService: SoftwareService,
                private renderer: Renderer2,
                private globals: Globals) {}

    ngOnInit() {
        this.softwareService.currentSoftware.subscribe(software => {
            this.mainColor = software?.settingValue('main_color');
        });

		var url = this.router.url;
        let user = this.localStorageService.getLSItem('currentUser');
        if(!user || !user.roles) {
            this.layoutAdmin = false;
            this.router.navigate(['/login']);
        }
        else if(url == "/") {
            this.router.navigate(['/dashboard']);
        }

        this.pageTitleService.currentTitle.subscribe(title => this.pageTitle = title); // get page's title

        if(this.isNightMode === undefined) {
            let mode = this.localStorageService.getLSItem('isNightMode') === "true";
            this.nightModeService.changeMode(mode);
        }

        this.nightModeService.currentMode.subscribe(data => {
            this.isNightMode = data;
            if (this.isNightMode){
                this.renderer.addClass(document.body, 'dark-mode');
            } else {
                this.renderer.removeClass(document.body, 'dark-mode');
            }
        })

    }
}
