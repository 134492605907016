import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms';
import { MiscService, ChoiceService, ChoiceGroupService } from '@services/index';
import { Globals } from '@app/app.globals';

@Component({
    selector: "app-choice-modal-add-multiple",
    templateUrl: "./multiple.component.html",
})
export class ChoiceModalsAddMultipleComponent implements OnInit {
    @Input() choiceGroup: any;
    @Output() addEvent = new EventEmitter<any>();

    isLoading = true;
    isSubmitting = false;
    randId = Math.floor(Math.random() * 10000);
    modal = "#addChoiceModal-" + this.randId;
    continents: any;
    choiceGroupsValues = this.globals.choiceGroupsValues;
    dataValues: any;
    addForm = this.fb.group({
        choice_group_id: [null, Validators.required],
        choices: this.fb.array([])
    });
    hasData = {
        choiceGroup: false
    };

    constructor(private fb: UntypedFormBuilder,
                private miscService: MiscService,
                private choiceService: ChoiceService,
                private choiceGroupService: ChoiceGroupService,
                private globals: Globals) {}

    ngOnInit() {
        this.hasData.choiceGroup = this.miscService.checkDataLength(this.choiceGroup);
    }

    reset() {
        this.addForm.reset();
        const control = <UntypedFormArray> this.addForm.get('choices');
        while(control.length) {
            control.removeAt(0);
        }
        this.dataValues = undefined;
    }

    openModal() {
        if(this.miscService.checkEnvPermission("cudChoice")) {
            this.isLoading = true;
            this.reset();
            if(this.hasData.choiceGroup) {
                this.addForm.get('choice_group_id').patchValue(this.choiceGroup.id);
                this.addChoice();
                if(this.choiceGroup.key == "countries") {
                    this.choiceGroupService.getByKey("continent").subscribe(data => {
                        this.continents = data.choices;
                        $(this.modal).modal('show');
                    });
                }
                else {
                    $(this.modal).modal('show');
                }
            }
            else {
                this.miscService.showMsg({ msg: "CHOICE_GROUP_NOT_FOUND" });
            }
            this.isLoading = false;
        }
    }

    setDataType() {
        let dataType = null;
        if(this.choiceGroupsValues[this.choiceGroup.key] !== undefined) {
            if(Array.isArray(this.choiceGroupsValues[this.choiceGroup.key])) {
                dataType = "select";
                this.dataValues = this.choiceGroupsValues[this.choiceGroup.key];
            }
            else {
                dataType = "text";
            }
        }

        return dataType;
    }

    addChoice() {
        let dataType = this.setDataType();
        const control = <UntypedFormArray> this.addForm.get('choices');
        if(dataType) {
            control.push(this.fb.group({
                name: [null, Validators.required],
                data: [null, Validators.required],
                choice_id: null,
                data_type: dataType
            }));
        }
        else {
            control.push(this.fb.group({
                name: [null, Validators.required],
                data: null,
                choice_id: null,
                data_type: null
            }));
        }
    }

    deleteChoice(i) {
        (<UntypedFormArray> this.addForm.get('choices')).removeAt(i);
    }

    submit() {
        this.isSubmitting = true;
        let data = this.addForm.value;
        this.choiceService.createMultiple(data).subscribe(data => {
            this.miscService.showMsg({ msg: "CHOICES_CREATED", status: "success" });
            this.isSubmitting = false;
            $(this.modal).modal('hide');
            this.addEvent.emit(data);
        }, error => {
            this.miscService.showMsg({ msg: "CHOICES_NOT_CREATED" });
            this.isSubmitting = false;
        });
    }
}
