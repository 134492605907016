import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { MiscService, VisitorMedicalService } from '@services/index';

@Component({
    selector: 'app-util-visitor-parts-medical-infirmary',
    templateUrl: './infirmary.component.html',
})
export class UtilVisitorPartsMedicalInfirmaryComponent implements OnInit {
    @Input() visitor: any;

    isLoading = false;
    visitorMedical: any;
    hasData = {
        visitorMedical: false
    };

    constructor(private cdref: ChangeDetectorRef,
                private miscService: MiscService,
                private visitorMedicalService: VisitorMedicalService) {
        this.miscService.setPageTitle("TITLES.VISITOR_INFIRMARY_MEDICAL_DATA");
    }

    ngOnInit() {
        this.miscService.can("getVisitorMedical");
        this.getVisitorMedical();
        this.cdref.detectChanges();
    }

    getVisitorMedical() {
        this.isLoading = true;
        this.visitorMedicalService.getByVisitor(this.visitor.id).subscribe(data => {
            this.visitorMedical = data;
            this.hasData.visitorMedical = this.miscService.checkDataLength(this.visitorMedical);
            this.isLoading = false;
        });
    }
}
