<div class="row">
    <div class="col-md-12 align-items-center justify-content-right">
        <span class="text-info pointer" (click)="toggleShowInputBlock()">
            <ng-container *ngIf="showInput">
                <i class="fa fa-angle-double-down"></i> {{ 'UPLOAD.HIDE_FORM' | translate }}
            </ng-container>
            <ng-container *ngIf="!showInput">
                <i class="fa fa-angle-double-up"></i> {{ 'UPLOAD.SHOW_FORM' | translate }}
            </ng-container>
        </span>
    </div>
</div>
<ng-container *ngIf="showInput">
    <div class="input-file-container" appDragAndDrop (fileDropped)="fileChanged($event)">
        <div class="row align-items-center">
            <div class="col-md-{{ pictures ? '6' : '12' }}">
                <input type="file" #inputFileInput id="inputFileInput" [accept]="accept ? accept.join(',') : '*'" [multiple]="multiple" (change)="fileChanged($event.target.files)">
                <app-icon [width]="75" name="upload_animated"></app-icon>
                <h4>
                    <ng-container *ngIf="!multiple">
                        {{ 'UPLOAD.DRAG_AND_DROP_FILE' | translate }}
                    </ng-container>
                    <ng-container *ngIf="multiple">
                        {{ 'UPLOAD.DRAG_AND_DROP_FILES' | translate }}
                    </ng-container>
                </h4>
                <h4>{{ 'UPLOAD.OR' | translate | lowercase }} </h4>
                <label for="inputFileInput" class="btn btn-fill btn-primary text-white">
                    <i class="fa fa-plus-square-o"></i> 
                    <ng-container *ngIf="!multiple">
                        {{ 'UPLOAD.SELECT_FILE' | translate }}
                    </ng-container>
                    <ng-container *ngIf="multiple">
                        {{ 'UPLOAD.SELECT_FILES' | translate }}
                    </ng-container>
                </label>

                <div class="mt-4" *ngIf="accept">
                    <small>
                        {{ 'UPLOAD.ACCEPTED_FORMATS' | translate }} : <i class="text-muted">{{ accept.join(', ') }}</i>
                    </small>
                </div>
            </div>
            <div class="col-md-6" *ngIf="pictures">
                <app-icon [width]="75" name="picture_animated"></app-icon>
                <h4>{{ 'UPLOAD.TAKE_A_PHOTO' | translate }}</h4>
                <button type="button" class="btn btn-fill btn-primary" (click)="cameraModalComponent.open()">
                    <i class="fa fa-camera"></i> {{ 'UPLOAD.OPEN_CAMERA' | translate }}
                </button>
                <app-camera-modal (onSave)="fileChanged([$event])" toFile #cameraModalComponent></app-camera-modal>
            </div>
        </div>
    </div>

    <table class="table my-3" *ngIf="files.length">
        <thead>
            <tr>
                <th>{{ 'SHARED.ACTIONS' | translate }}</th>
                <th>{{ 'UPLOAD.TYPE' | translate }}</th>
                <th width="50%">{{ 'UPLOAD.NAME' | translate }}</th>
                <th>{{ 'UPLOAD.SIZE' | translate }}</th>
            </tr>
            </thead>
        <tbody>
            <tr *ngFor="let file of files; let index = index">
                <td class="td-actions">
                    <button type="button"
                            class="btn btn-xs btn-fill btn-danger"
                            title="{{ 'SHARED.DELETE' | translate }}"
                            (click)="delete(index)"
                            appTooltip>
                        <i class="fa fa-trash-o"></i>
                    </button>
                </td>
                <td width="50%">
                    <app-icon [name]="getIcon(file?.name)" [width]="40"></app-icon>
                </td>
                <td>
                    <input class="form-control" type="text" [(ngModel)]="file.new_name" *ngIf="editable">
                    <span *ngIf="!editable" style="word-wrap: anywhere;">{{ file.name }}</span>
                </td>
                <td>{{ formatBytes(file?.size) }}</td>
            </tr>
        </tbody>
    </table>
</ng-container>