import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MiscService, VisitorInfirmaryEntryService } from '@services/index';

declare var swal: any;

@Component({
    selector: 'app-util-visitor-parts-infirmary-entry-list',
    templateUrl: './list.component.html',
})
export class UtilVisitorPartsInfirmaryEntryListComponent implements OnInit {
    @Input() visitor: any = null;
    @Input() manageEntries = true;      // Not a permission, used to show or not buttons
    @Output() updateEvent = new EventEmitter();

    isLoading = true;
    isLoadingVisitors = false;
    selfVisitor = false;
    filter: any = {
        date_begin: null,
        date_end: null
    };
    limit = 20;
    page = 1;
    visitorInfirmaryEntries: any;
    hasData = {
        visitorInfirmaryEntries: false
    };

    constructor(private cdref: ChangeDetectorRef,
                private miscService: MiscService,
                private visitorInfirmaryEntryService: VisitorInfirmaryEntryService) {}

    ngOnInit() {
        this.miscService.can("getVisitorInfirmaryEntry");
        this.isLoading = true;
        this.selfVisitor = this.miscService.checkDataLength(this.visitor) ? true : false;
        this.getVisitorInfirmaryEntries();
        this.cdref.detectChanges();
    }

    filterVisitorInfirmaryEntries(filter = null) {
        this.filter = filter;
        this.getVisitorInfirmaryEntries(true);
    }

    getVisitorInfirmaryEntries(hasFilter = false) {
        this.isLoading = true;
        let data = this.filter;
        data.visitor_ids = this.selfVisitor ? [this.visitor.id] : (data.visitor_ids && data.visitor_ids.length ? data.visitor_ids : null);
        data.limit = this.limit;
        data.page = !hasFilter ? this.page : 1;     // if user has filtered, then reset page to 1 (number of pages can change due to filters)
        this.visitorInfirmaryEntryService.getByVisitors(data).subscribe(data => {
            this.visitorInfirmaryEntries = data;
            this.hasData.visitorInfirmaryEntries = this.miscService.checkDataLength(this.visitorInfirmaryEntries.data);
            this.isLoading = false;
        }, error => {
            this.visitorInfirmaryEntries = null;
            this.hasData.visitorInfirmaryEntries = false;
            this.miscService.showMsg({ msg: "VISITOR_INFIRMARY_ACTS_NOT_FOUND_PLEASE_CHECK_FILTERS" });
            this.isLoading = false;
        });
    }
    
    addInfirmaryEntry() {
        this.getVisitorInfirmaryEntries();
        this.updateEvent.emit();
    }

    switchPage(page) {
        this.page = page;
        this.getVisitorInfirmaryEntries();
    }

    delete(visitorInfirmaryEntry) {
        let self = this;
        let title = this.miscService._t('VISITORS_MEDICAL.DELETE_ACT') + " ?";
        swal({
            title: title,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.miscService._t('SHARED.CONFIRM'),
            cancelButtonText: this.miscService._t('SHARED.CANCEL'),
            closeOnConfirm: true,
            closeOnCancel: true,
        }, function(isConfirm) {
            if(isConfirm) {
                self.visitorInfirmaryEntryService.delete(visitorInfirmaryEntry.id).subscribe(data => {
                    self.getVisitorInfirmaryEntries(true);  // resets page to 1 (in case of there is only 1 visitor infirmary entry on the current page)
                    self.miscService.showMsg({ msg: "VISITOR_INFIRMARY_ACT_DELETED", status: "success" });
                    self.updateEvent.emit();
                }, error => {
                    self.miscService.showMsg({ msg: "VISITOR_INFIRMARY_ACT_NOT_DELETED" });
                });
            }
        });
    }
}
