
<h5 class="title mb-3">
    {{ 'VISITORS_PSYCHO.SEARCH_VISITOR_PSYCHOLOGICAL_ENTRIES' | translate }}
    <i class="fa pointer text-primary" 
        [ngClass]="{'fa-angle-double-down': !showFilters,
                    'fa-angle-double-up': showFilters}"
        (click)="toggleShowFilters()"></i>
</h5>
<form [formGroup]="searchForm" (ngSubmit)="submit()" #sForm="ngForm" autocomplete="off" [hidden]="!showFilters">
    <div class="row mb-3" *ngIf="!selfVisitor">
        <div class="form-group">
            <div class="col-md-3">
                <label class="control-label control-label-sm" for="visitor_ids" >
                    {{ 'VISITORS_PSYCHO.VISITOR' | translate }}
                </label>
            </div>
            <div class="col-md-9">
                <app-ng-select-add-visitor (changeEvent)="visitorChanged($event)"></app-ng-select-add-visitor>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="form-group">
            <div class="col-md-3">
                <label class="control-label control-label-sm" for="date_begin">
                    {{ 'FILTER.DATE_BEGIN' | translate }}
                </label>
            </div>
            <div class="col-md-9">
                <app-date-picker [datepickerConfig]="pickerConfig"
                                [col]="null"
                                [hasResetButton]="true"
                                (onDateChanged)="dateChanged($event, 'date_begin')"
                                #datePickerBegin>
                </app-date-picker>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="form-group">
            <div class="col-md-3">
                <label class="control-label control-label-sm" for="date_end">
                    {{ 'FILTER.DATE_END' | translate }}
                </label>
            </div>
            <div class="col-md-9">
                <app-date-picker [datepickerConfig]="pickerConfig"
                                [col]="null"
                                [hasResetButton]="true"
                                (onDateChanged)="dateChanged($event, 'date_end')"
                                #datePickerEnd>
                </app-date-picker>
            </div>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-md-12">
            <button type="submit"
                    class="btn btn-success btn-fill">
                <i class="fa fa-search"></i> {{ 'SHARED.SEARCH' | translate }}
            </button>
            <button type="button"
                    class="btn btn-danger btn-fill ml-3"
                    (click)="reset()"
                    *ngIf="filledForm">
                <i class="fa fa-close"></i> {{ 'SHARED.RESET' | translate }}
            </button>
        </div>
    </div>
</form>