<div class="tab-content">
    <ul class="nav nav-tabs">
        <li class="active" (click)="utilVisitorPartsTreatmentActive.switchPage(1)">
            <a href="#active" data-toggle="tab"><i class="fa fa-hourglass-start"></i> {{ 'SHARED.IN_PROGRESS' | translate }}</a>
        </li>
        <li (click)="utilVisitorPartsTreatmentArchived.switchPage(1)">
            <a href="#archived" data-toggle="tab"><i class="fa fa-history"></i> {{ 'SHARED.HISTORY' | translate }}</a>
        </li>
    </ul>

    <div class="tab-pane active" id="active">
        <app-util-visitor-parts-treatment-active
                                    [visitor]="visitor"
                                    [limit]="limit"
                                    (updateEvent)="update()"
                                    #utilVisitorPartsTreatmentActive></app-util-visitor-parts-treatment-active>
    </div>
    <div class="tab-pane" id="archived">
        <app-util-visitor-parts-treatment-archived
                                    [visitor]="visitor"
                                    [limit]="limit"
                                    (updateEvent)="update()"
                                    #utilVisitorPartsTreatmentArchived></app-util-visitor-parts-treatment-archived>
    </div>
</div>