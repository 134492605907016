import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MiscService, VisitorTreatmentService } from '@services/index';

declare var swal: any;

@Component({
    selector: 'app-util-visitor-parts-treatment-per-os-list-items',
    templateUrl: './items.component.html',
})
export class UtilVisitorPartsTreatmentPerOsListItemsComponent implements OnInit {
    @Input() visitor: any = null;
    @Input() archived = false;
    @Input() limit = 10;
    @Output() updateEvent = new EventEmitter();

    isLoading = true;
    isLoadingVisitors = false;
    selfVisitor = false;
    filter: any = {
        date_begin: null,
        date_end: null
    };
    page = 1;
    visitorTreatments: any;
    hasData = {
        visitorTreatments: false
    };

    constructor(private cdref: ChangeDetectorRef,
                private miscService: MiscService,
                private visitorTreatmentService: VisitorTreatmentService) {}

    ngOnInit() {
        this.miscService.can("getVisitorTreatment");
        this.isLoading = true;
        this.selfVisitor = this.miscService.checkDataLength(this.visitor) ? true : false;
        this.getVisitorsTreatments();
        this.cdref.detectChanges();
    }

    filterVisitorTreatments(filter = null) {
        this.filter = filter;
        this.getVisitorsTreatments(true);
    }

    getVisitorsTreatments(hasFilter = false) {
        this.isLoading = true;
        let data = this.filter;
        data.visitor_ids = this.selfVisitor ? [this.visitor.id] : (data.visitor_ids && data.visitor_ids.length ? data.visitor_ids : null);
        data.self_visitor = this.selfVisitor;
        data.archived = this.archived;
        data.limit = this.limit;
        data.page = !hasFilter ? this.page : 1;     // if user has filtered, then reset page to 1 (number of pages can change due to filters)
        this.visitorTreatmentService.getByVisitors(data).subscribe(data => {
            this.visitorTreatments = data;
            this.hasData.visitorTreatments = this.miscService.checkDataLength(this.visitorTreatments.data);
            this.isLoading = false;
        });
    }
    
    addTreatment() {
        this.getVisitorsTreatments();
        this.updateEvent.emit();
    }

    switchPage(page) {
        this.page = page;
        this.getVisitorsTreatments();
    }

    delete(visitorTreatment) {
        let self = this;
        let title = this.miscService._t('VISITORS_MEDICAL.DELETE_TREATMENT') + " ?";
        swal({
            title: title,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.miscService._t('SHARED.CONFIRM'),
            cancelButtonText: this.miscService._t('SHARED.CANCEL'),
            closeOnConfirm: true,
            closeOnCancel: true,
        }, function(isConfirm) {
            if(isConfirm) {
                self.visitorTreatmentService.delete(visitorTreatment.id).subscribe(data => {
                    self.getVisitorsTreatments(true);  // resets page to 1 (in case of there is only 1 visitor treatment on the current page)
                    self.miscService.showMsg({ msg: "VISITOR_TREATMENT_DELETED", status: "success" });
                    self.updateEvent.emit();
                }, error => {
                    self.miscService.showMsg({ msg: "VISITOR_TREATMENT_NOT_DELETED" });
                });
            }
        });
    }
}
