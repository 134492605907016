<div class="row mt-3">
    <div class="col-md-12">
        <app-util-statistics-list-filter [type]="type"
                                        (filterEvent)="filterStatistics($event)"
                                        (changedDateEvent)="changedDate($event)"></app-util-statistics-list-filter>
    </div>
</div>
<div class="row mt-5">
    <div class="col-md-12">
        <h5 class="title mb-3">
            <ng-container *ngIf="type == 'year'">
                {{ 'STATISTICS.BY_YEAR' | translate }} : {{ filter?.date }}
            </ng-container>
            <ng-container *ngIf="type == 'month'">
                {{ 'STATISTICS.BY_MONTH' | translate }} : {{ filter?.date }}
            </ng-container>
            <ng-container *ngIf="type == 'day'">
                {{ 'STATISTICS.BY_DAY' | translate }} : {{ filter?.date }}
            </ng-container>
        </h5>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-12">
        <ng-container *ngIf="!isLoading">
            <p>
                <i class="fa fa-exclamation-triangle text-warning"></i> {{ 'STATISTICS.NUMBERS_CAN_BE_TRICKY' | translate }}
            </p>
            <table class="table table-condensend table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{{ 'STATISTICS.ITEM' | translate }}</th>
                        <th>{{ 'STATISTICS.NUMBER' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style="background-color: #ddd;">
                        <td>1</td>
                        <td>{{ 'STATISTICS.DIFFERENT_VISITORS' | translate }}</td>
                        <td>{{ statistics?.different_visitors }}</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>{{ 'STATISTICS.WOMEN' | translate }}</td>
                        <td>{{ statistics?.dv_women }}</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>{{ 'STATISTICS.MEN' | translate }}</td>
                        <td>{{ statistics?.dv_men }}</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>{{ 'STATISTICS.OTHER_SEXUAL_IDENTITY' | translate }}</td>
                        <td>{{ statistics?.dv_other_sexual_identity }}</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>{{ 'STATISTICS.MIDDLE_AGE' | translate }}</td>
                        <td>{{ statistics?.dv_middle_age }}</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>{{ 'STATISTICS.SAME_VISITORS_PSYCHO_PSYCHIATRIST' | translate }}</td>
                        <td>{{ statistics?.dv_medical_psycho }}</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>{{ 'STATISTICS.SAME_VISITORS_PSYCHO_PSYCHIATRIST_NURSE' | translate }}</td>
                        <td>{{ statistics?.dv_medical_psycho_infirmary }}</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>{{ 'STATISTICS.FIRST_DETECTION' | translate }}</td>
                        <td>{{ statistics?.dv_first_detection }}</td>
                    </tr>
                    <ng-container *ngIf="lengths?.dvAgeRanges">
                        <ng-container *ngFor="let key of objectKeys(statistics?.dv_age_ranges); let i = index">
                            <tr>
                                <td>{{ (nbrBasicLines + i + 1) }}</td>
                                <td>{{ statistics?.dv_age_ranges[key]?.name }}</td>
                                <td>{{ statistics?.dv_age_ranges[key]?.count }}</td>
                            </tr>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="lengths?.dvIncomes">
                        <ng-container *ngFor="let key of objectKeys(statistics?.dv_incomes); let i = index">
                            <tr>
                                <td>{{ (nbrBasicLines + i + 1 + lengths?.dvAgeRanges) }}</td>
                                <td>{{ statistics?.dv_incomes[key]?.name }}</td>
                                <td>{{ statistics?.dv_incomes[key]?.count }}</td>
                            </tr>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="lengths?.dvContinents">
                        <ng-container *ngFor="let key of objectKeys(statistics?.dv_continents); let i = index">
                            <tr>
                                <td>{{ (nbrBasicLines + i + 1 + lengths?.dvAgeRanges + lengths?.dvIncomes) }}</td>
                                <td>{{ statistics?.dv_continents[key]?.name }}</td>
                                <td>{{ statistics?.dv_continents[key]?.count }}</td>
                            </tr>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="lengths?.dvMaritalStatus">
                        <ng-container *ngFor="let key of objectKeys(statistics?.dv_marital_status); let i = index">
                            <tr>
                                <td>{{ (nbrBasicLines + i + 1 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents) }}</td>
                                <td>{{ statistics?.dv_marital_status[key]?.name }}</td>
                                <td>{{ statistics?.dv_marital_status[key]?.count }}</td>
                            </tr>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="lengths?.dvOrientedBy">
                        <ng-container *ngFor="let key of objectKeys(statistics?.dv_oriented_by); let i = index">
                            <tr>
                                <td>{{ (nbrBasicLines + i + 1 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus) }}</td>
                                <td>{{ 'STATISTICS.ORIENTED_BY' | translate }} {{ statistics?.dv_oriented_by[key]?.name }}</td>
                                <td>{{ statistics?.dv_oriented_by[key]?.count }}</td>
                            </tr>
                        </ng-container>
                    </ng-container>

                    <tr style="background-color: #ddd;">
                        <td>{{ (nbrBasicLines + 1 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy) }}</td>
                        <td>{{ 'STATISTICS.DAILIES_ENTRIES' | translate }}</td>
                        <td>{{ statistics?.daily_entries }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 2 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy) }}</td>
                        <td>{{ 'STATISTICS.VISITS' | translate }}</td>
                        <td>{{ statistics?.de_visits }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 3 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy) }}</td>
                        <td>{{ 'STATISTICS.VISITS_WOMEN' | translate }}</td>
                        <td>{{ statistics?.de_women }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 4 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy) }}</td>
                        <td>{{ 'STATISTICS.VISITS_MEN' | translate }}</td>
                        <td>{{ statistics?.de_men }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 5 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy) }}</td>
                        <td>{{ 'STATISTICS.VISITS_OTHER_SEXUAL_IDENTITY' | translate }}</td>
                        <td>{{ statistics?.de_other_sexual_identity }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 6 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy) }}</td>
                        <td>{{ 'STATISTICS.OPENING_DAYS' | translate }}</td>
                        <td>{{ statistics?.de_opening_days }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 7 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy) }}</td>
                        <td>{{ 'STATISTICS.SHOWERS' | translate }}</td>
                        <td>{{ statistics?.de_showers }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 8 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy) }}</td>
                        <td>{{ 'STATISTICS.LAUNDRIES' | translate }}</td>
                        <td>{{ statistics?.de_laundries }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 9 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy) }}</td>
                        <td>{{ 'STATISTICS.MEDICAL_PEDICURES' | translate }}</td>
                        <td>{{ statistics?.de_pedicures }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 10 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy) }}</td>
                        <td>{{ 'STATISTICS.INSTRUCTIONS_IN' | translate }}</td>
                        <td>{{ statistics?.de_instructions_in }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 11 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy) }}</td>
                        <td>{{ 'STATISTICS.INSTRUCTIONS_OUT' | translate }}</td>
                        <td>{{ statistics?.de_instructions_out }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 12 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy) }}</td>
                        <td>{{ 'STATISTICS.CHANGING_ROOMS' | translate }}</td>
                        <td>{{ statistics?.de_changing_rooms }}</td>
                    </tr>
                    <tr style="background-color: #ddd;">
                        <td>{{ (nbrBasicLines + 13 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy) }}</td>
                        <td>{{ 'STATISTICS.INFIRMARY_ACTS' | translate }}</td>
                        <td>{{ statistics?.infirmary_entries }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 14 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy) }}</td>
                        <td>{{ 'STATISTICS.SHOWERS' | translate }}</td>
                        <td>{{ statistics?.ie_showers }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 15 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy) }}</td>
                        <td>{{ 'STATISTICS.FOOTCARES' | translate }}</td>
                        <td>{{ statistics?.ie_footcares }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 16 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy) }}</td>
                        <td>{{ 'STATISTICS.DRUGSTORES' | translate }}</td>
                        <td>{{ statistics?.ie_drugstores }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 17 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy) }}</td>
                        <td>{{ 'STATISTICS.PSYCHOLOGICAL_TREATMENTS' | translate }}</td>
                        <td>{{ statistics?.ie_psychological_treatments }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 18 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy) }}</td>
                        <td>{{ 'STATISTICS.SOMATIC_TREATMENTS' | translate }}</td>
                        <td>{{ statistics?.ie_somatic_treatments }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 19 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy) }}</td>
                        <td>{{ 'STATISTICS.ORIENTATIONS' | translate }}</td>
                        <td>{{ statistics?.ie_orientations }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 20 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy) }}</td>
                        <td>{{ 'STATISTICS.SUPPORTS' | translate }}</td>
                        <td>{{ statistics?.ie_supports }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 21 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy) }}</td>
                        <td>{{ 'STATISTICS.PROCEDURES' | translate }}</td>
                        <td>{{ statistics?.ie_procedures }}</td>
                    </tr>
                    <ng-container *ngIf="lengths?.ieParasites">
                        <ng-container *ngFor="let key of objectKeys(statistics?.ie_parasites); let i = index">
                            <tr>
                                <td>{{ (nbrBasicLines + i + 1 + 20 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy) }}</td>
                                <td>{{ statistics?.ie_parasites[key]?.name }}</td>
                                <td>{{ statistics?.ie_parasites[key]?.count }}</td>
                            </tr>
                        </ng-container>
                    </ng-container>
                    <tr style="background-color: #ddd;">
                        <td>{{ (nbrBasicLines + 22 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy + lengths?.ieParasites) }}</td>
                        <td>{{ 'STATISTICS.MEDICAL_DATA' | translate }}</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 23 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy + lengths?.ieParasites) }}</td>
                        <td>{{ 'STATISTICS.DIFFERENT_PERSONS' | translate }}</td>
                        <td>{{ statistics?.medicals }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 24 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy + lengths?.ieParasites) }}</td>
                        <td>{{ 'STATISTICS.WOMEN' | translate }}</td>
                        <td>{{ statistics?.m_women }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 25 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy + lengths?.ieParasites) }}</td>
                        <td>{{ 'STATISTICS.MEN' | translate }}</td>
                        <td>{{ statistics?.m_men }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 26 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy + lengths?.ieParasites) }}</td>
                        <td>{{ 'STATISTICS.CONSULTATIONS' | translate }}</td>
                        <td>{{ statistics?.m_psy_consultation }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 27 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy + lengths?.ieParasites) }}</td>
                        <td>{{ 'STATISTICS.CONCERTATIONS' | translate }}</td>
                        <td>{{ statistics?.m_psy_concertations }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 28 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy + lengths?.ieParasites) }}</td>
                        <td>{{ 'STATISTICS.ADM_CERTIFS' | translate }}</td>
                        <td>{{ statistics?.m_psy_adm_certifs }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 29 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy + lengths?.ieParasites) }}</td>
                        <td>{{ 'STATISTICS.NETWORKS' | translate }}</td>
                        <td>{{ statistics?.m_psy_networks }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 30 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy + lengths?.ieParasites) }}</td>
                        <td>{{ 'STATISTICS.FOLLOWED_UP_FILES' | translate }}</td>
                        <td>{{ statistics?.m_psy_followed_up_files }}</td>
                    </tr>
                    <tr style="background-color: #ddd;">
                        <td>{{ (nbrBasicLines + 31 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy + lengths?.ieParasites) }}</td>
                        <td>{{ 'STATISTICS.PSY_DATA' | translate }}</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 32 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy + lengths?.ieParasites) }}</td>
                        <td>{{ 'STATISTICS.FORMAL_ENTRIES' | translate }}</td>
                        <td>{{ statistics?.vpe_formal }}</td>
                    </tr>
                    <tr>
                        <td>{{ (nbrBasicLines + 33 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy + lengths?.ieParasites) }}</td>
                        <td>{{ 'STATISTICS.INFORMAL_ENTRIES' | translate }}</td>
                        <td>{{ statistics?.vpe_informal }}</td>
                    </tr>
                    <ng-container *ngIf="lengths?.vpeHelpTypes">
                        <ng-container *ngFor="let key of objectKeys(statistics?.vpe_help_types); let i = index">
                            <tr>
                                <td>{{ (nbrBasicLines + 33 + lengths?.dvAgeRanges + lengths?.dvIncomes + lengths?.dvContinents + lengths?.dvMaritalStatus + lengths?.dvOrientedBy + lengths?.ieParasites + i + 1) }}</td>
                                <td>{{ statistics?.vpe_help_types[key]?.name }}</td>
                                <td>{{ statistics?.vpe_help_types[key]?.count }}</td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </tbody>
            </table>
        </ng-container>
        <ng-container *ngIf="isLoading">
            <app-loading-spinner></app-loading-spinner>
        </ng-container>
    </div>
</div>