import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MiscService, VisitorPathologyService } from '@services/index';

declare var swal: any;

@Component({
    selector: 'app-util-visitor-parts-medical-infirmary-pathology',
    templateUrl: './pathology.component.html',
})
export class UtilVisitorPartsMedicalInfirmaryPathologyComponent implements OnInit {
    @Input() visitor: any = null;
    @Input() type: string;
    @Input() title: string;
    @Output() updateEvent = new EventEmitter();

    isLoading = true;
    isLoadingVisitorPathologies = false;
    selfVisitor = false;
    limit = 20;
    page = 1;
    visitorPathologies: any;
    fields: any;
    hasData = {
        visitorPathologies: false
    };

    constructor(private cdref: ChangeDetectorRef,
                private miscService: MiscService,
                private visitorPathologyService: VisitorPathologyService) {}

    ngOnInit() {
        this.miscService.can("getVisitorMedical");
        this.isLoading = true;
        this.selfVisitor = this.miscService.checkDataLength(this.visitor) ? true : false;
        this.getVisitorPathologies();
        this.cdref.detectChanges();
    }

    objectKeys(obj) {
        return Object.keys(obj);
    }

    getVisitorPathologies(reset = false) {
        this.isLoading = true;
        this.page = !reset ? this.page : 1;
        let data = {
            type: this.type,
            visitor_ids: [this.visitor.id],
            limit: this.limit,
            page: this.page
        };
        this.visitorPathologyService.getByVisitors(data).subscribe(data => {
            this.visitorPathologies = data;
            this.hasData.visitorPathologies = this.miscService.checkDataLength(this.visitorPathologies.data);
            this.setFields();
            this.isLoading = false;
        }, error => {
            this.visitorPathologies = null;
            this.hasData.visitorPathologies = false;
            this.miscService.showMsg({ msg: "VISITOR_PATHOLOGIES_NOT_FOUND_PLEASE_CHECK_FILTERS" });
            this.isLoading = false;
        });
    }

    setFields() {
        this.fields = {
            dermato: 0,
            cardio: 0,
            pulmonary: 0,
            gastro: 0,
            endocrino: 0,
            diabetes: 0,
            neuro: 0,
            ophtalmo: 0,
            dental: 0,
            uro: 0,
            gyneco: 0,
            infection: 0,
            hepatitis: 0,
            hiv: 0,
            bk: 0,
            diphtheria: 0,
            ortho: 0,
            remarks: 0
        };
        this.fields = {
            dermato: {
                title: "VISITORS_MEDICAL.TITLES.DERMATO",
                type: "bool",
                number: 0
            },
            cardio: {
                title: "VISITORS_MEDICAL.TITLES.CARDIO",
                type: "bool",
                number: 0
            },
            pulmonary: {
                title: "VISITORS_MEDICAL.TITLES.PULMONARY",
                type: "bool",
                number: 0
            },
            gastro: {
                title: "VISITORS_MEDICAL.TITLES.GASTRO",
                type: "bool",
                number: 0
            },
            endocrino: {
                title: "VISITORS_MEDICAL.TITLES.ENDOCRINO",
                type: "bool",
                number: 0
            },
            diabetes: {
                title: "VISITORS_MEDICAL.TITLES.DIABETES",
                type: "bool",
                number: 0
            },
            neuro: {
                title: "VISITORS_MEDICAL.TITLES.NEURO",
                type: "bool",
                number: 0
            },
            ophtalmo: {
                title: "VISITORS_MEDICAL.TITLES.OPHTALMO",
                type: "bool",
                number: 0
            },
            dental: {
                title: "VISITORS_MEDICAL.TITLES.DENTAL",
                type: "bool",
                number: 0
            },
            uro: {
                title: "VISITORS_MEDICAL.TITLES.URO",
                type: "bool",
                number: 0
            },
            gyneco: {
                title: "VISITORS_MEDICAL.TITLES.GYNECO",
                type: "bool",
                number: 0
            },
            infection: {
                title: "VISITORS_MEDICAL.TITLES.INFECTION",
                type: "bool",
                number: 0
            },
            hepatitis: {
                title: "VISITORS_MEDICAL.TITLES.HEPATITIS",
                type: "bool",
                number: 0
            },
            hiv: {
                title: "VISITORS_MEDICAL.TITLES.HIV",
                type: "bool",
                number: 0
            },
            bk: {
                title: "VISITORS_MEDICAL.TITLES.BK",
                type: "bool",
                number: 0
            },
            diphtheria: {
                title: "VISITORS_MEDICAL.TITLES.DIPHTHERIA",
                type: "bool",
                number: 0
            },
            ortho: {
                title: "VISITORS_MEDICAL.TITLES.ORTHO",
                type: "bool",
                number: 0
            },
            remarks: {
                title: "VISITORS_MEDICAL.TITLES.REMARKS",
                type: "string",
                number: 0
            }
        };
        if(this.hasData.visitorPathologies) {
            this.visitorPathologies.data.forEach(visitorPathology => {
                Object.keys(this.fields).forEach(field => {
                    if(visitorPathology[field]) {
                        this.fields[field].number++;
                    }
                });
            });
        }
    }

    getFieldComment(visitorPathology, key) {
        let commentKey = key + "_comment";
        if(visitorPathology[commentKey] !== undefined) {
            return visitorPathology[commentKey];
        }

        return null;
    }
    
    addPathology() {
        this.getVisitorPathologies();
        this.updateEvent.emit();
    }

    switchPage(page) {
        this.page = page;
        this.getVisitorPathologies();
    }

    delete(visitorPathology) {
        let self = this;
        let title = this.miscService._t('VISITORS_MEDICAL.DELETE_PATHOLOGY') + " ?";
        swal({
            title: title,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.miscService._t('SHARED.CONFIRM'),
            cancelButtonText: this.miscService._t('SHARED.CANCEL'),
            closeOnConfirm: true,
            closeOnCancel: true,
        }, function(isConfirm) {
            if(isConfirm) {
                self.visitorPathologyService.delete(visitorPathology.id).subscribe(data => {
                    self.getVisitorPathologies(true);  // resets page to 1 (in case of there is only 1 visitor pathology on the current page)
                    self.miscService.showMsg({ msg: "VISITOR_PATHOLOGY_DELETED", status: "success" });
                    self.updateEvent.emit();
                }, error => {
                    self.miscService.showMsg({ msg: "VISITOR_PATHOLOGY_NOT_DELETED" });
                });
            }
        });
    }
}
