import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MiscService } from '@services/index';
import { Globals } from '@app/app.globals';
import * as moment from 'moment';
import 'jquery';

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html'
})
export class DatePickerComponent implements OnInit {
    @Input() datepickerConfig: any;
    @Input() withHours = false;
    @Input() id: number;
    @Input() col: string = 'col-xs-9';
    @Input() reset: boolean = false;
    @Input() moreClasses: string = "";
    @Input() hasResetButton: boolean = false;
    @Input() randId = Math.floor(Math.random() * 10000);

    @Output() onDateChanged: EventEmitter<any> = new EventEmitter();

    dateModel = null;
    a2eOptions: any;
    formatDateFrench = this.globals.formatDateFrench;
    formatDateFrenchWithHours = this.globals.formatDateFrenchWithHours;
    formatDateSql = this.globals.formatDateSql;
    formatDateSqlWithHours = this.globals.formatDateSqlWithHours;
    currentDate = null;

    constructor(private miscService: MiscService,
                private globals: Globals) {}

    ngOnInit() {
        this.a2eOptions = this.datepickerConfig;
        this.a2eOptions.locale = this.miscService.getLanguage();

        if(this.reset == true) {
            this.dateModel = null;
        }
        this.currentDate = this.withHours ? moment().format(this.formatDateFrenchWithHours) : moment().format(this.formatDateFrench);
    }

    ngOnChanges() {
        if(this.reset == true) {
            this.dateModel = null;
        }
    }

    checkDate(date) {
        let year = moment().format('YYYY');
        let month = moment().format('MM');
        let datePartsArr = date.split(" ");     // splits on space between date and hours
        let dateArr = datePartsArr[0].split("/");
        if(dateArr.length == 1) {     // day only
            datePartsArr[0] += "/" + month + "/" + year;
        }
        else if(dateArr.length == 2) {     // day only and month
            datePartsArr[0] += "/" + year;
        }
        date = datePartsArr[0];
        if(this.withHours) {
            if(datePartsArr[1] === undefined) {
                datePartsArr[1] = "00:00";
            }
            date += " " + datePartsArr[1];
        }

        return date;
    }

    parseDate(str = null) {
        let date = null;
        if(str) {
            let patternFrench = this.withHours ? this.formatDateFrenchWithHours : this.formatDateFrench;
            let patternSql = this.withHours ? this.formatDateSqlWithHours : this.formatDateSql;
            str = this.checkDate(str);
            date = moment(str, patternFrench, true);
            if(!date.isValid()) {
                // date = moment(str, patternSql, true);
                // if(!date.isValid()) {
                //     date = null;
                // }
                date = null;
                this.miscService.showMsg({ msg: "CHOSEN_DATE_INVALID" });
            }
        }
        if(date) {
            this.dateModel = this.withHours ? date.format("DD/MM/YYYY HH:mm") : date.format("DD/MM/YYYY");
        }

        return date;
    }

    dateChanged() {
        let date = this.parseDate(this.dateModel);
        if(date) {
            date = this.withHours ? moment(date).format(this.formatDateSqlWithHours) : moment(date).format(this.formatDateSql);
        }
        this.onDateChanged.emit(date);

        return date;
    }

    setDate(date = null) {
        let pattern = this.withHours ? this.formatDateFrenchWithHours : this.formatDateFrench;
        this.dateModel = date ? moment(date).format(pattern) : null;
        this.dateChanged();
    }

    resetDate(changeDate = true) {
        this.dateModel = null;
        if(changeDate) {
            this.dateChanged();
        }
    }

    // Today at midnight
    resetDateToday() {
        let pattern = this.withHours ? this.formatDateFrenchWithHours : this.formatDateFrench;
        this.dateModel = moment().startOf('day').format(pattern);
        this.dateChanged();
    }

    // Today current hour
    resetDateNow() {
        let pattern = this.withHours ? this.formatDateFrenchWithHours : this.formatDateFrench;
        this.dateModel = moment().format(pattern);
        return this.dateChanged();
    }
}
