import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { MiscService, ChoiceGroupService, VisitorDailyEntryService } from '@services/index';
import { Globals } from '@app/app.globals';
import * as moment from 'moment';

declare var swal: any;

@Component({
    selector: 'app-util-visitor-parts-daily-entry-list',
    templateUrl: './list.component.html',
})
export class UtilVisitorPartsDailyEntryListComponent implements OnInit {
    @Input() visitor: any = null;

    isLoading = true;
    isLoadingVisitors = false;
    selfVisitor = false;
    choiceGroupInstruction: any;
    orderColumns = this.globals.defaultOrderColumns.daily_entries;
    columnHeaders = [];
    filter: any = {
        date: moment().startOf('day').format('YYYY-MM-DD HH:mm')
    };
    limit = 20;
    page = 1;
    visitorEntries: any;
    hasData = {
        choiceGroupInstruction: false,
        visitorEntries: false
    };

    constructor(private cdref: ChangeDetectorRef,
                private miscService: MiscService,
                private choiceGroupService: ChoiceGroupService,
                private visitorDailyEntryService: VisitorDailyEntryService,
                private globals: Globals) {
        this.miscService.setPageTitle("TITLES.DAILY_ENTRY_ENTRIES");
    }

    ngOnInit() {
        this.miscService.can("getVisitorDailyEntry");
        this.isLoading = true;
        this.selfVisitor = this.miscService.checkDataLength(this.visitor) ? true : false;
        this.choiceGroupService.getByKey('instruction').subscribe(data => {
            this.choiceGroupInstruction = data;
            this.hasData.choiceGroupInstruction = this.miscService.checkDataLength(this.choiceGroupInstruction);
            this.isLoading = false;
        });
        this.visitorDailyEntryService.getOrderColumns().subscribe(data => {
            if(data && this.miscService.checkDataLength(data)) {
                this.orderColumns = data;
            }
            this.getVisitorsEntries();
        });
        this.cdref.detectChanges();
    }

    isInArray(arr, value) {
        return arr.includes(value);
    }

    setHeaderColumn(key, text) {
        if(this.hasData.visitorEntries) {
            let count = 0
            if(this.visitorEntries.count[key] !== undefined) {
                count = this.visitorEntries.count[key];
            }
            let header = this.miscService.filterArray(this.columnHeaders, "key", key);
            if(header) {
                header.count = count;
            }
            else {
                this.columnHeaders.push({
                    key: key,
                    text: text,
                    count: count
                });
            }
        }
    }

    setOrderColumns() {
        this.orderColumns.forEach(key => {
            let text = "";
            switch(key) {
                case "entry":
                    text = this.miscService._t("VISITORS_DAILY_ENTRIES.TITLES.ENTRY");
                    break;
                case "shower":
                    text = this.miscService._t("VISITORS_DAILY_ENTRIES.TITLES.SHOWER");
                    break;
                case "laundry":
                    text = this.miscService._t("VISITORS_DAILY_ENTRIES.TITLES.LAUNDRY");
                    break;
                case "infirmary":
                    text = this.miscService._t("VISITORS_DAILY_ENTRIES.TITLES.INFIRMARY");
                    break;
                case "psy":
                    text = this.miscService._t("VISITORS_DAILY_ENTRIES.TITLES.PSY");
                    break;
                case "dispensary":
                    text = this.miscService._t("VISITORS_DAILY_ENTRIES.TITLES.DISPENSARY");
                    break;
                case "pedicure":
                    text = this.miscService._t("VISITORS_DAILY_ENTRIES.TITLES.MEDICAL_PEDICURE");
                    break;
                case "hairdresser":
                    text = this.miscService._t("VISITORS_DAILY_ENTRIES.TITLES.HAIRDRESSER");
                    break;
                case "social_work":
                    text = this.miscService._t("VISITORS_DAILY_ENTRIES.TITLES.SOCIAL_WORK");
                    break;
                case "visit":
                    text = this.miscService._t("VISITORS_DAILY_ENTRIES.TITLES.VISIT");
                    break;
                case "underpants":
                    text = this.miscService._t("VISITORS_DAILY_ENTRIES.TITLES.UNDERPANTS");
                    break;
                case "socks":
                    text = this.miscService._t("VISITORS_DAILY_ENTRIES.TITLES.SOCKS");
                    break;
                case "t_shirt":
                    text = this.miscService._t("VISITORS_DAILY_ENTRIES.TITLES.T_SHIRT");
                    break;
                case "trousers":
                    text = this.miscService._t("VISITORS_DAILY_ENTRIES.TITLES.TROUSERS");
                    break;
                case "pull":
                    text = this.miscService._t("VISITORS_DAILY_ENTRIES.TITLES.PULL");
                    break;
                case "jacket":
                    text = this.miscService._t("VISITORS_DAILY_ENTRIES.TITLES.JACKET");
                    break;
                case "shoes":
                    text = this.miscService._t("VISITORS_DAILY_ENTRIES.TITLES.SHOES");
                    break;
                case "scarf_hat_gloves":
                    text = this.miscService._t("VISITORS_DAILY_ENTRIES.TITLES.SCARF_HAT_AND_GLOVES");
                    break;
                case "blanket":
                    text = this.miscService._t("VISITORS_DAILY_ENTRIES.TITLES.BLANKET");
                    break;
                case "other_clothes":
                    text = this.miscService._t("VISITORS_DAILY_ENTRIES.TITLES.OTHER");
                    break;
                case "instruction_id":
                    text = this.miscService._t("VISITORS_DAILY_ENTRIES.TITLES.INSTRUCTION");
                    break;
                case "instruction_paper_id":
                    text = this.miscService._t("VISITORS_DAILY_ENTRIES.TITLES.INSTRUCTION_PAPER");
                    break;
                case "miscellaneous":
                    text = this.miscService._t("VISITORS_DAILY_ENTRIES.TITLES.REMARK");
                    break;
            }
            this.setHeaderColumn(key, text);
        });
    }

    filterVisitorEntries(filter = null) {
        this.filter = filter;
        this.getVisitorsEntries(true);
    }

    getVisitorsEntries(hasFilter = false) {
        this.isLoadingVisitors = true;
        let data = this.filter;
        data.visitor_ids = this.selfVisitor ? [this.visitor.id] : (data.visitor_ids && data.visitor_ids.length ? data.visitor_ids : null);
        data.limit = this.limit;
        data.page = !hasFilter ? this.page : 1;     // if user has filtered, then reset page to 1 (number of pages can change due to filters)
        this.visitorDailyEntryService.getByVisitors(data).subscribe(data => {
            this.visitorEntries = data;
            this.hasData.visitorEntries = this.miscService.checkDataLength(this.visitorEntries.data);
            this.setOrderColumns();
            this.isLoadingVisitors = false;
        }, error => {
            this.visitorEntries = null;
            this.hasData.visitorEntries = false;
            this.miscService.showMsg({ msg: "VISITOR_ENTRIES_NOT_FOUND_PLEASE_CHECK_FILTERS" });
            this.isLoadingVisitors = false;
        });
    }

    showInstructionChoice(value) {
        if(value) {
            let choice = this.miscService.filterArray(this.choiceGroupInstruction.choices, "id", value);
            return choice ? choice.name : "-";
        }

        return "-";
    }

    switchPage(page) {
        this.page = page;
        this.getVisitorsEntries();
    }

    delete(visitorEntry) {
        let self = this;
        let title = this.miscService._t('VISITORS_DAILY_ENTRIES.DELETE_ENTRY') + " ?";
        swal({
            title: title,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.miscService._t('SHARED.CONFIRM'),
            cancelButtonText: this.miscService._t('SHARED.CANCEL'),
            closeOnConfirm: true,
            closeOnCancel: true,
        }, function(isConfirm) {
            if(isConfirm) {
                self.visitorDailyEntryService.delete(visitorEntry.id).subscribe(data => {
                    self.getVisitorsEntries(true);  // resets page to 1 (in case of there is only 1 visitor entry on the current page)
                    self.miscService.showMsg({ msg: "VISITOR_ENTRY_DELETED", status: "success" });
                }, error => {
                    self.miscService.showMsg({ msg: "VISITOR_ENTRY_NOT_DELETED" });
                });
            }
        });
    }
}
