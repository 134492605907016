import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MiscService, ChoiceService, ChoiceGroupService } from '@services/index';

@Component({
    selector: 'app-ng-select-add-single',
    templateUrl: './single.component.html'
})
export class NgSelectAddSingleComponent implements OnInit {
    @Input() items: any;
    @Input() key = "";
    @Input() selectedId: number = null;
    @Input() resettable = true;
    @Input() needsLoading = true;
    @Output() changeEvent = new EventEmitter<any>();

    isLoading = true;
    value: any;
    choiceGroup: any;
    hasData = {
        choiceGroup: false
    };

    @ViewChild('ngSelectSingle') ngSelectSingle;

    constructor(private miscService: MiscService,
                private choiceService: ChoiceService,
                private choiceGroupService: ChoiceGroupService) {}

    ngOnInit() {
        this.isLoading = true;
        if(this.selectedId) {
            let value = this.miscService.filterArray(this.items, "id", this.selectedId);
            if(value) {
                this.value = value;
            }
        }
        this.choiceGroupService.getByKey(this.key).subscribe(data => {
            this.choiceGroup = data;
            this.hasData.choiceGroup = this.miscService.checkDataLength(this.choiceGroup);
            if(!this.hasData.choiceGroup) {
                this.miscService.showMsg({ msg: "CHOICE_GROUP_NOT_FOUND" });
            }
            this.isLoading = false;
        });
    }

    createNewChoice = (name) => {
        let data = {
            name: name,
            choice_group_id: this.choiceGroup.id
        };
        this.choiceService.create(data).subscribe(data => {
            this.items = this.items && this.items.length ? [...this.items, data] : [data];
            this.value = data;
            this.change();
            this.ngSelectSingle.itemsList['_selectedModel'] = [];
        });
    }

    change() {
        this.changeEvent.emit(this.value ? this.value : null);
    }

    reset() {
        this.value = null;
        this.changeEvent.emit(null);
    }
}