import { Component, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MiscService, ChoiceGroupService, VisitorDailyEntryService } from '@services/index';
import { Globals } from '@app/app.globals';

@Component({
    selector: 'app-util-visitor-parts-daily-entry-modals-add',
    templateUrl: './add.component.html',
})
export class UtilVisitorPartsDailyEntryModalsAddComponent implements OnInit {
    @Output() addEntryEvent = new EventEmitter<any>();

    isSubmitting = false;
    wrongDate = false;
    modal = "#addEntryModal";
    cud = "add";
    pickerConfig: any;
    choiceGroupInstruction: any;
    visitorEntry: any;
    visitorId: number;
    forbiddenDates = [];
    addForm = this.fb.group({
        id: null,
        visitor_id: [null, Validators.required],
        date: [null, Validators.required],
        entry: true,
        number_showers: false,
        number_laundries: false,
        infirmary: false,
        psy: false,
        pedicure: false,
        social_work: false,
        visit: false,
        dispensary: false,
        underpants: false,
        socks: false,
        t_shirt: false,
        trousers: false,
        pull: false,
        jacket: false,
        shoes: false,
        blanket: false,
        hairdresser: false,
        scarf_hat_gloves: false,
        other_clothes: false,
        instruction_id: null,
        instruction_paper_id: null,
        miscellaneous: null
    });
    hasData = {
        visitorEntry: false,
        choiceGroupInstruction: false
    };

    @ViewChild('datePicker') datePicker;

    constructor(private cdref: ChangeDetectorRef,
                private fb: UntypedFormBuilder,
                private miscService: MiscService,
                private choiceGroupService: ChoiceGroupService,
                private visitorDailyEntryService: VisitorDailyEntryService,
                public globals: Globals) {}

    ngOnInit() {
        if(this.miscService.checkEnvPermission("cudVisitorDailyEntry")) {
            this.pickerConfig = JSON.parse(JSON.stringify(this.globals.pickerConfig));
            this.choiceGroupService.getByKey("instruction").subscribe(data => {
                this.choiceGroupInstruction = data;
                this.hasData.choiceGroupInstruction = this.miscService.checkDataLength(this.choiceGroupInstruction);
            });
        }
        this.cdref.detectChanges();
    }

    reset() {
        this.addForm.reset({ entry: true });
        this.visitorId = null;
        this.cud = "add";
    }
    
    openModal(visitorId = null, visitorEntry = null) {
        if(this.miscService.checkEnvPermission("cudVisitorDailyEntry")) {
            $(this.modal).modal('show');
            this.reset();
            this.visitorEntry = visitorEntry;
            this.hasData.visitorEntry = this.miscService.checkDataLength(this.visitorEntry);
            this.visitorId = visitorId;
            if(this.visitorId) {
                this.addForm.get('visitor_id').patchValue(visitorId);
                this.getForbiddenDates(true);
            }
            else {
                this.resetDate();
                this.checkEdit();
            }
        }
    }

    getForbiddenDates(setup = false) {
        let data = {
            visitor_id: this.addForm.value.visitor_id,
            visitor_daily_entries_id: this.hasData.visitorEntry ? [this.visitorEntry.id] : null
        };
        this.visitorDailyEntryService.getDatesByVisitor(data).subscribe(data => {
            this.forbiddenDates = data;
            if(setup) {
                this.resetDate();
            }
            else {
                this.checkDate(this.addForm.value.date, "date");
            }
            this.checkEdit();
        });
    }

    checkEdit() {
        if(this.hasData.visitorEntry) {
            this.cud = "edit";
            this.addForm.patchValue(this.visitorEntry);
            if(this.visitorEntry.date) {
                this.datePicker.setDate(this.visitorEntry.date);
            }
        }
    }

    goToAddVisitorUrl() {
        $(this.modal).modal('hide');
        this.miscService.goToUrlDelay(['/visitor/add']);
    }

    addChoices(items, choices) {
        choices.forEach(choice => {
            this[items] = [...this[items], choice];
        });
    }

    changeVisitor(value) {
        this.addForm.get('visitor_id').patchValue(value ? value : null);
        this.getForbiddenDates(false);
    }

    changeChoice(field, value) {
        this.addForm.get(field).patchValue(value ? value.id : null);
    }

    dateChanged(date, field) {
        if(date) {
            this.checkDate(date, field);
        }
        else {
            this.resetField(field);
        }
	}

    checkDate(date, field) {
        if(!this.miscService.checkDateInArray(date, this.forbiddenDates)) {
            this.addForm.get(field).patchValue(date);
            this.wrongDate = false;
        }
        else {
            this.miscService.showMsg({ msg: "DATE_ALREADY_USED_FOR_OTHER_DAILY_ENTRY_FOR_VISITOR", status: "warning", ignoreLastNotif: true });
            this.resetField(field);
            this.wrongDate = true;
        }
    }

    resetDate() {
        this.datePicker.resetDateNow();
    }

    resetField(field) {
        this.addForm.get(field).reset();
    }

    submit() {
        this.isSubmitting = true;
        let data = this.addForm.value;
        if(this.cud == "add") {
            this.create(data);
        }
        else {
            this.update(data);
        }
    }

    create(data) {
        this.visitorDailyEntryService.create(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_ENTRY_CREATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addEntryEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_ENTRY_NOT_CREATED" });
        });
    }

    update(data) {
        this.visitorDailyEntryService.update(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_ENTRY_UPDATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addEntryEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_ENTRY_NOT_UPDATED" });
        });
    }
}
