import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/_others/shared.module';

import { FooterComponent } from './footer.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule
    ],
    declarations: [
        FooterComponent
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        FooterComponent
    ]
})

export class FooterModule {}
