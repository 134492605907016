
<nav #navbar class="navbar navbar-default">
    <div class="container-fluid">
        <div class="navbar-header">
            <button type="button"
                    class="navbar-left btn btn-simple btn-goBack btn-icon pull-left mt-2 mb-0"
                    title="{{ 'NAVBAR.BACK' | translate }}"
                    (click)="goBack()"
                    appTooltip>
                <i class="fa fa-angle-left fa-2x "></i>
            </button>

            <button type="button" class="navbar-toggle" data-toggle="collapse" (click)="sidebarToggle()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <span class="navbar-brand hidden-md mt-2 mb-0">
                {{ getTitle() | limitTo:'20' }}
            </span>
        </div>
        <div class="collapse navbar-collapse">
            <ng-container *ngIf="!isLoading">
                <div class="" *ngIf="isMobileMenu()">
                    <ul class="nav navbar-nav navbar-right">
                        <li class="dropdown dropdown-with-icons">
                            <a href="#" class="dropdown-toggle mb-0" data-toggle="dropdown">
                                <i class="fa fa-list"></i>
                                <p class="hidden-md hidden-lg">
                                    More
                                    <b class="caret"></b>
                                </p>
                            </a>
                            <ul class="dropdown-menu dropdown-with-icons">
                                <ng-container>
                                    <li *ngxPermissionsOnly="['cudSettings', 'cudOption', 'cudRoles']">
                                        <a routerLinkActive="active" [routerLink]="['/settings/edit']">
                                            <i class="fa fa-wrench"></i> {{ 'NAVBAR.SETTINGS_SOFTWARE' | translate }}
                                        </a>
                                    </li>
                                    <li *ngIf="editAdmin">
                                        <a routerLinkActive="active" [routerLink]="['/settings/admins']">
                                            <i class="fa fa-user"></i> {{ 'NAVBAR.MANAGE_SUPER_ADMINS' | translate }}
                                        </a>
                                    </li>
                                </ng-container>
                                <li>
                                    <a (click)="openLanguageModal()" class="btn-logout">
                                        <p>
                                            <img src="/assets/img/flags/{{ getFlagForLang() }}.png" class="mx-2"> {{ 'NAVBAR.CHANGE_LANG' | translate }}
                                        </p>
                                    </a>
                                </li>
                                <li>
                                    <bSwitch
                                        [switch-on-color]="'primary'"
                                        [(ngModel)]="isNightMode"
                                        (ngModelChange)="switchTheme($event)">
                                    </bSwitch>
                                    <p>{{ 'NAVBAR.NIGHT_MODE' | translate }}</p>
                                </li>
                                <li>
                                    <bSwitch
                                        [switch-on-color]="'primary'"
                                        [(ngModel)]="isMiniSidebar"
                                        (ngModelChange)="switchMini($event)">
                                    </bSwitch>
                                    <p>{{ 'NAVBAR.MINI' | translate }}</p>
                                </li>
                                <li role="separator" class="divider"></li>
                                <li>
                                    <a [routerLink]="['/auth/lock']">
                                        <p><i class="fa fa-lock"></i>{{ 'NAVBAR.LOCK' | translate }}</p>
                                    </a>
                                </li>
                                <li>
                                    <a (click)="logout()" class="btn-logout">
                                        <p class="text-danger"><i class="fa fa-power-off"></i>{{ 'NAVBAR.LOGOUT' | translate }}</p>
                                    </a>
                                </li>
                            </ul>
                        </li>
    
                    </ul>
                </div>
            </ng-container>
            <ng-container *ngIf="isLoading">
                <p>
                    <app-loading-spinner></app-loading-spinner>
                </p>
            </ng-container>
        </div>
    </div>
</nav>

<div [id]="languageModalId" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
                <h4 class="modal-title">{{ 'NAVBAR.LANG_CHOOSE' | translate }}</h4>
            </div>
            <div class="modal-body" *ngIf="!isLoading">
                <div class="row">
                    <div class="col-md-4" *ngFor="let lang of languages">
                        <div class="card cursor-ptr" (click)="setLang(lang?.code)">
                            <div class="content">
                                <img src="/assets/img/flags/{{ strToUppercase(lang?.code) }}.png" class="mr-3"> {{ lang?.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
