import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from '@services/index';

@Injectable({
  providedIn: 'root'
})
export class NightModeService {

  	private isNightModeSource = new BehaviorSubject(false);
	currentMode = this.isNightModeSource.asObservable();

	constructor(private localStorageService: LocalStorageService) {}

	changeMode(mode){
        this.localStorageService.setLSItem('isNightMode', mode);
		this.isNightModeSource.next(mode);
	}

}
