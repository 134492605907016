import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
    providedIn: 'root'
})
export class VisitorTreatmentInjectionService extends ApiService {
    getByVisitors(data: any) {
        return super.post('/visitors-treatment-injection/visitors', data);
    }

    getCount(id: number) {
        return super.get("/visitors-treatment-injection/count/" + id);
    }

    generatePdf(data: any) {
        return super.post('/visitors-treatment-injection/pdf', data);
    }

    create(data: any) {
        return super.post('/visitors-treatment-injection/create', data);
    }

    update(data: any) {
        return super.put('/visitors-treatment-injection/update', data);
    }

    delete(id: number) {
        return super.delete('/visitors-treatment-injection/' + id);
    }
}
