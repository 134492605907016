import { Component, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MiscService, VisitorSuspensionService } from '@services/index';
import { Globals } from '@app/app.globals';
import * as moment from 'moment';

@Component({
    selector: 'app-util-visitor-modals-suspensions-add',
    templateUrl: './add.component.html',
})
export class UtilVisitorModalsSuspensionsAddComponent implements OnInit {
    @Output() addEvent = new EventEmitter<any>();

    isSubmitting = false;
    modal = "#suspensionsAddModal";
    cud = "add";
    datepickerConfig: any;
    visitor: any;
    addForm = this.fb.group({
        id: null,
        visitor_id: [null, Validators.required],
        begin: [null, Validators.required],
        end: null,
        reason: null
    });

    @ViewChild('datePickerBeginSuspension') datePickerBeginSuspension;
    @ViewChild('datePickerEndSuspension') datePickerEndSuspension;

    constructor(private cdref: ChangeDetectorRef,
                private fb: UntypedFormBuilder,
                private miscService: MiscService,
                private visitorSuspensionService: VisitorSuspensionService,
                public globals: Globals) {}

    ngOnInit() {
        this.datepickerConfig = JSON.parse(JSON.stringify(this.globals.dateOnlyPickerConfig));
        this.cdref.detectChanges();
    }
    
    openModal(visitor, visitorSuspension = null) {
        if(this.miscService.checkEnvPermission("cudVisitor")) {
            this.cud = visitorSuspension ? "edit" : "add";
            this.addForm.reset({visitor_id: visitor.id});
            this.resetDates();
            this.addForm.get('begin').patchValue(moment().startOf('day').format('YYYY-MM-DD'));
            if(this.cud == "edit") {
                this.addForm.patchValue(visitorSuspension);
                if(visitorSuspension.begin) {
                    this.datePickerBeginSuspension.dateModel = moment(visitorSuspension.begin).format(this.globals.formatDateFrenchWithHours);
                }
                if(visitorSuspension.end) {
                    this.datePickerEndSuspension.dateModel = moment(visitorSuspension.end).format(this.globals.formatDateFrenchWithHours);
                }
            }
            $(this.modal).modal('show');
        }
    }

    dateChanged(evt, field) {
        if(evt) {
            let date = evt;
            this.addForm.get(field).patchValue(date);
        }
        else {
            this.resetField(field);
        }
	}

    resetDates() {
        this.datePickerBeginSuspension.dateModel = moment().format(this.globals.formatDateFrenchWithHours);
        this.datePickerEndSuspension.dateModel = null;
    }

    resetField(field) {
        this.addForm.get(field).reset();
    }

    submit() {
        this.isSubmitting = true;
        let data = this.addForm.value;
        if(this.cud == "add") {
            this.create(data);
        }
        else {
            this.update(data);
        }
    }

    create(data) {
        this.visitorSuspensionService.create(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_SUSPENSION_CREATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_SUSPENSION_NOT_CREATED" });
        });
    }

    update(data) {
        this.visitorSuspensionService.update(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg:  "VISITOR_SUSPENSION_UPDATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_SUSPENSION_NOT_UPDATED" });
        });
    }
}
