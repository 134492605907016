<ng-container *ngIf="higherThanLimit">
    <span class="pointer" (click)="openModal()">{{ showText }} <i class="fa fa-question-circle ml-2"></i></span>
</ng-container>
<ng-container *ngIf="!higherThanLimit">{{ showText | ifEmpty: '-' }}</ng-container>

<div aria-labelledby="textLimitModalLabel" class="modal modal-big fade" id="textLimitModal-{{ randId }}" role="dialog" tabindex="-1" style="display: none; z-index: 9999;">
    <div class="modal-dialog width-900" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
                <h4 class="modal-title" style="text-align: left;">
                    {{ 'SHARED.' + title | translate }}
                </h4>
            </div>
            <div class="modal-body">
                <p style="text-align: left; white-space: pre-wrap;">{{ text }}</p>
            </div>
        </div>
    </div>
</div>