export class Software {
    name: string;
    env: string;
    settings: any;
    options: any;
    version: any;

    settingValue(key: string): any {
        return this?.settings?.[key] || null;
    }

    optionValue(key: string): string[] {
        return this?.options?.[key] || null;
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
