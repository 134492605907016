<div style="width: 100%;">
    <div style="width: 100%;">
        <ng-select [items]="items"
                    bindId="id"
                    bindLabel="name"
                    [loading]="needsLoading && !items"
                    [hideSelected]="true"
                    [addTag]="createNewChoice"
                    [(ngModel)]="value"
                    (change)="change()"
                    (clear)="reset()"
                    #ngSelectSingle>
        </ng-select>
    </div>
</div>