import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MiscService, VisitorService, VisitorSuspensionService } from '@services/index';
import { environment } from '@env/environment';

declare var swal: any;

@Component({
    selector: 'app-util-visitor-list',
    templateUrl: './list.component.html',
})
export class UtilVisitorListComponent implements OnInit {
    isLoading = false;
    isExporting = false;
    hasFiltered = false;
    docUrl = environment.baseUrl;
    filter: any;
    limit = 20;
    page = 1;
    visitors: any;
    textVisitorPermanent = "";
    hasData = {
        visitors: false
    };

    constructor(private cdref: ChangeDetectorRef,
                private router: Router,
                private miscService: MiscService,
                private visitorService: VisitorService,
                private visitorSuspensionService: VisitorSuspensionService) {}

    ngOnInit() {
        this.miscService.can("getVisitor");
        this.textVisitorPermanent = this.miscService.getTextPermanent();
        this.getVisitors();
        this.cdref.detectChanges();
    }

    goToEntries(id) {
        this.router.navigate(['/visitor', id, 'daily-entry']);
    }

    filterVisitors(filter = null) {
        this.filter = filter;
        this.getVisitors();
    }

    getVisitors() {
        this.isLoading = true;
        this.hasFiltered = true;
        if(this.filter === undefined) {
            this.filter = {};
        }
        this.filter.limit = this.limit;
        this.filter.page = this.page;
        this.visitorService.getAll(this.filter).subscribe(data => {
            this.visitors = data;
            this.hasData.visitors = this.miscService.checkDataLength(this.visitors.data);
            this.isLoading = false;
        });
    }

    switchPage(page) {
        this.page = page;
        this.getVisitors();
    }

    export() {
        this.isExporting = true;
        this.visitorService.export(this.filter).subscribe(data => {
            let url = this.docUrl + "file/" + data.name;
            var pdfLink = document.createElement("a");     // Obligé de créer un faux lien sinon ça ne marche pas sur Chrome
            pdfLink.setAttribute("id", "link-export-stats");
            pdfLink.href = url;
            pdfLink.target = "_blank";
            pdfLink.download = "export.csv";
            pdfLink.click();
            this.isExporting = false;
        });
    }

    cancelAllVisitorSuspensions() {
        let self = this;
        swal({
            title: this.miscService._t('VISITORS_SUSPENSIONS.END_ALL_SUSPENSIONS'),
            text: this.miscService._t('VISITORS_SUSPENSIONS.ARE_YOU_SURE_END_ALL_SUSPENSIONS'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.miscService._t('SHARED.CONFIRM'),
            cancelButtonText: this.miscService._t('SHARED.CANCEL'),
            closeOnConfirm: true,
            closeOnCancel: true,
        }, function(isConfirm) {
            if(isConfirm) {
                self.visitorSuspensionService.endAll().subscribe(data => {
                    self.getVisitors();
                    self.miscService.showMsg({ msg: "VISITOR_SUSPENSIONS_ENDED", status: "success" });
                }, error => {
                    self.miscService.showMsg({ msg: "VISITOR_SUSPENSIONS_NOT_ENDED" });
                });
            }
        });
    }

    archive(visitor) {
        let self = this;
        let title = (visitor.isArchived ? this.miscService._t('VISITORS.UNARCHIVE') : this.miscService._t('VISITORS.ARCHIVE') ) + " ?";
        let wasArchived = visitor.isArchived;
        swal({
            title: title,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.miscService._t('SHARED.CONFIRM'),
            cancelButtonText: this.miscService._t('SHARED.CANCEL'),
            closeOnConfirm: true,
            closeOnCancel: true,
        }, function(isConfirm) {
            if(isConfirm) {
                self.visitorService.archive(visitor.id).subscribe(data => {
                    let msg = wasArchived ? "VISITOR_UNARCHIVED" : "VISITOR_ARCHIVED";
                    self.getVisitors();
                    self.miscService.showMsg({ msg: msg, status: "success" });
                }, error => {
                    let msg = wasArchived ? "VISITOR_NOT_UNARCHIVED" : "VISITOR_NOT_ARCHIVED";
                    self.miscService.showMsg({ msg: msg });
                });
            }
        });
    }

    delete(visitor) {
        let self = this;
        swal({
            title: this.miscService._t('SHARED.DELETE') + " ?",
            text: this.miscService._t('VISITORS.ARE_YOU_SURE_DELETE'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.miscService._t('SHARED.CONFIRM'),
            cancelButtonText: this.miscService._t('SHARED.CANCEL'),
            closeOnConfirm: true,
            closeOnCancel: true,
        }, function(isConfirm) {
            if(isConfirm) {
                self.visitorService.delete(visitor.id).subscribe(data => {
                    self.getVisitors();
                    self.miscService.showMsg({ msg: "VISITOR_DELETED", status: "success" });
                }, error => {
                    self.miscService.showMsg({ msg: "VISITOR_NOT_DELETED" });
                });
            }
        });
    }
}
