import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: "app-btn-loading",
    templateUrl: "./btn-loading.component.html",
})
export class BtnLoadingComponent implements OnInit {
    @Input() color: string = "success";
    @Input() loading: boolean = false;
    @Input() disabled: boolean = false;
    @Input() icon: string;
    @Input() type: string = "submit";
    @Input() block: boolean = false;
    @Input() fill: boolean = false;
    @Input() small: boolean = false;
    @Input() title: string = "";
    @Input() simple: boolean = false;
    @Input() size: string = '';

    ngOnInit(): void {
        this.block = this.block != undefined && this.block !== false;
        this.fill = this.fill != undefined && this.fill !== false;
        this.small = this.small != undefined && this.small !== false;
        this.simple = this.simple != undefined && this.simple !== false;
    }
}
