import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'app-form-group',
    templateUrl: './form-group.component.html',
})
export class FormGroupComponent implements OnInit {
    @Input() control: AbstractControl;
    @Input() label: string;
    @Input() title = "";
    @Input() forId: string;
    @Input() star: boolean = false;
    @Input() sm: boolean = false;
    @Input() col_label: number;
    @Input() col_input: number;
    @Input() formGroupClass: string = '';

    ngOnInit(): void {
        this.star = this.star != undefined && this.star !== false;
        this.sm = this.sm != undefined && this.sm !== false;
    }
}
