import { Component, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MiscService, VisitorMedicalService } from '@services/index';
import { Globals } from '@app/app.globals';

@Component({
    selector: 'app-util-visitor-parts-medical-file-modals-add',
    templateUrl: './add.component.html',
})
export class UtilVisitorPartsMedicalFileModalsAddComponent implements OnInit {
    @Output() addMedicalEvent = new EventEmitter<any>();

    isLoading = false;
    isSubmitting = false;
    modal = "#addMedicalModal";
    cud = "add";
    pickerConfig: any;
    addForm = this.fb.group({
        id: null,
        visitor_id: [null, Validators.required],
        anxiety_disorder: null,
        alcohol_addiction: null,
        drugs_addiction: null,
        pharmaceuticals_addiction: null,
        alcohol_addiction_remark: null,
        drugs_addiction_remark: null,
        pharmaceuticals_addiction_remark: null,
        unipolar_depression: null,
        bipolar_disorders: null,
        psychosis: null,
        mental_retardation: null,
        significant_cognitive_disorders: null,
        eating_disorders: null,
        personality_disorders: null,
        pathological_gambling: null,
        other_disorder: null,
        not_established_disorders: null,
        informations: null,
        psy_history: null,
        psy_somatic_history: null,
        psy_old_treatments: null,
        psy_intolerance: null
    });
    hasData = {
        visitorMedical: false
    };

    constructor(private cdref: ChangeDetectorRef,
                private fb: UntypedFormBuilder,
                private miscService: MiscService,
                private visitorMedicalService: VisitorMedicalService,
                public globals: Globals) {}

    ngOnInit() {
        this.pickerConfig = JSON.parse(JSON.stringify(this.globals.pickerConfig));
        this.cdref.detectChanges();
    }

    reset() {
        this.addForm.reset();
        this.hasData.visitorMedical = false;
        this.cud = "add";
    }
    
    openModal(visitorId, visitorMedical = null) {
        if(this.miscService.can("cudVisitorMedical")) {
            this.isLoading = true;
            this.reset();
            this.hasData.visitorMedical = this.miscService.checkDataLength(visitorMedical);
            this.addForm.get('visitor_id').patchValue(visitorId);
            if(this.hasData.visitorMedical) {
                this.cud = "edit";
                this.addForm.patchValue(visitorMedical);
            }
            this.isLoading = false;
            $(this.modal).modal('show');
        }
    }

    addChoices(items, choices) {
        this[items] = this.miscService.addChoices(items, choices);
    }

    changeChoice(field, value) {
        let val = this.miscService.getChoice(value);
        this.addForm.get(field).patchValue(val);
    }

    resetField(field) {
        this.addForm.get(field).reset();
    }

    submit() {
        this.isSubmitting = true;
        let data = this.addForm.value;
        data.from = "medical";
        if(this.cud == "add") {
            this.create(data);
        }
        else {
            this.update(data);
        }
    }

    create(data) {
        this.visitorMedicalService.create(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_MEDICAL_DATA_CREATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addMedicalEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_MEDICAL_DATA_NOT_CREATED" });
        });
    }

    update(data) {
        this.visitorMedicalService.update(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_MEDICAL_DATA_UPDATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addMedicalEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_MEDICAL_DATA_NOT_UPDATED" });
        });
    }
}
