<div class="card">
    <div class="header">
        <h4 class="title">{{ type }}</h4>
    </div>
    <div class="content {{ files?.length > 0 ? 'table-full-width pb-0' : '' }}">
        <ng-container *ngIf="files?.length > 0; then showFiles else notShowFiles"></ng-container>
        <ng-template #showFiles>
            <div class="overflow-auto mb-3">
                <table class="table table-striped table-condensed mb-0">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{{ 'SHARED.ACTIONS' | translate }}</th>
                            <th>{{ 'UPLOAD.FILE' | translate }}</th>
                            <th>{{ 'UPLOAD.DATE' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let file of files; let fileIndex = index">
                            <td>{{ fileIndex + 1 }}</td>
                            <td>
                                <span class="align-items-center justify-content-left">
                                    <label class="customcheck" style="margin-top: 2px;">
                                        <input type="checkbox" [(ngModel)]="file.is_selected" (ngModelChange)="onCheckboxClicked()">
                                        <span class="checkmark"></span>
                                    </label>
                                    <a class="btn btn-info btn-simple btn-icon ml-2" href="{{ file | file }}" target="_blank" appTooltip>
                                        <i class="fa fa-link"></i>
                                    </a>
                                    <button class="btn btn-success btn-simple btn-icon ml-2" (click)="openEditModal(file)">
                                        <i class="fa fa-edit"></i>
                                    </button>
                                    <button class="btn btn-danger btn-simple btn-icon ml-2" (click)="destroy(file)">
                                        <i class="fa fa-trash-o"></i>
                                    </button>
                                </span>
                            </td>
                            <td>
                                <span title="{{ file?.name }}" appTooltip>{{ file?.name | limitTo: '30' }}</span>
                            </td>
                            <td>{{ file?.created_at | localizedDate | ifEmpty: '---' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-template>
        <ng-template #notShowFiles>{{ 'UPLOAD.NO_FILE' | translate }}</ng-template>
    </div>
</div>
<div [id]="editModalId" class="modal fade" role="dialog" tabindex="-1" style="z-index: 9999;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
                <h4 class="modal-title">{{ 'SHARED.EDIT' | translate }}</h4>
            </div>
            <form [formGroup]="editForm" (ngSubmit)="submitEditForm()" autocomplete="off">
                <div class="modal-body">
                    <app-form-group [control]="editForm.get('name')" label="{{ 'UPLOAD.NO_FILE' | translate }}" star>
                        <input type="text" class="form-control" formControlName="name">
                    </app-form-group>
                </div>
                <div class="modal-footer">
                    <app-btn-loading fill [loading]="editFormLoading" [disabled]="editForm.invalid" icon="floppy-o">{{ 'SHARED.SAVE' | translate }}</app-btn-loading>
                </div>
            </form>
        </div>
    </div>
</div>
