import { Component, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { concat, Observable, of, Subject } from 'rxjs';
import { MiscService, VisitorService, VisitorPsyFollowUpService } from '@services/index';
import { Globals } from '@app/app.globals';

@Component({
    selector: 'app-util-visitor-parts-medical-follow-up-modals-add',
    templateUrl: './add.component.html',
})
export class UtilVisitorPartsMedicalFollowUpModalsAddComponent implements OnInit {
    @Output() addFollowUpEvent = new EventEmitter<any>();

    isLoading = false;
    isSubmitting = false;
    modal = "#addPsyFollowUpModal";
    cud = "add";
    pickerConfig: any;
    visitorPsyFollowUp: any;
    visitorId: number;
    visitors: Observable<any[]>;
    visitorsLoading = false;
    visitorsInput = new Subject();
    addForm = this.fb.group({
        id: null,
        visitor_id: [null, Validators.required],
        date: [null, Validators.required],
        informations: [null, Validators.required]
    });
    hasData = {
        visitorPsyFollowUp: false
    };

    @ViewChild('datePicker') datePicker;

    constructor(private cdref: ChangeDetectorRef,
                private fb: UntypedFormBuilder,
                private miscService: MiscService,
                private visitorService: VisitorService,
                private visitorPsyFollowUpService: VisitorPsyFollowUpService,
                public globals: Globals) {
        this.miscService.setPageTitle("TITLES.VISITOR_PSY_FOLLOW_UPS");
    }

    ngOnInit() {
        this.pickerConfig = JSON.parse(JSON.stringify(this.globals.pickerConfig));
        this.cdref.detectChanges();
    }

    reset() {
        this.addForm.reset();
        this.resetDate();
        this.visitorId = null;
        this.cud = "add";
    }
    
    openModal(visitorId = null, visitorPsyFollowUp = null) {
        if(this.miscService.can("cudVisitorPsychoFollowUp")) {
            this.isLoading = true;
            this.visitorPsyFollowUp = visitorPsyFollowUp;
            this.hasData.visitorPsyFollowUp = this.miscService.checkDataLength(this.visitorPsyFollowUp);
            this.reset();
            this.visitorId = visitorId;
            if(this.visitorId) {
                this.addForm.get('visitor_id').patchValue(visitorId);
            }
            else {
                this.loadVisitors();
            }
            if(this.hasData.visitorPsyFollowUp) {
                this.cud = "edit";
                this.addForm.patchValue(this.visitorPsyFollowUp);
                if(this.visitorPsyFollowUp.date) {
                    this.datePicker.setDate(this.visitorPsyFollowUp.date);
                }
            }
            this.isLoading = false;
            $(this.modal).modal('show');
        }
    }

    goToAddVisitorUrl() {
        $(this.modal).modal('hide');
        this.miscService.goToUrlDelay(['/visitor/add']);
    }

    loadVisitors() {
        this.visitors = concat(
            of([]),
            this.visitorsInput.pipe(
                distinctUntilChanged(),
                tap(() => this.visitorsLoading = true),
                debounceTime(500),
                switchMap(term => this.visitorService.getAll({term: term, limit: 5, filter: true}).pipe(
                    catchError(() => of([])),
                    tap(() => this.visitorsLoading = false),
                ))
            )
        );
    }

    dateChanged(evt, field) {
        if(evt) {
            let date = evt;
            this.addForm.get(field).patchValue(date);
        }
        else {
            this.resetField(field);
        }
	}

    resetDate() {
        this.datePicker.resetDateNow();
    }

    resetField(field) {
        this.addForm.get(field).reset();
    }

    submit() {
        this.isSubmitting = true;
        let data = this.addForm.value;
        if(this.cud == "add") {
            this.create(data);
        }
        else {
            this.update(data);
        }
    }

    create(data) {
        this.visitorPsyFollowUpService.create(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_PSY_FOLLOW_UP_CREATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addFollowUpEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_PSY_FOLLOW_UP_NOT_CREATED" });
        });
    }

    update(data) {
        this.visitorPsyFollowUpService.update(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_PSY_FOLLOW_UP_UPDATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addFollowUpEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_PSY_FOLLOW_UP_NOT_UPDATED" });
        });
    }
}
