import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
    providedIn: 'root'
})
export class VisitorPsyFollowUpService extends ApiService {
    getById(id: number) {
        return super.get('/visitors-psy-follow-up/' + id);
    }

    getByVisitors(data: any) {
        return super.post('/visitors-psy-follow-up/visitors', data);
    }

    create(data: any) {
        return super.post('/visitors-psy-follow-up/create', data);
    }

    update(data: any) {
        return super.put('/visitors-psy-follow-up/update', data);
    }

    delete(id: number) {
        return super.delete('/visitors-psy-follow-up/' + id);
    }
}
