
import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
    providedIn: 'root'
})
export class SettingsService extends ApiService {
    getAll() {
        return super.get('/settings');
    }

    getByKey(key: string) {
        return super.get('/setting/' + key);
    }

    getAllEditable() {
        return super.get('/settings/editable');
    }

    getPasswordLength() {
        return super.get('/settings/password');
    }

    update(data: any) {
        return super.put('/settings', data);
    }
}
