import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-text-limit',
    templateUrl: './text-limit.component.html',
})
export class TextLimitComponent implements OnInit {
    @Input() text: string = null;
    @Input() limit: number = 20;
    @Input() trail: string = "...";
    @Input() title: string = "TEXT";

    randId = Math.floor(Math.random() * 10000);
    modal = "#textLimitModal-" + this.randId;
    showText = null;
    higherThanLimit = false;

    ngOnInit() {
        if(this.text) {
            this.text = this.text.trim();
            this.higherThanLimit = this.text.length > this.limit;
            this.showText = this.higherThanLimit ? this.text.substring(0, this.limit) + this.trail : this.text;
		}
    }

    openModal() {
        $(this.modal).modal("show");
    }
}
