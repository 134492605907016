import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MiscService, VisitorPsychoEntryService } from '@services/index';

declare var swal: any;

@Component({
    selector: 'app-util-visitor-parts-psycho-entry-list',
    templateUrl: './list.component.html',
})
export class UtilVisitorPartsPsychoEntryListComponent implements OnInit {
    @Input() visitor: any = null;
    @Input() manageEntries = true;      // Not a permission, used to show or not buttons
    @Output() updateEvent = new EventEmitter();

    isLoading = true;
    isLoadingVisitors = false;
    selfVisitor = false;
    filter: any = {
        date_begin: null,
        date_end: null
    };
    limit = 20;
    page = 1;
    visitorPsychoEntries: any;
    hasData = {
        visitorPsychoEntries: false
    };

    constructor(private cdref: ChangeDetectorRef,
                private miscService: MiscService,
                private visitorPsychoEntryService: VisitorPsychoEntryService) {}

    ngOnInit() {
        this.miscService.can("getVisitorPsychoEntry");
        this.isLoading = true;
        this.selfVisitor = this.miscService.checkDataLength(this.visitor) ? true : false;
        this.getVisitorPsychoEntries();
        this.cdref.detectChanges();
    }

    filterVisitorPsychoEntries(filter = null) {
        this.filter = filter;
        this.getVisitorPsychoEntries(true);
    }

    getVisitorPsychoEntries(hasFilter = false) {
        this.isLoading = true;
        let data = this.filter;
        data.visitor_ids = this.selfVisitor ? [this.visitor.id] : (data.visitor_ids && data.visitor_ids.length ? data.visitor_ids : null);
        data.limit = this.limit;
        data.page = !hasFilter ? this.page : 1;     // if user has filtered, then reset page to 1 (number of pages can change due to filters)
        this.visitorPsychoEntryService.getByVisitors(data).subscribe(data => {
            this.visitorPsychoEntries = data;
            this.hasData.visitorPsychoEntries = this.miscService.checkDataLength(this.visitorPsychoEntries.data);
            this.isLoading = false;
        }, error => {
            this.visitorPsychoEntries = null;
            this.hasData.visitorPsychoEntries = false;
            this.miscService.showMsg({ msg: "VISITOR_PSYCHO_ENTRIES_NOT_FOUND_PLEASE_CHECK_FILTERS" });
            this.isLoading = false;
        });
    }

    getInterviewType(interviewType) {
        if(interviewType) {
            if(interviewType == "formal") {
                return this.miscService._t("VISITORS_PSYCHO.FORMAL");
            }
            else {
                return this.miscService._t("VISITORS_PSYCHO.INFORMAL");
            }
        }

        return "-";
    }
    
    addPsychoEntry() {
        this.getVisitorPsychoEntries();
        this.updateEvent.emit();
    }

    switchPage(page) {
        this.page = page;
        this.getVisitorPsychoEntries();
    }

    delete(visitorIPsychoEntry) {
        let self = this;
        let title = this.miscService._t('VISITORS_PSYCHO.DELETE_ENTRY') + " ?";
        swal({
            title: title,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.miscService._t('SHARED.CONFIRM'),
            cancelButtonText: this.miscService._t('SHARED.CANCEL'),
            closeOnConfirm: true,
            closeOnCancel: true,
        }, function(isConfirm) {
            if(isConfirm) {
                self.visitorPsychoEntryService.delete(visitorIPsychoEntry.id).subscribe(data => {
                    self.getVisitorPsychoEntries(true);  // resets page to 1 (in case of there is only 1 visitor psycho entry on the current page)
                    self.miscService.showMsg({ msg: "VISITOR_PSYCHO_ENTRY_DELETED", status: "success" });
                    self.updateEvent.emit();
                }, error => {
                    self.miscService.showMsg({ msg: "VISITOR_PSYCHO_ENTRY_NOT_DELETED" });
                });
            }
        });
    }
}
