import { Injectable } from '@angular/core';
import { LocalStorageService } from '@services/index';

declare var moment:any;

Injectable()

@Injectable()
export class Globals{
    constructor(private localStorageService: LocalStorageService) {}

	getCurrentLocale = () => {
		let lang = this.localStorageService.getLSItem('lang');

		return moment.locale(lang);
	};

	pickerConfig:any = {
		icons: {
			time: "fa fa-clock-o",
			date: "fa fa-calendar",
			up: "fa fa-chevron-up",
			down: "fa fa-chevron-down",
			previous: 'fa fa-chevron-left',
			next: 'fa fa-chevron-right',
			today: 'fa fa-screenshot',
			clear: 'fa fa-trash',
			close: 'fa fa-remove'
		},
        format: "DD/MM/YYYY HH:mm",
		sideBySide: true,
		defaultDate: false
	};

	dateOnlyPickerConfig:any = {
		icons: {
			time: "fa fa-clock-o",
			date: "fa fa-calendar",
			up: "fa fa-chevron-up",
			down: "fa fa-chevron-down",
			previous: 'fa fa-chevron-left',
			next: 'fa fa-chevron-right',
			today: 'fa fa-screenshot',
			clear: 'fa fa-trash',
			close: 'fa fa-remove'
		},
		locale: this.getCurrentLocale(),
		sideBySide: true,
		format: "DD/MM/YYYY",
		defaultDate: false
	};

    formatDateFrench = "DD/MM/YYYY";
    formatDateFrenchWithHours = "DD/MM/YYYY HH:mm";
    formatDateSql = "YYYY-MM-DD";
    formatDateSqlWithHours = "YYYY-MM-DD HH:mm";

	// Extensions acceptées pour les uploads de fichier
	extensions: any = [
		"jpg", "jpeg", "gif", "png", "bmp", "pdf", "doc", "docx", "xls", "xlsx", "xlsm", "csv"
	];

    choiceGroupsValues = {
        countries: ['belg', 'EU', 'NEU']
    };

    defaultOrderColumns = {
        daily_entries: [
            'entry', 'shower', 'laundry', 'infirmary', 'psy', 'dispensary', 'pedicure', 'social_work', 'underpants', 'socks', 't_shirt',
            'trousers', 'pull', 'jacket', 'shoes', 'blanket', 'hairdresser', 'scarf_hat_gloves', 'other_clothes', 'instruction_id',
            'instruction_paper_id', 'miscellaneous'
        ]
    };
}
