import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
    providedIn: 'root'
})
export class ChoiceService extends ApiService {
    getByGroup(id: number) {
        return super.get('/choices/group/' + id);
    }

    getByKey(key: string) {
        return super.post('/choices/key/' + key);
    }

    searchByGroup(data: any) {
        return super.post('/choices/group/search', data);
    }

    create(data: any) {
        return super.post('/choices/create', data);
    }

    createMultiple(data: any) {
        return super.post('/choices/create/multiple', data);
    }

    update(data: any) {
        return super.put('/choices/update', data);
    }

    delete(id: number) {
        return super.delete('/choices/' + id);
    }
}
