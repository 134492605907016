
import {map} from 'rxjs/operators';
import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MiscService, AuthenticationService, LanguageService, SettingsService } from '@services/index';
import { LocalStorageService } from '@services/localStorage.service';
import { PageTitleService } from '@services/pageTitle.service';
import { NightModeService } from '@services/nightMode.service';
import { environment } from '@env/environment';

var misc:any ={
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
}
declare var $: any;

@Component({
    moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit, AfterViewInit {
    isLoading = false;
    private listTitles: any[];
    location: Location;
    private nativeElement: Node;
    private toggleButton;
    public absoluteSearch: any = {};
    public displayAutocompleteResults: boolean = false;
    public pageTitle:string;
    public companyID:number;
    public isNightMode: any;
    public isMiniSidebar: boolean = false;
    public env = environment;
    public canResetDb = false;
    public editAdmin = false;
    public currentUserData:any;
    public settingsData = {
        company_id: null
    };
    public companyData:any = {
        name: "Udoo"
    };
    notifNumber: number;

    searchLoading: boolean = false;
    languages: any[];

    @ViewChild("navbar-cmp", { static: false }) button;
    languageModalId: string = this.miscService.uuid();

    constructor(location:Location,
                private cdref: ChangeDetectorRef,
                private renderer : Renderer2,
                private element : ElementRef,
                private miscService: MiscService,
                private settingsService: SettingsService,
                private localStorageService: LocalStorageService,
                private router: Router,
                private authenticationService: AuthenticationService,
                private languageService: LanguageService,
                private pageTitleService: PageTitleService,
                private nightModeService: NightModeService) {
        this.location = location;
        this.nativeElement = element.nativeElement;
    }

    ngOnInit() {
        this.listTitles = ROUTES.filter(listTitle => listTitle);

        this.isLoading = true;
        this.languageService.getAll().subscribe(data => {
            this.languages = data;
            this.isLoading = false;
        });

        this.editAdmin = this.miscService.checkEnvPermission("editAdmin");

        if($('body').hasClass('sidebar-mini')){
            misc.sidebar_mini_active = true;
        }

        this.isNightMode = this.localStorageService.getLSItem('isNightMode') === "true";
        this.isMiniSidebar = this.localStorageService.getLSItem('sidebarMini') === "true";

        const c = this.localStorageService.getLSItem('company');
        this.companyID = c && c.id ? c.id : undefined;
        this.cdref.detectChanges();
    }

    ngAfterViewInit(){
        var navbar : HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
        $("body").click(function(){
            this.displayAutocompleteResults = false;
            $(".autocomplete").hide();
        })
    }

    strToUppercase(str) {
        return str.toUpperCase();
    }

    setSettings() {
        this.currentUserData = this.localStorageService.getLSItem("currentUser");
        this.getAllSettings().subscribe(data => {
            let settings: any = {};
            for(let d of data) {
                settings[d.key] = {id: d.id, value: d.value};
            }

            this.localStorageService.setLSItem("settings", settings);
        });
    }

    switchTheme(e){
        this.isNightMode = e;
        this.nightModeService.changeMode(e);
    }

    goBack() {
		this.location.back();
	}

    search(term){
        /* Call search api */
        if (term.length >= 3){
            $(".autocomplete").show();
            let doc_prefixes = ['quo', 'po', 'prepo', 'inv', 'ino', 'dn', 'cn', 'quo-tpl'];
            let prematch = false;
            for (let prefix of doc_prefixes){
                let match = new RegExp('^' + prefix + '_', 'i')
                if (term.match(match)){
                    this.displayAutocompleteResults = true;
                    this.absoluteSearch.result = {};
                    this.absoluteSearch.result.documents = [];
                    this.absoluteSearch.result.documents[0] = {};

                    this.absoluteSearch.result.documents[0].doc_number = term;
                    prematch = true;
                }
            }
        } else {
            this.displayAutocompleteResults = false;
        }
    }

    get notResultFound() {
        if (this.searchLoading === false) {
            if (this.absoluteSearch.result) {
                let findResult = false;
                let keys = Object.keys(this.absoluteSearch.result);
                for (let key of keys) {
                    if (this.absoluteSearch.result[key].length > 0) {
                        findResult = true;
                    }
                }
                return !findResult;
            }
        }
        return false;
    }

    displayAutocomplete(display = true){
        this.displayAutocompleteResults = display;
    }

    isMobileMenu() {
        if($(window).width() < 991){
            return false;
        }
        return true;
    }

    sidebarOpen() {
        var toggleButton = this.toggleButton;
        var body = document.getElementsByTagName('body')[0];
        toggleButton.classList.add('toggled');
        body.classList.add('nav-open');
    }

    sidebarClose() {
        var body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        body.classList.remove('nav-open');
    }

    sidebarToggle() {
        if(!this.toggleButton.classList.contains('toggled')){
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }

    getTitle() {
        this.pageTitleService.currentTitle.subscribe(title => {
            this.pageTitle = title;
        });

        return this.pageTitle;
    }

    logout() {
        this.authenticationService.logout();
    }

    getPath() {
        return this.location.prepareExternalUrl(this.location.path());
    }

    switchMini($event) {
        if(misc.sidebar_mini_active == true) {
            $('body').removeClass('sidebar-mini');
            misc.sidebar_mini_active = false;
            this.localStorageService.setLSItem('sidebarMini', "false");

        }
        else{
            $('.sidebar .collapse').collapse('hide').on('hidden.bs.collapse',function() {
                $(this).css('height','auto');
            });

            this.localStorageService.setLSItem('sidebarMini', "true");
            setTimeout(function(){
                $('body').addClass('sidebar-mini');

                $('.sidebar .collapse').css('height','auto');
                misc.sidebar_mini_active = true;
            },300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        var simulateWindowResize = setInterval(function(){
            window.dispatchEvent(new Event('resize'));
        },180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function(){
            clearInterval(simulateWindowResize);
        },1000);
    }

    private getAllSettings() {
        return this.settingsService.getAll().pipe(map(data => data));
    }

    getFlagForLang() {
        const localLang = this.localStorageService.getLSItem('lang');
        for(let lang of this.languages) {
            if(localLang == lang.code) {
                return lang.code.toUpperCase();
            }
        }

        this.miscService.setLang('fr');

        return 'FR';
    }

    openLanguageModal() {
        $(`#${this.languageModalId}`).appendTo('body').modal('show');
    }

    setLang(code: string) {
        this.miscService.setLang(code);
        this.miscService.setUserLanguage(code);
        $(`#${this.languageModalId}`).modal('hide');
    }
}
