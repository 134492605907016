import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Globals } from '@app/app.globals';

@Component({
    selector: 'app-util-visitor-parts-follow-up-list-filter',
    templateUrl: './filter.component.html',
})
export class UtilVisitorPartsFollowUpListFilterComponent implements OnInit {
    @Input() selfVisitor = false;
    @Output() filterEvent = new EventEmitter<any>();

    showFilters = true;
    pickerConfig: any;
    searchForm = this.fb.group({
        visitor_ids: [],
        date_begin: null,
        date_end: null
    });

    constructor(public fb: UntypedFormBuilder,
                public globals: Globals) {}

    @ViewChild('datePickerBegin') datePickerBegin;
    @ViewChild('datePickerEnd') datePickerEnd;

    ngOnInit() {
        this.pickerConfig = JSON.parse(JSON.stringify(this.globals.pickerConfig));
    }

    toggleShowFilters() {
        this.showFilters = !this.showFilters;
    }

    visitorChanged(evt) {
        this.searchForm.get('visitor_ids').patchValue(evt);
    }

    dateChanged(evt, field) {
        if(evt) {
            let date = evt;
            this.searchForm.patchValue({
                [field]: date
            });
        }
        else {
            this.searchForm.get(field).reset();
        }
	}

    get filledForm() {
        let check = false;
        Object.keys(this.searchForm.controls).forEach(key => {
            let value = this.searchForm.value[key];
            /**
             * form is considered filled if any value is filled
             * except for visitor_ids field:
             *      - it is filled only if not self visitor and if it has value
             */
            if((key != "visitor_ids" && value) || (key == "visitor_ids" && !this.selfVisitor && value !== null && value.length)) {
                check = true;
            }
        });

        return check;
    }

    reset() {
        this.searchForm.reset();
        this.datePickerBegin.resetDate();
        this.datePickerEnd.resetDate();
        this.submit();
    }

    submit() {
        let data = this.searchForm.value;
        this.filterEvent.emit(data);
    }
}
