export class User {
    id: number;
    name: string;
    firstname: string;
    niss: string;
    title: string;
    phone: string;
    mobilephone: string;
    birthdate: string;
    email: string;
    nqCompany: string[];
    identifier: string;
    api_token: string;
    days_work: number[];
}
