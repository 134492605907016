<div aria-labelledby="addChoiceModalLabel" class="modal modal-big fade" id="addChoiceModal-{{ randId }}" role="dialog" tabindex="-1" style="display: none; z-index: 9999;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
                <h4 class="modal-title" *ngIf="hasData?.choiceGroup">
                    {{ 'CHOICES.ADD_CHOICES_TO_GROUP' | translate: { name: choiceGroup?.name } }}
                </h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="addForm" #aForm="ngForm" class="form-horizontal" (submit)="submit()">
                    <div>
                        <h5 class="title align-items-center justify-content-left mb-2">
                            <span>{{ 'CHOICES.CHOICE' | translate }}</span>
                            <button type="button"
                                    class="btn btn-success btn-simple ml-2 p-0"
                                    title="{{ 'CHOICES.ADD_CHOICE' | translate }}"
                                    (click)="addChoice()"
                                    appTooltip>
                                <i class="fa fa-plus"></i>
                            </button>
                        </h5>
                    </div>
                    
                    <ng-container formArrayName="choices">
                        <ng-container *ngFor="let choice of addForm?.get('choices')?.controls; let i = index">
                            <ng-container [formGroupName]="i">
                                <div class="card">
                                    <div class="content">
                                        <div class="align-items-center justify-content-left mb-2">
                                            <strong>{{ 'CHOICES.CHOICE' | translate }} #{{ i + 1 }}</strong>
                                            <button type="button"
                                                    class="btn btn-danger btn-simple ml-2 p-0"
                                                    title="{{ 'CHOICES.DELETE_CHOICE' | translate }}"
                                                    (click)="deleteChoice(i)"
                                                    appTooltip
                                                    *ngIf="i">
                                                <i class="fa fa-minus"></i>
                                            </button>
                                        </div>
                                        <div class="form-group">
                                            <div class="col-md-3">
                                                <label class="control-label control-label-sm" for="name">
                                                    {{ "CHOICES.NAME" | translate }}<span class="star">*</span>
                                                </label>
                                            </div>
                                            <div class="col-md-9">
                                                <input type="text"
                                                        id="name"
                                                        class="form-control input-sm"
                                                        formControlName="name" />
                                            </div>
                                        </div>
                                        <div class="form-group" *ngIf="choice?.value?.data_type">
                                            <div class="col-md-3">
                                                <label class="control-label control-label-sm" for="data">
                                                    {{ "CHOICES.VALUE" | translate }}<span class="star">*</span>
                                                </label>
                                            </div>
                                            <div class="col-md-9">
                                                <ng-container *ngIf="choice?.value?.data_type == 'text'">
                                                    <input type="text"
                                                            id="data"
                                                            class="form-control input-sm"
                                                            formControlName="data" />
                                                </ng-container>
                                                <ng-container *ngIf="choice?.value?.data_type == 'select'">
                                                    <select class="form-control input-sm" id="data" formControlName="data">
                                                        <option value="{{ value }}" *ngFor="let value of dataValues; let i = index">
                                                            {{ value }}
                                                        </option>
                                                    </select>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="form-group" *ngIf="choiceGroup?.key == 'countries'">
                                            <div class="col-md-3">
                                                <label for="name" class="control-label control-label-sm">
                                                    {{ 'CHOICES.CONTINENT' | translate }}<span class="star">*</span>
                                                </label>
                                            </div>
                                            <div class="col-md-9">
                                                <select class="form-control input-sm" id="data" formControlName="choice_id">
                                                    <option value="{{ continent?.id }}" *ngFor="let continent of continents; let i = index">
                                                        {{ continent?.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <div class="form-group">
                        <div class="col-md-12">
                            <button type="submit"
                                    class="btn"
                                    [ngClass]="{'btn-default':isSubmitting, 'btn-success': !isSubmitting}"                                                        
                                    [disabled]="!aForm.form.valid || isSubmitting">
                                <i class="fa"
                                    [ngClass]="{'fa-plus': !isSubmitting,
                                                'fa-spinner fa-spin': isSubmitting}"></i> {{ 'CHOICES.ADD_CHOICES' | translate }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>