import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MiscService, VisitorMedicalService } from '@services/index';

@Component({
    selector: 'app-util-visitor-parts-medical-infirmary-modals-add',
    templateUrl: './add.component.html',
})
export class UtilVisitorPartsMedicalInfirmaryModalsAddComponent implements OnInit {
    @Output() addMedicalEvent = new EventEmitter<any>();

    isLoading = false;
    isSubmitting = false;
    modal = "#addMedicalModal";
    cud = "add";
    addForm = this.fb.group({
        id: null,
        visitor_id: [null, Validators.required],
        infirmary_comment: false,
        alcohol_addiction: false,
        drugs_addiction: false,
        pharmaceuticals_addiction: false,
        alcohol_addiction_remark: null,
        drugs_addiction_remark: null,
        pharmaceuticals_addiction_remark: null
    });
    hasData = {
        visitorMedical: false
    };

    constructor(private cdref: ChangeDetectorRef,
                private fb: UntypedFormBuilder,
                private miscService: MiscService,
                private visitorMedicalService: VisitorMedicalService) {}

    ngOnInit() {
        this.cdref.detectChanges();
    }

    reset() {
        this.addForm.reset();
        this.hasData.visitorMedical = false;
        this.cud = "add";
    }
    
    openModal(visitorId, visitorMedical = null) {
        if(this.miscService.checkEnvPermission("cudVisitorMedical")) {
            this.isLoading = true;
            this.reset();
            this.hasData.visitorMedical = this.miscService.checkDataLength(visitorMedical);
            this.addForm.get('visitor_id').patchValue(visitorId);
            if(this.hasData.visitorMedical) {
                this.cud = "edit";
                this.addForm.patchValue(visitorMedical);
            }
            this.isLoading = false;
            $(this.modal).modal('show');
        }
    }

    submit() {
        this.isSubmitting = true;
        let data = this.addForm.value;
        data.from = "infirmary";
        if(this.cud == "add") {
            this.create(data);
        }
        else {
            this.update(data);
        }
    }

    create(data) {
        this.visitorMedicalService.create(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_MEDICAL_DATA_CREATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addMedicalEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_MEDICAL_DATA_NOT_CREATED" });
        });
    }

    update(data) {
        this.visitorMedicalService.update(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_MEDICAL_DATA_UPDATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addMedicalEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_MEDICAL_DATA_NOT_UPDATED" });
        });
    }
}
