import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { AuthService } from '@services/auth-service.service';
import { AuthenticationService, LocalStorageService } from '@services/index';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(public auth: AuthService,
                public router: Router,
                private authService: AuthenticationService,
                private localStorageService: LocalStorageService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let lang = this.localStorageService.getLSItem('lang');

        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.auth.getToken()}`,
                'Accept-Language': lang,
            },
        });
        return next
            .handle(request).pipe(
                tap((ev: HttpEvent<any>) => {
                    if (ev instanceof HttpResponse) {
                        //
                    }
                }),
                catchError(response => {
                    if (response instanceof HttpErrorResponse) {
                        if (response.status == 401) {
                            this.authService.logout();
                        }
                    }

                    return observableThrowError(response);
                }),);
    }
}
