
import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
    providedIn: 'root'
})
export class StatisticsService extends ApiService {
    getAll(data: any) {
        return super.post('/statistics', data);
    }

    export(data: any) {
        return super.post('/statistics/export', data);
    }
}
