import { Pipe, PipeTransform } from '@angular/core';
import { AsYouType, parse } from 'libphonenumber-js';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(value: string, args?: string): any {
    if (!value) return null;

    let formatted = new AsYouType().input(value);
    let country = parse(value).country ? " (" + parse(value).country + ")" : "";

    //return `${formatted} (${country})`;
    return formatted + country;
  }

}
