import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MiscService, VisitorObservationService } from '@services/index';

declare var swal: any;

@Component({
    selector: 'app-util-visitor-parts-observation-list',
    templateUrl: './list.component.html',
})
export class UtilVisitorPartsObservationListComponent implements OnInit {
    @Input() visitor: any = null;
    @Output() updateEvent = new EventEmitter();

    isLoading = true;
    isLoadingVisitors = false;
    selfVisitor = false;
    filter: any = {
        date_begin: null,
        date_end: null
    };
    limit = 20;
    page = 1;
    visitorObservations: any;
    hasData = {
        visitorObservations: false
    };

    constructor(private cdref: ChangeDetectorRef,
                private miscService: MiscService,
                private visitorObservationService: VisitorObservationService) {}

    ngOnInit() {
        this.miscService.can("getVisitorObservation");
        this.isLoading = true;
        this.selfVisitor = this.miscService.checkDataLength(this.visitor) ? true : false;
        this.getVisitorsObservations();
        this.cdref.detectChanges();
    }

    filterVisitorObservations(filter = null) {
        this.filter = filter;
        this.getVisitorsObservations(true);
    }

    getVisitorsObservations(hasFilter = false) {
        this.isLoading = true;
        let data = this.filter;
        data.visitor_ids = this.selfVisitor ? [this.visitor.id] : (data.visitor_ids && data.visitor_ids.length ? data.visitor_ids : null);
        data.limit = this.limit;
        data.page = !hasFilter ? this.page : 1;     // if user has filtered, then reset page to 1 (number of pages can change due to filters)
        this.visitorObservationService.getByVisitors(data).subscribe(data => {
            this.visitorObservations = data;
            this.hasData.visitorObservations = this.miscService.checkDataLength(this.visitorObservations.data);
            this.isLoading = false;
        }, error => {
            this.visitorObservations = null;
            this.hasData.visitorObservations = false;
            this.miscService.showMsg({ msg: "VISITOR_OBSERVATIONS_NOT_FOUND_PLEASE_CHECK_FILTERS" });
            this.isLoading = false;
        });
    }
    
    addObservation() {
        this.getVisitorsObservations();
        this.updateEvent.emit();
    }

    switchPage(page) {
        this.page = page;
        this.getVisitorsObservations();
    }

    delete(visitorObservation) {
        let self = this;
        let title = this.miscService._t('VISITORS_MEDICAL.DELETE_OBSERVATION') + " ?";
        swal({
            title: title,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.miscService._t('SHARED.CONFIRM'),
            cancelButtonText: this.miscService._t('SHARED.CANCEL'),
            closeOnConfirm: true,
            closeOnCancel: true,
        }, function(isConfirm) {
            if(isConfirm) {
                self.visitorObservationService.delete(visitorObservation.id).subscribe(data => {
                    self.getVisitorsObservations(true);  // resets page to 1 (in case of there is only 1 visitor observation on the current page)
                    self.miscService.showMsg({ msg: "VISITOR_OBSERVATION_DELETED", status: "success" });
                    self.updateEvent.emit();
                }, error => {
                    self.miscService.showMsg({ msg: "VISITOR_OBSERVATION_NOT_DELETED" });
                });
            }
        });
    }
}
