import { Component, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MiscService, ChoiceGroupService, VisitorAppointmentService } from '@services/index';
import { Globals } from '@app/app.globals';

@Component({
    selector: 'app-util-visitor-parts-appointment-modals-add',
    templateUrl: './add.component.html',
})
export class UtilVisitorPartsAppointmentModalsAddComponent implements OnInit {
    @Output() addAppointmentEvent = new EventEmitter<any>();

    isLoading = false;
    isSubmitting = false;
    modal = "#addAppointmentModal";
    cud = "add";
    pickerConfig: any;
    places: any;
    attendants: any;
    visitorAppointment: any;
    visitorId: number;
    addForm = this.fb.group({
        id: null,
        visitor_id: [null, Validators.required],
        date: [null, Validators.required],
        place_id: null,
        attendant_id: null,
        consulted_professional: null,
        remark: null
    });
    hasData = {
        visitorAppointment: false
    };

    @ViewChild('datePicker') datePicker;
    @ViewChild('ngSelectAddAppointmentDataSinglePlace') ngSelectAddAppointmentDataSinglePlace;
    @ViewChild('ngSelectAddAppointmentDataSingleAttendant') ngSelectAddAppointmentDataSingleAttendant;

    constructor(private cdref: ChangeDetectorRef,
                private fb: UntypedFormBuilder,
                private miscService: MiscService,
                private choiceGroupService: ChoiceGroupService,
                private visitorAppointmentService: VisitorAppointmentService,
                public globals: Globals) {
        this.miscService.setPageTitle("TITLES.DAILY_ENTRY_APPOINTMENTS");
    }

    ngOnInit() {
        this.pickerConfig = JSON.parse(JSON.stringify(this.globals.pickerConfig));
        this.cdref.detectChanges();
    }

    reset() {
        this.addForm.reset();
        this.resetDate();
        this.visitorId = null;
        this.cud = "add";
    }
    
    openModal(visitorId = null, visitorAppointment = null) {
        if(this.miscService.checkEnvPermission("cudVisitorDailyEntry")) {
            this.isLoading = true;
            this.visitorAppointment = visitorAppointment;
            this.hasData.visitorAppointment = this.miscService.checkDataLength(this.visitorAppointment);
            this.reset();
            this.visitorId = visitorId;
            this.getPlaces();
            this.getAttendants();
            if(this.visitorId) {
                this.addForm.get('visitor_id').patchValue(visitorId);
            }
            if(this.hasData.visitorAppointment) {
                this.cud = "edit";
                this.addForm.patchValue(this.visitorAppointment);
                if(this.visitorAppointment.date) {
                    this.datePicker.setDate(this.visitorAppointment.date);
                }
            }
            this.isLoading = false;
            $(this.modal).modal('show');
        }
    }

    goToAddVisitorUrl() {
        $(this.modal).modal('hide');
        this.miscService.goToUrlDelay(['/visitor/add']);
    }

    changeVisitor(value) {
        this.addForm.get('visitor_id').patchValue(value ? value : null);
    }

    changeChoice(field, value) {
        this.addForm.get(field).patchValue(value ? value.id : null);
    }

    getPlaces() {
        this.choiceGroupService.getByKey("places").subscribe(data => {
            this.places = data;
        });
    }

    getAttendants() {
        this.choiceGroupService.getByKey("attendants").subscribe(data => {
            this.attendants = data;
        });
    }

    dateChanged(evt, field) {
        if(evt) {
            let date = evt;
            this.addForm.get(field).patchValue(date);
        }
        else {
            this.resetField(field);
        }
	}

    resetDate() {
        this.datePicker.resetDateNow();
    }

    resetField(field) {
        this.addForm.get(field).reset();
    }

    submit() {
        this.isSubmitting = true;
        let data = this.addForm.value;
        if(this.cud == "add") {
            this.create(data);
        }
        else {
            this.update(data);
        }
    }

    create(data) {
        this.visitorAppointmentService.create(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_APPOINTMENT_CREATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addAppointmentEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_APPOINTMENT_NOT_CREATED" });
        });
    }

    update(data) {
        this.visitorAppointmentService.update(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_APPOINTMENT_UPDATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addAppointmentEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_APPOINTMENT_NOT_UPDATED" });
        });
    }
}
