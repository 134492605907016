<div class="modal fade" id="{{ forId }}" role="dialog" tabindex="-1" style="display: none; z-index: 9999;">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button" (click)="close()"><span aria-hidden="true">×</span></button>
                <h4 class="modal-title">Prendre une photo</h4>
            </div>
            <div class="modal-body justify-content-center">
                <webcam [imageQuality]="1" [allowCameraSwitch]="true" [trigger]="triggerObservable()" (imageCapture)="save($event)" *ngIf="showCamera"></webcam>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" class="btn btn-success" (click)="triggerCapture()">
                    <i class="fa fa-camera"></i> Prende une photo
                </button>
            </div>
        </div>
    </div>
</div>
