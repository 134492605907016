import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '@services/index';

declare var $:any;
declare var swal: any;

@Component({
    selector: 'footer-cmp',
    templateUrl: 'footer.component.html'
})

export class FooterComponent implements OnInit{
    test : Date = new Date();
    public version: string;
    public updateDate: string;
    public changeLog:any = {};

    constructor(private localStorageService: LocalStorageService) {}

    ngOnInit(){
        this.initVer();
        if(this.localStorageService.getLSItem('okBrowser') !== true) {
            this.checkBrowser();
        }
    }

    public initVer(){
        let version = this.localStorageService.getRealLSItem("version");
        this.version = version ? version : "1.0";
        let lastUpdate = this.localStorageService.getRealLSItem("last_update");
        this.updateDate = lastUpdate ? lastUpdate : "01/01/2024" ;
    }

    public arrayUnique(array) {
        var a = array.concat();
        for(var i=0; i<a.length; ++i) {
            for(var j=i+1; j<a.length; ++j) {
                if(a[i] === a[j])
                    a.splice(j--, 1);
            }
        }

        return a;
    }

    private checkBrowser(){
        let userAgent = "";

        if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
            userAgent = "opera";
        } else if (navigator.userAgent.indexOf("Chrome") != -1 ) {
            userAgent = "chrome";
        } else if (navigator.userAgent.indexOf("Safari") != -1) {
            userAgent = "safari";
        } else if (navigator.userAgent.indexOf("Firefox") != -1 ) {
            userAgent = "firefox";
        } else if (navigator.userAgent.indexOf("MSIE") != -1 ) {
            userAgent = "IE";
        } else {
            userAgent = "unknown";
        }

        if (userAgent != "firefox" && userAgent != "chrome"){
            swal({
                title: "Attention!",
                type: "warning",
                text: "Ce logiciel est optimisé pour Chrome et Firefox, sur d'autres navigateurs, il est possible que certains éléments ne s'affichent pas correctement. Pour un résultat optimal, téléchargez l'un de ces deux navigateurs."
            },function(){
                this.localStorageService.setLSItem('okBrowser', "true");
            });
        }
    }
}
