import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {UserService} from '@app/_services';
import {environment} from '@env/environment';

@Pipe({
    name: 'file'
})

export class FilePipe implements PipeTransform {

    constructor(private userService: UserService) {}

    transform(file: any): string {
        if(file) {
            const filesToken = this.userService.getFilesTokens;
            if(filesToken) {
                const queryParamsString = `?kol=${filesToken.kol}&aar=${filesToken.aar}&fel=${filesToken.fel}`;
                let url = "";
                if(Array.isArray(file)) {
                    url = `${environment.baseUrl}file-token/${file[0].id}/${file[0].path}${queryParamsString}`;
                }
                else {
                    url = `${environment.baseUrl}file-token/${file.id}/${file.path}${queryParamsString}`;
                }

                return url;
            }
        }

        return '---';
    }
}
