import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { MiscService } from '@app/_services';

@Directive({
    selector: '[appReadableColor]',
})
export class ReadableColorDirective implements OnInit {

    @Input() appReadableColor: string;

    constructor(
        private miscService: MiscService,
        private element: ElementRef,
    ) {
    }

    ngOnInit(): void {
        this.element.nativeElement.style.color = this.miscService.readableColorFromHex(this.appReadableColor);
    }

}
