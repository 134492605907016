import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OptionService } from '@app/_services';
import { SoftwareService } from '@services/software.service';

@Component({
    selector: 'app-input-file',
    templateUrl: './input-file.component.html',
    styleUrls: ['./input-file.component.scss'],
})
export class InputFileComponent implements OnInit {

    @ViewChild('inputFileInput', { static: true }) inputFileInput: ElementRef;

    @Output() onFileChanged: EventEmitter<any[]> = new EventEmitter<any[]>()
    @Input() multiple: boolean = false;
    @Input() pictures: boolean = false;
    @Input() editable: boolean = false;
    @Input() showInput: boolean = true;
    @Input() accept: any[];

    randId = Math.ceil(Math.random() * 10000);
    showInputBlock = "#showInputBlock-" + this.randId;
    files: any[] = [];

    constructor(
        private softwareService: SoftwareService,
    ) {
    }

    ngOnInit(): void {
        this.multiple = this.multiple != undefined && this.multiple !== false;
        this.pictures = this.pictures != undefined && this.pictures !== false;
        this.editable = this.editable != undefined && this.editable !== false;

        if (!this.accept) {
            this.softwareService.currentSoftware.subscribe(software => {
                const formats = software?.optionValue('authorized_formats');

                if (formats) {
                    this.accept = formats.map(format => `.${format.toLowerCase()}`)
                }
            });
        }
    }

    toggleShowInputBlock() {
        this.showInput = !this.showInput;
    }

    fileChanged(files: any[]) {
        if(this.multiple) {
            for(const file of files) {
                if(file.size > 0 &&
                    (!this.accept || this.accept.length === 0 || this.accept.includes(`.${this.getExtension(file.name)}`))) {
                    const find = this.files.find(item => item.name === file.name && item.lastModified === file.lastModified && item.size === file.size);
                    if(!find) {
                        if(this.editable) {
                            file.new_name = this.getFileName(file.name);
                        }
                        this.files.push(file);
                    }
                }
            }
        }
        else {
            const file = files[0];
            if(!this.accept || this.accept.length == 0 || this.accept.includes(`.${this.getExtension(file.name)}`)) {
                if(this.editable) {
                    file.new_name = this.getFileName(file.name);
                }
                this.files = [file];
            }
        }
        this.emit();
        if(this.inputFileInput) {
            this.inputFileInput.nativeElement.value = "";
        }
    }

    delete(index: number) {
        this.files.splice(index, 1);
        this.emit();
    }

    emit() {
        this.onFileChanged.emit(this.files);
    }

    formatBytes(bytes, decimals = 2) {
        if(bytes === 0) {
            return "0 Bytes";
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    getIcon(name: string) {
        const ext = this.getExtension(name);

        return `files/${ext}.png`;
    }

    getExtension(name: string) {
        const index = name.lastIndexOf('.');
        return name.substring(index + 1).toLowerCase();
    }

    getFileName(name: string) {
        const index = name.lastIndexOf('.');
        return name.substring(0, index).toLowerCase();
    }

    clear() {
        this.files = [];
        this.emit();
    }
}
