<div aria-labelledby="addEntryModalLabel" class="modal modal-big fade" id="addEntryModal" role="dialog" tabindex="-1" style="display: none; z-index: 9999;">
    <div class="modal-dialog width-900" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
                <h4 class="modal-title">
                    <ng-container *ngIf="cud == 'add'">
                        {{ "VISITORS_DAILY_ENTRIES.ADD_ENTRY" | translate }}
                    </ng-container>
                    <ng-container *ngIf="cud == 'edit'">
                        {{ "VISITORS_DAILY_ENTRIES.EDIT_ENTRY" | translate }}
                    </ng-container>
                </h4>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="hasData?.choiceGroupInstruction">
                    <form [formGroup]="addForm" #aForm="ngForm" class="form-horizontal" (submit)="submit()">
                        <fieldset>
                            <legend>{{ "VISITORS.GENERAL_INFORMATION" | translate }}</legend>
                            <div class="form-group" *ngIf="!visitorId && cud == 'add'">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="visitor_id" >
                                        {{ 'VISITORS_DAILY_ENTRIES.VISITOR' | translate }} <span class="star">*</span>
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <app-ng-select-add-visitor
                                        [canAddVisitors]="true"
                                        [multiple]="false"
                                        (changeEvent)="changeVisitor($event)"
                                        (goToAddVisitorUrlEvent)="goToAddVisitorUrl()"></app-ng-select-add-visitor>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="date">
                                        {{ "VISITORS_DAILY_ENTRIES.DATE" | translate }} <span class="star">*</span>
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <app-date-picker [datepickerConfig]="pickerConfig"
                                                    [id]="'date'"
                                                    [col]="null"
                                                    [withHours]="true"
                                                    (onDateChanged)="dateChanged($event, 'date')"
                                                    #datePicker>
                                    </app-date-picker>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm">
                                        {{ "VISITORS_DAILY_ENTRIES.INFORMATION" | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <div class="row m-0">
                                        <div class="col-md-4" style="padding-left: 6px;">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="entry"
                                                        formControlName="entry" />
                                                <label for="entry">
                                                    {{ "VISITORS_DAILY_ENTRIES.ENTRY" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ng-template *ngTemplateOutlet="btnAdd; context: {}"></ng-template>
                        </fieldset>
                        <fieldset>
                            <legend>{{ "VISITORS.SERVICES" | translate }}</legend>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm">
                                        {{ "VISITORS_DAILY_ENTRIES.INFORMATION" | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <div class="row m-0">
                                        <div class="col-md-4 align-items-center justify-content-left" style="padding-left: 6px;">
                                            <input type="number"
                                                    class="form-control input-sm"
                                                    style="width: 80px;"
                                                    id="number_showers"
                                                    formControlName="number_showers" />
                                            <label class="ml-2" for="shower">
                                                {{ "VISITORS_DAILY_ENTRIES.SHOWER" | translate }}
                                            </label>
                                        </div>
                                        <div class="col-md-4 align-items-center justify-content-left">
                                            <input type="number"
                                                    class="form-control input-sm"
                                                    style="width: 80px;"
                                                    id="number_laundries"
                                                    formControlName="number_laundries" />
                                            <label class="ml-2" for="laundry">
                                                {{ "VISITORS_DAILY_ENTRIES.LAUNDRY" | translate }}
                                            </label>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="infirmary"
                                                        formControlName="infirmary" />
                                                <label for="infirmary">
                                                    {{ "VISITORS_DAILY_ENTRIES.INFIRMARY" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="psy"
                                                        formControlName="psy" />
                                                <label for="psy">
                                                    {{ "VISITORS_DAILY_ENTRIES.PSY" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="dispensary"
                                                        formControlName="dispensary" />
                                                <label for="dispensary">
                                                    {{ "VISITORS_DAILY_ENTRIES.DISPENSARY" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="pedicure"
                                                        formControlName="pedicure" />
                                                <label for="pedicure">
                                                    {{ "VISITORS_DAILY_ENTRIES.MEDICAL_PEDICURE" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="hairdresser"
                                                        formControlName="hairdresser" />
                                                <label for="hairdresser">
                                                    {{ "VISITORS_DAILY_ENTRIES.HAIRDRESSER" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="social_work"
                                                        formControlName="social_work" />
                                                <label for="social_work">
                                                    {{ "VISITORS_DAILY_ENTRIES.SOCIAL_WORK" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="visit"
                                                        formControlName="visit" />
                                                <label for="visit">
                                                    {{ "VISITORS_DAILY_ENTRIES.VISIT" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>{{ "VISITORS_DAILY_ENTRIES.CHANGING_ROOM" | translate }}</legend>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm">
                                        {{ "VISITORS_DAILY_ENTRIES.INFORMATION" | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <div class="row m-0">
                                        <div class="col-md-4" style="padding-left: 6px;">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="underpants"
                                                        formControlName="underpants" />
                                                <label for="underpants">
                                                    {{ "VISITORS_DAILY_ENTRIES.UNDERPANTS" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="socks"
                                                        formControlName="socks" />
                                                <label for="socks">
                                                    {{ "VISITORS_DAILY_ENTRIES.SOCKS" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="t_shirt"
                                                        formControlName="t_shirt" />
                                                <label for="t_shirt">
                                                    {{ "VISITORS_DAILY_ENTRIES.T_SHIRT" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="trousers"
                                                        formControlName="trousers" />
                                                <label for="trousers">
                                                    {{ "VISITORS_DAILY_ENTRIES.TROUSERS" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="pull"
                                                        formControlName="pull" />
                                                <label for="pull">
                                                    {{ "VISITORS_DAILY_ENTRIES.PULL" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="jacket"
                                                        formControlName="jacket" />
                                                <label for="jacket">
                                                    {{ "VISITORS_DAILY_ENTRIES.JACKET" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="shoes"
                                                        formControlName="shoes" />
                                                <label for="shoes">
                                                    {{ "VISITORS_DAILY_ENTRIES.SHOES" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="scarf_hat_gloves"
                                                        formControlName="scarf_hat_gloves" />
                                                <label for="scarf_hat_gloves">
                                                    {{ "VISITORS_DAILY_ENTRIES.SCARF_HAT_AND_GLOVES" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="blanket"
                                                        formControlName="blanket" />
                                                <label for="blanket">
                                                    {{ "VISITORS_DAILY_ENTRIES.BLANKET" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="other_clothes"
                                                        formControlName="other_clothes" />
                                                <label for="other_clothes">
                                                    {{ "VISITORS_DAILY_ENTRIES.OTHER" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>{{ "VISITORS_DAILY_ENTRIES.INSTRUCTION" | translate }}</legend>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="instruction_id">
                                        {{ "VISITORS_DAILY_ENTRIES.INSTRUCTION" | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <app-ng-select-add-multiple
                                                        [choiceGroup]="choiceGroupInstruction"
                                                        [items]="choiceGroupInstruction?.choices"
                                                        [id]="'instruction_id'"
                                                        [selectedId]="visitorForm?.value?.instruction_id"
                                                        (addChoicesEvent)="addChoices('instruction', $event)"
                                                        (changeEvent)="changeChoice('instruction_id', $event)"></app-ng-select-add-multiple>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="instruction_paper_id">
                                        {{ "VISITORS_DAILY_ENTRIES.INSTRUCTION_PAPER" | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <app-ng-select-add-multiple
                                                        [choiceGroup]="choiceGroupInstruction"
                                                        [items]="choiceGroupInstruction?.choices"
                                                        [id]="'instruction_paper_id'"
                                                        [selectedId]="visitorForm?.value?.instruction_paper_id"
                                                        (addChoicesEvent)="addChoices('instruction', $event)"
                                                        (changeEvent)="changeChoice('instruction_paper_id', $event)"></app-ng-select-add-multiple>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>{{ "VISITORS_DAILY_ENTRIES.REMARK" | translate }}</legend>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="miscellaneous">
                                        {{ "VISITORS_DAILY_ENTRIES.REMARK" | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <textarea cols="30"
                                                rows="5"
                                                class="form-control input-sm"
                                                id="miscellaneous"
                                                formControlName="miscellaneous"></textarea>
                                </div>
                            </div>
                        </fieldset>

                        <ng-template *ngTemplateOutlet="btnAdd; context: {}"></ng-template>

                        <ng-template #btnAdd>
                            <div class="form-group">
                                <div class="col-md-12">
                                    <button type="submit"
                                            class="btn"
                                            [ngClass]="{'btn-default':isSubmitting, 'btn-success': !isSubmitting}"                                                        
                                            [disabled]="!aForm.form.valid || isSubmitting || wrongDate">
                                        <ng-container *ngIf="cud == 'add'">
                                            <i class="fa"
                                                [ngClass]="{'fa-plus': !isSubmitting,
                                                            'fa-spinner fa-spin': isSubmitting}"></i> {{ "SHARED.ADD" | translate }}
                                        </ng-container>
                                        <ng-container *ngIf="cud == 'edit'">
                                            <i class="fa"
                                                [ngClass]="{'fa-edit': !isSubmitting,
                                                            'fa-spinner fa-spin': isSubmitting}"></i> {{ "SHARED.EDIT" | translate }}
                                        </ng-container>
                                    </button>
                                </div>
                            </div>
                        </ng-template>
                    </form>
                </ng-container>
                <ng-container *ngIf="!hasData?.choiceGroupInstruction">
                    <i class="fa fa-exclamation-triangle text-warning mr-2"></i>
                    {{ "CHOICESNO_CHOICES_INSTRUCTION_FOUND" | translate }}
                </ng-container>
            </div>
        </div>
    </div>
</div>