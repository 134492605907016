<div aria-labelledby="addMeasureModalLabel" class="modal modal-big fade" id="addMeasureModal" role="dialog" tabindex="-1" style="display: none; z-index: 9999;">
    <div class="modal-dialog width-900" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
                <h4 class="modal-title">
                    <ng-container *ngIf="cud == 'add'">
                        {{ "VISITORS_MEDICAL.ADD_SETTING" | translate }}
                    </ng-container>
                    <ng-container *ngIf="cud == 'edit'">
                        {{ "VISITORS_MEDICAL.EDIT_SETTING" | translate }}
                    </ng-container>
                </h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="addForm" #aForm="ngForm" class="form-horizontal" (submit)="submit()">
                    <div class="form-group" *ngIf="!visitorId && cud == 'add'">
                        <div class="col-md-3">
                            <label class="control-label control-label-sm" for="visitor_id" >
                                {{ 'VISITORS_MEDICAL.VISITOR' | translate }} <span class="star">*</span>
                            </label>
                        </div>
                        <div class="col-md-9">
                            <ng-select class="ng-select-input-sm"
                                    id="visitor_id"
                                    formControlName="visitor_id" 
                                    bindValue="id"
                                    bindLabel="showName"
                                    [items]="visitors | async"
                                    [hideSelected]="true"
                                    [loading]="visitorsLoading"
                                    [typeahead]="visitorsInput"
                                    loadingText="{{ 'SHARED.LOADING' | translate }}"
                                    typeToSearchText="{{ 'FILTER.SEARCH_BY_UNIQUE_CODE_FIRSTNAME_LASTNAME' | translate }}"
                                    notFoundText="{{ 'VISITORS.NO_VISITOR_FOUND' | translate }}">
                                <ng-template ng-footer-tmp>
                                    <p class="add-choices pointer m-0 mt-2 mb-2">
                                        <a href="javascript:void(0)" class="text-primary" (click)="goToAddVisitorUrl()">
                                            {{ 'VISITORS.ADD_VISITOR' | translate }} <i class="fa fa-plus text-success"></i>
                                        </a>
                                    </p>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-md-3">
                            <label class="control-label control-label-sm" for="date">
                                {{ "VISITORS_MEDICAL.DATE" | translate }} <span class="star">*</span>
                            </label>
                        </div>
                        <div class="col-md-9">
                            <app-date-picker [datepickerConfig]="pickerConfig"
                                            [id]="'date'"
                                            [col]="null"
                                            [withHours]="true"
                                            (onDateChanged)="dateChanged($event, 'date')"
                                            #datePicker>
                            </app-date-picker>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-md-3">
                            <label class="control-label control-label-sm" for="blood_pressure">
                                {{ "VISITORS_MEDICAL.BLOOD_PRESSURE" | translate }}
                            </label>
                        </div>
                        <div class="col-md-9">
                            <input type="text"
                                    class="form-control input-sm"
                                    id="blood_pressure"
                                    formControlName="blood_pressure" />
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-md-3">
                            <label class="control-label control-label-sm" for="heartbeat">
                                {{ "VISITORS_MEDICAL.HEARTBEATS" | translate }}
                            </label>
                        </div>
                        <div class="col-md-9">
                            <input type="text"
                                    class="form-control input-sm"
                                    id="heartbeat"
                                    formControlName="heartbeat" />
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-md-3">
                            <label class="control-label control-label-sm" for="blood_sugar">
                                {{ "VISITORS_MEDICAL.BLOOD_SUGAR" | translate }}
                            </label>
                        </div>
                        <div class="col-md-9">
                            <input type="text"
                                    class="form-control input-sm"
                                    id="blood_sugar"
                                    formControlName="blood_sugar" />
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-md-3">
                            <label class="control-label control-label-sm" for="saturation">
                                {{ "VISITORS_MEDICAL.SATURATION" | translate }}
                            </label>
                        </div>
                        <div class="col-md-9">
                            <input type="text"
                                    class="form-control input-sm"
                                    id="saturation"
                                    formControlName="saturation" />
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-md-3">
                            <label class="control-label control-label-sm" for="temperature">
                                {{ "VISITORS_MEDICAL.TEMPERATURE" | translate }}
                            </label>
                        </div>
                        <div class="col-md-9">
                            <input type="text"
                                    class="form-control input-sm"
                                    id="temperature"
                                    formControlName="temperature" />
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-md-3">
                            <label class="control-label control-label-sm" for="weight">
                                {{ "VISITORS_MEDICAL.WEIGHT" | translate }}
                            </label>
                        </div>
                        <div class="col-md-9">
                            <input type="text"
                                    class="form-control input-sm"
                                    id="weight"
                                    formControlName="weight" />
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="col-md-12">
                            <button type="submit"
                                    class="btn"
                                    [ngClass]="{'btn-default':isSubmitting, 'btn-success': !isSubmitting}"                                                        
                                    [disabled]="!aForm.form.valid || isSubmitting || wrongDate">
                                <ng-container *ngIf="cud == 'add'">
                                    <i class="fa"
                                        [ngClass]="{'fa-plus': !isSubmitting,
                                                    'fa-spinner fa-spin': isSubmitting}"></i> {{ "SHARED.ADD" | translate }}
                                </ng-container>
                                <ng-container *ngIf="cud == 'edit'">
                                    <i class="fa"
                                        [ngClass]="{'fa-edit': !isSubmitting,
                                                    'fa-spinner fa-spin': isSubmitting}"></i> {{ "SHARED.EDIT" | translate }}
                                </ng-container>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>