import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
    providedIn: 'root'
})
export class VisitorMedicalStatService extends ApiService {
    getById(id: number) {
        return super.get('/visitors-medical-stat/' + id);
    }

    getByVisitors(data: any) {
        return super.post('/visitors-medical-stat/visitors', data);
    }

    create(data: any) {
        return super.post('/visitors-medical-stat/create', data);
    }

    update(data: any) {
        return super.put('/visitors-medical-stat/update', data);
    }

    delete(id: number) {
        return super.delete('/visitors-medical-stat/' + id);
    }
}
