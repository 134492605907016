import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
})
export class PaginationComponent implements OnInit {
    @Input() data: any;
    @Input() color: string = 'primary';
    @Input() size: string = 'md';
    @Input() full: boolean = false;

    @Output() switchPage: EventEmitter<number> = new EventEmitter();

    ngOnInit() {
        this.full = (this.full != undefined && this.full !== false);

        if (this.data) {
            this.data.current_page = parseInt(this.data.current_page);
            this.data.last_page = parseInt(this.data.last_page);

            if (this.current_page > this.last_page) {
                this.switch(this.last_page);
            }
        }
    }

    get current_page() {
        return this.data.current_page;
    }

    get last_page() {
        return this.data.last_page;
    }

    switch(page: number) {
        this.switchPage.emit(page);
    }
}
