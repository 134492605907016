import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WebcamImage } from 'ngx-webcam';
import { Subject } from 'rxjs';
import { MiscService } from '@app/_services';

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-camera-modal',
    templateUrl: './camera-modal.component.html',
})
export class CameraModalComponent implements OnInit {

    @Input() forId: string = this.miscService.uuid();
    @Output() onSave: EventEmitter<string|File> = new EventEmitter<string|File>();
    @Input() toFile: boolean = false;

    trigger: Subject<void> = new Subject<void>();
    showCamera: boolean = false;

    constructor(
        private miscService: MiscService,
    ) {
    }

    ngOnInit() {
        this.toFile = this.toFile != undefined && this.toFile !== false;
    }

    open() {
        this.showCamera = true;
        $(`#${this.forId}`).appendTo('body').modal('show');
    }

    close() {
        $(`#${this.forId}`).modal('hide');
        this.showCamera = false;
    }

    save(picture: WebcamImage) {
        if (this.toFile) {
            const byteString = window.atob(picture.imageAsBase64);
            const arrayBuffer = new ArrayBuffer(byteString.length);
            const int8Array = new Uint8Array(arrayBuffer);

            for (let i = 0; i < byteString.length; i++) {
                int8Array[i] = byteString.charCodeAt(i);
            }

            const name = 'picture_' + moment().format('YYYYMMDDHHmmSS') + '.jpeg';
            const blob = new Blob([int8Array], { type: 'image/png' });
            this.onSave.emit(new File([blob], name, { type: 'image/jpeg' }));
        } else {
            this.onSave.emit(picture.imageAsDataUrl);
        }

        this.close();
    }

    triggerObservable() {
        return this.trigger.asObservable();
    }

    triggerCapture() {
        this.trigger.next();
    }
}
