
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService extends ApiService {

    public checkPermission(perm) {
        return super.post('/permission/check', perm).pipe(map((data:any) => data));
    }

  	public getUserPermissions(){
        return super.get('/permissions/user').pipe(map((data:any) => data));
  	}

  	public getAllRoles(safe = 1, params: any = null){
  		  let safeString = "";

    		if (safe == 0){
    			safeString = "/" + safe;
    		}

        return super.get('/roles' + safeString, params).pipe(map((data:any) => data));
  	}

    public getRolesWithTicketAssigns(data) {
        return this.post('/roles/ticket-assigns', data);
    }

    public getSettablePerms() {
        return super.get('/permissions/settable').pipe(map((data: any) => data));
    }

    public getSettableWithFuncPerms() {
        return super.get('/permissions/settableWithFunc').pipe(map((data: any) => data));
    }

    public getAllPerms() {
        return super.get('/permissions').pipe(map((data:any) => data));
    }

    public getRolePerms(roleid){
        return super.get('/roles/'+roleid+'/permissions').pipe(map((data:any) => data));
    }

    public givePermissionTo(data){
        return super.post('/roles/givePermission', data).pipe(map((data:any) => data));
    }

    public giveRolePermissions(data){
        return super.post('/roles/permissions', data).pipe(map((data:any) => data));
    }

    public createRole(data){
        return super.post('/roles/create', data).pipe(map((data:any) => data));
    }

    public updateRole(data){
        return super.put('/roles/update', data).pipe(map((data:any) => data));
    }

    public deleteRole(rolename){
        return super.delete('/roles/delete/' + rolename).pipe(map((data:any) => data));
    }

    public setDescription(data) {
        return super.post('/permissions/description', data).pipe(map((data:any) => data));
    }

    public setDescriptionGroups(data) {
        return super.post('/permissions/description/groups', data).pipe(map((data:any) => data));
    }

    public getAllPermsGroups(){
        return super.get('/permissions/groups').pipe(map((data:any) => data));
    }

    public getAllPermsEnv(id){
        return super.get('/permissions/groups/'+id).pipe(map((data:any) => data));
    }

    public giveGroupFunctionnality(data){
        return super.post('/permissions/give', data).pipe(map((data:any) => data));
    }

    public updateGroupsPermsEnv(data){
        return super.post('/permissions/update', data).pipe(map((data:any) => data));
    }

    public giveGroupBasic(data){
        return super.post('/permissions/give/basic', data).pipe(map((data:any) => data));
    }

    public createGroupPerms(data){
        return super.post('/permissions/add/group', data).pipe(map((data:any) => data));
    }

    public createPerms(data){
        return super.post('/permissions/add/perms', data).pipe(map((data:any) => data));
    }
}
