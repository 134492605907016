<ng-select class="ng-select-input-sm ng-select-multiple-footer-options"
        id="visitor_ids"
        bindValue="id"
        bindLabel="showName"
        [items]="visitors"
        [hideSelected]="true"
        [multiple]="multiple"
        [(ngModel)]="value"
        (search)="search($event.term)"
        (change)="change()"
        (clear)="reset()"
        (focus)="onFocus()"
        loadingText="{{ 'SHARED.LOADING' | translate }}"
        typeToSearchText="{{ 'FILTER.SEARCH_BY_UNIQUE_CODE_FIRSTNAME_LASTNAME' | translate }}"
        notFoundText="{{ 'VISITORS.NO_VISITOR_FOUND' | translate }}"
        #ngSelectVisitor>
    <ng-template ng-footer-tmp *ngIf="hasData?.visitors || canAddVisitors">
        <p class="add-choices pointer m-0 mt-2 mb-2" *ngIf="hasData?.visitors">
            <a href="javascript:;" class="text-success" (click)="nextPage()">
                {{ 'VISITORS.LOAD_MORE_VISITORS' | translate }} <i class="fa fa-plus"></i>
            </a>
        </p>
        <p class="add-choices pointer m-0 mt-2 mb-2" *ngIf="canAddVisitors">
            <a href="javascript:void(0)" class="text-primary" (click)="goToAddVisitorUrl()">
                {{ 'VISITORS.ADD_VISITOR' | translate }} <i class="fa fa-plus"></i>
            </a>
        </p>
    </ng-template>
</ng-select>