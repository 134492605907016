import { Component, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MiscService, VisitorMedicalStatService } from '@services/index';
import { Globals } from '@app/app.globals';

@Component({
    selector: 'app-util-visitor-parts-medical-stat-modals-add',
    templateUrl: './add.component.html',
})
export class UtilVisitorPartsMedicalStatModalsAddComponent implements OnInit {
    @Output() addEntryEvent = new EventEmitter<any>();

    isSubmitting = false;
    modal = "#addStatModal";
    cud = "add";
    pickerConfig: any;
    visitorMedicalStat: any;
    visitorId: number;
    addForm = this.fb.group({
        id: null,
        visitor_id: [null, Validators.required],
        date: [null, Validators.required],
        consultation: true,
        concertation: false,
        administrative: false,
        cm: false,
        network: false,
        followed_up_file: false,
        other: null
    });
    hasData = {
        visitorMedicalStat: false,
    };

    @ViewChild('datePicker') datePicker;

    constructor(private cdref: ChangeDetectorRef,
                private fb: UntypedFormBuilder,
                private miscService: MiscService,
                private visitorMedicalStatService: VisitorMedicalStatService,
                public globals: Globals) {}

    ngOnInit() {
        if(this.miscService.checkEnvPermission("cudVisitorMedicalStat")) {
            this.pickerConfig = JSON.parse(JSON.stringify(this.globals.pickerConfig));
        }
        this.cdref.detectChanges();
    }

    reset() {
        this.addForm.reset({ consultation: true });
        this.visitorId = null;
        this.cud = "add";
    }
    
    openModal(visitorId = null, visitorMedicalStat = null) {
        if(this.miscService.checkEnvPermission("cudVisitorMedicalStat")) {
            $(this.modal).modal('show');
            this.reset();
            this.visitorMedicalStat = visitorMedicalStat;
            this.hasData.visitorMedicalStat = this.miscService.checkDataLength(this.visitorMedicalStat);
            this.visitorId = visitorId;
            if(this.visitorId) {
                this.addForm.get('visitor_id').patchValue(visitorId);
            }
            this.resetDate();
            this.checkEdit();
        }
    }

    checkEdit() {
        if(this.hasData.visitorMedicalStat) {
            this.cud = "edit";
            this.addForm.patchValue(this.visitorMedicalStat);
            if(this.visitorMedicalStat.date) {
                this.datePicker.setDate(this.visitorMedicalStat.date);
            }
        }
    }

    goToAddVisitorUrl() {
        $(this.modal).modal('hide');
        this.miscService.goToUrlDelay(['/visitor/add']);
    }

    changeVisitor(value) {
        this.addForm.get('visitor_id').patchValue(value ? value : null);
    }

    dateChanged(date, field) {
        if(date) {
            this.addForm.get(field).patchValue(date);
        }
        else {
            this.resetField(field);
        }
	}

    resetDate() {
        this.datePicker.resetDateNow();
    }

    resetField(field) {
        this.addForm.get(field).reset();
    }

    submit() {
        this.isSubmitting = true;
        let data = this.addForm.value;
        if(this.cud == "add") {
            this.create(data);
        }
        else {
            this.update(data);
        }
    }

    create(data) {
        this.visitorMedicalStatService.create(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_MEDICAL_STAT_CREATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addEntryEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_MEDICAL_STAT_NOT_CREATED" });
        });
    }

    update(data) {
        this.visitorMedicalStatService.update(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_MEDICAL_STAT_UPDATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addEntryEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_MEDICAL_STAT_NOT_UPDATED" });
        });
    }
}
