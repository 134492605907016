import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchUser'
})

export class SearchUserPipe implements PipeTransform {
    transform(items: any[], term: string): any[] {

            if(!items) return [];
            if(!term) return items;

            term = term.toLowerCase();

            return term
                ? items.filter(item => {
                    var foundCompany = false;

                    if (item && item.companies && item.companies.length){
                        for (let company of item.companies){
                            if (company.name.toLowerCase().indexOf(term) !== -1){
                                foundCompany = true;
                            }
                        }
                    }

                    return  (item.title ? (item.title.toLowerCase().indexOf(term) !== -1) : 0) ||
                            (item.name ? (item.name.toLowerCase().indexOf(term) !== -1) : 0) ||
                            (item.firstname ? (item.firstname.toLowerCase().indexOf(term) !== -1) : 0) ||
                            (item.email ? (item.email.toLowerCase().indexOf(term) !== -1) : 0) ||
                            (item.homephone ? item.homephone.toLowerCase().indexOf(term) !== -1 : false) ||
                            (item.mobilephone ? item.mobilephone.toLowerCase().indexOf(term) !== -1 : false) ||
                            (item.identifier ? item.identifier.toLowerCase().indexOf(term) !== -1 : false) ||
                            (foundCompany);
                    }
                )
                : items;

     }
}

@Pipe({
  name: 'searchProject'
})

export class SearchProjectPipe implements PipeTransform {
    transform(items: any[], term: string): any[] {

            if(!items) return [];
            if(!term) return items;

            term = term.toLowerCase();

            return term
                ? items.filter(item => {
                    return  (item.name ? item.name.toLowerCase().indexOf(term) !== -1 : false) ||
                            (item.project_number ? item.project_number.toLowerCase().indexOf(term) !== -1 : false) ||
                            (item.company.name ? item.company.name.toLowerCase().indexOf(term) !== -1 : false)
                    }
                )
                : items;

     }
}

@Pipe({
  name: 'searchProjectWarehouse'
})
export class SearchProjectWarehousePipe implements PipeTransform {
    transform(items: any[], term: string): any[] {

            if(!items) return [];
            if(!term) return items;

            term = term.toLowerCase();

            return term
                ? items.filter(item => {
                    return  (item.pName ? item.pName.toLowerCase().indexOf(term) !== -1 : false) ||
                            (item.pNumber ? item.pNumber.toLowerCase().indexOf(term) !== -1 : false) ||
                            (item.cName ? item.cName.toLowerCase().indexOf(term) !== -1 : false)
                    }
                )
                : items;

     }
}


@Pipe({
  name: 'searchCompany'
})

export class SearchCompanyPipe implements PipeTransform {
    transform(items: any[], term: string): any[] {

            if(!items) return [];
            if(!term) return items;

            term = term.toLowerCase();

            return term
                ? items.filter(item => {
                    return  (item.enterprisenum ? item.enterprisenum.toLowerCase().indexOf(term) !== -1 : false) ||
                            (item.website ? item.website.toLowerCase().indexOf(term) !== -1 : false) ||
                            (item.name ? item.name.toLowerCase().indexOf(term) !== -1 : false) ||
                            (item.email ? item.email.toLowerCase().indexOf(term) !== -1 : false) ||
                            (item.homephone ? item.homephone.toLowerCase().indexOf(term) !== -1 : false) ||
                            (item.mobilephone ? item.mobilephone.toLowerCase().indexOf(term) !== -1 : false) ||
                            (item.faxphone ? item.faxphone.toLowerCase().indexOf(term) !== -1 : false) ||
                            (item.vat ? item.vat.toLowerCase().indexOf(term) !== -1 : false) ||
                            (item.name ? item.name.toLowerCase().indexOf(term) !== -1 : false) ||
                            (item.iban ? item.iban.toLowerCase().indexOf(term) !== -1 : false);
                    }
                )
                : items;

     }
}



@Pipe({
  name: 'searchStock'
})

export class SearchStockPipe implements PipeTransform {
    transform(items: any[], term: string): any[] {

            if(!items) return [];
            if(!term) return items;

            term = term.toLowerCase();

            return term
                ? items.filter(item => {
                    return  ((item.product.internal_number) ? item.product.internal_number.toLowerCase().indexOf(term) !== -1 : false) ||
                            ((item.product.product_number)  ? item.product.product_number.toLowerCase().indexOf(term)  !== -1 : false) ||
                            ((item.product.serial_number)   ? item.product.serial_number.toLowerCase().indexOf(term)   !== -1 : false) ||
                            ((item.product.label)           ? item.product.label.toLowerCase().indexOf(term)           !== -1 : false) ||
                            ((item.product.description)     ? item.product.description.toLowerCase().indexOf(term)     !== -1 : false) ||
                            ((item.product.article_type)    ? item.product.article_type.toLowerCase().indexOf(term)    !== -1 : false) ||
                            ((item.comment)                 ? item.comment.toLowerCase().indexOf(term)                 !== -1 : false);
                    }
                )
                : items;

     }
}


@Pipe({
  name: 'searchMainProduct'
})

export class SearchMainProductPipe implements PipeTransform {
    transform(items: any[], term: string): any[] {

            if(!items) return [];
            if(!term) return items;

            term = term.toLowerCase();
            return term
                ? items.filter(item => {
                    return  ((item.internal_number) ? item.internal_number.toLowerCase().indexOf(term) !== -1 : false) ||
                            ((item.product_number)  ? item.product_number.toLowerCase().indexOf(term)  !== -1 : false) ||
                            ((item.serial_number)   ? item.serial_number.toLowerCase().indexOf(term)   !== -1 : false) ||
                            ((item.label)           ? item.label.toLowerCase().indexOf(term)           !== -1 : false) ||
                            ((item.description)     ? item.description.toLowerCase().indexOf(term)     !== -1 : false) ||
                            ((item.article_type)    ? item.article_type.toLowerCase().indexOf(term)    !== -1 : false) ||
                            ((item.comment)                 ? item.comment.toLowerCase().indexOf(term)                 !== -1 : false);
                    }
                )
                : items;

     }
}



@Pipe({
  name: 'searchTicket'
})

export class SearchTicketPipe implements PipeTransform {
    transform(items: any[], term: string): any[] {

            if(!items) return [];
            if(!term) return items;

            term = term.toLowerCase();
            return term
                ? items.filter(item => {
                    return  ((item.description) ? item.description.toLowerCase().indexOf(term) !== -1 : false) ||
                            ((item.project)  ? item.project.company.name.toLowerCase().indexOf(term)  !== -1 : false) ||
                            ((item.project)   ? item.project.name.toLowerCase().indexOf(term)   !== -1 : false);
                    }
                )
                : items;

     }
}

@Pipe({
  name: 'searchLabor'
})

export class SearchLaborPipe implements PipeTransform {
    transform(items: any[], term: string): any[] {

            if(!items) return [];
            if(!term) return items;

            term = term.toLowerCase();
            return term
                ? items.filter(item => {
                    return  ((item.name) ? item.name.toLowerCase().indexOf(term) !== -1 : false) ||
                            ((item.hourly_price)  ? item.hourly_price.toLowerCase().indexOf(term)  !== -1 : false)
                    }
                )
                : items;

     }
}

@Pipe({
  name: 'searchIeProduct'
})

export class SearchIeProductPipe implements PipeTransform {
    transform(items: any[], term: string): any[] {

            if(!items) return [];
            if(!term) return items;

            term = term.toLowerCase();
            return term
                ? items.filter(item => {
                    return  ((item.consignement_ref) ? item.consignement_ref.toLowerCase().indexOf(term) !== -1 : false) ||
                            ((item.description)  ? item.description.toLowerCase().indexOf(term)  !== -1 : false) ||
                            ((item.ie_bag.bag_number)  ? item.ie_bag.bag_number.toLowerCase().indexOf(term)  !== -1 : false)
                    }
                )
                : items;

     }
}

@Pipe({
  name: 'searchPallet'
})

export class SearchPalletPipe implements PipeTransform {
    transform(items: any[], term: string): any[] {

            if(!items) return [];
            if(!term) return items;

            term = term.toLowerCase();
            return term
                ? items.filter(item => {
                    return  ((item.identifier) ? item.identifier.toLowerCase().indexOf(term) !== -1 : false) ||
                            ((item.area) ? item.area.name.toLowerCase().indexOf(term)  !== -1 : false)
                    }
                )
                : items;

     }
}

@Pipe({
  name: 'searchImport'
})

export class SearchImportPipe implements PipeTransform {
    transform(items: any[], term: string): any[] {

            if(!items) return [];
            if(!term) return items;

            term = term.toLowerCase();
            return term
                ? items.filter(item => {
                    let files = item.files;
                    let filesCheck = false;
                    if(files.length) {
                        files.forEach(f => {
                            if(f.toLowerCase().indexOf(term) !== -1) {
                                filesCheck = true;
                            }
                        });
                    }
                    return  ((item.company_name) ? item.company_name.toLowerCase().indexOf(term) !== -1 : false) ||
                            ((item.project_name) ? item.project_name.toLowerCase().indexOf(term)  !== -1 : false) ||
                            ((item.type_ie_name) ? item.type_ie_name.toLowerCase().indexOf(term)  !== -1 : false) ||
                            filesCheck
                    }
                )
                : items;

     }
}
