import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MiscService, StatisticsService } from '@services/index';

@Component({
  selector: 'app-util-statistics-list',
  templateUrl: './list.component.html'
})
export class UtilStatisticsListComponent implements OnInit {
    @Input() type: string;
    @Output() changeFilterEvent = new EventEmitter<any>();

    isLoading = false;
    statistics: any;
    filter: any;
    /**
     *  number lines from different visitors
     *      - different_visitors
     *      - dv_women
     *      - dv_men
     *      - dv_other_sexual_identity
     *      - dv_middle_age
     *      - dv_medical_psycho
     *      - dv_medical_psycho_infirmary
     *      - dv_first_detection
     **/
    nbrBasicLines = 8;
    lengths = {
        dvAgeRanges: 0,
        dvIncomes: 0,
        dvContinents: 0,
        dvMaritalStatus: 0,
        dvOrientedBy: 0,
        ieParasites: 0,
        vpeHelpTypes: 0
    };

    constructor(private miscService: MiscService,
                private statisticsService: StatisticsService) {}

    ngOnInit() {}

    objectKeys(obj) {
        return Object.keys(obj);
    }

    filterStatistics(filter) {
        this.filter = filter;
        this.getStatistics();
        this.changeFilterEvent.emit(this.filter);
    }

    changedDate(date) {
        this.changeFilterEvent.emit({ date: date, type: this.type });
    }

    getStatistics() {
        this.isLoading = true;
        let data = this.filter;
        this.statisticsService.getAll(data).subscribe(data => {
            this.statistics = data;
            this.getLengths();
            this.isLoading = false;
        });
    }

    getLengths() {
        this.lengths.dvAgeRanges = this.miscService.checkDataLength(this.statistics.dv_age_ranges) ? Object.keys(this.statistics.dv_age_ranges).length : 0;
        this.lengths.dvIncomes = this.miscService.checkDataLength(this.statistics.dv_incomes) ?Object.keys(this.statistics.dv_incomes).length : 0;
        this.lengths.dvContinents = this.miscService.checkDataLength(this.statistics.dv_continents) ?Object.keys(this.statistics.dv_continents).length : 0;
        this.lengths.dvMaritalStatus = this.miscService.checkDataLength(this.statistics.dv_marital_status) ?Object.keys(this.statistics.dv_marital_status).length : 0;
        this.lengths.dvOrientedBy = this.miscService.checkDataLength(this.statistics.dv_oriented_by) ?Object.keys(this.statistics.dv_oriented_by).length : 0;
        this.lengths.ieParasites = this.miscService.checkDataLength(this.statistics.ie_parasites) ?Object.keys(this.statistics.ie_parasites).length : 0;
        this.lengths.vpeHelpTypes = this.miscService.checkDataLength(this.statistics.vpe_help_types) ?Object.keys(this.statistics.vpe_help_types).length : 0;
    }
}
