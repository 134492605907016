import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appTooltip]',
})
export class TooltipDirective {
    @HostListener('mouseover', ['$event'])
    mouseover(event) {
        $(event.srcElement).tooltip('show');
    }
}
