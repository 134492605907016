import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-icon-check-close',
    templateUrl: './check-close.component.html'
})
export class IconCheckCloseComponent implements OnInit {
    @Input() value = false;
    @Input() moreClasses = "";
    @Input() text = "";
    @Input() title = "";
    @Input() showTitle = false;

    ngOnInit() {}
}
