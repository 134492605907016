
<h5 class="title mb-3">
    {{ 'VISITORS.SEARCH_VISITORS' | translate }}
    <i class="fa pointer text-primary" 
        [ngClass]="{'fa-angle-double-down': !showFilters,
                    'fa-angle-double-up': showFilters}"
        (click)="toggleShowFilters()"></i>
</h5>
<form [formGroup]="searchForm" (ngSubmit)="submit()" #sForm="ngForm" autocomplete="off" [hidden]="!showFilters">
    <div class="row">
        <div class="form-group">
            <div class="col-md-3">
                <label class="control-label control-label-sm" for="number">
                    {{ 'FILTER.UNIQUE_CODE' | translate }}
                </label>
            </div>
            <div class="col-md-9">
                <input type="text"
                        id="number"
                        class="form-control input-sm"
                        placeholder="{{ 'FILTER.SEARCH_BY_UNIQUE_CODE' | translate }}"
                        formControlName="number" />
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="form-group">
            <div class="col-md-3">
                <label class="control-label control-label-sm" for="firstname">
                    {{ 'FILTER.FIRSTNAME' | translate }}
                </label>
            </div>
            <div class="col-md-9">
                <input type="text"
                        id="firstname"
                        class="form-control input-sm"
                        placeholder="{{ 'FILTER.SEARCH_BY_FIRSTNAME' | translate }}"
                        formControlName="firstname" />
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="form-group">
            <div class="col-md-3">
                <label class="control-label control-label-sm" for="lastname">
                    {{ 'FILTER.LASTNAME' | translate }}
                </label>
            </div>
            <div class="col-md-9">
                <input type="text"
                        id="lastname"
                        class="form-control input-sm"
                        placeholder="{{ 'FILTER.SEARCH_BY_LASTNAME' | translate }}"
                        formControlName="lastname" />
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="form-group">
            <div class="col-md-3">
                <label class="control-label control-label-sm" for="date_of_birth">
                    {{ 'FILTER.DATE_OF_BIRTH' | translate }}
                </label>
            </div>
            <div class="col-md-9">
                <app-date-picker [datepickerConfig]="datepickerConfig"
                                [col]="null"
                                [hasResetButton]="true"
                                (onDateChanged)="dateChanged($event, 'date_of_birth')"
                                #datePicker>
                </app-date-picker>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="form-group">
            <div class="col-md-3">
                <label class="control-label control-label-sm" for="suspended">
                    {{ 'VISITORS.CURRENTLY_SUSPENDED' | translate }}
                </label>
            </div>
            <div class="col-md-9">
                <select class="form-control input-sm"
                        id="suspended"
                        formControlName="suspended">
                    <option value="-1">-</option>
                    <option value="1">{{ 'SHARED.YES' | translate }}</option>
                    <option value="0">{{ 'SHARED.NO' | translate }}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="form-group">
            <div class="col-md-3">
                <label class="control-label control-label-sm" for="archived">
                    {{ 'VISITORS.ARCHIVED' | translate }}
                </label>
            </div>
            <div class="col-md-9">
                <select class="form-control input-sm"
                        id="archived"
                        formControlName="archived">
                    <option value="-1">-</option>
                    <option value="1">{{ 'SHARED.YES' | translate }}</option>
                    <option value="0">{{ 'SHARED.NO' | translate }}</option>
                </select>
            </div>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-md-12">
            <button type="submit"
                    class="btn btn-success btn-fill">
                <i class="fa fa-search"></i> {{ 'SHARED.SEARCH' | translate }}
            </button>
            <button type="button"
                    class="btn btn-danger btn-fill ml-3"
                    (click)="reset()"
                    *ngIf="filledForm">
                <i class="fa fa-close"></i> {{ 'SHARED.RESET' | translate }}
            </button>
        </div>
    </div>
</form>