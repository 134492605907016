import { ApiService } from '@services/api.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class VisitorAppointmentService extends ApiService {
    getByVisitors(data: any) {
        return super.post("/visitors-appointments/visitors", data);
    }

    getCountDayAndFuture(id: number) {
        return super.get("/visitors-appointments/count/day-future/" + id);
    }

    create(data) {
        return super.post("/visitors-appointments/create", data);
    }

    update(data) {
        return super.put("/visitors-appointments/update", data);
    }

    delete(id: number) {
        return super.delete("/visitors-appointments/" + id);
    }
}
