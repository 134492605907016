<div aria-labelledby="addInfirmaryEntryModalLabel" class="modal modal-big fade" id="addInfirmaryEntryModal" role="dialog" tabindex="-1" style="display: none; z-index: 9999;">
    <div class="modal-dialog width-900" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
                <h4 class="modal-title">
                    <ng-container *ngIf="cud == 'add'">
                        {{ "VISITORS_MEDICAL.ADD_ACT" | translate }}
                    </ng-container>
                    <ng-container *ngIf="cud == 'edit'">
                        {{ "VISITORS_MEDICAL.EDIT_ACT" | translate }}
                    </ng-container>
                </h4>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="!isLoading">
                    <form [formGroup]="addForm" #aForm="ngForm" class="form-horizontal" (submit)="submit()">
                        <fieldset>
                            <legend>{{ 'VISITORS.GENERAL_INFORMATION' | translate }}</legend>
                            <div class="form-group" *ngIf="!visitorId && cud == 'add'">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="visitor_id" >
                                        {{ 'VISITORS_MEDICAL.VISITOR' | translate }} <span class="star">*</span>
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <ng-select class="ng-select-input-sm"
                                            id="visitor_id"
                                            formControlName="visitor_id" 
                                            bindValue="id"
                                            bindLabel="showName"
                                            [items]="visitors | async"
                                            [hideSelected]="true"
                                            [loading]="visitorsLoading"
                                            [typeahead]="visitorsInput"
                                            loadingText="{{ 'SHARED.LOADING' | translate }}"
                                            typeToSearchText="{{ 'FILTER.SEARCH_BY_UNIQUE_CODE_FIRSTNAME_LASTNAME' | translate }}"
                                            notFoundText="{{ 'VISITORS.NO_VISITOR_FOUND' | translate }}"
                                            (change)="changeVisitor()">
                                        <ng-template ng-footer-tmp>
                                            <p class="add-choices pointer m-0 mt-2 mb-2">
                                                <a href="javascript:void(0)" class="text-primary" (click)="goToAddVisitorUrl()">
                                                    {{ 'VISITORS.ADD_VISITOR' | translate }} <i class="fa fa-plus text-success"></i>
                                                </a>
                                            </p>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="date">
                                        {{ "VISITORS_MEDICAL.DATE" | translate }} <span class="star">*</span>
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <app-date-picker [datepickerConfig]="pickerConfig"
                                                    [id]="'date'"
                                                    [col]="null"
                                                    [withHours]="true"
                                                    (onDateChanged)="dateChanged($event, 'date')"
                                                    #datePicker>
                                    </app-date-picker>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="country_id">
                                        {{ "VISITORS_MEDICAL.PARASITES" | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <ng-container *ngIf="!isLoadingParasites">
                                        <app-ng-select-add-multiple
                                                            [choiceGroup]="parasites"
                                                            [items]="parasites?.choices"
                                                            [id]="'parasite_ids'"
                                                            [selectedId]="addForm?.value?.parasite_ids"
                                                            [multiple]="true"
                                                            (addChoicesEvent)="addChoices('parasites', $event)"
                                                            (changeEvent)="changeChoice('parasite_ids', $event)"></app-ng-select-add-multiple>
                                    </ng-container>
                                    <ng-container *ngIf="isLoadingParasites">
                                        <app-loading-spinner></app-loading-spinner>
                                    </ng-container>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>{{ 'VISITORS.SERVICES' | translate }}</legend>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm">
                                        {{ "VISITORS_MEDICAL.INFORMATION" | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <div class="row m-0">
                                        <div class="col-md-4" style="padding-left: 6px;">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="shower_inf"
                                                        formControlName="shower_inf" />
                                                <label for="shower_inf">
                                                    {{ "VISITORS_MEDICAL.SHOWER_INF" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="footcare"
                                                        formControlName="footcare" />
                                                <label for="footcare">
                                                    {{ "VISITORS_MEDICAL.FOOTCARE" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="bandage"
                                                        formControlName="bandage" />
                                                <label for="bandage">
                                                    {{ "VISITORS_MEDICAL.BANDAGE" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="drugstore"
                                                        formControlName="drugstore" />
                                                <label for="drugstore">
                                                    {{ "VISITORS_MEDICAL.DRUGSTORE" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="psychological_treatment"
                                                        formControlName="psychological_treatment" />
                                                <label for="psychological_treatment">
                                                    {{ "VISITORS_MEDICAL.PSYCHOLOGICAL_TREATMENT" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="somatic_treatment"
                                                        formControlName="somatic_treatment" />
                                                <label for="somatic_treatment">
                                                    {{ "VISITORS_MEDICAL.SOMATIC_TREATMENT" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="pregnancy_test"
                                                        formControlName="pregnancy_test" />
                                                <label for="pregnancy_test">
                                                    {{ "VISITORS_MEDICAL.PREGNANCY_TEST" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset>
                            <legend>{{ 'VISITORS_MEDICAL.MISCELLANEOUS' | translate }}</legend>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm">
                                        {{ "VISITORS_MEDICAL.INFORMATION" | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <div class="row m-0">
                                        <div class="col-md-4" style="padding-left: 6px;">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="settings"
                                                        formControlName="settings" />
                                                <label for="settings">
                                                    {{ "VISITORS_MEDICAL.SETTINGS" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="maintenance"
                                                        formControlName="maintenance" />
                                                <label for="maintenance">
                                                    {{ "VISITORS_MEDICAL.MAINTENANCE" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="support"
                                                        formControlName="support" />
                                                <label for="support">
                                                    {{ "VISITORS_MEDICAL.SUPPORTS" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="procedures"
                                                        formControlName="procedures" />
                                                <label for="procedures">
                                                    {{ "VISITORS_MEDICAL.PROCEDURES" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="health_promotion"
                                                        formControlName="health_promotion" />
                                                <label for="health_promotion">
                                                    {{ "VISITORS_MEDICAL.HEALTH_PROMOTION" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="checkbox">
                                                <input type="checkbox"
                                                        class="form-control"
                                                        id="syringe_exchange"
                                                        formControlName="syringe_exchange" />
                                                <label for="syringe_exchange">
                                                    {{ "VISITORS_MEDICAL.SYRINGUE_EXCHANGE" | translate }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="country_id">
                                        {{ "VISITORS_MEDICAL.ORIENTATION" | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <ng-container *ngIf="!isLoadingOrientations">
                                        <app-ng-select-add-multiple
                                                            [choiceGroup]="orientations"
                                                            [items]="orientations?.choices"
                                                            [id]="'orientation_id'"
                                                            [selectedId]="addForm?.value?.orientation_id"
                                                            [multiple]="false"
                                                            (addChoicesEvent)="addChoices('orientations', $event)"
                                                            (changeEvent)="changeChoice('orientation_id', $event)"></app-ng-select-add-multiple>
                                    </ng-container>
                                    <ng-container *ngIf="isLoadingOrientations">
                                        <app-loading-spinner></app-loading-spinner>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-3">
                                    <label class="control-label control-label-sm" for="observations">
                                        {{ "VISITORS_MEDICAL.OBSERVATIONS" | translate }}
                                    </label>
                                </div>
                                <div class="col-md-9">
                                    <textarea cols="30"
                                            rows="5"
                                            class="form-control input-sm"
                                            id="observations"
                                            formControlName="observations"></textarea>
                                </div>
                            </div>
                        </fieldset>

                        <div class="form-group">
                            <div class="col-md-12">
                                <button type="submit"
                                        class="btn"
                                        [ngClass]="{'btn-default':isSubmitting, 'btn-success': !isSubmitting}"                                                        
                                        [disabled]="!aForm.form.valid || isSubmitting || wrongDate">
                                    <ng-container *ngIf="cud == 'add'">
                                        <i class="fa"
                                            [ngClass]="{'fa-plus': !isSubmitting,
                                                        'fa-spinner fa-spin': isSubmitting}"></i> {{ "SHARED.ADD" | translate }}
                                    </ng-container>
                                    <ng-container *ngIf="cud == 'edit'">
                                        <i class="fa"
                                            [ngClass]="{'fa-edit': !isSubmitting,
                                                        'fa-spinner fa-spin': isSubmitting}"></i> {{ "SHARED.EDIT" | translate }}
                                    </ng-container>
                                </button>
                            </div>
                        </div>
                    </form>
                </ng-container>
                <ng-container *ngIf="isLoading">
                    <app-loading-spinner></app-loading-spinner>
                </ng-container>
            </div>
        </div>
    </div>
</div>