import { NgModule } from '@angular/core';
import {
    SearchCompanyPipe,
    SearchIeProductPipe,
    SearchImportPipe,
    SearchLaborPipe,
    SearchMainProductPipe,
    SearchPalletPipe,
    SearchProjectPipe,
    SearchProjectWarehousePipe,
    SearchStockPipe,
    SearchTicketPipe,
    SearchUserPipe
} from '@pipes/filter.pipe';
import { AbsoluteValuePipe } from '@pipes/absolute-value.pipe';
import { DurationPipe } from '@pipes/duration.pipe';
import { DurationDaysPipe } from '@pipes/duration-days.pipe';
import { FilePipe } from '@pipes/file.pipe';
import { IfEmptyPipe } from '@pipes/if-empty.pipe';
import { LimitToPipe } from '@pipes/limit-to.pipe';
import { LocalizedDatePipe } from '@pipes/localized-date.pipe';
import { Nl2BrPipe } from '@pipes/nl2br.pipe';
import { PhonePipe } from '@pipes/phone.pipe';

const pipes = [
    AbsoluteValuePipe,
    DurationPipe,
    DurationDaysPipe,
    FilePipe,
    IfEmptyPipe,
    LimitToPipe,
    LocalizedDatePipe,
    Nl2BrPipe,
    PhonePipe,
    SearchCompanyPipe,
    SearchIeProductPipe,
    SearchImportPipe,
    SearchLaborPipe,
    SearchMainProductPipe,
    SearchPalletPipe,
    SearchProjectPipe,
    SearchProjectWarehousePipe,
    SearchStockPipe,
    SearchTicketPipe,
    SearchUserPipe
];

@NgModule({
    declarations: pipes,
    exports: pipes,
    providers: pipes
})
export class PipeModule {
}
