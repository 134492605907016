import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({
    providedIn: 'root'
})
export class VisitorTreatmentOtherService extends ApiService {
    getByVisitors(data: any) {
        return super.post('/visitors-treatment-other/visitors', data);
    }

    getCount(id: number) {
        return super.get("/visitors-treatment-other/count/" + id);
    }

    generatePdf(data: any) {
        return super.post('/visitors-treatment-other/pdf', data);
    }

    create(data: any) {
        return super.post('/visitors-treatment-other/create', data);
    }

    update(data: any) {
        return super.put('/visitors-treatment-other/update', data);
    }

    delete(id: number) {
        return super.delete('/visitors-treatment-other/' + id);
    }
}
